import {
  Paper,
  InputLabel,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
// import Checkbox from '@mui/material/Checkbox';
import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  CandidateCertificationModel,
  CandidateDetailsModel,
  CandidateEducationModel,
} from '../../../models/candidate-form.model';
import { useState, useEffect } from 'react';
import '../candidate.scss';
import SectionHeaderWithEdit from '../../common/section-heading';
import CandidateDataTable, { HeaderProps } from './candidate-data-table';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import dateFormat from 'dateformat';
import { isPermissionsWrite } from '../../../common/commonUtil';
import { MODULE, SubModuleTypes } from '../../../constants/common';
import { deleteCertification, deleteCandidateEducation } from '../../../service/candidateService';
import Notify from '../../common/notify';
import ConfirmationDialog from '../../common/confirmation-dialog';

interface Props {
  values: CandidateDetailsModel;
  sjdId: any;
  mode?: 'create-page' | 'details-page';
  next?: (value: CandidateDetailsModel) => void;
  previous?: () => void;
}

const CandidateEducationForm = ({
  values,
  sjdId,
  mode = 'create-page',
  next = () => {},
  previous = () => {},
}: Props) => {
  const candidateEducationSchema = Yup.object().shape({
    institution: Yup.string()
      .max(500, 'Institution Name should not exceed 500 characters')
      .required('Institution Name is required'),
    location: Yup.string().max(200, 'Location should not exceed 200 characters').nullable(),
    qualification: Yup.string()
      .max(200, 'Qualification should not exceed 200 characters')
      .nullable(),
    domain: Yup.string().max(200, 'Domain should not exceed 200 characters').nullable(),
  });
  const educationForm = useFormik({
    initialValues: {
      institution: '',
      qualification: '',
      domain: '',
      location: '',
      startDate: '',
      endDate: '',
    } as CandidateEducationModel,
    validationSchema: candidateEducationSchema,
    onSubmit: (values, { resetForm }) => {
      setEducationList([...educationList, values]);
      resetForm({
        values: {
          institution: '',
          qualification: '',
          domain: '',
          location: '',
          startDate: '',
          endDate: '',
        },
      });
      setEducationSubmitted(false);
      setShowEducationDialog(false);
      setEducationEditValue(undefined as unknown as CandidateEducationModel);
    },
  });
  const candidateCertificationSchema = Yup.object().shape({
    certificationName: Yup.string()
      .max(1000, 'Certification Name should not exceed 1000 characters')
      .required('Name is required'),
    certificationYear: Yup.number()
      .typeError('Please enter a valid year')
      .test('len', 'Please enter a valid year', (val) => !val || val.toString().length === 4)
      .nullable()
      .transform((_, val) => (val ? Number(val) : null)),
    issuedBy: Yup.string().max(1000, 'Issued by should not exceed 1000 characters'),
  });
  const certificationForm = useFormik({
    initialValues: {
      certificationName: '',
      issuedBy: '',
      certificationYear: '',
    } as CandidateCertificationModel,
    validationSchema: candidateCertificationSchema,
    onSubmit: (values, { resetForm }) => {
      setCertificationList([...certificationList, values]);
      resetForm({
        values: {
          certificationName: '',
          issuedBy: '',
          certificationYear: '',
        },
      });
      setCertificationSubmitted(false);
      setShowCertificationDialog(false);
      setCertificationEditValue(undefined as unknown as CandidateCertificationModel);
    },
  });
  const [educationSubmitted, setEducationSubmitted] = useState(false);
  const [certificationSubmitted, setCertificationSubmitted] = useState(false);

  const [educationList, setEducationList] = useState([] as CandidateEducationModel[]);
  const [certificationList, setCertificationList] = useState([] as CandidateCertificationModel[]);

  const [eduObjectError, setEduObjectError] = useState(false);

  const [showEducationDialog, setShowEducationDialog] = useState(false);
  const [showCertificationDialog, setShowCertificationDialog] = useState(false);

  const [educationEditValue, setEducationEditValue] = useState(
    undefined as unknown as CandidateEducationModel,
  );
  const [certificationEditValue, setCertificationEditValue] = useState(
    undefined as unknown as CandidateCertificationModel,
  );

  const [educationDialogNotification, setEducationDialogNotification] = useState(false);
  const [educationId, setEducationId] = useState(0);
  const [showCertificationDeleteDialog, setShowCertificationDeleteDialog] = useState(false);
  const [certificationDeleteId, setCertificationDeleteId] = useState(-1);

  const [edit, setEdit] = useState(mode == 'create-page');

  type AlertColor = 'success' | 'info' | 'warning' | 'error';
  const [showApiResponseNotification, setShowApiResponseNotification] = useState(false);
  const [apiResponseNotification, setApiResponseNotification] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  useEffect(() => {
    if (values.educationDetails?.length) setEducationList(values.educationDetails);
    if (values.certificationDetails?.length) setCertificationList(values.certificationDetails);
  }, []);

  const getEducationError = (formik: FormikProps<any>, fieldName: string) => {
    if ((educationSubmitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  };

  const getCertificationError = (formik: FormikProps<any>, fieldName: string) => {
    if ((certificationSubmitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  };

  const handleNextClick = () => {
    if (educationList.length === 0) {
      setEduObjectError(true);
      return;
    }

    let isValid = true;
    setEduObjectError(false);

    educationList.forEach((edu) => {
      if (!candidateEducationSchema.isValidSync(edu)) {
        setEduObjectError(true);
        isValid = isValid && false;
      }
    });

    certificationList.forEach((cert) => {
      if (!candidateCertificationSchema.isValidSync(cert)) {
        isValid = isValid && false;
      }
    });

    if (isValid) {
      next({ educationDetails: educationList, certificationDetails: certificationList });
    }
  };

  const educationTableHeaders: HeaderProps[] = [
    {
      name: 'School / Institution *',
      key: 'institution',
    },
    {
      name: 'Degree',
      key: 'qualification',
    },
    {
      name: 'Major',
      key: 'domain',
    },
    {
      name: 'From',
      key: 'startDate',
      type: 'date',
      format: 'mm/yyyy',
    },
    {
      name: 'To',
      key: 'endDate',
      type: 'date',
      format: 'mm/yyyy',
    },
    {
      name: 'Location',
      key: 'location',
    },
  ];

  const certificationTableHeaders: HeaderProps[] = [
    {
      name: 'Certification Name',
      key: 'certificationName',
    },
    {
      name: 'Issued By',
      key: 'issuedBy',
    },
    {
      name: 'Year',
      key: 'certificationYear',
    },
  ];

  const handleEdit = (index: number) => {
    educationForm.setValues(educationList[index]);
    setEducationEditValue(educationList[index]);
    handleDelete(index);
    setShowEducationDialog(true);
  };

  const handleDelete = (index: number) => {
    const list = educationList;
    list.splice(index, 1);
    setEducationList([...list]);
  };

  const handleEducationDelete = (index: number) => {
    setEducationDialogNotification(false);
    const list = educationList;
    if (list[index].id == undefined) {
      setEducationDialogNotification(false);
      list.splice(index, 1);
      setEducationList([...list]);
    } else {
      deleteCandidateEducation({
        candidateId: values.id,
        sjdId: sjdId,
        idToBeDeleted: list[index].id,
      })
        .then(() => {
          setEducationDialogNotification(false);
          list.splice(index, 1);
          setEducationList([...list]);
          setApiResponseNotification({
            severity: 'success',
            msg: 'Candidate Education deleted successfully',
          });
          setShowApiResponseNotification(true);
        })
        .catch(() => {
          setApiResponseNotification({
            severity: 'error',
            msg: 'Something went wrong, please try again!',
          });
          setShowApiResponseNotification(true);
        });
    }
  };

  const handleCertificationEdit = (index: number) => {
    certificationForm.setValues(certificationList[index]);
    setCertificationEditValue(certificationList[index]);
    handleCertificationDelete(index);
    setShowCertificationDialog(true);
  };

  const handleCertificationDelete = (index: number) => {
    const list = certificationList;
    list.splice(index, 1);
    setCertificationList([...list]);
  };

  const handleDeleteCertificate = () => {
    const list = certificationList;
    setShowCertificationDeleteDialog(false);
    if (certificationList[certificationDeleteId].id == undefined) {
      list.splice(certificationDeleteId, 1);
      setCertificationList([...list]);
    } else {
      deleteCertification({
        candidateId: values.id,
        sjdId: sjdId,
        idToBeDeleted: list[certificationDeleteId].id,
      })
        .then(() => {
          list.splice(certificationDeleteId, 1);
          setCertificationList([...list]);
          setApiResponseNotification({
            severity: 'success',
            msg: 'Certification Deleted Successfully!',
          });
          setShowApiResponseNotification(true);
        })
        .catch(() => {
          setApiResponseNotification({
            severity: 'error',
            msg: 'Something went wrong. Please try later!',
          });
          setShowApiResponseNotification(true);
        });
    }
  };

  return (
    <div className='candidate'>
      <Paper sx={{ padding: [5, 2], boxShadow: 'none' }}>
        {mode === 'details-page' && (
          <>
            <SectionHeaderWithEdit
              text=''
              edit={edit}
              hideIcon={
                values.sjdCandidateInfo?.length == 0 ||
                !isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_EDUCATION)
              }
              click={() => {
                if (edit) {
                  next({
                    educationDetails: educationList,
                    certificationDetails: certificationList,
                  });
                }
                setEdit(!edit);
              }}
            />
            <SectionHeaderWithEdit text='Education' hideIcon={true} edit={true} click={() => {}} />
          </>
        )}
        {mode === 'create-page' && (
          <SectionHeaderWithEdit text='Education' hideIcon={true} edit={edit} click={() => {}} />
        )}
        {eduObjectError && (
          <div className='mt-0 form-err-msg'>Please fill the mandatory data in the table</div>
        )}
        <CandidateDataTable
          component={SubModuleTypes.CANDIDATE_EDUCATION}
          headers={educationTableHeaders}
          data={educationList}
          readonly={!edit}
          edit={handleEdit}
          remove={(index) => {
            setEducationId(index);
            setEducationDialogNotification(true);
          }}></CandidateDataTable>
        <Notify
          open={showApiResponseNotification}
          severity={apiResponseNotification.severity}
          onClose={() => setShowApiResponseNotification(false)}>
          <span>{apiResponseNotification.msg}</span>
        </Notify>
        {educationDialogNotification && (
          <ConfirmationDialog
            title='Confirm'
            content={`Are you sure you want to delete education "${educationList[educationId].institution}" ?`}
            onClose={() => setEducationDialogNotification(false)}
            btn1Label='Cancel'
            btn1Action={() => setEducationDialogNotification(false)}
            btn2Label='Delete'
            btn2Action={() => handleEducationDelete(educationId)}
          />
        )}
        {edit && (
          <div className='center-contents' style={{ marginBottom: '30px' }}>
            <Button
              className='default-btn'
              sx={{ marginLeft: 3 }}
              onClick={() => {
                setShowEducationDialog(true);
              }}>
              Add Education
            </Button>
          </div>
        )}
        {showEducationDialog && (
          <Dialog
            open={showEducationDialog}
            fullWidth={true}
            maxWidth='md'
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>Add Education</DialogTitle>
            <DialogContent>
              <form
                style={{ marginBottom: '10px' }}
                onSubmit={(values) => {
                  setEducationSubmitted(true);
                  educationForm.handleSubmit(values);
                }}>
                <div className='row'>
                  <div className='field'>
                    <InputLabel className='field-label' id='institution'>
                      School / Institution *
                    </InputLabel>
                    <TextField
                      value={educationForm.values.institution}
                      fullWidth
                      id='institution'
                      size='small'
                      name='institution'
                      onChange={educationForm.handleChange}
                      onBlur={educationForm.handleBlur}
                    />
                    <div className='mt-0 form-err-msg'>
                      {getEducationError(educationForm, 'institution')}
                    </div>
                  </div>
                  <div className='field'>
                    <InputLabel className='field-label' id='qualification'>
                      Degree
                    </InputLabel>
                    <TextField
                      value={educationForm.values.qualification}
                      fullWidth
                      id='qualification'
                      size='small'
                      name='qualification'
                      onChange={educationForm.handleChange}
                      onBlur={educationForm.handleBlur}
                    />
                    <div className='mt-0 form-err-msg'>
                      {getEducationError(educationForm, 'qualification')}
                    </div>
                  </div>
                  <div className='field'>
                    <InputLabel className='field-label' id='domain'>
                      Major
                    </InputLabel>
                    <TextField
                      value={educationForm.values.domain}
                      fullWidth
                      id='domain'
                      size='small'
                      name='domain'
                      onChange={educationForm.handleChange}
                      onBlur={educationForm.handleBlur}
                    />
                    <div className='mt-0 form-err-msg'>
                      {getEducationError(educationForm, 'domain')}
                    </div>
                  </div>
                  <div className='field'>
                    <InputLabel className='field-label' id='location'>
                      Location
                    </InputLabel>
                    <TextField
                      value={educationForm.values.location}
                      fullWidth
                      id='location'
                      size='small'
                      name='location'
                      onChange={educationForm.handleChange}
                      onBlur={educationForm.handleBlur}
                    />
                    <div className='mt-0 form-err-msg'>
                      {getEducationError(educationForm, 'location')}
                    </div>
                  </div>{' '}
                </div>
                <div className='row' style={{ marginTop: '20px' }}>
                  <div className='field'>
                    <InputLabel className='field-label' id='startDate'>
                      From
                    </InputLabel>
                    <DatePicker
                      className='date-picker'
                      format='MM/YYYY'
                      views={['year', 'month']}
                      value={
                        educationForm.values.startDate
                          ? dayjs(educationForm.values.startDate)
                          : null
                      }
                      onChange={(value) => {
                        educationForm.setFieldValue(
                          'startDate',
                          dateFormat(value?.toString(), 'isoDateTime'),
                          true,
                        );
                      }}
                    />
                    <div className='mt-0 form-err-msg'>
                      {getEducationError(educationForm, 'startDate')}
                    </div>
                  </div>
                  <div className='field'>
                    <InputLabel className='field-label' id='endDate'>
                      To
                    </InputLabel>
                    <DatePicker
                      className='date-picker'
                      format='MM/YYYY'
                      views={['year', 'month']}
                      value={dayjs(
                        educationForm.values.endDate ? educationForm.values.endDate : null,
                      )}
                      onChange={(value) => {
                        educationForm.setFieldValue(
                          'endDate',
                          dateFormat(value?.toString(), 'isoDateTime'),
                          true,
                        );
                      }}
                    />
                    <div className='mt-0 form-err-msg'>
                      {getEducationError(educationForm, 'endDate')}
                    </div>
                  </div>
                  <div className='field'>
                    {/* {edit && (
                      <>
                        <InputLabel className='field-label' id='currentlyAttending'>
                          Currently attending?
                        </InputLabel>
                        <Checkbox />
                      </>
                    )} */}
                  </div>
                  <div className='field'></div>
                </div>
              </form>
              <div className='center-contents' style={{ marginBottom: '30px' }}>
                <Button
                  className='default-btn'
                  sx={{ marginLeft: 3 }}
                  onClick={() => {
                    setEducationSubmitted(true);
                    educationForm.handleSubmit();
                    setEduObjectError(false);
                  }}>
                  Add
                </Button>
                <Button
                  className='default-btn'
                  sx={{ marginLeft: 3 }}
                  onClick={() => {
                    educationForm.resetForm();
                    setShowEducationDialog(false);
                    if (educationEditValue) {
                      setEducationList([...educationList, educationEditValue]);
                    }
                  }}>
                  Cancel
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {mode === 'create-page' && (
          <SectionHeaderWithEdit
            text='Certification'
            hideIcon={true}
            edit={edit}
            click={() => {}}
          />
        )}
        {mode === 'details-page' && (
          <SectionHeaderWithEdit
            text='Certification'
            hideIcon={true}
            edit={true}
            click={() => {}}
          />
        )}

        <CandidateDataTable
          component={SubModuleTypes.CANDIDATE_EDUCATION}
          headers={certificationTableHeaders}
          data={certificationList}
          edit={handleCertificationEdit}
          readonly={!edit}
          remove={(index) => {
            setCertificationDeleteId(index);
            setShowCertificationDeleteDialog(true);
          }}></CandidateDataTable>

        {showCertificationDeleteDialog && (
          <ConfirmationDialog
            onClose={() => setShowCertificationDeleteDialog(false)}
            title={'Confirm'}
            content={`Are you sure you want to delete this certification "${certificationList[certificationDeleteId].certificationName}" ?`}
            btn1Label={'Cancel'}
            btn1Action={() => setShowCertificationDeleteDialog(false)}
            btn2Label={'Delete'}
            btn2Action={handleDeleteCertificate}
          />
        )}

        {edit && (
          <div className='center-contents' style={{ marginBottom: '30px' }}>
            <Button
              className='default-btn'
              sx={{ marginLeft: 3 }}
              onClick={() => {
                setShowCertificationDialog(true);
              }}>
              Add Certification
            </Button>
          </div>
        )}
        {showCertificationDialog && (
          <Dialog
            open={showCertificationDialog}
            fullWidth={true}
            maxWidth='md'
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>Add Certification</DialogTitle>
            <DialogContent>
              <form
                style={{ marginBottom: '10px' }}
                onSubmit={(values) => {
                  setCertificationSubmitted(true);
                  certificationForm.handleSubmit(values);
                }}>
                <div className='row'>
                  <div className='field'>
                    <InputLabel className='field-label' id='certificationName'>
                      Certification Name *
                    </InputLabel>
                    <TextField
                      value={certificationForm.values.certificationName}
                      fullWidth
                      id='certificationName'
                      size='small'
                      name='certificationName'
                      onChange={certificationForm.handleChange}
                      onBlur={certificationForm.handleBlur}
                    />
                    <div className='mt-0 form-err-msg'>
                      {getCertificationError(certificationForm, 'certificationName')}
                    </div>
                  </div>
                  <div className='field'>
                    <InputLabel className='field-label' id='issuedBy'>
                      Issued By
                    </InputLabel>
                    <TextField
                      value={certificationForm.values.issuedBy}
                      fullWidth
                      id='issuedBy'
                      size='small'
                      name='issuedBy'
                      onChange={certificationForm.handleChange}
                      onBlur={certificationForm.handleBlur}
                    />
                    <div className='mt-0 form-err-msg'>
                      {getCertificationError(certificationForm, 'issuedBy')}
                    </div>
                  </div>
                  <div className='field'>
                    <InputLabel className='field-label' id='certificationYear'>
                      Year
                    </InputLabel>
                    <TextField
                      value={certificationForm.values.certificationYear}
                      fullWidth
                      id='certificationYear'
                      size='small'
                      name='certificationYear'
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\s/g, '');
                        certificationForm.handleChange({
                          target: { name: 'certificationYear', value: numericValue },
                        });
                      }}
                      onBlur={certificationForm.handleBlur}
                    />
                    <div className='mt-0 form-err-msg'>
                      {getCertificationError(certificationForm, 'certificationYear')}
                    </div>
                  </div>
                  <div className='field'></div>
                </div>
              </form>
              <div className='center-contents' style={{ marginBottom: '30px' }}>
                <Button
                  className='default-btn'
                  sx={{ marginLeft: 3 }}
                  onClick={() => {
                    setCertificationSubmitted(true);
                    certificationForm.handleSubmit();
                  }}>
                  Add
                </Button>
                <Button
                  className='default-btn'
                  sx={{ marginLeft: 3 }}
                  onClick={() => {
                    certificationForm.resetForm();
                    setShowCertificationDialog(false);
                    if (certificationEditValue) {
                      setCertificationList([...certificationList, certificationEditValue]);
                    }
                  }}>
                  Cancel
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </Paper>
      {mode === 'create-page' && (
        <div className='bottom-btn-block'>
          <Button sx={{ marginLeft: 3 }} onClick={previous} className='default-btn bg-btn'>
            Previous
          </Button>
          <Button
            sx={{ marginLeft: 3 }}
            onClick={() => {
              handleNextClick();
            }}
            className='default-btn bg-btn'>
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default CandidateEducationForm;
