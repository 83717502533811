import React, { useEffect, useState } from 'react';
import { getIdFromName, getNameFromId, isPermissionsWrite } from '../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';
import { getAllSjd, updateSjd } from '../../service/sjdService';
import TableComponent from '../common/table';
import PageHeader from '../common/page-header/page-header';
import useNavigate from './../../common/useNavigate';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Notify from '../common/notify';
import { MODULE, SJD_STATUS } from '../../constants/common';
import dateFormat from 'dateformat';
import ConfirmationDialog from '../common/confirmation-dialog';

export default function SourceJobDefinition() {
  const [navigate] = useNavigate();
  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [publish, setPublish] = useState(true);
  const handleOnHoldConfirmAction = () => {
    sjdActionPatch(selectedRow, dialog);
    setDialog('');
  };

  const headers = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'name',
      label: 'Job Definition',
    },
    {
      id: 'priority',
      label: 'Priority',
    },
    {
      id: 'client',
      label: 'Client',
    },
    {
      id: 'hiringManager',
      label: 'Hiring Manager',
    },
    {
      id: 'totalCandidates',
      label: 'Candidates',
    },
    {
      id: 'openPositions',
      label: 'Open Positions',
    },

    {
      id: 'createdBy',
      label: 'Created By',
    },

    {
      id: 'modifiedOn',
      label: 'Modified On',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'actions',
      label: 'Actions',
    },
  ];

  const sjdActions = [
    SJD_STATUS.IN_PROGRESS,
    SJD_STATUS.ON_HOLD,
    SJD_STATUS.COMPLETED,
    publish === false || publish === null ? 'Publish' : 'Unpublish',
    'Remove',
  ];

  useEffect(() => {
    fetchSJDs();
  }, []);
  const fetchSJDs = () => {
    setLoading(true);
    getAllSjd()
      .then((resp) => {
        const response: any[] = resp.data;
        const respObj: any[] = [];
        response.forEach((r) => {
          respObj.push({
            id: r.id,
            name: r.name,
            hiringManager: r.hiringManagers
              ? r.hiringManagers.map((h: any) => h.name).join(', ')
              : '',
            totalCandidates: r.totalCandidates,
            client: getNameFromId(r.clientId, LookupTypes.CLIENT),
            status: getNameFromId(r.sjdStatusId, LookupTypes.SJD_STATUS),
            priority: getNameFromId(r.priorityId, LookupTypes.PRIORITY),
            modifiedOn: dateFormat(new Date(r.modifiedOn), 'dd/mm/yyyy'),
            createdBy: `${r.createdBy.firstName} ${r.createdBy.lastName}`,
            openPositions: `New: ${r.newOpenPositions} | Backfill: ${r.backfillOpenPositions}`,
            publish: r.publish,
          });
        });
        setData([...respObj]);
      })
      .catch((_err) => {})
      .finally(() => setLoading(false));
  };
  const sjdActionPatch = (sjd: any, action: string) => {
    setLoading(true);
    const patchObj: any = {};
    patchObj.id = sjd.id;
    if (action === 'Remove') patchObj.active = false;
    else if (action === 'Publish') patchObj.publish = true;
    else if (action === 'Unpublish') patchObj.publish = false;
    else patchObj.sjdStatusId = getIdFromName(action, LookupTypes.SJD_STATUS);

    updateSjd(patchObj).then((_resp) => {
      fetchSJDs();
    });
    setShowRemoveNotification(true);
  };
  const ActionMenu = ({ row }: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const closeUserMenu = () => {
      setAnchorEl(null);
    };
    const handleUserMenu = (_event: any) => {
      setPublish(row.publish);
      setAnchorEl(_event.currentTarget);
    };
    return (
      <>
        <div>
          <IconButton onClick={handleUserMenu}>
            <MoreHorizIcon sx={{ color: '#a72037' }} />
          </IconButton>
          <Menu
            id={`menu_${row.id}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeUserMenu}
            sx={{ top: '30px' }}
            keepMounted
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            {sjdActions.map((action, index) => (
              <MenuItem
                key={index}
                disabled={!isPermissionsWrite(MODULE.SJD) || row.status === action}
                sx={{ fontSize: '14px' }}
                onClick={() => {
                  setSelectedRow({ ...row });
                  setDialog(action);
                }}>
                {action}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </>
    );
  };

  const getActions = (row: any) => {
    return <ActionMenu row={row}></ActionMenu>;
  };
  const [showRemoveNotification, setShowRemoveNotification] = useState(false);
  return (
    <>
      <PageHeader title='Job Definition'></PageHeader>
      <div className='source-block'>
        <TableComponent
          loading={loading}
          headers={headers}
          rowData={data}
          isSjd={true}
          getActions={getActions}
          onAddClick={() =>
            navigate([
              {
                label: 'Add SJD',
                link: '/source-job-definition/create',
              },
            ])
          }
        />
      </div>
      {dialog && dialog.length > 0 && (
        <ConfirmationDialog
          title='Confirm'
          content={`Change the JD status to ${dialog}?`}
          onClose={() => setDialog('')}
          btn1Label='CANCEL'
          btn2Label='CONFIRM'
          btn1Action={() => setDialog('')}
          btn2Action={handleOnHoldConfirmAction}
        />
      )}
      <Notify
        severity='success'
        open={showRemoveNotification}
        onClose={() => setShowRemoveNotification(false)}>
        <span>Action completed successfully!</span>
      </Notify>
    </>
  );
}
