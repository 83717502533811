import React, { useEffect, useState } from 'react';
import './login.scss';
import ptglogo from './../assets/login/ptglogo.png';
import CheckIcon from './../assets/login/checkIcon.svg';
import {
  AlertColor,
  Backdrop,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { ReactComponent as Visibility } from './../assets/login/visibility.svg';
import { ReactComponent as VisibilityOff } from './../assets/login/visibility_off.svg';
import { ReactComponent as TadLogo } from '../assets/login/tad-logo.svg';
import tadLoader from './../assets/common-assets/Whole animation.gif';
import { useParams } from 'react-router-dom';

import * as Yup from 'yup';
import Notify from '../common/notify';
import TermsOfService from '../terms-and-policies/TermsOfService';
import PrivacyPolicy from '../terms-and-policies/PrivacyPolicy';
import useNavigate from '../../common/useNavigate';
import { resetPassword } from '../service/tadSecurityService';

const ForgotPassword = () => {
  let { username } = useParams();
  if (username) username = atob(username);
  const { token } = useParams();

  const signInSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(12, 'Password must be at least 12 characters long')
      .required('New Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'New password and confirm password must be the same')
      .required('Confirm password is required'),
  });

  const [navigate] = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: (values) => {
      handleResetPassoword(values);
    },
    validationSchema: signInSchema,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleOpenDialog = (content: any) => {
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  useEffect(() => {
    localStorage.setItem('token', token || '');
    localStorage.removeItem('breadcrumb-config');
  }, []);

  const handleResetPassoword = (values: any) => {
    setShowOverlay(true);
    resetPassword({
      username: username,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    })
      .then((_resp) => {
        // console.log('respnse:', resp.data);
        setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Password has been reset successfully!',
        });
        setShowOverlay(false);
        navigate([
          {
            label: 'Login',
            link: '/login',
          },
        ]);
        localStorage.removeItem('breadcrumb-config');
      })
      .catch((_err) => {
        setShowOverlay(false);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Failed to Reset Password.',
        });
      });
  };

  return (
    <div className='login-background'>
      <div className='login-container'>
        <div className='div-container'>
          <div className='left-login-div'>
            <div className='ptg-logo'>
              <img src={ptglogo} alt='peopletech-logo'></img>
            </div>
            <div className='text-container'>
              <div
                style={{
                  width: '20.5625rem',
                  color: '#fff',
                  fontSize: '2.0625rem',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  fontFamily: 'Roboto',
                  lineHeight: '1.5625rem',
                }}>
                Password Instructions
              </div>
              <Typography
                style={{
                  width: '21.3115rem',
                  height: '6.25rem',
                  color: '#fff',
                  fontSize: '1.1875rem',
                  fontWeight: '400',
                  lineHeight: '1.5625rem',
                }}>
                For your password to be up to the latest security standards, make sure to consider
                the following points when creating your password.
              </Typography>
              <div className='text-content'>
                <div className='text-icon-container'>
                  <div className='text-icon'>
                    <img className='login-icons' src={CheckIcon}></img>
                  </div>
                  <Typography
                    style={{
                      width: '19.8125rem',
                      height: '3.125rem',
                      color: '#fff',
                      fontSize: '1.1875rem',
                      fontWeight: '400',
                      lineHeight: '1.5625rem',
                    }}>
                    The password must consist of a minimum of 12 characters
                  </Typography>
                </div>
                <div className='text-icon-container'>
                  <div className='text-icon'>
                    {/* <img className='icons-bg-img' src={Ellipse} alt='ellipse' /> */}
                    <img className='login-icons' src={CheckIcon}></img>
                  </div>
                  <Typography
                    style={{
                      width: '19.8125rem',
                      height: '3.125rem',
                      color: '#fff',
                      fontSize: '1.1875rem',
                      fontWeight: '400',
                      lineHeight: '1.5625rem',
                    }}>
                    It should include one number, one CAPS letter as well as at least one special
                    character(!#$%^&*@?_)
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className='right-login-div'>
            <div className='sign-in-container' style={{ height: '27.875rem' }}>
              <TadLogo className='sign-in-logo' />
              <Typography className='sign-in-header' variant='h5' component='h1'>
                Reset Password!
              </Typography>
              <div className='sign-in-content'>
                <form onSubmit={formik.handleSubmit}>
                  <OutlinedInput
                    className={
                      formik.touched.newPassword && formik.errors.newPassword
                        ? 'sign-in-fields-error'
                        : 'sign-in-fields'
                    }
                    placeholder='New Password *'
                    type={showNewPassword ? 'text' : 'password'}
                    value={formik.values.newPassword}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='newPassword'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          size='small'
                          aria-label='toggle password visibility'
                          onClick={() => setShowNewPassword((show) => !show)}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'>
                          {showNewPassword ? (
                            <Visibility className='my-svg-icon' />
                          ) : (
                            <VisibilityOff className='my-svg-icon' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className='error-msg' style={{ width: '23rem', fontSize: '0.81rem' }}>
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                  <OutlinedInput
                    className={
                      formik.touched.confirmPassword && formik.errors.confirmPassword
                        ? 'sign-in-fields-error'
                        : 'sign-in-fields'
                    }
                    placeholder='Confirm Your Password *'
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={formik.values.confirmPassword}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='confirmPassword'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          size='small'
                          aria-label='toggle password visibility'
                          onClick={() => setShowConfirmPassword((show) => !show)}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'>
                          {showConfirmPassword ? (
                            <Visibility className='my-svg-icon' />
                          ) : (
                            <VisibilityOff className='my-svg-icon' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className='error-msg' style={{ width: '23rem', fontSize: '0.81rem' }}>
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                  <Button fullWidth type='submit' className='sign-in-button'>
                    Reset my password
                  </Button>
                </form>
                <div className='terms-conditions'>
                  By clicking continue, you agree to our{' '}
                  <Link
                    component='button'
                    onClick={() => handleOpenDialog('terms')}
                    sx={{ cursor: 'pointer' }}
                    color={'#fff'}
                    aria-label='Terms of Service'>
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link
                    component='button'
                    onClick={() => handleOpenDialog('privacy')}
                    sx={{ cursor: 'pointer' }}
                    color={'#fff'}
                    aria-label='Privacy Policy'>
                    Privacy Policy
                  </Link>
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth='xl'
                    PaperProps={{
                      sx: {
                        width: '44.3125rem',
                        height: '48rem',
                        margin: 'auto',
                        paddingBottom: '15px',
                      },
                    }}>
                    {dialogContent === 'terms' && (
                      <TermsOfService handleCloseDialog={handleCloseDialog} />
                    )}
                    {dialogContent === 'privacy' && (
                      <PrivacyPolicy handleCloseDialog={handleCloseDialog} />
                    )}
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='login-footer'>© 2024 People Tech Group all rights reserved</div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showOverlay}
        onClick={() => setShowOverlay(false)}>
        <img src={tadLoader} alt='Loading' />
      </Backdrop>
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={handleToastClose}
        message={toastMsg.msg}></Notify>
    </div>
  );
};

export default ForgotPassword;
