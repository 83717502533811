import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  DialogActions,
  DialogTitle,
  Select,
  MenuItem,
  Button,
  DialogContent,
  Dialog,
  InputLabel,
  TextField,
  Autocomplete,
  Slider,
  Typography,
  Paper,
  TableContainer,
  Card,
  TableSortLabel,
  Box,
  IconButton,
  TablePagination,
  Tab,
  Tabs,
  DialogContentText,
  Link,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  Menu,
  styled,
  AlertColor,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import * as Yup from 'yup';
import '../../components/candidate/candidate.scss';
import { useEffect, useState } from 'react';
import {
  deleteEmployeeCourseDetailsByManager,
  getAllUsersByManagerId,
  getInternByManager,
  getStatusCount,
  tagCoursesToIntern,
  updateInternByManager,
} from '../../service/tempInternService';
import Fade from '@mui/material/Fade';
import globalObject from '../../common/global-variables';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import SourceIcon from '@mui/icons-material/Source';
import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Form, useNavigate } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';
import exportIcon from '../../assets/csvexport.png';
import { getAllTechStack, getAllTechStackCoursesById } from '../../service/EmployeeService';
import { useFormik } from 'formik';
import AddCourse from './add-course';
import { getCertificationDetails } from '../../service/certificationService';
import { ArrowDropDown, Search } from '@mui/icons-material';
import { ReactComponent as CompletedIcon } from '../../assets/courses completed.svg';
import { ReactComponent as YetToStartIcon } from '../../assets/courses yet to start.svg';
import { ReactComponent as InProgressIcon } from '../../assets/courses in progress.svg';
import { ReactComponent as AssignCourse } from '../../assets/assign course.svg';
import CloseIcon from '@mui/icons-material/Close';
import dateFormat from 'dateformat';
import LmsReporting from './lms-Reporting';
import Notify from '../common/notify';
import DeleteIcon from '@mui/icons-material/Delete';
import { MODULE, SubModuleTypes } from '../../constants/common';
import { isPermissions, isPermissionsDelete, isPermissionsWrite } from '../../common/commonUtil';

interface Props {
  selectedType: any;
}

export interface HeaderProps {
  name: string;
  key: string;
  type?: 'date' | 'long-text' | undefined;
  format?: 'dd/mm/yyyy' | 'mm/yyyy' | 'dd/mm/yyyy hh:MM TT';
}

const LmsManagerDashboard = ({ selectedType }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [documentView, setDocumentView] = useState(false);
  const [documents, setDocuments] = useState([] as any[]);
  const [interns, setInterns] = useState([] as any[]);
  const [statusCompletedCount, setStatusCompletedCount] = useState(0);
  const [statusInProgressCount, setStatusInProgressCount] = useState(0);
  const [statusYetToStartCount, setStatusYetToStartCount] = useState(0);
  const [allInterns, setAllInterns] = useState([] as any[]);
  const [allCourses, setAllCourses] = useState([] as any[]);
  const [selectedEmployee, setSelectedEmployee] = useState({} as any);
  const [selectedInternId, setSelectedInternId] = useState(0);
  const [startDate, setStartDate] = useState(null as any as dayjs.Dayjs);
  const [endDate, setEndDate] = useState(null as any as dayjs.Dayjs);
  const [edit, setEdit] = useState(false);
  const [actualEndDate, setActualEndDate] = useState(null as any as dayjs.Dayjs);
  const [percentage, setPercentage] = useState(0);
  const [techStacks, setTechStacks] = useState([] as any[]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [submitted, setSubmitted] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  type subTabType = 'yet to start' | 'in progress' | 'completed';
  const selectedTabType: any = localStorage.getItem('selectedSubType')
    ? localStorage.getItem('selectedSubType')
    : 'yet to start';
  const [selectedSubType, setSelectedSubType] = useState<subTabType>(selectedTabType);
  const [statusSearch, setStatusSearch] = useState(selectedTabType);
  const [userName, setUserName] = useState('');
  const [documentsPage, setDocumentsPage] = useState(0);
  const [documentsRowsPerPage, setDocumentsRowsPerPage] = useState(5);
  const [certificationPage, setCertificationPage] = useState(0);
  const [certificationRowsPerPage, setCertificationRowsPerPage] = useState(5);
  const [coursePage, setCoursePage] = useState(0);
  const [courseRowsPerPage, setCourseRowsPerPage] = useState(5);
  const [certficationRows, setCertificationRows] = useState([] as any[]);
  const [courseSearchList, setCourseSearchList] = useState([] as any[]);
  const [filteredList, setFilteredList] = useState([] as any[]);
  const [filterSearchList, setFilterSearchList] = useState([] as any[]);
  const navigate = useNavigate();
  type Order = 'asc' | 'desc';
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof any>('');
  const [header, setHeader] = useState('');
  const [certificatedialogIsOpen, setCertificateDialogIsOpen] = useState(false);
  const [certificationlist, setCertificationList] = useState([] as any[]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showToast, setShowToast] = useState(false);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const [internSearch, setInternSearch] = useState('');
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  const handleToastClose = () => {
    setShowToast(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const managerSchema = Yup.object().shape({
    employee: Yup.string().required('Please select an employee'),
    techStack: Yup.string().required('Tech stack is required'),
    course: Yup.string().required('Please select a course'),
    startDate: Yup.date().required('Please select start date'),
    targetEndDate: Yup.date()
      .required('Please select target end date')
      .min(Yup.ref('targetEndDate'), 'Target end date should be greater or equal to start date'),
  });

  const formik = useFormik({
    initialValues: {
      employee: '',
      course: '',
      techStack: '',
      startDate: '',
      targetEndDate: '',
    },

    validationSchema: managerSchema,

    onSubmit: () => {
      tagInternCourses();
    },
    onReset: () => {},
  });

  const getError = (formik: any, fieldName: string) => {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  };
  const headers = [
    { id: 'userName', label: 'Employee' },
    { id: 'courseName', label: 'Course' },
    { id: 'startDate', label: 'Start Date' },
    { id: 'endDate', label: 'Target End Date' },
    { id: 'actualEndDate', label: 'Actual End Date' },
    { id: 'percentage', label: 'Completed %' },
    { id: 'status', label: 'Status' },
    { id: 'assignedBy', label: 'Assigned By' },
    { id: 'actions', label: 'Actions' },
  ];

  const handelInternSearch = (searchedVal: string, data?: any) => {
    setInternSearch(searchedVal);
    const filteredRows = (data ? data : filteredList).filter((row: any) => {
      return Object.keys(row)
        .filter((k) => k !== 'modifiedOn')
        .some((k) => {
          if (
            typeof row[k] === 'number' &&
            (k === 'percentage' || k === 'courseId' || k === 'managerId' || k === 'userId')
          ) {
            return String(row[k]).toLowerCase().includes(searchedVal.toLowerCase());
          } else if (
            typeof row[k] === 'number' &&
            (k === 'startDate' || k === 'endDate' || k === 'actualEndDate')
          ) {
            const dateStr = new Date(row[k]).toLocaleDateString();
            return dateStr.toLowerCase().includes(searchedVal.toLowerCase());
          } else if (row[k] === null) {
            return false;
          } else {
            return String(row[k]).toLowerCase().includes(searchedVal.toLowerCase());
          }
        });
    });
    setFilterSearchList(filteredRows);
    handleCourseChangePage(null, 0);
  };

  const handleTabChange = (newValue: subTabType) => {
    setInternSearch('');
    setStatusSearch(newValue);
    setSelectedSubType(newValue);
    setPage(0);
  };

  useEffect(() => {
    localStorage.setItem('selectedSubType', selectedSubType);
  }, [selectedSubType]);

  useEffect(() => {
    getLmsDashboardData();
  }, []);

  const getLmsDashboardData = () => {
    setLoading(true);
    localStorage.setItem('flag', 'false');
    getAllUsersByManagerId(globalObject.userObject.id).then((res) => {
      setAllInterns(res.data);
    });

    getStatusCount(globalObject.userObject.id).then((res) => {
      const internStatus = res.data;
      internStatus.forEach((countStatus: any) => {
        if (countStatus.status == 'completed') {
          setStatusCompletedCount(countStatus.count);
        }
        if (countStatus.status == 'in progress') {
          setStatusInProgressCount(countStatus.count);
        }
        if (countStatus.status == 'yet to start') {
          setStatusYetToStartCount(countStatus.count);
        }
      });
    });
    getInternByManager(globalObject.userObject.id).then((res) => {
      setInterns(res.data);
      setLoading(false);
      setFilteredList(
        res.data.filter((item: any) => item.status.toLowerCase().includes(selectedTabType)),
      );
      const data = res.data.filter((item: any) =>
        item.status.toLowerCase().includes(selectedTabType),
      );
      handelInternSearch(internSearch, data);
      // setFilterSearchList(
      //   res.data.filter((item: any) => item.status.toLowerCase().includes(selectedTabType)),
      // );
      setCourseSearchList(res.data);
    });
  };

  useEffect(() => {
    const statusFilterData = interns.filter((item: any) =>
      item.status.toLowerCase().includes(statusSearch.toLowerCase()),
    );
    setFilterSearchList(statusFilterData);
    setFilteredList(statusFilterData);
  }, [statusSearch]);

  const handleCertificateApi = (id: any) => {
    getCertificationDetails(id).then((res: any) => {
      setCertificationRows(res.data);
      setCertificationList(res.data);
    });
  };

  const tagInternCourses = () => {
    tagCoursesToIntern({
      courseId: formik.values.course,
      userId: formik.values.employee,
      managerId: globalObject.userObject.id,
      startDate: formik.values.startDate,
      endDate: formik.values.targetEndDate,
      status: 'yet to start',
      percentage: 0,
    })
      .then((_res) => {
        setToastMsg({
          severity: 'success',
          msg: 'Course assigned successfully.',
        });
        setShowToast(true);
        setDialogOpen(false);
        localStorage.setItem('flag', 'true');
        window.location.reload();
      })
      .catch((err) => {
        setToastMsg({
          severity: 'error',
          msg: `${err.response.data.message}`,
        });
        setShowToast(true);
      });
  };

  const updateEmployeeCourseDetails = (employee: any) => {
    updateInternByManager({
      internId: employee.userId,
      courseId: employee.courseId,
      startDate: startDate ? startDate : employee.startDate,
      targetEndDate: endDate ? endDate : employee.endDate,
      actualEndDate: actualEndDate ? actualEndDate : employee.actualEndDate,
      percentage: employee.actualEndDate
        ? 100
        : percentage > 0 && percentage <= 100
        ? percentage
        : 0,
      status:
        employee.actualEndDate || percentage === 100
          ? 'completed'
          : percentage > 0 && percentage < 100
          ? 'in progress'
          : 'yet to start',
    })
      .then((_resp) => {
        setToastMsg({
          severity: 'success',
          msg: 'Details updated successfully.',
        });
        setShowToast(true);
        setEdit(false);
        localStorage.setItem('selectedSubType', selectedSubType);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const handleDeleteData = () => {
    const obj = {
      internId: selectedEmployee.userId,
      courseId: selectedEmployee.courseId,
      managerId: selectedEmployee.managerId,
    };
    deleteEmployeeCourseDetailsByManager(obj)
      .then((_resp) => {
        setToastMsg({
          severity: 'success',
          msg: 'Details deleted successfully.',
        });
        setShowToast(true);
        getLmsDashboardData();
      })
      .catch((_error) => {
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
    setDeleteData(false);
  };

  const cardStyles = (isSelected: boolean) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexBasis: 'calc(100% / 3)',
    flexGrow: 1,
    color: 'black',
    backgroundColor: 'white',
    marginTop: '20px',
    borderRadius: '8px',
    border: isSelected ? '2px solid #a72037' : 'none',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
    transition: '0.3s',
  });

  const tabStyles = (isSelected: boolean) => ({
    height: '10px',
    backgroundColor: isSelected ? 'white' : '',
    borderBottom: !isSelected ? '1px solid black' : '',
    fontSize: 'small',
    fontWeight: 'bold',
    marginRight: '4px',
    color: isSelected ? '#a72037' : 'black',
    boxShadow: isSelected ? '0 4px 8px 0 rgba(0,0,0,0.1)' : '',
    borderRadius: isSelected ? '5px' : '',
  });

  function handleExport() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'User_Details';
    const excelDataCandidates: any[] = filteredList.map((c: any) => ({
      EmployeeName: c.userName,
      Course: c.courseName,
      'start date': dayjs(c.startDate).format('DD-MMM-YYYY'),
      'target end date': dayjs(c.endDate).format('DD-MMM-YYYY'),
      'actual end date': dayjs(c.actualEndDate).format('DD-MMM-YYYY'),
      Percentage: c.percentage,
      Status: c.status,
    }));
    const ws = XLSX.utils.json_to_sheet(excelDataCandidates);
    const range = XLSX.utils.decode_range(String(ws['!ref']));
    const columns = [2, 3, 4, 5];
    if (filteredList.length != 0) {
      ws['!cols'] = Array(Object.keys(excelDataCandidates[0]).length).fill({ wpx: 150 });
      for (let col = range.s.c; col <= range.e.c; col++) {
        const headerCellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        ws[headerCellAddress].s = {
          font: {
            bold: true,
          },
          alignment: {
            horizontal: 'center',
          },
        };
        for (let row = range.s.r + 1; row <= range.e.r; row++) {
          if (columns.includes(col)) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            ws[cellAddress].s = {
              alignment: {
                horizontal: 'center',
                vertical: 'center',
              },
            };
          }
        }
      }
    }
    const wb = {
      Sheets: { candidates: ws },
      SheetNames: ['candidates'],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const openAssignCourses = () => {
    navigate('/assign-courses');
  };

  const disablePastEndDate = (date: any) => {
    if (!formik.values.startDate) return false;
    return dayjs(date).isBefore(dayjs(formik.values.startDate));
  };

  const handleEmployeeTextStack = (newValue: any) => {
    getAllTechStack(newValue.id).then((res) => setTechStacks(res.data));
    setSelectedInternId(newValue ? newValue.id : 0);
    if (newValue) {
      formik.setFieldValue('employee', newValue.id);
    }
  };

  const sortInterns = (array: any[]) => {
    const flag = order === 'desc' ? -1 : 1;

    const sortByDate = (a: any, b: any, key: string) => {
      const dateA = new Date(a[key]).getTime();
      const dateB = new Date(b[key]).getTime();
      return dateA > dateB ? flag * 1 : dateB > dateA ? flag * -1 : 0;
    };

    if (orderBy === 'actualEndDate') {
      const allDatesNotNull = array.every(
        (row) => row['actualEndDate'] !== null && row['actualEndDate'] !== undefined,
      );
      if (!allDatesNotNull) {
        return array;
      }
      return array.sort((a: any, b: any) => sortByDate(a, b, 'actualEndDate'));
    }

    if (orderBy === 'startDate' || orderBy === 'endDate') {
      return array.sort((a, b) => sortByDate(a, b, orderBy));
    }

    if (orderBy === 'percentage') {
      const sortByNumber = (a: any, b: any, key: string) =>
        a[key] > b[key] ? flag * 1 : b[key] > a[key] ? flag * -1 : 0;
      return array.sort((a, b) => sortByNumber(a, b, orderBy));
    }

    return array.sort((a, b) =>
      a[orderBy]?.toLowerCase() > b[orderBy]?.toLowerCase()
        ? flag * 1
        : b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()
        ? flag * -1
        : 0,
    );
  };

  const handleRequestSort = (property: keyof any, _isAsc?: boolean) => {
    if (_isAsc !== undefined) setOrder(_isAsc ? 'asc' : 'desc');
    else {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
    }
    setOrderBy(property);
    setPage(0);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleCertificationChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCertificationRowsPerPage(parseInt(event.target.value, 10));
    setCertificationPage(0);
  };

  const handleCertificationChangePage = (event: unknown, newPage: number) => {
    setCertificationPage(newPage);
  };

  const handleDocumentChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentsRowsPerPage(parseInt(event.target.value, 10));
    setDocumentsPage(0);
  };

  const handleDocumentChangePage = (event: unknown, newPage: number) => {
    setDocumentsPage(newPage);
  };

  const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleCertificaterequestSearch = (searchedVal: string) => {
    const filteredRows = certificationlist.filter((row) => {
      return Object.keys(row).some((k) => {
        if (typeof row[k] === 'object' && row[k] !== null) {
          return Object.keys(row[k]).some((nestedKey) =>
            String(row[k][nestedKey]).toLowerCase().includes(searchedVal.toLowerCase()),
          );
        } else if (typeof row[k] === 'number' && (k === 'completeDate' || k === 'expiryDate')) {
          const dateStr = new Date(row[k]).toLocaleDateString();
          return dateStr.toLowerCase().includes(searchedVal.toLowerCase());
        } else {
          return String(row[k]).toLowerCase().includes(searchedVal.toLowerCase());
        }
      });
    });
    setCertificationRows(filteredRows);
    handleCertificationChangePage(null, 0);
  };

  const handleCourseChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCourseRowsPerPage(parseInt(event.target.value, 10));
    setCoursePage(0);
  };

  const handleCourseChangePage = (event: unknown, newPage: number) => {
    setCoursePage(newPage);
  };

  const documentHeaders: HeaderProps[] = [
    {
      name: 'Document Name',
      key: 'documentName',
    },
    {
      name: 'Uploaded By',
      key: 'createdByName',
    },
    {
      name: 'Uploaded On',
      key: 'createdOn',
      type: 'date',
      format: 'dd/mm/yyyy',
    },
  ];

  const handleCourserequestSearch = (searchedVal: string) => {
    const filteredRows = filteredList.filter((row: any) => {
      return Object.keys(row)
        .filter((k) => k !== 'modifiedOn')
        .some((k) => {
          if (
            typeof row[k] === 'number' &&
            (k === 'percentage' || k === 'courseId' || k === 'managerId' || k === 'userId')
          ) {
            return String(row[k]).toLowerCase().includes(searchedVal.toLowerCase());
          } else if (
            typeof row[k] === 'number' &&
            (k === 'startDate' || k === 'endDate' || k === 'actualEndDate')
          ) {
            const dateStr = new Date(row[k]).toLocaleDateString();
            return dateStr.toLowerCase().includes(searchedVal.toLowerCase());
          } else if (row[k] === null) {
            return false;
          } else {
            return String(row[k]).toLowerCase().includes(searchedVal.toLowerCase());
          }
        });
    });
    setCourseSearchList(filteredRows);
    handleCourseChangePage(null, 0);
  };
  useEffect(() => {}, [courseRowsPerPage]);
  useEffect(() => {}, [certificationRowsPerPage]);

  useEffect(() => {
    console.log('rowperPage:', rowsPerPage);
  }, [rowsPerPage]);

  return (
    <Grid sx={{ padding: '10px' }}>
      {selectedType === 'Dashboard' && isPermissions(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD) && (
        <Grid style={{ width: '100%' }}>
          <Grid item sx={{ display: 'flex', padding: '10px', gap: '2rem' }}>
            <Card style={cardStyles(statusSearch === 'completed')}>
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  flexDirection: 'column',
                  padding: '20px',
                }}>
                <Typography variant='h5' component='div'>
                  Courses Completed
                </Typography>
                <Typography variant='h3' component='div'>
                  {statusCompletedCount}
                </Typography>
              </div>
              <div style={{ alignContent: 'center' }}>
                <CompletedIcon style={{ padding: '20px' }} />
              </div>
            </Card>

            <Card style={cardStyles(statusSearch === 'in progress')}>
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  flexDirection: 'column',
                  padding: '20px',
                }}>
                <Typography variant='h5' component='div'>
                  Courses In Progress
                </Typography>
                <Typography variant='h3' component='div'>
                  {statusInProgressCount}
                </Typography>
              </div>
              <div style={{ alignContent: 'center' }}>
                <InProgressIcon style={{ padding: '20px' }} />
              </div>
            </Card>

            <Card style={cardStyles(statusSearch === 'yet to start')}>
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  flexDirection: 'column',
                  padding: '20px',
                }}>
                <Typography variant='h5' component='div'>
                  Courses Yet To Start
                </Typography>
                <Typography variant='h3' component='div'>
                  {statusYetToStartCount}
                </Typography>
              </div>
              <div style={{ alignContent: 'center' }}>
                <YetToStartIcon style={{ padding: '20px' }} />
              </div>
            </Card>
          </Grid>
          <div style={{ fontSize: 'large', fontWeight: 'bold', marginBottom: '10px' }}>
            <span>Courses</span>
          </div>
          <div>
            <Tabs
              className='sub-tabs'
              sx={{ borderBottom: '1px solid black' }}
              value={selectedSubType}
              onChange={(_e, v) => {
                handleTabChange(v);
              }}>
              <Tab
                className='sub-tab'
                style={tabStyles(selectedSubType == 'yet to start')}
                label={'YET TO START'}
                value={'yet to start'}
              />
              <Tab
                className='sub-tab'
                style={tabStyles(selectedSubType == 'in progress')}
                label={'IN PROGRESS'}
                value={'in progress'}
              />
              <Tab
                className='sub-tab'
                style={tabStyles(selectedSubType == 'completed')}
                label={'COMPLETED'}
                value={'completed'}
              />
            </Tabs>
          </div>
          <div
            style={{
              marginBottom: '-40px',
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '8px',
              marginTop: '5px',
              gap: '1rem',
              alignItems: 'center',
            }}>
            <div>
              <Tooltip title={'export to CSV'}>
                <IconButton
                  style={{
                    fontSize: 'small',
                    color: '#a72037',
                    borderRadius: '20px',
                    gap: '0.5rem',
                  }}
                  onClick={handleExport}>
                  <img src={exportIcon} />
                  <span style={{ fontWeight: 'bold' }}>Export CSV</span>
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <OutlinedInput
                id='table-search'
                type='text'
                placeholder='employee/course'
                className='search-bar'
                value={internSearch}
                sx={{ margin: '0', height: '30px', borderRadius: '10px', backgroundColor: 'white' }}
                onChange={(e) => {
                  handelInternSearch(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <Search />
                  </InputAdornment>
                }
              />
            </div>
            <div>
              <Button
                className='default-btn bg-btn'
                disabled={!isPermissionsWrite(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD)}
                style={{ height: '65%', display: 'flex', gap: '4px', textAlign: 'center' }}
                // style={{ maxWidth: '200px' }}
                onClick={handleClick}>
                <AssignCourse style={{ width: '15px', height: '15px', paddingRight: '4px' }} />
                <div>
                  <InputLabel sx={{ color: 'white' }}>Assign Course</InputLabel>
                </div>
                <ArrowDropDown />
              </Button>
              <Menu
                id='fade-menu'
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}>
                <MenuItem onClick={() => setDialogOpen(true)}>By Employee</MenuItem>
                <MenuItem onClick={openAssignCourses}>By Level</MenuItem>
              </Menu>
            </div>
          </div>
          <Form
            onSubmit={(values) => {
              setSubmitted(true);
              formik.handleSubmit(values);
            }}>
            <Dialog
              open={dialogOpen}
              maxWidth='sm'
              onClose={() => {
                setSelectedInternId(0);
                setStartDate(dayjs(''));
                setEndDate(dayjs(''));
                setDialogOpen(false);
              }}>
              <DialogTitle>Assign New Course</DialogTitle>
              <DialogContent>
                <div>
                  <InputLabel id='intern' className='field-label'>
                    Employee
                  </InputLabel>
                  <Autocomplete
                    id='intern'
                    fullWidth
                    size='small'
                    options={allInterns}
                    getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`}
                    value={allInterns.find((intern: any) => {
                      intern.id === selectedInternId;
                    })}
                    onChange={(_e, newValue: any) => {
                      handleEmployeeTextStack(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} placeholder='Search by name' />}
                    sx={{ marginBottom: '20px' }}
                  />
                  <div className='mt-0 form-err-msg' style={{ marginTop: '15px' }}>
                    {getError(formik, 'employee')}
                  </div>
                </div>

                <div style={{ width: '100%' }}>
                  <div style={{ padding: '0px 0px 10px 0px', width: '100%' }}>
                    <InputLabel className='field-label' id='tech-stack'>
                      Tech Stack
                    </InputLabel>
                    <div style={{ width: '100%' }}>
                      <Select
                        disabled={formik.values.employee === '' ? true : false}
                        id='techStack'
                        sx={{ width: '100%' }}
                        size='small'
                        error={techStacks.length == 0 ? true : false}
                        onChange={(e: any) => {
                          getAllTechStackCoursesById(e.target.value, selectedInternId).then((res) =>
                            setAllCourses(res.data),
                          );
                          formik.setFieldValue('techStack', e.target.value);
                          formik.setFieldValue('course', '');
                        }}>
                        {techStacks.map((courses1: any) => (
                          <MenuItem key={courses1.id} value={courses1.id}>
                            {courses1.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className='mt-0 form-err-msg' style={{ marginTop: '15px' }}>
                      {getError(formik, 'techStack')}
                    </div>
                  </div>
                  <div style={{ width: '100%' }}>
                    <InputLabel className='field-label' id='course'>
                      Course
                    </InputLabel>
                    <Select
                      disabled={formik.values.techStack === '' ? true : false}
                      id='course'
                      fullWidth
                      sx={{ marginBottom: '20px' }}
                      labelId='students-label'
                      value={formik.values.course}
                      onChange={(e: any) => {
                        formik.setFieldValue('course', e.target.value);
                      }}>
                      {allCourses.length == 0 && <MenuItem disabled>No Courses Found</MenuItem>}
                      {allCourses.map((courses: any) => (
                        <MenuItem key={courses.id} value={courses.id}>
                          {courses.courseName}
                        </MenuItem>
                      ))}
                    </Select>
                    <div
                      className='mt-0 form-err-msg'
                      style={{ marginTop: '-10px', marginBottom: '10px' }}>
                      {getError(formik, 'course')}
                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <InputLabel className='field-label' id='start-date'>
                      Start Date
                    </InputLabel>
                    <DatePicker
                      sx={{
                        marginRight: '10px',
                        marginBottom: '20px',
                        height: '20px',
                        width: '195px',
                      }}
                      disablePast
                      value={formik.values.startDate}
                      onChange={(value) => {
                        setStartDate(dayjs(value));
                        formik.setFieldValue(
                          'startDate',
                          dateFormat(value?.toString(), 'isoDateTime'),
                        );
                      }}
                    />
                    <div className='mt-0 form-err-msg' style={{ marginTop: '15px' }}>
                      {getError(formik, 'startDate')}
                    </div>
                  </div>
                  <div>
                    <InputLabel className='field-label' id='target-end-date'>
                      Target End Date
                    </InputLabel>
                    <DatePicker
                      shouldDisableDate={disablePastEndDate}
                      sx={{
                        marginRight: '10px',
                        marginBottom: '20px',
                        height: '20px',
                        width: '195px',
                      }}
                      value={formik.values.targetEndDate}
                      onChange={(value) => {
                        setEndDate(dayjs(value));
                        formik.setFieldValue(
                          'targetEndDate',
                          dateFormat(value?.toString(), 'isoDateTime'),
                        );
                      }}
                    />
                    <div className='mt-0 form-err-msg' style={{ marginTop: '15px' }}>
                      {getError(formik, 'targetEndDate')}
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '2rem',
                    alignItems: 'flex-end',
                    padding: '0px 24px 0px 24px',
                  }}>
                  <Button
                    sx={{ color: '#a72037' }}
                    onClick={() => {
                      setSelectedInternId(0);
                      setStartDate(dayjs());
                      setEndDate(dayjs());
                      setDialogOpen(false);
                      formik.resetForm();
                    }}>
                    Cancel
                  </Button>
                  <Button
                    className='default-btn bg-btn'
                    onClick={() => {
                      formik.handleSubmit();
                    }}>
                    Add
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          </Form>
          <Dialog
            open={edit}
            fullWidth={true}
            maxWidth='sm'
            onClose={() => {
              setSelectedInternId(0);
              setStartDate(dayjs(''));
              setEndDate(dayjs(''));
              setEdit(false);
            }}>
            <DialogTitle>Edit Details</DialogTitle>
            <DialogContent>
              <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10.3rem' }}>
                <div>
                  <InputLabel className='field-label'>Employee name</InputLabel>
                  <Typography>{selectedEmployee.userName}</Typography>
                </div>
                <div>
                  <InputLabel className='field-label'>Selected Course</InputLabel>
                  <Typography>{selectedEmployee.courseName}</Typography>
                </div>
              </div>
              <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <InputLabel className='field-label'>Start Date</InputLabel>
                  <DatePicker
                    sx={{ marginBottom: '20px' }}
                    disablePast
                    value={dayjs(selectedEmployee.startDate)}
                    onChange={(value) => setStartDate(dayjs(value))}
                  />
                </div>
                <div>
                  <InputLabel className='field-label'>Target End Date</InputLabel>
                  <DatePicker
                    sx={{ marginBottom: '20px' }}
                    disablePast
                    value={dayjs(selectedEmployee.endDate)}
                    onChange={(value) => setEndDate(dayjs(value))}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <InputLabel className='field-label'>Actual End Date</InputLabel>
                  <DatePicker
                    disabled={
                      selectedEmployee.endDate &&
                      dayjs(selectedEmployee.endDate)?.isBefore(dayjs(), 'day')
                        ? true
                        : false
                    }
                    minDate={dayjs(selectedEmployee.startDate)}
                    maxDate={dayjs(selectedEmployee.endDate)}
                    sx={{ marginBottom: '20px' }}
                    value={dayjs(selectedEmployee.actualEndDate)}
                    onChange={(value) => setActualEndDate(dayjs(value))}
                  />
                </div>
              </div>

              <DialogActions sx={{ justifyContent: 'center' }}>
                <Slider
                  disabled={
                    selectedEmployee.endDate &&
                    dayjs(selectedEmployee.endDate)?.isBefore(dayjs(), 'day')
                      ? true
                      : false
                  }
                  onChange={(_event, newValue) =>
                    setPercentage(Array.isArray(newValue) ? newValue[0] : newValue)
                  }
                  value={percentage}
                  style={{
                    width: '50%',
                    alignItems: 'center',
                    color:
                      selectedEmployee.endDate &&
                      dayjs(selectedEmployee.endDate)?.isBefore(dayjs(), 'day')
                        ? 'gray'
                        : '#c02424',
                  }}
                  aria-label='Default'
                  valueLabelDisplay='on'
                />
              </DialogActions>

              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                  onClick={() => updateEmployeeCourseDetails(selectedEmployee)}
                  style={{ backgroundColor: '#c02424', color: 'white' }}>
                  Save
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Dialog
            open={deleteData}
            onClose={() => {
              setDeleteData(false);
            }}>
            <DialogTitle>Are you sure you want to delete this data?</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  setDeleteData(false);
                }}
                sx={{ color: '#a72037' }}>
                No
              </Button>
              <Button sx={{ color: '#a72037' }} onClick={() => handleDeleteData()} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Grid>
            <TableContainer component={Paper} sx={{ marginTop: '50px' }}>
              <Table sx={{ minWidth: 650, margin: 'auto' }} aria-label='simple table' size='small'>
                <TableHead sx={{ background: 'rgb(224 224 224)' }}>
                  <TableRow>
                    {headers.map((headCell, index: any) => (
                      <TableCell key={index}>
                        <div style={{ display: 'flex' }}>
                          <div>{headCell.label}</div>
                          <div
                            onClick={() => handleRequestSort(headCell.id)}
                            onMouseEnter={() => {
                              setHeader(headCell.label);
                            }}
                            onMouseLeave={() => {
                              setHeader('');
                            }}>
                            {headCell.label != 'Actions' && headCell.label != 'Status' && (
                              <TableSortLabel
                                active={orderBy === headCell.id || header === headCell.label}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => handleRequestSort(headCell.id)}>
                                {orderBy === headCell.id ? (
                                  <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null}
                              </TableSortLabel>
                            )}
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ textAlign: 'start' }}>
                  {filterSearchList.length > 0 ? (
                    sortInterns(filterSearchList)
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((name: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Link
                              className='hyper-link'
                              onClick={() => {
                                setCertificateDialogIsOpen(true);
                                handleCertificateApi(name.userId);
                                setUserName(name.userName);
                              }}>
                              {name.userName}
                            </Link>
                          </TableCell>
                          <TableCell>{name.courseName}</TableCell>
                          <TableCell>{dayjs(name.startDate).format('DD-MMM-YYYY')}</TableCell>
                          <TableCell sx={{ paddingLeft: '30px' }}>
                            {dayjs(name.endDate).format('DD-MMM-YYYY')}
                          </TableCell>
                          <TableCell>
                            {name.actualEndDate && dayjs(name.actualEndDate).format('DD-MMM-YYYY')}
                          </TableCell>
                          <TableCell sx={{ paddingLeft: '50px' }}>{name.percentage}%</TableCell>
                          <TableCell>{name.status}</TableCell>
                          <TableCell>{name.assignedBy}</TableCell>
                          <TableCell>
                            <IconButton
                              disabled={
                                !isPermissionsWrite(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD) ||
                                name.status == 'completed'
                              }
                              onClick={() => {
                                setPercentage(name.percentage);
                                setSelectedEmployee(name);
                                setEdit(true);
                              }}
                              style={{
                                color: name.status === 'completed' ? 'gray' : '#a72037',
                              }}>
                              <EditIcon
                                className={
                                  name.status === 'completed' ||
                                  isPermissionsWrite(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD)
                                    ? 'gray'
                                    : 'delete-icon-disabled'
                                }
                              />
                            </IconButton>
                            <IconButton
                              disabled={
                                !isPermissionsDelete(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD)
                              }>
                              <DeleteIcon
                                onClick={() => {
                                  setSelectedEmployee(name);
                                  setDeleteData(true);
                                }}
                                className={
                                  isPermissionsDelete(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD)
                                    ? 'delete-icon'
                                    : 'delete-icon-disabled'
                                }
                              />
                            </IconButton>
                            <IconButton>
                              <Tooltip title='view documents'>
                                <SourceIcon
                                  onClick={() => {
                                    setDocuments(name.documentUrls);
                                    setDocumentView(true);
                                  }}
                                  sx={{ color: '#a72037' }}
                                />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>No records to display</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Dialog
                open={documentView}
                fullWidth={true}
                maxWidth='lg'
                sx={{ padding: '10px', margin: 'auto' }}
                onClose={() => {
                  setDocumentView(false);
                }}>
                <DialogTitle>Documents</DialogTitle>
                <DialogContent>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {documentHeaders.map((header, i) => (
                            <TableCell key={i}>{header.name}</TableCell>
                          ))}
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {documents.length > 0 ? (
                          documents
                            .slice(
                              documentsPage * documentsRowsPerPage,
                              documentsPage * documentsRowsPerPage + documentsRowsPerPage,
                            )
                            .map((document, i) => (
                              <TableRow key={i}>
                                <TableCell>{document.documentName}</TableCell>
                                <TableCell>{document.createdByName}</TableCell>
                                <TableCell>
                                  {document.createdOn &&
                                    dayjs(document.createdOn).format('DD-MMM-YYYY')}
                                </TableCell>
                                <TableCell>
                                  <IconButton>
                                    <a
                                      href={document.documentUrl}
                                      download='MyExampleDoc'
                                      rel='noreferrer'
                                      target='_blank'
                                      style={{ color: '#a72037' }}>
                                      <DownloadRoundedIcon />
                                    </a>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>No records to display</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {documents.length > 0 && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <TablePagination
                        sx={{ marginLeft: 'auto' }}
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={documents.length}
                        rowsPerPage={documentsRowsPerPage}
                        page={documentsPage}
                        onPageChange={handleDocumentChangePage}
                        onRowsPerPageChange={handleDocumentChangeRowsPerPage}
                        className='pagination'
                      />
                    </div>
                  )}
                </DialogContent>
              </Dialog>
              <Dialog
                open={certificatedialogIsOpen}
                fullWidth={true}
                maxWidth='lg'
                sx={{ padding: '10px', margin: 'auto' }}
                onClose={() => {
                  setCertificateDialogIsOpen(false);
                }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>{userName}</div>
                  <div>
                    <CloseIcon
                      onClick={() => setCertificateDialogIsOpen(false)}
                      sx={{ cursor: 'pointer' }}></CloseIcon>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <div>
                      <DialogContentText>All Certificates</DialogContentText>
                    </div>
                    {certificationlist.length > 0 && (
                      <div>
                        <OutlinedInput
                          id='table-search'
                          type='text'
                          placeholder='Search...'
                          className='search-bar'
                          onChange={(event) => handleCertificaterequestSearch(event.target.value)}
                          endAdornment={
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          }
                        />
                      </div>
                    )}
                  </div>
                  <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
                    <Table sx={{ margin: 'auto' }} aria-label='simple table' size='small'>
                      <TableHead sx={{ background: 'rgb(224 224 224)' }}>
                        <TableRow>
                          <TableCell>Certification Name</TableCell>
                          <TableCell>Certification Area</TableCell>
                          <TableCell>Certification Issue Date</TableCell>
                          <TableCell>Certification Expiry Date</TableCell>
                          <TableCell>Exam Code</TableCell>
                          <TableCell>Certificate Number</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {certficationRows.length > 0 ? (
                          certficationRows
                            .slice(
                              certificationPage * certificationRowsPerPage,
                              certificationPage * certificationRowsPerPage +
                                certificationRowsPerPage,
                            )
                            .map((cert: any) => (
                              <TableRow key={cert.id}>
                                <TableCell>{cert.certificationName}</TableCell>
                                <TableCell>
                                  {cert.certificationArea ? cert.certificationArea.name : 'N/A'}
                                </TableCell>
                                <TableCell>
                                  {cert.completeDate &&
                                    dayjs(cert.completeDate).format('DD-MMM-YYYY')}
                                </TableCell>
                                <TableCell>
                                  {cert.expiryDate && dayjs(cert.expiryDate).format('DD-MMM-YYYY')}
                                </TableCell>
                                <TableCell>{cert.examCode}</TableCell>
                                <TableCell>{cert.certificationNumber}</TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <StyledTableRow>
                            <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                              No certifications registered
                            </TableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {certficationRows.length > 0 && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <TablePagination
                        sx={{ marginLeft: 'auto' }}
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={certificationlist.length}
                        rowsPerPage={certificationRowsPerPage}
                        page={certificationPage}
                        onPageChange={handleCertificationChangePage}
                        onRowsPerPageChange={handleCertificationChangeRowsPerPage}
                        className='pagination'
                      />
                    </div>
                  )}

                  <div style={{ marginTop: '10px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <div>
                        <DialogContentText>All Courses</DialogContentText>
                      </div>
                      <div>
                        <OutlinedInput
                          id='table-search'
                          type='text'
                          placeholder='Search...'
                          className='search-bar'
                          onChange={(event) => handleCourserequestSearch(event.target.value)}
                          endAdornment={
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          }
                        />
                      </div>
                    </div>
                    <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
                      <Table sx={{ margin: 'auto' }} aria-label='simple table' size='small'>
                        <TableHead sx={{ background: 'rgb(224 224 224)' }}>
                          <TableRow>
                            {headers.map(
                              (headCell: any, index) =>
                                headCell.id != 'actions' && (
                                  <TableCell key={index}>{headCell.label}</TableCell>
                                ),
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {courseSearchList
                            .filter((e) => e.userName == userName)
                            .slice(
                              coursePage * courseRowsPerPage,
                              coursePage * courseRowsPerPage + courseRowsPerPage,
                            )
                            .map((name: any, index) => (
                              <TableRow key={index}>
                                <TableCell>{name.userName}</TableCell>
                                <TableCell>{name.courseName}</TableCell>
                                <TableCell>{dayjs(name.startDate).format('DD-MMM-YYYY')}</TableCell>
                                <TableCell sx={{ paddingLeft: '30px' }}>
                                  {dayjs(name.endDate).format('DD-MMM-YYYY')}
                                </TableCell>
                                <TableCell>
                                  {name.actualEndDate &&
                                    dayjs(name.actualEndDate).format('DD-MMM-YYYY')}
                                </TableCell>
                                <TableCell sx={{ paddingLeft: '50px' }}>
                                  {name.percentage}%
                                </TableCell>
                                <TableCell>{name.status}</TableCell>
                                <TableCell>{name.assignedBy}</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <TablePagination
                        sx={{ marginLeft: 'auto' }}
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredList.filter((e) => e.userName == userName).length}
                        rowsPerPage={courseRowsPerPage}
                        page={coursePage}
                        onPageChange={handleCourseChangePage}
                        onRowsPerPageChange={handleCourseChangeRowsPerPage}
                        className='pagination'
                      />
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <TablePagination
                sx={{ marginLeft: 'auto' }}
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={filterSearchList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='pagination'
              />
            </div>
          </Grid>
          <Notify open={showToast} severity={toastMsg.severity} onClose={handleToastClose}>
            <span>{toastMsg.msg}</span>
          </Notify>
        </Grid>
      )}
      {selectedType === 'Course Catalogue' &&
        isPermissions(MODULE.LMS, SubModuleTypes.COURSE_CATALOGUE) && <AddCourse />}
      {selectedType === 'Lms Report' && isPermissions(MODULE.LMS, SubModuleTypes.LMS_REPORT) && (
        <LmsReporting />
      )}
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </Grid>
  );
};

export default LmsManagerDashboard;
