import { useParams } from 'react-router';
import {
  Box,
  Paper,
  Backdrop,
  CircularProgress,
  AlertColor,
  Tabs,
  Tab,
  Tooltip,
  Link,
} from '@mui/material';
import './source-job-definition.scss';
import { useState, useRef, useEffect } from 'react';
import { getSjd, tagCandidateToSjd, updateSjd } from '../../service/sjdService';
import { getCandidatesForSjd } from '../../service/candidateService';
import SjdDetailsForm from './forms/sjd-details-form';
import SourcingStrategyForm from './forms/sourcing-strategy-form';
import HiringTeamForm from './forms/hiring-team-form';
import InterviewProcess from './forms/interview-process';
import SjdCandidateTable from './sjd-candidate-table';
import SjdActivity from './sjd-activity';
import PageHeader from '../common/page-header/page-header';
import useNavigate from './../../common/useNavigate';
import CandidateQuestionnaire from '../questionnaire/candidate-questionnaire';
import Notify from '../common/notify';
import SjdDetailsDashBoard from './sjd-details-dashboard';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import { isPermissions, isUserRole } from '../../common/commonUtil';
import { MODULE, ROLES, SubModuleTypes } from '../../constants/common';
import { getApplicantsForSjd } from '../../service/applicantService';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface Candidate {
  id: number;
  // Add other properties of the candidate object
}

const SourceJobDefinitionEdit = () => {
  let { sjdId } = useParams();
  if (sjdId) sjdId = atob(sjdId);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [sjd, setSjd] = useState({} as any);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [loading, setLoading] = useState(false);
  const [candidateLoading, setCandidateLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [summary, setSummary] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const reference: any = useRef<HTMLDivElement>(null);
  const menuItems = [
    { section: 'candidate-details', name: 'Candidates', subModule: SubModuleTypes.JD_CANDIDATES },
    { section: 'basic-details', name: 'SJD Details', subModule: SubModuleTypes.JD_SJD_DETAILS },
    { section: 'hiring-team', name: 'Hiring Team', subModule: SubModuleTypes.JD_HIRING_TEAM },
    {
      section: 'source-strategy',
      name: 'Sourcing Strategy',
      subModule: SubModuleTypes.JD_SOURCING_STRATEGY,
    },
    {
      section: 'interview-process',
      name: 'Interview Process',
      subModule: SubModuleTypes.JD_INTERVIEW_PROCESS,
    },
    { section: 'questionnaire', name: 'Questionnaire', subModule: SubModuleTypes.JD_QUESTIONNAIRE },
    { section: 'activity', name: 'Activity', subModule: SubModuleTypes.JD_ACTIVITY },
  ];

  const filteredMenuItems = menuItems.filter((menu) => isPermissions(MODULE.SJD, menu.subModule));
  const references: any[] = [];
  filteredMenuItems.forEach(() => references.push(useRef<HTMLDivElement>(null)));
  const [navigate] = useNavigate();
  const [applicants, setApplicants] = useState<Candidate[]>([]);

  const save = (values: any): Promise<boolean> => {
    const obj = { ...sjd, ...values, events: null };
    setSjd(obj);
    setLoading(true);
    updateSjd(obj)
      .then((_resp) => {
        setLoading(false);
        setToastMsg({
          severity: 'success',
          msg: 'Details updated successfully',
        });
        setShowToast(true);
        loadSjd();
        return new Promise((resolve, _reject) => {
          resolve(true);
        });
      })
      .catch((_err) => {
        setLoading(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
        return new Promise((_resolve, reject) => {
          reject(false);
        });
      });
    return new Promise((resolve, _reject) => {
      resolve(true);
    });
  };

  useEffect(() => {
    loadSjd();
    loadCandidates();
    loadApplicants();
  }, []);

  const loadSjd = () => {
    setLoading(true);
    getSjd(sjdId)
      .then((resp) => {
        setLoading(false);
        setSjd((_prev: any) => resp.data);
      })
      .catch((_err) => {
        setLoading(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const loadCandidates = () => {
    setCandidateLoading(true);
    getCandidatesForSjd(sjdId)
      .then((resp) => {
        setCandidateLoading(false);
        setCandidates([...resp.data]);
      })
      .catch((_err) => {
        setCandidateLoading(false);
        setToastMsg({
          severity: 'error',
          msg: 'Unable to fetch the candidate list',
        });
        setShowToast(true);
        setCandidates([]);
      });
  };

  const loadApplicants = () => {
    getApplicantsForSjd(sjdId)
      .then((resp) => {
        setApplicants(resp.data);
      })
      .catch((_error) => {
        setToastMsg({
          severity: 'error',
          msg: 'Unable to fetch the applicant list',
        });
        setShowToast(true);
        setApplicants([]);
      });
  };

  const tagCandidate = (resumeId: any): Promise<boolean> => {
    setLoading(true);
    tagCandidateToSjd({
      sjdId,
      resumeId,
    })
      .then((_resp) => {
        setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Candidate tagged to SJD successfully',
        });
        loadSjd();
        loadCandidates();
        return Promise.resolve(true);
      })
      .catch((_err) => {
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong!',
        });
        return Promise.reject(false);
      })
      .finally(() => setLoading(false));
    return Promise.resolve(true);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const elementPos = references[newValue].current.getBoundingClientRect().top;
    const tabsPos = document.getElementById('page-tabs')?.getBoundingClientRect().top;
    const offsetPos = elementPos + window.pageYOffset - (!tabsPos ? 0 : tabsPos + 5);
    window.scrollTo({
      top: offsetPos,
      behavior: 'smooth',
    });
    setSelectedIndex(newValue);
  };
  return (
    <>
      {sjd.id && !isUserRole(ROLES.EVALUATOR, true) && (
        <Box>
          <div
            style={{
              display: 'flex',
              position: 'sticky',
              top: 52,
              zIndex: 100,
              justifyContent: 'space-between',
            }}>
            <div style={{ width: '70%' }}>
              <PageHeader title='Job Definition Details'></PageHeader>
            </div>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                display: 'flex',
                width: '30%',
                justifyContent: 'right',
              }}>
              <Link
                sx={{ cursor: 'pointer', minwidth: '970px' }}
                onClick={() => (summary === true ? setSummary(false) : setSummary(true))}>
                {summary === true ? 'hide summary' : 'show summary'}
              </Link>

              {summary === true ? (
                <KeyboardArrowUpIcon fontSize='small' style={{ color: '#1976d2' }} />
              ) : (
                <KeyboardArrowDownIcon fontSize='small' style={{ color: '#1976d2' }} />
              )}
            </div>
          </div>
          <div style={{ position: 'sticky', top: 78, zIndex: 100 }}>
            {summary && (
              <SjdDetailsDashBoard sjd={sjd} candidates={candidates}></SjdDetailsDashBoard>
            )}

            <div className='page-tabs' id='page-tabs'>
              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={selectedIndex}
                  onChange={handleChange}
                  aria-label='secondary tabs example'>
                  {filteredMenuItems.map(
                    (menu, index) =>
                      isPermissions(MODULE.SJD, menu.subModule) && (
                        <Tab key={index} value={index} label={menu.name} />
                      ),
                  )}
                </Tabs>
              </Box>
            </div>
          </div>
          <div className='details-container' ref={reference}>
            <div className='details'>
              <Paper>
                {filteredMenuItems.map((item, index) => {
                  if (!isPermissions(MODULE.SJD, item.subModule)) return null;
                  const commonProps = {
                    className: `details-section ${item.section}`,
                    ref: references[index],
                    key: item.subModule,
                  };
                  switch (item.subModule) {
                    case SubModuleTypes.JD_CANDIDATES:
                      return (
                        <div {...commonProps} style={{ paddingTop: '5px' }}>
                          <SjdCandidateTable
                            candidates={candidates}
                            applicants={applicants}
                            sjd={sjd}
                            loading={candidateLoading}
                            setCandidates={setCandidates}
                            addCandidate={() =>
                              navigate([
                                {
                                  label: 'Add Candidate',
                                  link: `/source-job-definition/candidate/${btoa(sjd.id)}`,
                                },
                              ])
                            }
                            tagCandidate={tagCandidate}
                          />
                        </div>
                      );
                    case SubModuleTypes.JD_SJD_DETAILS:
                      return (
                        <div {...commonProps}>
                          <SjdDetailsForm values={sjd} save={save} mode='details-page' />
                        </div>
                      );
                    case SubModuleTypes.JD_HIRING_TEAM:
                      return (
                        <div {...commonProps}>
                          <HiringTeamForm values={sjd} save={save} mode='details-page' />
                        </div>
                      );
                    case SubModuleTypes.JD_SOURCING_STRATEGY:
                      return (
                        <div {...commonProps}>
                          <SourcingStrategyForm values={sjd} save={save} mode='details-page' />
                        </div>
                      );
                    case SubModuleTypes.JD_INTERVIEW_PROCESS:
                      return (
                        <div {...commonProps}>
                          <InterviewProcess values={sjd} save={save} mode='details-page' />
                        </div>
                      );
                    case SubModuleTypes.JD_QUESTIONNAIRE:
                      return (
                        <div {...commonProps}>
                          <CandidateQuestionnaire sjd={sjd} mode='sjd-page' />
                        </div>
                      );
                    case SubModuleTypes.JD_ACTIVITY:
                      return (
                        <div {...commonProps}>
                          <SjdActivity _value={sjd} />
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
                <div className='move-to-top'>
                  <div className='circle'>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        reference.current?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                          inline: 'nearest',
                        });
                        setTimeout(() => (window.location.href = '#root'), 10);
                      }}>
                      <Tooltip title='Move to top'>
                        <KeyboardArrowUpSharpIcon fontSize='large' />
                      </Tooltip>
                    </a>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </Box>
      )}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Notify open={showToast} severity={toastMsg.severity} onClose={() => setShowToast(false)}>
        <span>{toastMsg.msg}</span>
      </Notify>
    </>
  );
};

export default SourceJobDefinitionEdit;
