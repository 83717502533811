import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { getLocations } from '../../service/commonService';

interface PlaceType {
  label: string;
  value: string;
}
interface Prop {
  onSelect: (str?: string) => void;
  initialValue?: string;
}

export default function LocationAutoComplete({ onSelect, initialValue }: Prop) {
  const [value, setValue] = React.useState<PlaceType | null>(
    initialValue ? { label: initialValue, value: initialValue } : null,
  );
  const [inputValue, setInputValue] = React.useState(initialValue || '');
  const [options, setOptions] = React.useState<PlaceType[]>([]);

  const fetchLocations = React.useMemo(
    () =>
      debounce(async (query: string, callback: (results: PlaceType[]) => void) => {
        try {
          const response = await getLocations(query);
          const results = response.data.results
            .filter((item: any) => !!item.city) // Ensure city exists
            .map((item: any) => ({
              label: `${item.city}, ${item.country}`,
              value: `${item.city}, ${item.country}`,
            }))
            .filter(
              (option: PlaceType, index: any, self: any[]) =>
                index === self.findIndex((t: { label: string }) => t.label === option.label) && // Remove duplicates
                option.label.toLowerCase().startsWith(query.toLowerCase()), // Match input exactly
            );
          callback(results);
        } catch (error) {
          console.error('Error fetching locations:', error);
          callback([]);
        }
      }, 400),
    [],
  );

  React.useEffect(() => {
    if (inputValue) {
      fetchLocations(inputValue, setOptions);
    } else {
      setOptions([]);
    }
  }, [inputValue, fetchLocations]);

  return (
    <Autocomplete
      id='location-autocomplete'
      size='small'
      options={options}
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      noOptionsText='No locations'
      onChange={(event, newValue: PlaceType | null) => {
        setValue(newValue);
        onSelect(newValue?.value); // Call the callback with the selected value
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder='Start typing to search' label='' fullWidth />
      )}
    />
  );
}
