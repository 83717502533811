import React, { useEffect, useRef, useState } from 'react';
import './tad-candidate-details-page.scss';
import {
  AlertColor,
  Backdrop,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { getNameFromId, handleCandidateInitials } from '../../common/commonUtil';
import CandidateHistory from './add-candidate/candidate-history';
import CandidateJobDefinition from './add-candidate/candidate-job-definition';
import CandidateMatchingJob from './add-candidate/candidate-matchingJob';
import TadCandidateAttachments from './add-candidate/attachments';
import CandidateEmployementDetails from './add-candidate/candidate-employmentdetails';
import CandidateInterviewes from './add-candidate/candidate-interviews';
import InitialScreeningRatings from './initial-screening-ratings';
import TadInterviewFeedback from './tad-interview-feedback';
import TadPageHeader from '../common/page-header/tad-page-header';
import { ReactComponent as LocationIcon } from '../../assets/candidate-assets/location_on.svg';
import { ReactComponent as EmailIcon } from '../../assets/candidate-assets/email.svg';
import { ReactComponent as PhoneNumberIcon } from '../../assets/candidate-assets/call.svg';
import { ReactComponent as TrendingUp } from '../../assets/candidate-assets/trending_up.svg';
import { ReactComponent as TrendingDown } from '../../assets/candidate-assets/trending_up (1).svg';
import { ReactComponent as DotsIcon } from '../../assets/dashboard-assets/more_vert.svg';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';

// import { ReactComponent as AddIcon } from '../../assets/sjd/add (1).svg';
// import { ReactComponent as DownloadIcon } from '../../assets/candidate-assets/download.svg';
// import { ReactComponent as CopyIcon } from '../../assets/common-assets/content_copy.svg';
// import { ReactComponent as EmployeesIcon } from '../../assets/dashboard-assets/account_box.svg';
// import { ReactComponent as ShareIcon } from '../../assets/candidate-assets/share (1).svg';
import { ReactComponent as EditIcon } from '../../assets/sjd/Edit.svg';
// import { ReactComponent as ArchiveIcon } from '../../assets/sjd/Archive.svg';

import SliderComponent from '../common/slider/tad-slider';

import { getCandidate, tadUpdateResume } from '../../service/tadCandidateService';
import { useParams } from 'react-router-dom';
import CandidateCertificateTable from './add-candidate/candidate-certificate-table';
import { LookupTypes } from '../../../common/lookupTypes';
import { getInitials } from '../../../common/commonUtil';
import AdditionalDetails from './add-candidate/additional-details';
import CandidateEducationTable from './add-candidate/candidate-education-table';
import CandidatePrimarySkills from './add-candidate/primary-skills';
import CandidateDetails from './add-candidate/candidate-details';
import { TadCandidateDetailsModel } from '../../models/tad-candidate-form-model';
import CandidateResume from './add-candidate/candidate-resume';
import AddCandidateToJobDrawer from './add-candidate/AddCandidateToJobDrawer';
import ContactCard from '../common/contact-card';
import Notify from '../../common/notify';
import useNavigate from '../../../common/useNavigate';
import globalObject from '../../../common/global-variables';
import { getTadSjd } from '../../service/tadSjdService';
import TadCandidateQuestionnaire from './tad-candidate-questionnaire';
// import CandidateEducationTable from './add-candidate/candidate-education-table';

const CandidateDetailsPage = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  let { candidateId, sjdId } = useParams();
  if (sjdId) sjdId = atob(sjdId);
  if (candidateId) candidateId = atob(candidateId);
  const [candidate, setCandidate] = useState({} as any);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);
  const [contact, setContact] = useState({} as any);
  const [showToast, setShowToast] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const reference: any = useRef<HTMLDivElement>(null);
  const [navigate] = useNavigate();
  const [existingfeedback, setExistingfeedback] = useState();
  const [sjd, setSjd] = useState({} as any);
  const [resumeList, setResumeList] = useState();
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [isVisible, setIsVisible] = useState(false);

  const headers = [
    { id: 1, label: 'Summary' },
    { id: 2, label: 'Interviews' },
    { id: 3, label: 'Resume' },
    { id: 4, label: 'Attachments' },
    // { id: 1, label: 'Recommendations' },
    { id: 5, label: 'Job Definitions' },
    // { id: 1, label: 'Interview Process' },
    { id: 6, label: 'Questionnaire' },
    { id: 7, label: 'Matching Jobs' },
    { id: 8, label: 'History' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const references: any[] = [];
  headers.forEach(() => references.push(useRef<HTMLDivElement>(null)));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const elementPos = references[newValue].current.getBoundingClientRect().top;
    const tabsPos = document.getElementById('page-tabs')?.getBoundingClientRect().top;
    const offsetPos = elementPos + window.pageYOffset - (!tabsPos ? 0 : tabsPos + 5);
    window.scrollTo({
      top: offsetPos,
      behavior: 'smooth',
    });
    setSelectedTab(newValue);
  };

  const loadCandidate = () => {
    getCandidate(sjdId, candidateId).then((res) => {
      setCandidate(res.data);
      let oldestUnsubmittedFeedback: any = null;
      res.data.interview?.forEach((val: any) => {
        if (val.evaluator.id == globalObject.userObject.id) {
          if (val.feedback) {
            return;
          }
          if (
            !oldestUnsubmittedFeedback ||
            new Date(val.startDate) < new Date(oldestUnsubmittedFeedback.startDate)
          ) {
            oldestUnsubmittedFeedback = val;
          }
        }
      });
      if (oldestUnsubmittedFeedback) {
        setExistingfeedback(oldestUnsubmittedFeedback);
      }
      setLoading(false);
    });
  };

  sjdId &&
    useEffect(() => {
      setLoading(true);
      getTadSjd(sjdId)
        .then((resp) => {
          setLoading(false);
          setSjd(resp.data);
        })
        .catch((_err) => {
          setLoading(false);
          alert('Something went wrong. Please try later!', false);
        });
    }, []);

  const alert = (msg: string, success: boolean) => {
    setToastMsg({
      severity: success ? 'success' : 'error',
      msg,
    });
    setShowToast(true);
  };

  useEffect(() => {
    setLoading(true);
    loadCandidate();
  }, []);

  const actionsList = [
    // {
    //   name: 'Add to Job Definition',
    //   icon: AddIcon,
    // },
    // { name: 'Download Resume', icon: DownloadIcon },
    // { name: 'Check for Duplicate', icon: CopyIcon },
    // { name: 'Mark as an employee', icon: EmployeesIcon },
    // { name: 'Share', icon: ShareIcon },
    { name: 'Edit', icon: EditIcon },
    // { name: 'Archive', icon: ArchiveIcon },
  ];

  const sampleData = {
    noOfSteps: 7.5,
    selectedIndex: 5,
    currentStatus: 'Offer Accepted',
    sjd: {
      profileUpload: { status: 'Completed', date: '2024-10-01' },
      profiling: { status: 'Completed', date: '2024-10-03' },
      interviewScheduled: [
        { round: 'L1', status: 'Cleared', date: '2024-10-05' },
        { round: 'L2', status: 'Cleared', date: '2024-10-08' },
        { round: 'Client', status: 'Cleared', date: '2024-10-10' },
      ],
      hrDiscussion: { status: 'Cleared', date: '2024-10-12' },
      documentVerification: { status: 'Cleared', date: '2024-10-15' },
      offer: { status: 'Accepted', date: '2024-10-20' },
      joiningStatus: { status: 'Joined', date: '2024-10-25' },
      employee: { status: 'Active', date: '2024-11-01' },
    },
  };

  const resumeUpdate = (): Promise<boolean> => {
    const obj = {
      id: candidate.id,
      name: candidate.name,
      email: candidate.email,
      phoneNumber: candidate.phoneNumber,
      location: candidate.location,
      experience: candidate.experience,
      skills: candidate.skills,
      isMigrated: candidate.isMigrated,
      oldId: candidate.resumeId,
    };
    setLoading(true);
    tadUpdateResume(resumeList, obj)
      .then((_resp) => {
        setLoading(false);
        loadCandidate();
        alert('Details updated successfully', true);
        return new Promise((resolve, _reject) => {
          resolve(true);
        });
      })
      .then(() => {
        window.location.reload();
      })
      .catch((_err) => {
        setLoading(false);
        alert('Something went wrong. Please try later!', false);
        return new Promise((_resolve, reject) => {
          reject(true);
        });
      });
    return new Promise((resolve, _reject) => {
      resolve(true);
    });
  };

  return (
    <div className='main-div'>
      <div>
        <TadPageHeader title={candidate.name} />
      </div>
      <div
      // className='candidate-container'
      >
        <div className='header'>
          <Paper
            sx={{
              height: '19.9375rem',
              flexDirection: 'column',
              display: 'flex',
              marginBottom: '1.88rem',
            }}>
            <div style={{ height: '13.44rem', width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    padding: '1.69rem 0rem 0rem 1.19rem',
                  }}>
                  <div
                    style={{
                      backgroundColor: '#DC3857',
                      color: 'white',
                      padding: '0.5rem',
                      borderRadius: '50%',
                      width: '3.75rem',
                      height: '3.75rem',
                      fontSize: '1.625rem',
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}>
                    {handleCandidateInitials(candidate?.name)}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='overview' style={{ gap: '0.5rem' }}>
                      <span style={{ fontSize: '1.625rem' }}>{candidate.name}</span>
                      <img
                        src={require('../../assets/candidate-assets/linkedin.png')}
                        style={{
                          borderRadius: '0.3125rem',
                          color: '#333333',
                          fontWeight: '400',
                          textTransform: 'uppercase',
                        }}
                      />
                      {candidate?.sjdCandidateInfo?.length > 0 && (
                        <Chip
                          label={getNameFromId(
                            candidate?.sjdCandidateInfo?.filter((sjd: any) => sjd.sjdId == sjdId)[0]
                              .candidateStatusId,
                            LookupTypes.TAD_CANDIDATE_STATUS,
                          )}
                          style={{
                            borderRadius: '0.3125rem',
                            backgroundColor: 'var(--Primary-Blue-100, #B8D5F1)',
                            color: '#333333',
                            fontWeight: '400',
                          }}
                        />
                      )}

                      {candidate?.sjdCandidateInfo?.length > 0 &&
                        candidate?.sjdCandidateInfo?.filter((sjd: any) => sjd.sjdId == sjdId)[0]
                          .candidateSubStatus && (
                          <Chip
                            label={
                              candidate?.sjdCandidateInfo
                                ? `${
                                    candidate?.sjdCandidateInfo?.filter(
                                      (sjd: any) => sjd.sjdId == sjdId,
                                    )[0].candidateSubStatus
                                  }`
                                : ''
                            }
                            style={{
                              borderRadius: '0.3125rem',
                              backgroundColor:
                                candidate?.sjdCandidateInfo &&
                                candidate?.sjdCandidateInfo?.filter(
                                  (sjd: any) => sjd.sjdId == sjdId,
                                )[0].candidateSubStatus === 'Rejected'
                                  ? 'var(--Status-Red-100, #F4C1CB)'
                                  : candidate?.sjdCandidateInfo?.filter(
                                      (sjd: any) => sjd.sjdId == sjdId,
                                    )[0].candidateSubStatus === 'On Hold'
                                  ? 'var(--Status-Yellow-100, #FEE6B8)'
                                  : '',
                              color: '#333333',
                              fontWeight: '400',
                              // textTransform: 'uppercase',
                            }}
                          />
                        )}
                    </div>

                    <div className='overview' style={{ marginBottom: '0.87rem' }}>
                      <span style={{ fontSize: '1rem' }}>{candidate.currentRole}</span>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                      <div className='overview'>
                        <LocationIcon style={{ color: '#868686', marginRight: '1rem' }} />
                        {/* <span style={{ fontSize: '1rem' }}>{candidate.location}</span> */}
                      </div>
                      <div className='overview'>
                        <EmailIcon style={{ marginRight: '1rem' }} />
                        <span style={{ fontSize: '1rem', textTransform: 'lowercase' }}>
                          {candidate.email}
                        </span>
                      </div>
                      <div className='overview'>
                        <PhoneNumberIcon style={{ marginRight: '1rem', color: '#868686' }} />
                        <span style={{ fontSize: '1rem' }}>
                          {candidate.phoneNumber}
                          {/* {parseLocation(sjd.jobLocation).join(', ')} */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', padding: '1.69rem 0rem 0rem 1.19rem' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '3rem' }}>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Career Growth Score
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <span className='text-label'>{candidate.careerGrowthScore}</span>
                          <TrendingUp style={{ color: '#4BB543' }} />
                        </div>
                      </div>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Suitability Score
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <span className='text-label'>
                            {candidate?.sjdCandidateInfo &&
                              candidate?.sjdCandidateInfo[0]?.suitabilityScore}
                          </span>
                          <TrendingDown style={{ color: '#DC3857' }} />
                        </div>
                      </div>
                    </div>
                    <div style={{ marginRight: '3rem' }}>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Experience
                        </Typography>
                        <span className='text-label'>{`${candidate.totalExperience} ${
                          candidate.totalExperience > 1 ? 'Years' : 'Year'
                        }`}</span>
                      </div>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Current Company
                        </Typography>
                        <span className='text-label'>{candidate.lastEmployer}</span>
                      </div>
                    </div>
                    <div style={{ marginRight: '3rem' }}>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Notice Period (Days)
                        </Typography>
                        <span className='text-label'>{`${candidate.noticePeriod} Days`}</span>
                      </div>
                      <div
                        style={{
                          marginRight: '3rem',
                          marginBottom: '3.06rem',
                        }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Source
                        </Typography>
                        <span className='text-label'>
                          {getNameFromId(candidate.sourceChannel, LookupTypes.TAD_SOURCE_CHANNEL)}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div style={{ marginBottom: '2.5rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Uploaded by
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <div
                            style={{
                              backgroundColor: '#DC3857',
                              color: 'white',
                              borderRadius: '50%',
                              width: '1.89731rem',
                              height: '1.89731rem',
                              fontWeight: '400',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}>
                            {candidate.createdBy && getInitials(candidate.createdBy)}
                          </div>
                          <Link
                            onClick={(e) => {
                              setContact(candidate.createdBy);
                              setContactAnchor(e.currentTarget);
                            }}>
                            <span
                              className='text-label'
                              style={{ color: '#1976D2', cursor: 'pointer' }}>
                              {`${candidate.createdBy?.firstName} ${candidate.createdBy?.lastName}`}
                            </span>
                          </Link>
                          {/* <span
                            onMouseEnter={(e) => {
                              setContact(candidate.createdBy);
                              setContactAnchor(e.currentTarget);
                            }}
                            onMouseLeave={() => {
                              setContactAnchor(candidate.createdBy);
                              setContact(null);
                            }}
                            className='text-label'
                            style={{ color: '#1976D2', cursor: 'pointer' }}>
                            {`${candidate.createdBy?.firstName} ${candidate.createdBy?.lastName}`}
                          </span> */}
                          <ContactCard
                            contact={contact}
                            anchorEl={contactAnchor}
                            onClose={() => {
                              setContactAnchor(null);
                              setContact(null);
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Sponsorship Required
                        </Typography>
                        <span className='text-label'>
                          {candidate.sponsorshipRequired ? 'Yes' : 'No'}
                        </span>
                      </div>
                    </div>
                    <div>
                      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <DotsIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}>
                        {actionsList.map((action, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setAnchorEl(null);
                              if (action.name === 'Add to Job Definition') {
                                setOpenDrawer(true);
                              } else if (action.name === 'Edit') {
                                navigate([
                                  {
                                    label: 'Candidate Edit',
                                    link: `/candidate-edit/${btoa(candidate.id)}`,
                                  },
                                ]);
                              }
                            }}>
                            <ListItemIcon>
                              <action.icon
                                style={{
                                  color: action.name === 'Check for Duplicate' ? '#1976d2' : '',
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={action.name} />
                          </MenuItem>
                        ))}
                      </Menu>
                      <Drawer
                        sx={{ width: '21.0625rem', flexShrink: 0 }}
                        anchor='right'
                        open={openDrawer}
                        onClose={() => setOpenDrawer(false)}>
                        <AddCandidateToJobDrawer candidate={candidate} />
                      </Drawer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Divider />
            </div>
            <div style={{ padding: '2.5rem' }}>
              <SliderComponent
                noOfSteps={sampleData.noOfSteps}
                selectedIndex={sampleData.selectedIndex}
                currentStatus={sampleData.currentStatus}
                sjd={sampleData.sjd}
              />
            </div>
          </Paper>
        </div>

        <div style={{ width: '100%' }}>
          <Tabs
            className='tab'
            sx={{ margin: '0 !important', borderBottom: '1px solid rgb(193, 192, 192)' }}
            value={selectedTab}
            onChange={handleChange}>
            {headers.map((header, index) => (
              <Tab
                className='selected-tab'
                sx={{
                  color: 'var(--Body-P, var(--Primary-Dark-grey-500, #333))',
                  fontWeight: 400,
                  fontSize: '1rem',
                  textTransform: 'none',
                }}
                key={header.id}
                value={index}
                label={
                  header.label === 'Interviews'
                    ? `${header.label} (${candidate.interview ? candidate.interview?.length : 0})`
                    : header.label === 'Attachments'
                    ? `Attachments (${candidate.documents ? candidate.documents?.length : 0})`
                    : header.label === 'Job Definitions'
                    ? `Job Definitions (${
                        candidate.sjdCandidateInfo ? candidate.sjdCandidateInfo?.length : 0
                      })`
                    : header.label
                }
              />
            ))}
          </Tabs>{' '}
        </div>
        <div className='candidate-content' style={{ marginTop: '30px' }}>
          <div className='candidate-content-left' ref={reference}>
            <div ref={references[0]}>
              <CandidateDetails
                values={candidate}
                next={(_value: TadCandidateDetailsModel) => {
                  throw new Error('Function not implemented.');
                }}
                mode='details-page'
              />
            </div>
            <div>
              <InitialScreeningRatings
                candidate={candidate}
                sjdId={sjdId}
                reloadCandidate={loadCandidate}
                alert={alert}
                setLoading={setLoading}
              />
            </div>
            <div style={{ width: '100%' }} ref={references[1]}>
              <CandidateInterviewes
                sjd={sjd != null ? sjd : {}}
                candidate={candidate}
                reloadCandidate={loadCandidate}
                alert={alert}
                setLoading={setLoading}
              />
            </div>
            <div style={{ width: '100%' }}>
              <CandidateEmployementDetails candidate={candidate} />
            </div>
            <div style={{ width: '100%' }} ref={references[3]}>
              <TadCandidateAttachments
                candidatedocument={candidate}
                reloadCandidate={loadCandidate}
                setLoading={setLoading}
                alert={alert}
              />
            </div>
            <div style={{ width: '100%' }} ref={references[6]}>
              <CandidateMatchingJob />
            </div>
            <div style={{ width: '100%' }} ref={references[7]}>
              <CandidateHistory _values={candidate.events} />
            </div>
            <div style={{ width: '100%' }} ref={references[4]}>
              <CandidateJobDefinition values={candidate} />
            </div>
          </div>
          <div className='candidate-content-right' ref={references[2]}>
            <div>
              <CandidateResume
                handleResumeUpload={async (files) => {
                  setResumeList(files);
                }}
                isNewIndex={candidate.isMigrated}
                resumeIds={candidate?.resumeIds}
                candidate={candidate}
                submit={resumeUpdate}
              />
            </div>
            <div>
              <CandidatePrimarySkills
                candidate={candidate}
                reloadCandidate={loadCandidate}
                alert={alert}
                setLoading={setLoading}
              />
            </div>
            <div>
              <AdditionalDetails values={candidate} mode='details-page' />
            </div>
            <div>
              <CandidateCertificateTable certificates={candidate.certificationDetails} />
            </div>
            <div></div>
            <div>
              <CandidateEducationTable education={candidate.educationDetails} />
            </div>
            {
              <div>
                <TadInterviewFeedback
                  candidate={candidate}
                  details={existingfeedback}
                  alert={alert}
                  setLoading={setLoading}
                />
              </div>
            }
            <div>
              <TadCandidateQuestionnaire />
            </div>
          </div>
          {/* <div>
          </div> */}
          {loading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}>
              <CircularProgress color='inherit' />
            </Backdrop>
          )}
          <Notify
            open={showToast}
            severity={toastMsg.severity}
            onClose={() => setShowToast(false)}
            message={toastMsg.msg}></Notify>
        </div>
        {isVisible && (
          <div className='move-to-top'>
            <div className='circle' style={{ backgroundColor: 'var(--Primary-Blue-500, #1976d2)' }}>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  reference.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                  setTimeout(() => (window.location.href = '#root'), 10);
                }}>
                <Tooltip title='Move to top'>
                  <KeyboardArrowUpSharpIcon fontSize='large' />
                </Tooltip>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateDetailsPage;
