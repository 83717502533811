// import { useState } from 'react';
import WorldFlag from 'react-world-flags';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
// import currencySymbols from 'currency-symbol-map';

countries.registerLocale(enLocale);

const countryObj = countries.getNames('en', { select: 'official' });

const countryOptions = Object.entries(countryObj).map(([code, name]) => ({
  value: code,
  label: name,
}));

interface Props {
  selectedCountry: string;
  setSelectedCountry: (value: string) => void;
}

const CountryCodes = ({ selectedCountry, setSelectedCountry }: Props) => {
  // const [selectedCountry, setSelectedCountry] = useState('IN');

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newCountryCode = event.target.value;
    setSelectedCountry(newCountryCode);
  };
  return (
    <Select
      value={selectedCountry}
      onChange={handleChange}
      displayEmpty
      renderValue={(selected) => {
        const selectedCountry = countryOptions.find((country) => country.value === selected);
        return (
          selectedCountry && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <WorldFlag
                code={selectedCountry.value}
                style={{
                  width: '3.125rem',
                  height: '1.91325rem',
                }}
              />
            </div>
          )
        );
      }}
      sx={{
        width: '15%',
        textAlign: 'center',
        borderRadius: '0.31rem 0rem 0rem 0.31rem',
      }}
      name='countryCode'>
      {countryOptions.map((country) => (
        <MenuItem key={country.value} value={country.value}>
          <ListItemIcon>
            <WorldFlag code={country.value} style={{ width: '20px' }} />
          </ListItemIcon>
          <ListItemText>{country.label}</ListItemText>
        </MenuItem>
      ))}
    </Select>
  );
};

export default CountryCodes;
