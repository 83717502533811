import { useParams } from 'react-router';
import {
  Box,
  Paper,
  Backdrop,
  CircularProgress,
  AlertColor,
  Button,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  TextField,
  DialogActions,
  Chip,
  Tooltip,
  Menu,
  Tabs,
  Tab,
} from '@mui/material';
import './candidate.scss';
import React, { useState, useRef, useEffect } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import {
  getCandidate,
  updateCandidate,
  updateCandidateStatus,
  updateCandidateSubStatus,
  updateResume,
} from '../../service/candidateService';
import PageHeader from '../common/page-header/page-header';
import { CandidateDetailsModel } from '../../models/candidate-form.model';
import CandidateDetailsForm from './forms/candidate-details-form';
import CandidateEmploymentForm from './forms/candidate-employment-form';
import CandidateEducationForm from './forms/candidate-education-form';
import UploadResumeForm from './forms/upload-resume';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import globalObject from '../../common/global-variables';
import { LookupTypes } from '../../common/lookupTypes';
import {
  filterSjdInfo,
  getIdFromName,
  isPermissions,
  isPermissionsSubModule,
  isUserRole,
  isUserRoleIn,
} from './../../common/commonUtil';
import RatingsAndFeedback from './forms/ratings-feedback';
import Slider from './../common/slider/slider';
import { getNameFromId } from '../../common/commonUtil';
import { getSkills } from '../../service/lookupService';
import ScheduleInterview from './forms/schedule-interview';
import { getSjd, tagCandidateToSjd } from '../../service/sjdService';
import InterviewFeedback from './forms/interview-feedback';
import { CANDIDATE_SUB_STATUS, MODULE, ROLES, SubModuleTypes } from './../../constants/common';
import CandidateQuestionnaire from '../questionnaire/candidate-questionnaire';
import AttachDocuments from './forms/attach-documents';
import CandidateActivity from './candidate-activity';
import Notify from '../common/notify';
import * as pdfjs from 'pdfjs-dist';
import CandidateAdditionalInfoForm from './forms/candidate-additional-info-form';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import CandidateAudit from './forms/candidate-audit';
import { deleteApplicantMapping } from '../../service/applicantService';
import useNavigate from './../../common/useNavigate';
import JobRequirement from './forms/job-requirement';
import CloseIcon from '@mui/icons-material/Close';
import mammoth from 'mammoth';

const CandidateEdit = () => {
  const [navigate] = useNavigate();
  const [candidateStatusList, setCandidateStatusList] = useState([] as any[]);
  const [currentStatusLabel, setCurrentStatusLabel] = useState('');
  const [subStatusLabel, setSubStatusLabel] = useState('');
  const [comment, setComment] = useState('');
  const [currentStatusIndex, setCurrentStatusIndex] = useState(0);
  let { sjdId, candidateId } = useParams();
  if (sjdId) sjdId = atob(sjdId);
  if (candidateId) candidateId = atob(candidateId);
  const [candidate, setCandidate] = useState({} as CandidateDetailsModel);
  const [sjd, setSjd] = useState({} as any);
  const [skills, setSkills] = useState([] as any[]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAcceptAlert, setShowAcceptAlert] = useState(false);
  const [reason, setReason] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [enterFeedback, setEnterFeedback] = useState(false);
  const [existingfeedback, setExistingfeedback] = useState();
  const workerSrcUrl = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const [file, setFile] = useState();
  const [docContent, setDocContent] = useState<string | null>(null);
  const [dialog, setDialog] = useState(false);
  const [resumeDialog, setResumeDialog] = useState(false);
  const [candidateYetToBeAccepted, setCandidateYetToBeAccepted] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [alertBoxType, setAlertBoxType] = useState(CANDIDATE_SUB_STATUS.REJECTED as string);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const menuItems = [
    {
      section: 'job-requirement',
      name: 'Job Requirement',
      subModule: SubModuleTypes.CANDIDATE_JOB_REQUIREMENT,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      section: 'candidate-details',
      name: 'Candidate Details',
      subModule: SubModuleTypes.CANDIDATE_CANDIDATE_DETAILS,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      section: 'employment',
      name: 'Employment',
      subModule: SubModuleTypes.CANDIDATE_EMPLOYEMENT,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    { section: 'education', name: 'Education', roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER] },
    {
      section: 'additional-info',
      name: 'Additional Info',
      subModule: SubModuleTypes.CANDIDATE_ADDITIONAL_INFO,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      section: 'attach-documents',
      name: 'Attach Documents',
      subModule: SubModuleTypes.CANDIDATE_ATTACH_DOCUMENTS,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      section: 'ratings',
      name: 'Resume & Feedback',
      check: true,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER, ROLES.EVALUATOR],
    },
    {
      section: 'interview',
      name: 'Interview',
      subModule: SubModuleTypes.CANDIDATE_INTERVIEW,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },

    {
      section: 'questionnaire',
      name: 'Questionnaire',
      subModule: SubModuleTypes.CANDIDATE_QUESTIONNAIRE,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      section: 'audit',
      name: 'Audit',
      subModule: SubModuleTypes.CANDIDATE_AUDIT,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    { section: 'activity', name: 'Activity', roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER] },
  ];

  const filteredMenuItems = menuItems.filter((menu) => isPermissions(MODULE.SJD, menu.subModule));
  const rejectionReasons = [
    'Did not show up for interview',
    'Failed in an interview',
    'Documents not valid',
    'Profile not suitable',
  ];

  const callbackReasons = ['Unable to reach candidate', 'Candidate requested callback'];

  const onHoldReasons = ['Interview decision pending'];

  const droppedReasons = ['Looking for a better salary', 'Other better offer', 'No response'];

  const notInterestedReasons = [
    'Candidate not interested in our company',
    'Candidate not looking for a change',
    'Location issue',
  ];

  const reasonsMap: any = {};
  reasonsMap[CANDIDATE_SUB_STATUS.REJECTED] = rejectionReasons;
  reasonsMap[CANDIDATE_SUB_STATUS.CALL_BACK] = callbackReasons;
  reasonsMap[CANDIDATE_SUB_STATUS.ON_HOLD] = onHoldReasons;
  reasonsMap[CANDIDATE_SUB_STATUS.DROPPED] = droppedReasons;
  reasonsMap[CANDIDATE_SUB_STATUS.NOT_INTERESTED] = notInterestedReasons;

  const reference: any = useRef<HTMLDivElement>(null);
  const update = (value: CandidateDetailsModel, flag?: boolean): Promise<boolean> => {
    const obj = { ...candidate, ...value };
    setCandidate(obj);
    setLoading(true);
    updateCandidate(obj, sjdId, flag ? flag : false)
      .then((_resp) => {
        setLoading(false);
        loadCandidate();
        alert('Details updated successfully', true);
        return new Promise((resolve, _reject) => {
          resolve(true);
        });
      })
      .catch((_err) => {
        setLoading(false);
        alert('Something went wrong. Please try later!', false);
        return new Promise((_resolve, reject) => {
          reject(true);
        });
      });
    return new Promise((resolve, _reject) => {
      resolve(true);
    });
  };

  const resumeUpdate = (): Promise<boolean> => {
    const obj = {
      id: candidate.id,
      name: candidate.name,
      email: candidate.email,
      phoneNumber: candidate.phoneNumber,
      location: candidate.location,
      experience: candidate.experience,
      skills: candidate.skills,
      isMigrated: candidate.isMigrated,
      oldId: candidate.resumeId,
    };
    setLoading(true);
    updateResume(file, obj, sjdId)
      .then((_resp) => {
        setLoading(false);
        loadCandidate();
        alert('Details updated successfully', true);
        return new Promise((resolve, _reject) => {
          resolve(true);
        });
      })
      .then(() => {
        window.location.reload();
      })
      .catch((_err) => {
        setLoading(false);
        alert('Something went wrong. Please try later!', false);
        return new Promise((_resolve, reject) => {
          reject(true);
        });
      });
    return new Promise((resolve, _reject) => {
      resolve(true);
    });
  };

  useEffect(() => {
    setLoading(true);
    getSjd(sjdId)
      .then((resp) => {
        setLoading(false);
        setSjd({ ...resp.data });
        populateStatusList(resp.data);
      })
      .catch((_err) => {
        setLoading(false);
        alert('Something went wrong. Please try later!', false);
      });
    getSkills()
      .then((resp) => {
        setSkills(resp.data);
      })
      .catch((_err) => setSkills([]));
  }, []);

  const loadCandidate = (statusList?: any[]) => {
    setLoading(true);
    getCandidate(sjdId, candidateId)
      .then((resp) => {
        setLoading(false);
        setCandidate({
          ...resp.data,
          operations: resp.data.operations
            ? resp.data.operations
            : globalObject.userObject.operations,
          currentCtcFrequency: resp.data.currentCtcFrequency
            ? resp.data.currentCtcFrequency
            : globalObject.lookupvalues[LookupTypes.PAY_TYPE].filter(
                (p: any) => p.name == 'Annually',
              )[0].id,
          expectedCtcFrequency: resp.data.currentCtcFrequency
            ? resp.data.expectedCtcFrequency
            : globalObject.lookupvalues[LookupTypes.PAY_TYPE].filter(
                (p: any) => p.name == 'Annually',
              )[0].id,
        });
        if (sjdId && resp.data.sjdCandidateInfo) {
          const sjdInfo = filterSjdInfo(resp.data.sjdCandidateInfo, sjdId);
          const candidateStatus = sjdInfo.candidateStatusId;
          if (resp.data.sjdCandidateInfo.length == 0) {
            setCandidateYetToBeAccepted(true);
            setCurrentStatusLabel('Applied');
          } else {
            setCandidateYetToBeAccepted(false);
            setCurrentStatusLabel(getNameFromId(candidateStatus, LookupTypes.CANDIDATE_STATUS));
          }
          if (sjdInfo.candidateSubStatus) {
            setSubStatusLabel(sjdInfo.candidateSubStatus);
            setComment(sjdInfo.comment);
          }
          if (statusList) {
            statusList.forEach((status, index) => {
              if (status.id == candidateStatus) {
                setCurrentStatusIndex(index);
              }
            });
          } else {
            candidateStatusList.forEach((status, index) => {
              if (status.id == candidateStatus) {
                setCurrentStatusIndex(index);
              }
            });
          }
        }

        resp.data.interview?.forEach((val: any) => {
          if (val.evaluator.id == globalObject.userObject.id) {
            setEnterFeedback(true);
            setExistingfeedback(val);
          }
        });
      })
      .catch((_err) => {
        setLoading(false);
        alert('Something went wrong. Please try later!', false);
      });
  };

  const populateStatusList = (sourceDef: any) => {
    const s: any[] = [];
    (globalObject.lookupvalues[LookupTypes.CANDIDATE_STATUS] as any[])
      .sort(function (a, b) {
        return a.id - b.id;
      })
      .forEach((status) => {
        if (status.name.startsWith('Online Test')) {
          if (sourceDef.isOnlineTest) {
            s.push(status);
          }
          return;
        }
        if (status.name.startsWith('Client Interview')) {
          if (sourceDef.isClientRound) {
            s.push(status);
          }
          return;
        }

        if (status.name.startsWith('Tech Interview')) {
          const level = Number(status.name.substr(status.name.lastIndexOf('-') + 1, 1));
          if (level <= sourceDef.noOfTechnicalInterview) {
            s.push(status);
          }
          return;
        }

        if (
          status.name != 'Rejected' &&
          status.name != 'Callback' &&
          status.name != 'Not Interested' &&
          status.name != 'On Hold'
        ) {
          s.push(status);
          return;
        }
      });
    setCandidateStatusList((_prev) => {
      return [...s];
    });
    loadCandidate(s);
  };

  const alert = (msg: string, success: boolean) => {
    setToastMsg({
      severity: success ? 'success' : 'error',
      msg,
    });
    setShowToast(true);
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleForwardClick = (newStatusIndex: any) => {
    setCurrentStatusIndex(newStatusIndex);
    updateStatus(candidateStatusList[newStatusIndex]);
  };
  const handleAcceptClick = () => {
    setShowAcceptAlert(false);
    setLoading(true);
    tagCandidateToSjd({ sjdId, candidateId })
      .then((_resp) => {
        deleteApplicantMapping(sjdId, candidateId)
          .then((_resp) => {
            setToastMsg({
              severity: 'success',
              msg: 'Status Accepted successfully',
            });
            setShowToast(true);
            location.reload();
          })
          .finally(() => setLoading(false));
      })
      .catch((_err) => {
        setToastMsg({
          severity: 'success',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
        setLoading(false);
      });
  };
  const handleRejectClick = () => {
    setLoading(true);
    setShowAlert(true);
  };
  const handleMenuItemClick = (event: any, index: number, option: any) => {
    setCurrentStatusIndex(index);
    setOpen(false);
    updateStatus(option);
  };

  const updateStatus = (option: any) => {
    setLoading(true);
    updateCandidateStatus({
      candidateId: candidate.id,
      sjdId: sjdId,
      statusId: option.id,
      reason: comment,
    })
      .then((_resp) => {
        setLoading(false);
        loadCandidate();
        setToastMsg({
          severity: 'success',
          msg: 'Status updated successfully',
        });
        setShowToast(true);
      })
      .catch((_err) => {
        setLoading(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const reject = () => {
    setShowAlert(false);
    setLoading(true);
    if (candidateYetToBeAccepted) {
      deleteApplicantMapping(sjdId, candidateId)
        .then((_resp) => {
          setLoading(false);
          // loadCandidate();
          alert('Status updated successfully', true);
          navigate(
            [
              {
                label: 'Job Definitions',
                link: '/source-job-definition',
              },
              {
                label: sjd.name,
                link: `/source-job-definition/${btoa(sjdId || '')}`,
              },
            ],
            true,
          );
        })
        .catch((_err) => {
          setLoading(false);
          alert('Something went wrong. Please try later!', false);
        });
    } else {
      updateCandidateSubStatus({
        sjdId,
        candidateId,
        subStatus: alertBoxType,
        comment: reason !== 'Other' ? reason : comment,
      })
        .then((_resp) => {
          setLoading(false);
          loadCandidate();
          alert('Status updated successfully', true);
        })
        .catch((_err) => {
          setLoading(false);
          alert('Something went wrong. Please try later!', false);
        });
    }
  };

  const getChipColor = () => {
    const subStatus = filterSjdInfo(candidate.sjdCandidateInfo, sjd.id)['candidateSubStatus'];
    if (!subStatus) return 'success';
    if (subStatus === CANDIDATE_SUB_STATUS.CALL_BACK || subStatus === CANDIDATE_SUB_STATUS.ON_HOLD)
      return 'warning';
    if (subStatus === CANDIDATE_SUB_STATUS.REJECTED || subStatus === CANDIDATE_SUB_STATUS.DROPPED)
      return 'error';
    if (subStatus === CANDIDATE_SUB_STATUS.NOT_INTERESTED) return 'default';
    return 'primary';
  };

  const handleMenuOptionChange = (event: React.SyntheticEvent, index: number) => {
    const elementPos = document
      .getElementById(filteredMenuItems[index].section)
      ?.getBoundingClientRect().top;
    const offsetPos = !elementPos ? 0 : elementPos + window.pageYOffset - 160;
    window.scrollTo({
      top: offsetPos,
      behavior: 'smooth',
    });
    setSelectedIndex(index);
  };

  return (
    <>
      {candidate.id && (
        <Box>
          <PageHeader title='Candidate Details'></PageHeader>
          <div style={{ position: 'sticky', top: 78, zIndex: 100 }}>
            {!isUserRole(ROLES.EVALUATOR, true) && (
              <Paper sx={{ position: 'sticky', top: 50, background: 'white', zIndex: 100 }}>
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <div style={{ paddingLeft: '30px', display: 'none !important' }}>
                    <span style={{ fontWeight: 'bold' }}>Status:</span>
                    <Chip
                      label={currentStatusLabel}
                      style={{ fontWeight: 'bold', height: '25px' }}
                      color={getChipColor()}
                    />
                    {subStatusLabel && (
                      <Tooltip title={comment} placement='right'>
                        <Chip
                          label={subStatusLabel}
                          style={{ fontWeight: 'bold', marginLeft: '10px', height: '25px' }}
                          color={getChipColor()}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <div className='status-div' style={{ minWidth: '65%' }}>
                    <div className='status-bar'>
                      {sjd.id && (
                        <Slider
                          sjd={sjd}
                          noOfSteps={candidateStatusList.length}
                          selectedIndex={currentStatusIndex + 1}
                          currentStatus={currentStatusLabel}></Slider>
                      )}
                    </div>
                    <div className='status-button'>
                      {candidateYetToBeAccepted ? (
                        <Button
                          sx={{
                            marginRight: '10px',
                          }}
                          className='success-btn'
                          disabled={
                            (!isUserRole(ROLES.LEAD) && !isUserRole(ROLES.RECRUITER)) ||
                            sjd.hiringTeam.filter((h: any) => h.id == globalObject.userObject.id)
                              .length == 0
                          }
                          onClick={() => setShowAcceptAlert(true)}>
                          Accept
                        </Button>
                      ) : (
                        <ButtonGroup variant='contained' ref={anchorRef} aria-label='split button'>
                          <Button
                            color='success'
                            sx={{ borderRight: '2px solid #fff !important', textTransform: 'none' }}
                            disabled={!isUserRole(ROLES.LEAD) && !isUserRole(ROLES.RECRUITER)}
                            onClick={() => handleForwardClick(currentStatusIndex + 1)}>
                            Move Forward
                          </Button>
                          <Button
                            size='small'
                            color='success'
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label='select merge strategy'
                            aria-haspopup='menu'
                            disabled={!isUserRole(ROLES.LEAD) && !isUserRole(ROLES.RECRUITER)}
                            onClick={() => setOpen((prevOpen) => !prevOpen)}>
                            <ArrowDropDownIcon />
                          </Button>
                        </ButtonGroup>
                      )}

                      <Popper
                        className='status-popper'
                        sx={{
                          zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal>
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                            }}>
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id='split-button-menu' autoFocusItem>
                                  {candidateStatusList.map((option, index) => (
                                    <MenuItem
                                      key={index}
                                      selected={index === currentStatusIndex}
                                      onClick={(event: any) =>
                                        handleMenuItemClick(event, index, option)
                                      }>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                      {candidateYetToBeAccepted ? (
                        <Button
                          className='default-btn bg-btn'
                          disabled={
                            (!isUserRole(ROLES.LEAD) && !isUserRole(ROLES.RECRUITER)) ||
                            sjd.hiringTeam.filter((h: any) => h.id == globalObject.userObject.id)
                              .length == 0
                          }
                          onClick={() => handleRejectClick()}>
                          Reject
                        </Button>
                      ) : (
                        <Button
                          disabled={!isUserRole(ROLES.LEAD) && !isUserRole(ROLES.RECRUITER)}
                          id='basic-button'
                          className='default-btn bg-btn'
                          sx={{ marginLeft: 3 }}
                          aria-controls={openMenu ? 'basic-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={openMenu ? 'true' : undefined}
                          endIcon={
                            openMenu ? (
                              <ArrowDropUpIcon fontSize='medium' />
                            ) : (
                              <ArrowDropDownIcon fontSize='medium' />
                            )
                          }
                          onClick={(e) => setAnchorEl(e.currentTarget)}>
                          Actions
                        </Button>
                      )}
                      <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}>
                        {Object.values(CANDIDATE_SUB_STATUS).map((status, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setAnchorEl(null);
                              setReason('');
                              setAlertBoxType(status);
                              setShowAlert(true);
                            }}>
                            {status}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </div>
                </div>
              </Paper>
            )}

            {dialog && (
              <Dialog
                open={dialog}
                fullWidth={true}
                maxWidth='xs'
                onClose={() => {
                  setDialog(false), setComment('');
                }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>Reason for Not Interested</DialogTitle>
                <DialogContent>
                  <TextField
                    value={comment}
                    fullWidth={true}
                    size='small'
                    onChange={(e: any) => setComment(e.target.value)}></TextField>
                </DialogContent>
                <DialogActions>
                  <Button className='default-btn' onClick={() => setDialog(false)}>
                    Cancel
                  </Button>
                  <Button
                    className='default-btn bg-btn'
                    onClick={() => {
                      setAnchorEl(null);
                      updateStatus({
                        id: getIdFromName('Not Interested', LookupTypes.CANDIDATE_STATUS),
                      });
                      setDialog(false);
                    }}
                    disabled={comment == ''}
                    autoFocus>
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {!isUserRole(ROLES.EVALUATOR, true) && (
              <div className='page-tabs'>
                <Box sx={{ width: '100%' }}>
                  <Tabs
                    value={selectedIndex}
                    onChange={handleMenuOptionChange}
                    aria-label='secondary tabs example'>
                    {filteredMenuItems.map(
                      (menu, index) =>
                        (!menu.roles || isUserRoleIn(menu.roles)) && (
                          <Tab key={index} value={index} label={menu.name} />
                        ),
                    )}
                  </Tabs>
                </Box>
              </div>
            )}
          </div>
          <div className='details-container'>
            <div className='details candidate-edit-container'>
              <Paper>
                <div className='details-section job-requirement' id='job-requirement'>
                  <JobRequirement sjd={sjd} />
                </div>

                {isPermissions(MODULE.SJD, SubModuleTypes.CANDIDATE_CANDIDATE_DETAILS) && (
                  <div
                    className='details-section candidate-details'
                    id='candidate-details'
                    ref={reference}>
                    <CandidateDetailsForm
                      mode='details-page'
                      values={candidate}
                      skills={skills}
                      next={(val) => update(val, true)}
                    />
                  </div>
                )}
                {isPermissions(MODULE.SJD, SubModuleTypes.CANDIDATE_EMPLOYEMENT) && (
                  <div className='details-section employment' id='employment'>
                    <CandidateEmploymentForm
                      mode='details-page'
                      values={candidate}
                      sjdId={sjdId}
                      next={update}
                    />
                  </div>
                )}
                {isPermissions(MODULE.SJD, SubModuleTypes.CANDIDATE_EDUCATION) && (
                  <div className='details-section education' id='education'>
                    <CandidateEducationForm
                      mode='details-page'
                      values={candidate}
                      sjdId={sjdId}
                      next={update}
                    />
                  </div>
                )}
                {isPermissions(MODULE.SJD, SubModuleTypes.CANDIDATE_ADDITIONAL_INFO) && (
                  <div className='details-section additional-info' id='additional-info'>
                    <CandidateAdditionalInfoForm
                      mode='details-page'
                      values={candidate}
                      next={update}
                    />
                  </div>
                )}
                {!candidateYetToBeAccepted &&
                  isPermissions(MODULE.SJD, SubModuleTypes.CANDIDATE_ATTACH_DOCUMENTS) && (
                    <div className='details-section attach-documents' id='attach-documents'>
                      <AttachDocuments
                        candidate={candidate}
                        reloadCandidate={loadCandidate}
                        alert={alert}
                        setLoading={setLoading}
                      />
                    </div>
                  )}

                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>
                    {!candidateYetToBeAccepted && (
                      <div className='ratings' id='ratings'>
                        <RatingsAndFeedback
                          candidate={candidate}
                          sjdId={sjdId}
                          reloadCandidate={loadCandidate}
                          alert={alert}
                          setLoading={setLoading}
                        />
                      </div>
                    )}
                    {enterFeedback &&
                      isPermissionsSubModule(
                        MODULE.SJD,
                        SubModuleTypes.CANDIDATE_INTERVIEW_FEEDBACK,
                      ) && (
                        <div
                          className='interview-feedback'
                          id='interview-feedback'
                          style={{ marginRight: '15px' }}>
                          <InterviewFeedback
                            sjdId={sjd.id}
                            candidate={candidate}
                            details={existingfeedback}
                            alert={alert}
                            setLoading={setLoading}
                          />
                        </div>
                      )}
                  </div>
                  <div className='resume-preview' id='resume' style={{ width: '50%' }}>
                    {sjd.id && (
                      <>
                        <UploadResumeForm
                          candidateYetToBeAccepted={candidateYetToBeAccepted}
                          mode='details-page'
                          isMigrated={candidate.isMigrated}
                          resumeId={candidate.resumeId}
                          handleResumeUpload={async (file) => {
                            setFile(file);
                            const fileType = file.name.split('.').pop().toLowerCase();

                            if (fileType === 'pdf') {
                              // PDF handling
                              setDocContent(null); // Clear docContent for PDF rendering
                            } else if (fileType === 'docx' || fileType === 'doc') {
                              // DOC or DOCX handling
                              const arrayBuffer = await file.arrayBuffer();
                              const result = await mammoth.convertToHtml({ arrayBuffer });
                              setDocContent(result.value);
                            }
                          }}
                          submit={resumeUpdate}
                          resumeDialog={(dialog) => setResumeDialog(dialog)}
                        />
                        {docContent && docContent != null ? (
                          <Paper>
                            <div
                              style={{ padding: '20px 20px', maxHeight: '150vh', overflow: 'auto' }}
                              dangerouslySetInnerHTML={{ __html: docContent }}
                            />
                          </Paper>
                        ) : (
                          <Worker workerUrl={workerSrcUrl}>
                            <Viewer
                              fileUrl={URL.createObjectURL(file ? file : new Blob()) as any}
                            />
                          </Worker>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {!candidateYetToBeAccepted &&
                  isPermissions(MODULE.SJD, SubModuleTypes.CANDIDATE_INTERVIEW) && (
                    <div className='interview' id='interview'>
                      <ScheduleInterview
                        sjd={sjd}
                        candidate={candidate}
                        reloadCandidate={loadCandidate}
                        alert={alert}
                        setLoading={setLoading}
                      />
                    </div>
                  )}
                {!candidateYetToBeAccepted &&
                  isPermissions(MODULE.SJD, SubModuleTypes.CANDIDATE_QUESTIONNAIRE) &&
                  sjd.id && (
                    <div className='details-section questionnaire' id='questionnaire'>
                      <CandidateQuestionnaire
                        sjd={sjd}
                        candidate={candidate}
                        mode='candidate-page'
                        reloadCandidate={loadCandidate}
                        alert={alert}
                        setLoading={setLoading}
                      />
                    </div>
                  )}
                {!candidateYetToBeAccepted &&
                  isPermissions(MODULE.SJD, SubModuleTypes.CANDIDATE_AUDIT) && (
                    <div className='details-section audit' id='audit'>
                      <CandidateAudit
                        candidate={candidate}
                        sjd={sjd}
                        reloadCandidate={loadCandidate}
                        alert={alert}
                        setLoading={setLoading}
                      />
                    </div>
                  )}

                {isPermissions(MODULE.SJD, SubModuleTypes.CANDIDATE_ACTIVITY) && (
                  <div className='details-section activity' id='activity'>
                    <CandidateActivity _value={candidate} />
                  </div>
                )}
              </Paper>
            </div>
          </div>
        </Box>
      )}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Notify open={showToast} severity={toastMsg.severity} onClose={() => setShowToast(false)}>
        <span>{toastMsg.msg}</span>
      </Notify>
      <Dialog
        open={showAlert}
        fullWidth={true}
        maxWidth='xs'
        onClose={() => {
          setShowAlert(false);
          setComment('');
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{`Reason for ${alertBoxType}`}</DialogTitle>
        <DialogContent>
          <Select
            size='small'
            fullWidth
            labelId='reject'
            name='reject'
            value={reason}
            onChange={(e: any, _c: any) => setReason(e.target.value)}
            id='demo-simple-select'>
            {reasonsMap[alertBoxType].map((reason: any, index: number) => (
              <MenuItem key={index} value={reason}>
                {reason}
              </MenuItem>
            ))}
            <MenuItem value={'Other'}>{'Other'}</MenuItem>
          </Select>
          {reason === 'Other' && (
            <TextField
              sx={{ marginTop: '10px' }}
              value={comment}
              fullWidth
              id='currentRole'
              size='small'
              name='currentRole'
              placeholder='Comments *'
              onChange={(e: React.ChangeEvent<any>) => setComment(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className='default-btn'
            onClick={() => {
              setShowAlert(false);
            }}>
            Cancel
          </Button>
          <Button
            className='default-btn bg-btn'
            onClick={() => {
              // TODO: Validate before submit
              reject();
            }}
            disabled={(reason == '' || reason == 'Other') && comment == ''}
            autoFocus>
            {alertBoxType == 'reject' ? 'Reject' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
      {candidate?.id && (
        <div className='move-to-top'>
          <div className='circle'>
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                reference.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest',
                });
                setTimeout(() => (window.location.href = '#root'), 10);
                // window.location.href = '#root';
              }}>
              <Tooltip title='Move to top'>
                <KeyboardArrowUpSharpIcon fontSize='large' />
              </Tooltip>
            </a>
          </div>
        </div>
      )}

      <Dialog
        open={showAcceptAlert}
        fullWidth={true}
        maxWidth='xs'
        onClose={() => {
          setShowAcceptAlert(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Accept</DialogTitle>
        <DialogContent>Do you want to accept the application of this candidate?</DialogContent>
        <DialogActions>
          <Button
            className='default-btn bg-btn'
            onClick={() => {
              setShowAcceptAlert(false);
            }}>
            Cancel
          </Button>
          <Button
            className='success-btn'
            onClick={() => {
              handleAcceptClick();
            }}
            autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={resumeDialog}
        fullWidth={true}
        maxWidth='lg'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            Resume
            <CloseIcon
              onClick={() => setResumeDialog(false)}
              sx={{ cursor: 'pointer' }}></CloseIcon>
          </div>
        </DialogTitle>
        <DialogContent>
          {docContent && docContent != null ? (
            <Paper>
              <div
                style={{ padding: '20px 20px', overflow: 'auto' }}
                dangerouslySetInnerHTML={{ __html: docContent }}
              />
            </Paper>
          ) : (
            <Worker workerUrl={workerSrcUrl}>
              <Viewer fileUrl={URL.createObjectURL(file ? file : new Blob()) as any} />
            </Worker>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CandidateEdit;
