import React, { useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList,
} from 'recharts';

interface IData {
  name: string;
  onboarded: number;
  onboardedTarget: number;
}

const data: IData[] = [
  { name: 'Akshaya Kukatla', onboarded: 1, onboardedTarget: 1 },
  { name: 'Sai Revathi Bandaru', onboarded: 0, onboardedTarget: 1 },
  { name: 'Gunasree Porandla', onboarded: 0, onboardedTarget: 1 },
  { name: 'Kratika Rojade', onboarded: 0, onboardedTarget: 1 },
  { name: 'Kusuma Kumari', onboarded: 10, onboardedTarget: 1 },
  { name: 'Lavanya Elupula', onboarded: 1, onboardedTarget: 1 },
];

const OnBoardingMatrix = () => {
  const [selectedDefinition, setSelectedDefinition] = useState<string>('All Job Definitions');

  return (
    <div style={{ flex: 1 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '-40px',
          marginBottom: '25px',
          padding: '0 20px', 
          maxWidth: '1350px',
          marginLeft: '10px',
          marginRight: 'auto',
        }}>
        <Select
          value={selectedDefinition}
          onChange={(e) => setSelectedDefinition(e.target.value as string)}
          variant='outlined'
          sx={{ width: '182px', height: '30px' }}
          size='small'>
          <MenuItem value="All Job Definitions">All Job Definitions</MenuItem>
        </Select>
        <span
          style={{
            fontSize: '1rem',
            color: '#1565c0',
            fontWeight: '400',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>
          View All
        </span>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
          width: '100%',
          maxWidth: '90%',
          margin: '0 auto',
          marginTop: '180px',
        }}>
        <ResponsiveContainer width='95%' height='100%'>
          <ComposedChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray='none' />
            <XAxis dataKey='name' />
            <YAxis orientation='right' />
            <Tooltip />
            <Legend />
            <Line
              type='linear'
              dataKey='onboardedTarget'
              stroke='#000080'
              name='Onboarded Target'>
              <LabelList dataKey='onboardedTarget' position='top' />
            </Line>
            <Line
              type='linear'
              dataKey='onboarded'
              stroke='#FF0000'
              name='Onboarded'>
              <LabelList dataKey='onboarded' position='top' />
            </Line>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default OnBoardingMatrix;
