import React from 'react';
import { Dialog, Button, Box, DialogContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './new-dashboard.scss';
import tadLoader from './../../assets/common-assets/Whole animation.gif';

interface PreDashboardDialogProps {
  open: boolean;
}

const DashboardOverlay: React.FC<PreDashboardDialogProps> = ({ open }) => {
  const navigate = useNavigate();

  const handleNavigation = (page: string) => {
    return page === 'jobDefinitions' ? navigate('/source-job-definition') : navigate('/candidate');
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      PaperProps={{
        sx: {
          textAlign: 'center',
          width: '81rem',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
        },
      }}>
      <DialogContent>
        <div style={{ height: '25rem' }}>
          <div>
            <Box display='flex' justifyContent='center' mb={2}>
              <img
                src={tadLoader}
                alt='Loading'
                style={{
                  width: '9.4rem',
                  height: '5.2rem',
                  marginTop: '2rem',
                }}
              />
            </Box>
          </div>

          <div>
            <div>
              <Typography
                variant='body1'
                paragraph
                className='dashboard-title'
                sx={{ fontWeight: '400', fontSize: '2.5rem', color: '#333', marginTop: '2rem' }}>
                Coming Soon!
              </Typography>
            </div>

            <div>
              <Typography variant='body1' paragraph className='dashboard-heading'>
                We’re working hard to bring you an enhanced dashboard experience.
              </Typography>

              <div>
                <Typography variant='body2' paragraph className='dashboard-heading'>
                  In the meantime, you can still explore and access all other features of the
                  <br />
                  platform. Thank you for your patience and understanding!
                </Typography>
              </div>
            </div>
          </div>

          <div>
            <Typography
              gutterBottom
              className='dashboard-heading'
              style={{ marginBottom: '1.1rem' }}>
              Explore
            </Typography>
          </div>

          <div>
            <Box display='flex' justifyContent='center' gap={2}>
              <Button
                variant='outlined'
                color='primary'
                style={{ textTransform: 'none' }}
                onClick={() => handleNavigation('jobDefinitions')}>
                Job Definitions
              </Button>
              <Button
                variant='outlined'
                color='primary'
                style={{ textTransform: 'none' }}
                onClick={() => handleNavigation('candidates')}>
                Candidates
              </Button>
            </Box>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DashboardOverlay;
