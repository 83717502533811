import { Paper } from '@mui/material';
import SectionHeaderWithEdit from '../common/section-heading';
import TimeLine from '../common/timeline/timeline';

interface Props {
  _value: any;
}
const SjdActivity = ({ _value }: Props) => {
  return (
    <div>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        <SectionHeaderWithEdit text='Activity' hideIcon={true} edit={false} click={() => {}} />
        <TimeLine events={_value.events} parent='sjd' />
      </Paper>
    </div>
  );
};

export default SjdActivity;
