import axiosClient from '../common/axiosClient';

export async function postCourses(request: any) {
  return axiosClient.post('/temp-courses', request);
}

export async function updateCourses(request: any) {
  return axiosClient.put('/temp-courses', request);
}

export async function getAllCourses() {
  return axiosClient.get('/temp-courses/all');
}
