import { Typography, IconButton, DialogTitle, DialogContent } from '@mui/material';
import Close from '../../tad-revamp-ui/assets/login/close.svg';
import Group from '../../tad-revamp-ui/assets/login/Group.svg';
import './TermsPrivacyPolicy.scss';

interface PrivacyPolicyProps {
  handleCloseDialog: () => void;
}

// Disable the prop-types ESLint rule for this component
// eslint-disable-next-line react/prop-types
const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ handleCloseDialog }) => {
  return (
    <>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2.12rem' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '1.13rem' }}>
              <img
                src={Group}
                style={{
                  width: '2.375rem',
                  height: '2.1875rem',
                  flexShrink: '0',
                  background: '#1976D2',
                }}
              />
            </div>
            <div>
              <Typography
                sx={{
                  color: 'var(--Primary-Dark-grey-500, #333)',
                  fontFamily: 'var(--Font, Roboto)',
                  fontSize: 'var(--Font-size-h6, 1.1875rem)',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.5625rem',
                }}>
                Privacy Policy
              </Typography>

              <Typography
                sx={{
                  color: 'var(--Secondary-colors-Grey, #868686)',
                  fontFamily: 'var(--Font, Roboto)',
                  fontSize: 'var(--Font-size-P, 1rem)',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.5625rem',
                }}>
                Last updated August 2024
              </Typography>
            </div>
          </div>
          <div>
            <IconButton style={{ marginLeft: '23rem' }} onClick={handleCloseDialog}>
              <img src={Close} alt='Close' />
            </IconButton>
          </div>
        </div>

        <div>
          <hr
            style={{
              margin: 0,
              width: '40rem',
              background: 'var(--Secondary-colors-Grey-2, #E6E7E8)',
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: 'auto',
          maxHeight: '100%',
          padding: '2rem',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
        <div style={{ width: '39.79413rem', paddingTop: '10px' }}>
          <Typography gutterBottom>
            <strong>PeopleTech - A Quest Global Company</strong> (&quot;we&quot;, &quot;our&quot;,
            or &quot;us&quot;) is committed to protecting the privacy of our users and ensuring the
            security of personal information collected through our{' '}
            <strong>Talent Acquisition and Development services</strong> (&quot;Services&quot;).
            This Privacy Policy outlines how we collect, use, disclose, and protect your personal
            information when you interact with our Services.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            1. Information We Collect
          </Typography>

          <Typography variant='body2' gutterBottom>
            We collect the following types of information when you use our Services:
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            1.1 Personal Information:
          </Typography>
          <Typography variant='body2' gutterBottom>
            This refers to any information that can identify you as an individual. Examples include:
          </Typography>

          <ul>
            <li>Full name</li>
            <li>Contact details (email address, phone number)</li>
            <li>Job title and company information</li>
            <li>Educational background and employment history</li>
            <li>Professional qualifications, skills, and certifications</li>
            <li>Performance reviews and work-related feedback</li>
            <li>Other information provided in resumes or during candidate assessments</li>
          </ul>

          <Typography className='custom-typography' gutterBottom>
            1.2 Non-Personal Information:
          </Typography>
          <Typography variant='body2' gutterBottom>
            We also collect non-personal information that does not directly identify you. Examples
            include:
          </Typography>

          <ul>
            <li>Browser type, device type, and operating system</li>
            <li>Usage data (pages visited, time spent on the platform)</li>
            <li>Cookies and tracking data to improve our Services</li>
          </ul>

          <Typography className='custom-typography' gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant='body2' gutterBottom>
            We use the information collected through our Services to:
          </Typography>
          <ul>
            <li>
              Facilitate the recruitment process, including job postings, candidate screening, and
              interview scheduling.
            </li>
            <li>Conduct background checks and verify candidate credentials, when necessary.</li>
            <li>Manage employee onboarding and development programs.</li>
            <li>Provide tailored training and career development resources.</li>
            <li>Improve and personalize your experience using the platform.</li>
            <li>
              Send notifications, updates, and administrative communications regarding our Services.
            </li>
            <li>
              Ensure compliance with applicable laws and regulations, including employment and data
              protection laws.
            </li>
          </ul>

          <Typography className='custom-typography' gutterBottom>
            3. How We Share Your Information
          </Typography>
          <Typography variant='body2' gutterBottom>
            We do not sell or rent your personal information. However, we may share your data in the
            following circumstances:
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            3.1 With Employers and Recruiters:
          </Typography>
          <Typography variant='body2' gutterBottom>
            When you apply for a job or participate in talent development programs, your personal
            information may be shared with the relevant employers, recruiters, or hiring managers as
            part of the recruitment process.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            3.2 With Service Providers:
          </Typography>
          <Typography variant='body2' gutterBottom>
            We may share your personal information with trusted third-party service providers who
            assist us in operating our platform, conducting background checks, or delivering
            development programs.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            3.3 For Legal Compliance:
          </Typography>
          <Typography variant='body2' gutterBottom>
            We may disclose your personal information if required by law, regulation, or legal
            process, or to respond to valid legal requests by government authorities.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            3.4 With Your Consent:
          </Typography>
          <Typography variant='body2' gutterBottom>
            In other instances, we will obtain your explicit consent before sharing your personal
            information with third parties.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            4. Data Security
          </Typography>
          <Typography variant='body2' gutterBottom>
            We take appropriate technical and organizational measures to protect your personal
            information from unauthorized access, alteration, disclosure, or destruction. These
            measures include encryption, secure data storage, and access controls. However, no
            system is entirely secure, and we cannot guarantee absolute security of your data.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            5. Your Data Rights
          </Typography>
          <Typography variant='body2' gutterBottom>
            Depending on your location and applicable data protection laws, you may have the
            following rights regarding your personal data:
          </Typography>

          <ul>
            <li>
              Access: You have the right to request access to the personal information we hold about
              you.
            </li>
            <li>
              Correction: You may request that we correct any inaccuracies or incomplete
              information.
            </li>
            <li>
              Deletion: You can request the deletion of your personal data in certain circumstances,
              such as when it is no longer necessary for the purposes for which it was collected.
            </li>
            <li>
              Data Portability: You may request a copy of your personal information in a structured,
              commonly used, and machine-readable format.
            </li>
            <li>
              Objection: You have the right to object to the processing of your personal data for
              certain purposes, such as marketing.
            </li>
            <li>
              Withdrawal of Consent: If we are processing your personal data based on consent, you
              have the right to withdraw your consent at any time.
            </li>
          </ul>
          <Typography variant='body2' gutterBottom>
            To exercise any of these rights, please contact us at{' '}
            <a className='itsupport-email' href='mailto:ITsupport@peopletech.com'>
              ITsupport@peopletech.com
            </a>
            .
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            6. Data Retention
          </Typography>
          <Typography variant='body2' gutterBottom>
            We retain your personal data for as long as necessary to fulfill the purposes outlined
            in this Privacy Policy or as required by law. Once your data is no longer needed, we
            will securely delete or anonymize it.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            7. Cookies and Tracking Technologies
          </Typography>
          <Typography variant='body2' gutterBottom>
            We use cookies and similar tracking technologies to enhance your experience on our
            platform. Cookies allow us to:
          </Typography>
          <ul>
            <li>Remember your preferences and login details.</li>
            <li>Analyze website traffic and usage patterns to improve performance.</li>
          </ul>
          <Typography variant='body2' gutterBottom>
            You can control the use of cookies through your browser settings. Please note that
            disabling cookies may affect your ability to use certain features of the Services.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            8. Third-Party Links
          </Typography>
          <Typography variant='body2' gutterBottom>
            Our Services may contain links to third-party websites or services. We are not
            responsible for the privacy practices or content of these third-party sites. We
            encourage you to review their privacy policies before providing any personal
            information.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            9. International Data Transfers
          </Typography>
          <Typography variant='body2' gutterBottom>
            As we operate globally, your personal information may be transferred to and processed in
            countries other than your own. We ensure that appropriate safeguards are in place to
            protect your personal information in accordance with this Privacy Policy and applicable
            data protection laws.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            10. Children&#39;s Privacy
          </Typography>
          <Typography variant='body2' gutterBottom>
            Our Services are not intended for individuals under the age of 18. We do not knowingly
            collect personal information from children. If you believe we have inadvertently
            collected such information, please contact us, and we will take steps to delete it.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            11. Changes to this Privacy Policy
          </Typography>
          <Typography variant='body2' gutterBottom>
            We may update this Privacy Policy from time to time to reflect changes in our practices
            or applicable laws. We will notify you of any significant changes by posting the updated
            Privacy Policy on our platform and updating the &quot;Effective Date&quot; at the top of
            this page.
          </Typography>

          <Typography className='custom-typography' gutterBottom>
            12. Contact Us
          </Typography>
          <Typography variant='body2' gutterBottom>
            If you have any questions, concerns, or requests regarding this Privacy Policy or our
            data practices, please contact us at:
            <br />
            <br></br>
            <a className='itsupport-email' href='mailto:ITSupport@peopletech.com'>
              ITSupport@peopletech.com
            </a>
            <br />
            <strong> PeopleTech IT Consultancy Private Limited</strong>
            <br />
            Hyderabad
            <br />
            RMZ Futura Block B,<br></br>Plot no. 14 & 15, Phase 2, <br></br>HITEC City, Hyderabad -
            500081
          </Typography>
        </div>
      </DialogContent>
    </>
  );
};

export default PrivacyPolicy;
