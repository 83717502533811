import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { Select, MenuItem, Box } from '@mui/material';

const OfferAcceptanceAndRejection = () => {
  const data = [
    { name: 'Rejected', value: 11, color: '#65a2f5' },
    { name: 'Accepted', value: 9, color: '#53ecf2' },
  ];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, value, index }: any) => {
    const bendPointX = cx + (outerRadius + 30) * Math.cos(-midAngle * RADIAN);
    const bendPointY = cy + (outerRadius + 30) * Math.sin(-midAngle * RADIAN);

    const labelX = bendPointX + (bendPointX > cx ? 30 : -30);
    const labelY = bendPointY;

    return (
      <>
        <line
          x1={cx + outerRadius * Math.cos(-midAngle * RADIAN)}
          y1={cy + outerRadius * Math.sin(-midAngle * RADIAN)}
          x2={bendPointX}
          y2={bendPointY}
          stroke={data[index].color}
          strokeWidth={2}
        />
        <line
          x1={bendPointX}
          y1={bendPointY}
          x2={labelX}
          y2={labelY}
          stroke={data[index].color}
          strokeWidth={2}
        />
        <text
          fontWeight={30}
          x={labelX}
          y={labelY}
          fill='black'
          textAnchor={labelX > cx ? 'start' : 'end'}
          dominantBaseline='central'
          fontSize={12}>
          {`${data[index].name} : ${value} %`}
        </text>
      </>
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '-40px',
          marginBottom: '25px',
          padding: '0 20px',
          maxWidth: '1000px',
          marginLeft: '10px',
          marginRight: 'auto',
          gap: '1rem',
        }}>
        <Box display='flex' gap='1rem'>
          <Select
            sx={{ width: '135px', height: '30px' }}
            size='small'
            labelId='year-select'
            name='year'
            defaultValue={2024}>
            <MenuItem value={2024}>Today</MenuItem>
            <MenuItem value={2023}>Tomorrow</MenuItem>
          </Select>

          <Select
            sx={{ width: '185px', height: '30px' }}
            size='small'
            labelId='job-def-select'
            name='jobDefinition'
            defaultValue='Job Definition'>
            <MenuItem value='Job Definition'>Department</MenuItem>
          </Select>
        </Box>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '450px',
          marginTop: '7.19rem',
        }}>
        <PieChart width={600} height={450}>
          <Pie
            data={data}
            cx={300}
            cy={225}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={150}
            fill='#8884d8'
            dataKey='value'>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export default OfferAcceptanceAndRejection;
