import React, { useEffect, useState } from 'react';
import { Paper, Typography, TextField, Button, IconButton } from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';

import { ReactComponent as MoreIcon } from '../../../assets/dashboard-assets/more_vert.svg';

import '../../source-job-definition/job-definition-details.scss';
import { getLabel } from '../../../common/commonUtil';
import { DatePicker } from '@mui/x-date-pickers';
import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import {
  TadCandidateDetailsModel,
  TadCandidateEmploymentDetails,
} from '../../../models/tad-candidate-form-model';
import LocationAutoComplete from '../../../../components/common/location-autocomplete';
import useNavigate from '../../../../common/useNavigate';

interface EmploymentDetailsProps {
  previous?: () => void;
  values: TadCandidateDetailsModel;
  next?: (value: TadCandidateDetailsModel) => void;
  mode?: 'edit-page' | 'details-page';
}

const EmploymentDetails: React.FC<EmploymentDetailsProps> = ({
  values,
  mode = 'edit-page',
  next = () => {},
  previous = () => {},
}: EmploymentDetailsProps) => {
  console.log('EmploymentDetailsProps => ', values);

  const [submitted, setSubmitted] = useState(false);
  const [currentEmployer, setCurrentEmployer] = useState(values.lastEmployer);

  const [edit, setEdit] = useState(mode === 'edit-page');
  const [navigate] = useNavigate();

  const candidateEmploymentSchema = Yup.object().shape({
    employments: Yup.array().of(
      Yup.object().shape({
        companyName: Yup.string().required('Company Name is required'),
        startDate: Yup.date().nullable().required('Start Date is required'),
        endDate: Yup.date()
          .nullable()
          .required('End Date is required')
          .min(Yup.ref('startDate'), `End Date can'${''}t be before Start Date`),
        location: Yup.string().required('Location is required'),
        currentEmployer: Yup.boolean(),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      employments: [values as TadCandidateEmploymentDetails],
    },
    validationSchema: candidateEmploymentSchema,
    onSubmit: (_values) => {
      formik.resetForm();
      setSubmitted(false);
    },
  });

  function getError(formik: FormikProps<any>, fieldName: string) {
    const { touched, error } = formik.getFieldMeta(fieldName);

    if ((touched && error) || submitted) {
      return error ? error.toString() : null;
    }
    return null;
  }

  const handleAddExperience = () => {
    formik.setFieldValue('employments', [
      ...formik.values.employments,
      {
        companyName: '',
        designation: '',
        startDate: '',
        endDate: '',
        location: '',
        currentEmployer: false,
      },
    ]);
  };

  const setLocationCallback = (str?: string, index?: any) => {
    formik.setFieldValue(`employments[${index}].location`, str);
  };

  const handleRemoveExperience = (index: number) => {
    const updatedExperiences = formik.values.employments.filter((_, i) => i !== index);
    formik.setFieldValue('employments', updatedExperiences);
  };

  const handleNextClick = async () => {
    formik.setFieldValue(
      'employments',
      formik.values.employments.map((employment) => {
        const startDate = dayjs(employment.startDate);
        const endDate = dayjs(employment.endDate);

        return {
          ...employment,
          startDate: startDate.isValid() ? startDate.toDate() : null,
          endDate: endDate.isValid() ? endDate.toDate() : null,
        };
      }),
    );
    const errors = await formik.validateForm();
    formik.setTouched({
      employments: formik.values.employments.map(() => ({
        companyName: true,
        startDate: true,
        endDate: true,
        location: true,
        currentEmployer: true,
      })),
    });
    setSubmitted(true);
    if (Object.keys(errors).length === 0) {
      values.lastEmployer = currentEmployer;
      // const formattedData = console.log(JSON.stringify(formattedData));
      next({ employmentDetails: formik.values.employments });
    }
  };

  useEffect(() => {
    if (values.employmentDetails && values.employmentDetails?.length > 0) {
      formik.setFieldValue('employments', [...values.employmentDetails]);
    } else {
      formik.setFieldValue('employments', [
        {
          companyName: '',
          designation: '',
          startDate: '',
          endDate: '',
          location: '',
          currentEmployer: false,
        },
      ]);
    }
  }, []);

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <Paper className='paper' sx={{ width: '100%' }}>
          {mode === 'details-page' && (
            <div className='header-container'>
              <div className='header-content-title'>
                <span>Employment Details</span>
              </div>
              <div className='header-content-icon'>
                <IconButton onClick={() => setEdit(!edit)}>
                  <MoreIcon />
                </IconButton>
              </div>
            </div>
          )}
          {formik.values.employments.map((experience, index) => (
            <div key={index} style={{ padding: '2.69rem 3rem 2.19rem 3rem' }}>
              {edit && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                  <IconButton
                    onClick={() => handleRemoveExperience(index)}
                    sx={{ color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))' }}>
                    <DeleteIcon />
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '1rem',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '1.5625rem',
                        textDecoration: 'underline',
                        marginLeft: '0.5rem',
                      }}>
                      Remove
                    </span>
                  </IconButton>
                </div>
              )}
              <div className='field-container'>
                {mode === 'edit-page' ? (
                  <div style={{ width: '50%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Company Name *</Typography>
                    <span className='feildHeader'>Provide the candidates company name</span>
                  </div>
                ) : (
                  <div style={{ width: '35%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Company Name: </Typography>
                  </div>
                )}
                {edit ? (
                  <div style={{ width: '50%' }}>
                    <TextField
                      className='input-field'
                      fullWidth
                      value={experience.companyName}
                      onChange={(e) => {
                        formik.setFieldValue(`employments[${index}].companyName`, e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      error={Boolean(getError(formik, `employments[${index}].companyName`))}
                    />
                    <div className='mt-0 form-err-message'>
                      {' '}
                      {getError(formik, `employments[${index}].companyName`)}
                    </div>
                  </div>
                ) : (
                  <span>{getLabel('')}</span>
                )}
              </div>

              <div className='field-container'>
                {mode === 'edit-page' ? (
                  <div style={{ width: '50%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Candidate Role</Typography>
                    <span className='feildHeader'>Provide the candidates role</span>
                  </div>
                ) : (
                  <div style={{ width: '35%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Candidate Role</Typography>
                  </div>
                )}
                {edit ? (
                  <div style={{ width: '50%' }}>
                    <TextField
                      className='input-field'
                      fullWidth
                      value={experience.designation}
                      onChange={(e) =>
                        formik.setFieldValue(`employments[${index}].designation`, e.target.value)
                      }
                      onBlur={formik.handleBlur}
                      error={Boolean(getError(formik, `employments[${index}].designation`))}
                      placeholder='Type here...'
                    />
                    <div className='mt-0 form-err-message'>
                      {getError(formik, `employments[${index}].designation`)}
                    </div>
                  </div>
                ) : (
                  <span>{getLabel('')}</span>
                )}
              </div>

              <div className='field-container'>
                {mode === 'edit-page' ? (
                  <div style={{ width: '50%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Start Date & End Date *</Typography>
                  </div>
                ) : (
                  <div style={{ width: '35%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Start Date & End Date</Typography>
                  </div>
                )}
                {edit ? (
                  <div style={{ display: 'flex', gap: '1.88rem', width: '50%' }}>
                    {/* <span>{JSON.stringify(dayjs(experience?.startDate))}</span> */}
                    <div>
                      <DatePicker
                        className='date-picker'
                        format='DD/MM/YYYY'
                        value={experience.startDate ? dayjs(experience.startDate) : null}
                        disableFuture
                        onChange={(date) =>
                          formik.setFieldValue(
                            `employments[${index}].startDate`,
                            date ? dayjs(date).toISOString() : null,
                            true,
                          )
                        }
                      />
                      <div className='mt-0 form-err-msg'>
                        {getError(formik, `employments[${index}].startDate`)}
                      </div>
                    </div>
                    <div>
                      <DatePicker
                        className='date-picker'
                        format='DD/MM/YYYY'
                        value={experience.endDate ? dayjs(experience.endDate) : null}
                        disableFuture
                        onChange={(e) =>
                          formik.setFieldValue(
                            `employments[${index}].endDate`,
                            dateFormat(e?.toString(), 'isoDateTime'),
                            true,
                          )
                        }
                      />
                      <div className='mt-0 form-err-msg'>
                        {getError(formik, `employments[${index}].endDate`)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <span style={{ overflowWrap: 'anywhere' }}>
                    {experience.startDate &&
                      dateFormat(new Date(experience.startDate), 'dd/mm/yyyy')}{' '}
                    -{experience.endDate && dateFormat(new Date(experience.endDate), 'dd/mm/yyyy')}
                  </span>
                )}
              </div>

              <div className='field-container'>
                {mode === 'edit-page' ? (
                  <div style={{ width: '50%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Location *</Typography>
                    <span style={{ overflowWrap: 'anywhere' }}>
                      Provide current location where candidate worked
                    </span>
                  </div>
                ) : (
                  <div style={{ width: '35%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Location *</Typography>
                  </div>
                )}
                {edit ? (
                  <div style={{ width: '50%' }}>
                    <LocationAutoComplete
                      onSelect={(value) => setLocationCallback(value, index)}
                      initialValue={experience.location}></LocationAutoComplete>
                    <div className='mt-0 form-err-message'>
                      {getError(formik, `employments[${index}].location`)}
                    </div>
                  </div>
                ) : (
                  <span>{getLabel(experience.location)}</span>
                )}
              </div>

              <div className='field-container'>
                {mode === 'edit-page' ? (
                  <div style={{ width: '50%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Current Employer</Typography>
                  </div>
                ) : (
                  <div style={{ width: '35%' }}>
                    <Typography sx={{ fontWeight: 600 }}>Current Employer:</Typography>
                  </div>
                )}
                {edit ? (
                  <div style={{ width: '50%' }}>
                    <input
                      type='checkbox'
                      style={{
                        transform: 'scale(1.2)',
                        marginRight: '8px',
                      }}
                      checked={experience.currentEmployer}
                      onChange={(_e) => {
                        setCurrentEmployer(experience.companyName);
                        const updatedEmployments = formik.values.employments.map((exp, i) => ({
                          ...exp,
                          currentEmployer: i === index ? !exp.currentEmployer : exp.currentEmployer,
                        }));
                        formik.setFieldValue('employments', updatedEmployments);
                      }}
                    />
                  </div>
                ) : (
                  <span>{experience.currentEmployer ? 'Yes' : 'No'}</span>
                )}
              </div>
              <hr style={{ background: '#868686' }} />
            </div>
          ))}

          <Button onClick={handleAddExperience}>Add Another</Button>

          <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                className='button'
                onClick={() => {
                  navigate([
                    {
                      label: 'Candidates',
                      link: '/candidate',
                    },
                  ]);
                }}
                sx={{
                  width: '7.375rem',
                  height: '2.5rem',
                  borderRadius: '5px',
                  border: '1px solid #2D7DFE',
                  backgroundColor: '#FFF',
                  color: 'black',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 400,
                }}>
                Cancel
              </Button>

              <div style={{ display: 'flex', gap: '15px' }}>
                <Button
                  className='button'
                  onClick={previous}
                  sx={{
                    width: '7.375rem',
                    height: '2.5rem',
                    borderRadius: '5px',
                    border: '1px solid #2D7DFE',
                    backgroundColor: '#FFF',
                    color: 'black',
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 400,
                  }}>
                  Previous
                </Button>
                <Button
                  className='button'
                  onClick={() => {
                    handleNextClick();
                  }}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '1rem',
                    width: '7.375rem',
                    height: '2.5rem',
                    color: 'white !important',
                    background: '#2D7DFE !important',
                  }}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default EmploymentDetails;
