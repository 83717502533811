import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

export const Loader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        color: 'grey.500',
      }}>
      <CircularProgress color='inherit' />
    </Box>
  );
};
