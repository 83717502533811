import { Button, Grid, InputLabel, Paper, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
  CandidateDetailsModel,
  CandidateDocumentModel,
} from '../../../models/candidate-form.model';
import { uploadDocument } from '../../../service/candidateService';
import SectionHeaderWithEdit from '../../common/section-heading';
import CandidateDataTable, { HeaderProps } from './candidate-data-table';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { isPermissionsWrite } from '../../../common/commonUtil';
import { MODULE, SubModuleTypes } from '../../../constants/common';

interface AttachDocumentsProps {
  candidate: CandidateDetailsModel;
  setLoading: (isLoading: boolean) => void;
  reloadCandidate: () => void;
  alert: (msg: string, success: boolean) => void;
}

const AttachDocuments: React.FC<AttachDocumentsProps> = ({
  candidate,
  setLoading,
  reloadCandidate,
  alert,
}: AttachDocumentsProps) => {
  const [data, setData] = useState([] as CandidateDocumentModel[]);
  useEffect(() => {
    if (candidate.documents) setData([...candidate.documents]);
  }, [candidate]);

  const headers: HeaderProps[] = [
    {
      name: 'Document Name',
      key: 'documentName',
    },
    {
      name: 'Uploaded By',
      key: 'createdByName',
    },
    {
      name: 'Uploaded On',
      key: 'createdOn',
      type: 'date',
      format: 'dd/mm/yyyy',
    },
  ];

  const formik = useFormik({
    initialValues: {
      documentName: '',
      attachment: undefined as unknown as File,
    },
    onSubmit: (values) => {
      upload(values);
      formik.resetForm();
      formik.setFieldValue('attachement', undefined);
    },
    validate: (values) => {
      const errors: { documentName?: string; attachment?: string } = {};
      if (!values.documentName || values.documentName == '')
        errors['documentName'] = 'Document name is required';
      else if (values.documentName.length > 200)
        errors['documentName'] = 'Document Name should not exceed 200 characters';
      if (!values.attachment) errors['attachment'] = 'Please attach a document';
      return errors;
    },
  });

  const upload = (values: any) => {
    setLoading(true);
    uploadDocument(values.attachment, candidate.id, values.documentName)
      .then((_resp) => {
        alert('Document added successfully', true);
        reloadCandidate();
      })
      .catch((_err) => {
        alert('Something went wrong. Please try later!', false);
      })
      .finally(() => setLoading(false));
  };

  const getActions = (_row: any): React.ReactNode => {
    return (
      <>
        {isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_ATTACH_DOCUMENTS) && (
          <a
            href={_row.documentUrl}
            download='MyExampleDoc'
            rel='noreferrer'
            target='_blank'
            style={{ color: '#a72037' }}>
            <DownloadRoundedIcon />
          </a>
        )}
      </>
    );
  };

  return (
    <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
      <SectionHeaderWithEdit
        text='Upload Documents'
        edit={false}
        hideIcon={true}
        click={() => {}}
      />
      <Grid container spacing={2}>
        <Grid item xs={4} md={4} lg={4}>
          <form onSubmit={formik.handleSubmit}>
            <div className='input-block'>
              <InputLabel htmlFor='documentName' className='field-label'>
                Document Name
              </InputLabel>
              <TextField
                id='documentName'
                name='documentName'
                size='small'
                fullWidth
                value={formik.values.documentName}
                placeholder='Eg: Education, Experience..'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched['documentName'] && formik.errors['documentName'] && (
                <div className='mt-0 form-err-msg'>{formik.errors['documentName']}</div>
              )}
            </div>
            <div className='input-block'>
              <InputLabel htmlFor='browse-document' className='field-label'>
                Browse Document
              </InputLabel>
              <TextField
                id='browse-document'
                type='file'
                name='attachment'
                size='small'
                fullWidth
                onChange={(event: any) => formik.setFieldValue('attachment', event.target.files[0])}
              />
            </div>
            <div className='center-contents'>
              <Button
                className='default-btn bg-btn'
                type='submit'
                disabled={
                  !formik.dirty ||
                  Object.keys(formik.errors).length > 0 ||
                  !isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_ATTACH_DOCUMENTS)
                }>
                Upload
              </Button>
            </div>
          </form>
        </Grid>
        <Grid item xs={8} md={8} lg={8}>
          <CandidateDataTable data={data} headers={headers} getActions={getActions} />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default AttachDocuments;
