import { useState, useEffect } from 'react';
import { getCandidateCountByStatus } from '../../service/dashboardService';
import { Loader } from '../common/loader';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getIdFromName } from '../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';
import dateFormat from 'dateformat';

interface Props {
  initialData: any;
}

const CandidateStatusChart = ({ initialData }: Props) => {
  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const barColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ff0000', '#ffbdc8'];

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
    } else {
      setLoading(false);
      formatData(initialData);
    }
  }, [initialData]);

  // useEffect(() => {
  //   setLoading(true);
  //   handleFilter('allTime');
  // }, []);

  const handleFilter = (filter: string) => {
    setLoading(true);
    const apiCall =
      filter == 'allTime'
        ? getCandidateCountByStatus()
        : getCandidateCountByStatus(
            dateFormat(new Date(), 'isoDateTime'),
            dateFormat(new Date(), 'isoDateTime'),
          );
    apiCall
      .then((resp) => {
        formatData(resp.data);
      })
      .catch((_err) => {})
      .finally(() => setLoading(false));
  };

  const formatData = (countData: any) => {
    const d: any[] = [];
    Object.keys(countData).forEach((status) => {
      d.push({
        name: status,
        value: countData[status],
        statusId: getIdFromName(status, LookupTypes.CANDIDATE_STATUS),
      });
    });
    d.sort((a, b) => a.statusId - b.statusId);
    setData(d);
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label'>{label}</p>
        </div>
      );
    }

    return null;
  };

  const CustomLabel = (props: any) => {
    const { x, y, fill, value, width } = props;
    return (
      <text x={x + width / 2} y={y} dy={-4} fontSize='16' fill={fill} textAnchor='middle'>
        {value}
      </text>
    );
  };

  return (
    <>
      <Stack spacing={1}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            marginRight: '5px',
          }}>
          <Typography variant='h6' fontWeight={700}>
            Candidate Count By Status
          </Typography>
          <select
            className='audit-period-select'
            onChange={(e: any) => handleFilter(e.target.value)}>
            <option value={'allTime'}>All Time</option>
            <option value={'today'}>Today</option>
          </select>
        </div>
      </Stack>
      {loading && <Loader />}
      {!loading && (
        <div className='container' style={{ display: 'flex', justifyContent: 'center' }}>
          <ResponsiveContainer>
            <BarChart data={data} height={300}>
              <XAxis dataKey='name' tick={false} label='Candidate Status' />
              <YAxis label={{ value: 'Count', angle: -90, position: 'insideLeft' }} />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey='value'
                fill='#00a0fc'
                strokeWidth={0}
                background={false}
                isAnimationActive={false}
                label={<CustomLabel />}>
                {data.map((_entry, index) => (
                  <Cell key={`cell-${index}`} fill={barColors[index % 6]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default CandidateStatusChart;
