import { useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  Menu,
  MenuItem,
} from '@mui/material';
import './new-dashboard.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReactComponent as Search } from '../../assets/candidate-assets/search.svg';

interface Props {
  list: Menu[];
}

interface Menu {
  id: string;
  title: string;
}

const CommonMenu = ({ list }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedJobs(list.map((job: any) => job.id));
    } else {
      setSelectedJobs([]);
    }
  };

  const handleJobSelect = (jobId: string) => {
    if (selectedJobs.includes(jobId)) {
      setSelectedJobs(selectedJobs.filter((id) => id !== jobId));
    } else {
      setSelectedJobs([...selectedJobs, jobId]);
    }
  };
  return (
    <>
      <div>
        <IconButton
          aria-controls={isOpen ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={isOpen ? 'true' : undefined}
          sx={{ padding: '0px' }}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setIsOpen(true);
          }}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Menu
          id='basic-menu'
          sx={{ overflow: 'hidden' }}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              height: '364px',
              width: '260px',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              overflow: 'hidden',
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <FormControl
            sx={{
              padding: 0,
              marginLeft: '20px',
              marginTop: '23px',
            }}>
            <InputBase
              style={{
                border: '1px solid #C1C0C0',
                borderRadius: '5px',
                width: '218px',
                height: '40px',
                flexShrink: 0,
              }}
              placeholder='Search'
              startAdornment={
                <InputAdornment position='start'>
                  <Search style={{ marginLeft: '12px', height: '20px', width: '20px' }} />
                </InputAdornment>
              }
            />
          </FormControl>
          <List
            sx={{
              overflowY: 'scroll',
              height: '281px',
              width: '231px',
              padding: '8px 11px 20px 9px',
            }}>
            <MenuItem>
              <FormControlLabel
                control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
                label='Select All'
              />
            </MenuItem>
            {list.map((job: any) => (
              <MenuItem key={job.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedJobs.includes(job.id)}
                      onChange={() => handleJobSelect(job.id)}
                    />
                  }
                  label={job.title}
                />
              </MenuItem>
            ))}
          </List>
        </Menu>
      </div>
    </>
  );
};
export default CommonMenu;
