import axiosClient from '../common/axiosClient';

export async function tagCoursesToIntern(request: any) {
  return axiosClient.post('/intern', request);
}

export async function getInternCandidates(internId: any) {
  return axiosClient.get('/intern', { params: { internId } });
}

export async function getInternByManager(managerId: any) {
  return axiosClient.get('/intern/manager', { params: { managerId } });
}

export async function getAllUsersByManagerId(managerId: any) {
  return axiosClient.get('/intern/all', { params: { managerId } });
}

export async function getStatusCount(id: any) {
  return axiosClient.get('/intern/count', { params: { id } });
}

export async function updateInternDetails(request: any) {
  return axiosClient.patch('/intern/employee', request);
}

export async function updateInternByManager(request: any) {
  return axiosClient.patch('/intern/manager', request);
}

export async function deleteEmployeeCourseDetailsByManager(request: any) {
  return axiosClient.delete('/intern/delete', { data: request });
}

export async function tagLevelTechStackToBulkEmployees(request: any) {
  return axiosClient.post('/intern/bulk', request);
}
export async function uploadInternDocument(
  file: File,
  internId: number | undefined,
  courseId: number | undefined,
  managerId: number | undefined,
  documentName: string,
) {
  const formData = new FormData();
  formData.append('document', file);
  formData.append(
    'details',
    JSON.stringify({
      internId,
      courseId,
      managerId,
      documentName,
    }),
  );
  return axiosClient.post('/intern/document', formData);
}
