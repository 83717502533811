import axiosClient from '../common/axiosClient';
import { CandidateDetailsModel } from '../models/candidate-form.model';

export async function getCandidatesForSjd(sjdId: any) {
  return axiosClient.get(`/candidate/sjd/${sjdId}`);
}

export async function getCandidate(sjdId: any, candidateId: any) {
  return axiosClient.get(`/candidate/${candidateId}`, { params: { sjdId } });
}

export async function deleteEmployment(req: any) {
  return axiosClient.delete('/candidate/employment', {
    data: req,
  });
}

export async function deleteCandidateEducation(req: any) {
  return axiosClient.delete('/candidate/education', {
    data: req,
  });
}

export async function deleteInterviewData(req: any, accessToken: string) {
  return axiosClient.delete('/candidate/interview', {
    data: req,
    headers: {
      accessToken,
    },
  });
}
export async function deleteCertification(req: any) {
  return axiosClient.delete('/candidate/certification', {
    data: req,
  });
}

export async function addCandidate(
  resume: any,
  request: CandidateDetailsModel,
  sjdId: string | undefined,
) {
  const formData = new FormData();
  delete request.resumeSkills;
  formData.append('resume', resume);
  formData.append('details', JSON.stringify(request));
  return axiosClient.post('/candidate', formData, {
    params: { sjdId },
  });
}

export async function updateCandidate(request: CandidateDetailsModel, sjdId: any, flag?: boolean) {
  return axiosClient.patch(
    '/candidate',
    {
      ...request,
    },
    {
      params: { sjdId, flag },
    },
  );
}

export async function updateResume(
  resume: any,
  request: CandidateDetailsModel,
  sjdId: string | undefined,
) {
  const formData = new FormData();
  delete request.resumeSkills;
  formData.append('resume', resume);
  formData.append('details', JSON.stringify(request));
  return axiosClient.patch('/candidate/resume', formData, {
    params: { sjdId },
  });
}

export async function updateCandidateRating(request: any, type: string) {
  return axiosClient.patch(`/candidate/rating/${type}`, {
    ...request,
  });
}

export async function addCommentsToCandidate(request: any) {
  return axiosClient.post('/candidate/comment', {
    ...request,
  });
}

export async function updateCandidateStatus(request: any) {
  return axiosClient.patch('/candidate/status', { ...request });
}

export async function updateCandidateSubStatus(request: any) {
  return axiosClient.patch('/candidate/sub-status', { ...request });
}

export async function parseResume(file: any) {
  const formData = new FormData();
  formData.append('uploaded_Resume', file);
  return axiosClient.post('/candidate/resume', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function bulkUploadResumes(files: any, sjdId: any, onUploadProgress: any) {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('resume', files[i]);
  }
  return axiosClient.post('/candidate/resumes', formData, {
    params: { 'sjdId ': sjdId },
    onUploadProgress: (data: any) => {
      const percent = Math.round((data.loaded / data.total) * 100);
      onUploadProgress(percent);
    },
  });
}

export async function checkDuplicate(request: any) {
  return axiosClient.post('/candidate/duplicate-check', {
    ...request,
  });
}

export async function rejectCandidate(request: any) {
  return axiosClient.post('/candidate/reject', {
    ...request,
  });
}

export async function scheduleInterview(request: any, accessToken: string) {
  return axiosClient.post('/candidate/interview', request, {
    headers: {
      accessToken,
    },
  });
}

export async function updateInterviewDetails(
  request: any,
  isFeedback: boolean,
  accessToken?: string,
) {
  return axiosClient.patch('/candidate/interview', request, {
    params: {
      isFeedback,
    },
    headers: {
      accessToken,
    },
  });
}

export async function uploadDocument(
  file: File,
  candidateId: number | undefined,
  documentName: string,
) {
  const formData = new FormData();
  formData.append('document', file);
  formData.append(
    'details',
    JSON.stringify({
      candidateId,
      documentName,
    }),
  );
  return axiosClient.post('/candidate/document', formData);
}
export async function getResume(resumeId: string, isNewIndex: boolean) {
  const url = `/candidate/resume/${resumeId}?isNewIndex=${isNewIndex}`;
  return axiosClient.get(url);
}
export async function searchCandidate(request: any) {
  return axiosClient.post('/candidate/search', request);
}

export async function auditCandidate(request: any) {
  return axiosClient.patch('/candidate/audit', request);
}

export async function postPowerBiAccessDetails(request: any) {
  return axiosClient.post('/power-bi/access', request);
}
