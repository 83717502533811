import axiosClient from '../common/axiosClient';

export async function getLmsReport(req: any) {
  return axiosClient.get('/lms/report', {
    params: {
      fromDate: req.fromDate,
      toDate: req.toDate,
      reportType: req.reportType,
    },
  });
}
