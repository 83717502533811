import React, { useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import boldIcon from './../../assets/sjd/format_bold.svg';
import italicIcon from './../../assets/sjd/format_italic.svg';
import listOrderedIcon from './../../assets/sjd/format_list_numbered.svg';
import listBulletIcon from './../../assets/sjd/format_list_bulleted.svg';
import linkIcon from './../../assets/sjd/link.svg';
import undoIcon from './../../assets/sjd/undo.svg';
import redoIcon from './../../assets/sjd/redo.svg';
import leftAlign from './../../assets/sjd/format_align_left.svg';
import './../source-job-definition/./tad-source-job-definition.scss';

const icons = Quill.import('ui/icons');
Quill.register('modules/history', Quill.import('modules/history'));

icons['bold'] = `<img src="${boldIcon}" alt="Bold" />`;
icons['italic'] = `<img src="${italicIcon}" alt="Italic" />`;
icons['link'] = `<img src="${linkIcon}" alt="Link" />`;
icons['list']['ordered'] = `<img src="${listOrderedIcon}" alt="Ordered List" />`;
icons['list']['bullet'] = `<img src="${listBulletIcon}" alt="Bullet List" />`;
icons['undo'] = `<img src="${undoIcon}" alt="Undo" />`;
icons['redo'] = `<img src="${redoIcon}" alt="Redo" />`;
icons['align']['left'] = `<img src="${leftAlign}" alt="Left Align" />`;

interface Props {
  selectedValue: (value: string) => void;
  value: string;
  width?: string;
  error?: string;
}

const TextEditorField: React.FC<Props> = ({ value, selectedValue, error }: Props) => {
  const quillRef = useRef<ReactQuill | null>(null);

  const toolbarOptions = [
    ['bold', 'italic'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['undo', 'redo'],
    [{ align: 'left' }],
    // ['clean'],
  ];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbarOptions,
        handlers: {
          undo: () => {
            if (quillRef.current) {
              const editor = quillRef.current.getEditor();
              const history = editor.getModule('history');
              history.undo();
            }
          },
          redo: () => {
            if (quillRef.current) {
              const editor = quillRef.current.getEditor();
              const history = editor.getModule('history');
              history.redo();
            }
          },
        },
      },
      history: {
        delay: 1000,
        maxStack: 50,
        userOnly: true,
      },
    }),
    [],
  );

  return (
    <div style={{ height: '17rem', width: '100%' }}>
      <ReactQuill
        ref={quillRef}
        value={value}
        style={{
          border:
            error == undefined || (value && value !== '<p><br></p>')
              ? '1px solid #ccc'
              : '1px solid #dc3857',
        }}
        className='reactQuill'
        theme='snow'
        modules={modules}
        onChange={(value) => selectedValue(value)}
      />
      {error && <div className='mt-0 form-err-message'>{error}</div>}
    </div>
  );
};

export default TextEditorField;
