import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface DialogProps {
  onClose: () => void;
  title: string;
  content: string;
  btn1Label: string;
  btn2Label: string;
  btn1Action: () => void;
  btn2Action: () => void;
}
const ConfirmationDialog = (props: DialogProps) => {
  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.btn1Action}>{props.btn1Label}</Button>
        <Button onClick={props.btn2Action} autoFocus>
          {props.btn2Label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
