import { SJD_STATUS } from '../constants/common';
import { CandidateEventModel } from '../models/candidate-form.model';
import globalObject from './global-variables';
import { LookupTypes } from './lookupTypes';

export function toUpperFirstChar(str: string): string {
  return str && str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function redirectToLogin() {
  window.location.href = `/login?from=${btoa(window.location.pathname + window.location.search)}`;
}

export function getNameFromId(id: number | undefined, type: LookupTypes) {
  const obj = globalObject.lookupvalues[type].find((v: any) => v.id === id);
  if (obj) {
    return obj.name;
  }
  return null;
}

export function isUserRole(roleName: string, strictCheck?: boolean) {
  if (strictCheck) {
    return (
      globalObject.userObject.roles.length == 1 &&
      globalObject.userObject.roles.some((role: any) => role.name === roleName)
    );
  }
  return globalObject.userObject.roles.some((role: any) => role.name === roleName);
}

export function isUserRoleIn(roleName: string[]): boolean {
  let isValid = false;
  roleName.forEach((role) => {
    isValid = isValid || isUserRole(role);
  });
  return isValid;
}

export function isPermissions(module: string, subModule?: string): boolean {
  const roles: any[] = globalObject.userObject.roles.map((role: any) => role.name);
  const permissionType = globalObject.lookupvalues[LookupTypes.PERMISSION_TYPE].find(
    (e: any) => e.name == 'No Access',
  );
  for (const role of roles) {
    const permission = globalObject.permissions[role]?.[module];
    if (permission && permission.value.read !== permissionType?.id) {
      if (subModule) {
        const subModulePermission = permission.subModules.find(
          (e: any) => e.subModuleId['name'] == subModule,
        );
        if (subModulePermission?.isActive) {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
}

export function isPermissionsSubModule(module: string, subModule: string): boolean {
  const roles: any[] = globalObject.userObject.roles.map((role: any) => role.name);
  for (const role of roles) {
    const permission = globalObject.permissions[role]?.[module];
    if (permission) {
      const subModulePermission = permission.subModules.find(
        (e: any) => e.subModuleId['name'] === subModule,
      );
      if (subModulePermission?.isActive === true) {
        return true;
      }
    }
  }
  return false;
}

export function isPermissionsWrite(module: string, subModule?: string): boolean {
  const roles: any[] = globalObject.userObject.roles.map((role: any) => role.name);
  const permissionType = globalObject.lookupvalues[LookupTypes.PERMISSION_TYPE].find(
    (e: any) => e.name == 'No Access',
  );
  for (const role of roles) {
    const permission = globalObject.permissions[role]?.[module];
    if (permission && permission.value.read !== permissionType?.id) {
      if (subModule) {
        const subModulePermission = permission.subModules.find(
          (e: any) => e.subModuleId['name'] == subModule,
        );
        if (subModulePermission?.write) {
          return true;
        }
      } else {
        if (permission && permission.value.write !== permissionType?.id) {
          return true;
        }
      }
    }
  }
  return false;
}

export function isPermissionsDelete(module: string, subModule?: string): boolean {
  const roles: any[] = globalObject.userObject.roles.map((role: any) => role.name);
  const permissionType = globalObject.lookupvalues[LookupTypes.PERMISSION_TYPE].find(
    (e: any) => e.name == 'No Access',
  );
  for (const role of roles) {
    const permission = globalObject.permissions[role]?.[module];
    if (permission && permission.value.read !== permissionType?.id) {
      if (subModule) {
        const subModulePermission = permission.subModules.find(
          (e: any) => e.subModuleId['name'] == subModule,
        );
        if (subModulePermission?.delete) {
          return true;
        }
      } else {
        if (permission && permission.value.delete !== permissionType?.id) {
          return true;
        }
      }
    }
  }
  return false;
}

export function getInitials(userObj: any) {
  let initials = '';
  if (userObj.firstName) {
    initials += userObj.firstName[0].toUpperCase();
  }
  if (userObj.lastName) {
    initials += userObj.lastName[0].toUpperCase();
  }
  return initials;
}

export function getLabel(value: string | number | boolean | undefined): string {
  switch (typeof value) {
    case 'boolean':
      return toUpperFirstChar(value.toString());
    case 'number':
      return value.toString();
    case 'string':
      return value && value.toString().length > 150
        ? `${value.toString().substring(0, 150)}...`
        : value;
    default:
      return value ? value : '';
  }
}

export function filterSjdInfo(candidateSjdInfoList: any[] | undefined, sjdId: any) {
  if (candidateSjdInfoList && candidateSjdInfoList.length > 0) {
    const info = candidateSjdInfoList.filter((info) => info.sjdId == sjdId);
    return info.length > 0 ? info[0] : {};
  }
  return {};
}
export function getRole(user: any) {
  return user.roles
    .map((role: any) => toUpperFirstChar(getNameFromId(role.id, LookupTypes.ROLE)))
    .join(', ');
}
export function sortById(elements: any[]): any[] {
  return elements.sort((a, b) => a.id - b.id);
}

export function filterCommentsFromEvents(
  events: CandidateEventModel[] | undefined,
): CandidateEventModel[] {
  if (!events) return [];
  const eventId = getIdFromName('COMMENTS_ADDED', LookupTypes.CANDIDATE_EVENT_TYPE);
  return events.filter((event) => event.eventTypeId === eventId);
}

export function getIdFromName(name: string, type: LookupTypes) {
  const obj = globalObject.lookupvalues[type].find((v: any) => v.name == name);
  if (obj) {
    return obj.id;
  }
  return null;
}

export function mapSkills(resumeSkills: string[] | undefined, skillsList: any[]) {
  const skills = [] as any[];
  if (resumeSkills)
    resumeSkills.forEach((skill) => {
      const result = skillsList.filter((s) => equalsIgnoreCase(s.name, skill));
      if (result.length > 0) {
        skills.push({ id: result[0].id, name: toUpperFirstChar(result[0].name) });
      }
    });

  return skills;
}

function equalsIgnoreCase(str1: string, str2: string): boolean {
  return str1.toUpperCase() === str2.toUpperCase();
}

export interface BreadCrumbConfig {
  label: string;
  link: string;
}

export function getActivityEventName(evnetId: number, eventType: LookupTypes): string {
  return toUpperFirstChar(getNameFromId(evnetId, eventType).replace(/_/g, ' '));
}

export const getSJDColorFromStatus = (status: SJD_STATUS): string => {
  if (status === SJD_STATUS.COMPLETED) return '#b4f1c1';
  if (status === SJD_STATUS.ON_HOLD) return '#f0f0b1';
  if (status === SJD_STATUS.IN_PROGRESS) return '#bae2f5';
  return '#f5f5f5';
};
