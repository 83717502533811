import {
  Collapse,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Search } from '@mui/icons-material';

export const SourcerReport = (props: { data: any }) => {
  const { data } = props;
  const [filteredData, setFilteredData] = useState(data);
  const [sjdCountObj, setSjdCountObj] = useState({} as any);

  useEffect(() => {
    const sjdCountObj = {} as any;
    Object.keys(data).forEach((key) => {
      const set = new Set();
      data[key].forEach((elem: any) => set.add(elem.sjdId));
      sjdCountObj[key] = set.size;
    });
    setSjdCountObj(sjdCountObj);
  }, []);

  const Row = ({ data, index, recruiterName }: any) => {
    const [open, setOpen] = useState(false);
    return (
      <React.Fragment key={index}>
        <TableRow>
          <TableCell>
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell sx={{ textTransform: 'capitalize' }}>{recruiterName}</TableCell>
          <TableCell>{sjdCountObj[recruiterName]}</TableCell>
          <TableCell>{data[recruiterName].length}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Table size='small' aria-label='reporting-table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Candidate Name</TableCell>
                    <TableCell>Job Definiton</TableCell>
                    <TableCell>Sourced On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data[recruiterName].map((eventData: any) => (
                    <TableRow key={eventData.candidateId}>
                      <TableCell>
                        <Link
                          underline='none'
                          target={'_blank'}
                          href={`/source-job-definition/candidate/${btoa(eventData.sjdId)}/${btoa(
                            eventData.candidateId,
                          )}`}>
                          {eventData.candidateName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          underline='none'
                          target={'_blank'}
                          href={`/source-job-definition/${btoa(eventData.sjdId)}`}>
                          {eventData.sjdName}
                        </Link>
                      </TableCell>
                      <TableCell>{moment(eventData.eventCreatedOn).format('DD-MM-YYYY')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const requestSearch = (_searchedVal: string) => {
    const filteredRows = {} as any;
    Object.keys(data)
      .filter((name) => name.toLowerCase().includes(_searchedVal.toLowerCase()))
      .forEach((name) => (filteredRows[name] = data[name]));
    setFilteredData(filteredRows);
  };

  return (
    <>
      <div className='report-table-header'>
        <h1>Sourcer Report</h1>
        <div className='export-search-block'>
          <OutlinedInput
            id='table-search'
            type='text'
            placeholder='Search...'
            className='search-bar-report'
            onChange={(event) => requestSearch(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <Search />
              </InputAdornment>
            }
          />
        </div>
      </div>
      <TableContainer>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>No Of Source Definitions</TableCell>
              <TableCell>No Of Candidates Sourced</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(filteredData).length > 0 &&
              Object.keys(filteredData).map((recruiterName: string, index: number) => {
                return <Row key={index} data={data} index={index} recruiterName={recruiterName} />;
              })}
            {Object.keys(filteredData).length == 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <span>No results found</span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
