import {
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
  Button,
  Tooltip,
  AlertColor,
  Chip,
  Autocomplete,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { LookupTypes } from '../../../common/lookupTypes';
import { Details } from '../../../models/sjd-form.model';
import globalObject from './../../../common/global-variables';
import { useState } from 'react';
import '../source-job-definition.scss';
import dayjs from 'dayjs';
import { getLabel, getNameFromId, isPermissionsWrite } from './../../../common/commonUtil';
import SectionHeaderWithEdit from '../../common/section-heading';
import dateFormat from 'dateformat';
import LocationAutoComplete from '../../common/location-autocomplete';
import { MODULE, SubModuleTypes } from '../../../constants/common';

import Notify from '../../common/notify';
import AddSJDSkills from './sjd-skills-modal';
import AddSkills from '../../common/add-skills';

interface BasicDetailsProps {
  save: (values: Details) => Promise<boolean>;
  mode?: 'create-page' | 'details-page';
  values: Details | undefined;
}

const SjdDetailsForm: React.FC<BasicDetailsProps> = ({
  save,
  mode = 'create-page',
  values,
}: BasicDetailsProps) => {
  const detailsSchema = Yup.object().shape({
    name: Yup.string()
      .required('Title is required')
      .max(225, 'Title should not exceed 225 charcters'),
    domainId: Yup.number().required('Domain is required'),
    priorityId: Yup.number().required('Priority is required'),
    description: Yup.string()
      .required('Roles & Responsibilities is required')
      .max(10000, 'Roles & Responsibilities should not exceed 10000 charcters'),
    skills: Yup.array().of(Yup.object()).required('Skills is required'),
    endDate: Yup.string().required('End date is required'),
    clientId: Yup.number().required('Client is required'),
    operationId: Yup.number().required('Operations is required'),
    jobLocation: Yup.string()
      .required('Job Location is required')
      .max(500, 'Job Location should not exceed 500 charcters'),
    newOpenPositions: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .moreThan(-1, 'The value must be positive')
      .required('This field is required'),
    backfillOpenPositions: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .moreThan(-1, 'The value must be positive'),
    jobTypeId: Yup.number().required('Job type is required'),
    payTypeId: Yup.number().required('Pay type is required'),
    payRate: Yup.number()
      .nullable()
      .typeError('The value must be a number')
      .positive('The value must be positive'),
    visaType: Yup.string().max(100, 'Visa Type should not exceed 100 characters').nullable(),
    hiringManagers: Yup.array().of(Yup.object()).min(1, 'Hiring Manager is required').required(),
  });
  const formik = useFormik({
    initialValues: values || {
      name: '',
      domainId: undefined,
      priorityId: undefined,
      description: undefined,
      skills: [],
      endDate: undefined,
      clientId: undefined,
      operationId: undefined,
      newOpenPositions: undefined,
      backfillOpenPositions: undefined,
      jobTypeId: undefined,
      payTypeId: undefined,
      payRate: undefined,
      jobLocation: undefined,
      isVisa: undefined,
      visaType: undefined,
      hiringManagers: undefined,
    },
    onSubmit: (fieldValues) => {
      save(fieldValues).then(() => setEdit(mode === 'create-page'));
    },
    validationSchema: detailsSchema,
    validateOnBlur: true,
  });
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode === 'create-page');
  const [showSkillsDialog, setShowSkillsDialog] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }
  const setLocationCallback = (str?: string) => {
    formik.setFieldValue('jobLocation', str);
  };
  const [skillOpen, setSkillOpen] = useState(false);
  const handleAddSkillsOpen = () => {
    setSkillOpen((prevValue) => !prevValue);
  };
  // const [sjdSkills, setSjdSkills] = useState(values?.skills);

  const setSJDSkills = (skills: any) => {
    formik.values.skills = skills.filter((item: any) => item.skill);
    // setSjdSkills(skills);
  };

  const getSkillChips = () => {
    return (
      <>
        {formik.values.skills?.map((item: any, index) => (
          <Chip
            style={{ margin: '2px' }}
            key={index}
            label={
              item.skill.name + (item.minExperience ? ' (' + item.minExperience + ')' : '')
            }></Chip>
        ))}
      </>
    );
  };

  const getSkillText = () => {
    const text = formik.values.skills
      ?.map((item) => {
        return item.skill.name + (item.minExperience ? ' (' + item.minExperience + ')' : '');
      })
      .join(', ');
    return <span>{text}</span>;
  };

  return (
    <>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        {mode === 'details-page' && (
          <SectionHeaderWithEdit
            text='SJD Details'
            edit={edit}
            hideIcon={!isPermissionsWrite(MODULE.SJD, SubModuleTypes.JD_SJD_DETAILS)}
            click={() => {
              if (edit) {
                setSubmitted(true);
                formik.handleSubmit();
              } else {
                setEdit(!edit);
              }
            }}
          />
        )}
        <form
          style={{ marginBottom: '10px' }}
          onSubmit={(values) => {
            setSubmitted(true);
            formik.handleSubmit(values);
          }}>
          <div className='row'>
            <div style={{ width: '75%', display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }} className='fields-row'>
                <div className='field'>
                  <InputLabel className='field-label' id='name'>
                    Title *
                  </InputLabel>
                  {edit ? (
                    <>
                      <TextField
                        value={formik.values.name}
                        fullWidth
                        id='name'
                        size='small'
                        name='name'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className='mt-0 form-err-msg'>{getError(formik, 'name')}</div>
                    </>
                  ) : (
                    <span>{getLabel(formik.values.name)}</span>
                  )}
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='domain'>
                    Domain *
                  </InputLabel>
                  {edit ? (
                    <Select
                      size='small'
                      fullWidth
                      labelId='domain'
                      value={formik.values.domainId}
                      name='domainId'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id='demo-simple-select'>
                      <MenuItem disabled selected>
                        Select
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.DOMAIN].map(
                        (domain: any, index: number) => (
                          <MenuItem key={index} value={domain.id}>
                            {domain.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <span>
                      {getLabel(getNameFromId(formik.values.domainId, LookupTypes.DOMAIN))}
                    </span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'domainId')}</div>
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='priority'>
                    Priority *
                  </InputLabel>
                  {edit ? (
                    <Select
                      size='small'
                      fullWidth
                      labelId='priority'
                      value={formik.values.priorityId}
                      name='priorityId'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id='demo-simple-select'>
                      <MenuItem disabled selected>
                        Select
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.PRIORITY].map(
                        (priority: any, index: number) => (
                          <MenuItem key={index} value={priority.id}>
                            {priority.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <span>
                      {getLabel(getNameFromId(formik.values.priorityId, LookupTypes.PRIORITY))}
                    </span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'priorityId')}</div>
                </div>
              </div>
              <div style={{ display: 'flex' }} className='fields-row'>
                <div className='field'>
                  <InputLabel className='field-label' id='name'>
                    Skills *
                  </InputLabel>
                  {/* {false ? (
                    <>
                      <TextField
                        value={getSkillsStr()[0]}
                        fullWidth
                        id='name'
                        size='small'
                        name='name'
                        onClick={handleAddSkillsOpen}
                      />

                      <div className='mt-0 form-err-msg'>{getError(formik, 'name')}</div>
                    </>
                  ) : ( */}
                  {edit ? (
                    <div
                      onClick={handleAddSkillsOpen}
                      style={{
                        width: '100%',
                        minHeight: '40px',
                        border: '1px solid #b7b0b0de',
                        borderRadius: '4px',
                        cursor: 'text',
                      }}>
                      {getSkillChips()}
                    </div>
                  ) : (
                    getSkillText()
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'skills')}</div>
                </div>
                {skillOpen && (
                  <AddSJDSkills
                    handleClose={() => setSkillOpen(false)}
                    handleSubmit={setSJDSkills}
                    existingSkills={formik.values.skills}></AddSJDSkills>
                )}

                <div className='field' style={{ width: '33%' }}>
                  <InputLabel className='field-label' id='end-date'>
                    Target Date *
                  </InputLabel>
                  {edit ? (
                    <DatePicker
                      className='date-picker'
                      sx={{ width: '100%' }}
                      format='DD/MM/YYYY'
                      disablePast
                      views={['year', 'month', 'day']}
                      value={formik.values.endDate ? dayjs(formik.values.endDate) : ''}
                      onChange={(value) => {
                        formik.setFieldValue(
                          'endDate',
                          dateFormat(value?.toString(), 'isoDateTime'),
                          true,
                        );
                      }}
                    />
                  ) : (
                    <span style={{ overflowWrap: 'anywhere' }}>
                      {formik.values.endDate &&
                        dateFormat(new Date(formik.values.endDate), 'dd/mm/yyyy')}
                    </span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'endDate')}</div>
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='client'>
                    Client *
                  </InputLabel>
                  {edit ? (
                    <Select
                      size='small'
                      fullWidth
                      labelId='client'
                      value={formik.values.clientId}
                      name='clientId'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id='demo-simple-select'>
                      <MenuItem disabled selected>
                        Select
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.CLIENT].map(
                        (client: any, index: number) => (
                          <MenuItem key={index} value={client.id}>
                            {client.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <span>
                      {getLabel(getNameFromId(formik.values.clientId, LookupTypes.CLIENT))}
                    </span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'clientId')}</div>
                </div>
              </div>
            </div>
            <div style={{ width: '25%' }} className='fields-row field'>
              <InputLabel className='field-label' id='role-responsibilities-block'>
                Roles & Responsibilities *
              </InputLabel>
              {edit ? (
                <textarea
                  className='textarea-field'
                  name='description'
                  id='role-responsibilities-block'
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  rows={7}
                  style={{ width: '97%', height: '80%' }}></textarea>
              ) : (
                <Tooltip title={formik.values.description}>
                  <span>{getLabel(formik.values.description)}</span>
                </Tooltip>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'description')}</div>
            </div>
          </div>
          <div className='row'>
            <div
              style={{
                width: '75%',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <div style={{ display: 'flex' }} className='fields-row'>
                <div className='field'>
                  <InputLabel className='field-label' id='operation'>
                    Operation *
                  </InputLabel>
                  {edit ? (
                    <Select
                      size='small'
                      fullWidth
                      labelId='operation'
                      value={formik.values.operationId}
                      name='operationId'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id='demo-simple-select'>
                      <MenuItem disabled selected>
                        Select
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.OPERATION].map(
                        (operation: any, index: number) => (
                          <MenuItem key={index} value={operation.id}>
                            {operation.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <span>
                      {getLabel(getNameFromId(formik.values.operationId, LookupTypes.OPERATION))}
                    </span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'operationId')}</div>
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='backfill-open-positions'>
                    Open positions *
                  </InputLabel>
                  {edit ? (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px' }}>
                        <TextField
                          value={formik.values.newOpenPositions}
                          fullWidth
                          id='new-open-positions'
                          size='small'
                          name='newOpenPositions'
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(/\s/g, '');
                            formik.handleChange({
                              target: { name: 'newOpenPositions', value: numericValue },
                            });
                          }}
                          onBlur={formik.handleBlur}
                          label='New'
                        />
                        <div className='mt-0 form-err-msg'>
                          {getError(formik, 'newOpenPositions')}
                        </div>
                      </div>
                      <div>
                        <TextField
                          value={formik.values.backfillOpenPositions}
                          fullWidth
                          id='backfill-open-positions'
                          size='small'
                          name='backfillOpenPositions'
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(/\s/g, '');
                            formik.handleChange({
                              target: { name: 'backfillOpenPositions', value: numericValue },
                            });
                          }}
                          onBlur={formik.handleBlur}
                          label='Backfill'
                        />
                        <div className='mt-0 form-err-msg'>
                          {getError(formik, 'backfillOpenPositions')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span className='field-label' style={{ fontWeight: '610' }}>
                        New:
                      </span>
                      <span>{getLabel(formik.values.newOpenPositions)}</span>
                      {'  '}
                      <span className='field-label' style={{ fontWeight: '610' }}>
                        Backfill:
                      </span>
                      <span>{getLabel(formik.values.backfillOpenPositions)}</span>
                    </div>
                  )}
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='job-type'>
                    Job Type *
                  </InputLabel>
                  {edit ? (
                    <Select
                      size='small'
                      fullWidth
                      labelId='job-type'
                      value={formik.values.jobTypeId}
                      name='jobTypeId'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id='demo-simple-select'>
                      <MenuItem disabled selected>
                        Select
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.JOB_TYPE].map(
                        (jobType: any, index: number) => (
                          <MenuItem key={index} value={jobType.id}>
                            {jobType.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <span>
                      {getLabel(getNameFromId(formik.values.jobTypeId, LookupTypes.JOB_TYPE))}
                    </span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'jobTypeId')}</div>
                </div>
              </div>
            </div>
            <div style={{ width: '25%' }} className='fields-row field'>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', marginRight: '10px' }}>
                  <InputLabel className='field-label' id='pay-type'>
                    Pay Type *
                  </InputLabel>
                  {edit ? (
                    <Select
                      size='small'
                      fullWidth
                      labelId='pay-type'
                      value={formik.values.payTypeId}
                      name='payTypeId'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id='demo-simple-select'>
                      <MenuItem disabled selected>
                        Select Pay Type
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.PAY_TYPE].map(
                        (payType: any, index: number) => (
                          <MenuItem key={index} value={payType.id}>
                            {payType.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  ) : (
                    <span>
                      {getLabel(getNameFromId(formik.values.payTypeId, LookupTypes.PAY_TYPE))}
                    </span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'payTypeId')}</div>
                </div>
                <div style={{ width: '50%' }}>
                  <InputLabel className='field-label' id='pay-rate'>
                    Pay Rate
                  </InputLabel>
                  {edit ? (
                    <TextField
                      value={formik.values.payRate}
                      fullWidth
                      id='pay-rate'
                      size='small'
                      name='payRate'
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\s/g, '');
                        formik.handleChange({ target: { name: 'payRate', value: numericValue } });
                      }}
                      onBlur={formik.handleBlur}
                    />
                  ) : (
                    <span>{getLabel(formik.values.payRate)}</span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'payRate')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div
              style={{
                width: '75%',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <div style={{ display: 'flex' }} className='fields-row'>
                <div className='field'>
                  <InputLabel className='field-label' id='hiringManagers'>
                    Hiring Manager *
                  </InputLabel>
                  {edit ? (
                    <Autocomplete
                      id='combo-box-demo'
                      options={globalObject.lookupvalues[LookupTypes.HIRING_MANAGER]}
                      value={formik.values.hiringManagers}
                      sx={{ width: 300 }}
                      size='small'
                      limitTags={1}
                      getOptionLabel={(option: any) => option.name}
                      filterSelectedOptions
                      onBlur={() => formik.setFieldTouched('hiringManagers', true, true)}
                      defaultValue={values?.hiringManagers ? values.hiringManagers : undefined}
                      renderInput={(params) => <TextField {...params} label='' />}
                      onChange={(_e, val) => {
                        formik.setFieldValue('hiringManagers', val ? val : undefined);
                      }}
                      multiple
                    />
                  ) : (
                    <span style={{ overflowWrap: 'anywhere' }}>
                      {formik.values.hiringManagers?.map((s) => s.name).join(', ')}
                    </span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'hiringManagers')}</div>
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='jobLocation'>
                    Job Location *
                  </InputLabel>
                  {edit ? (
                    <LocationAutoComplete
                      onSelect={setLocationCallback}
                      initialValue={formik.values.jobLocation}></LocationAutoComplete>
                  ) : (
                    <span>{getLabel(formik.values.jobLocation)}</span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'jobLocation')}</div>
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='visa'>
                    Visa
                  </InputLabel>
                  {edit ? (
                    <Select
                      size='small'
                      fullWidth
                      labelId='visa'
                      value={formik.values.isVisa}
                      name='isVisa'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id='demo-simple-select'>
                      <MenuItem disabled selected>
                        Select
                      </MenuItem>
                      <MenuItem value={true as any}>Required</MenuItem>
                      <MenuItem value={false as any}>Not required</MenuItem>
                    </Select>
                  ) : (
                    <span>{getLabel(formik.values.isVisa)}</span>
                  )}
                  <div className='mt-0 form-err-msg'>{getError(formik, 'isVisa')}</div>
                </div>
              </div>
            </div>
            <div style={{ width: '25%' }} className='fields-row field'>
              <div style={{ width: '100%' }}>
                <InputLabel className='field-label' id='visaType'>
                  VisaType
                </InputLabel>
                {edit ? (
                  <TextField
                    value={formik.values.visaType}
                    fullWidth
                    id='visaType'
                    size='small'
                    name='visaType'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                ) : (
                  <span>{getLabel(formik.values.visaType)}</span>
                )}
                <div className='mt-0 form-err-msg'>{getError(formik, 'visaType')}</div>
              </div>
            </div>
          </div>
        </form>
      </Paper>
      {mode === 'create-page' && (
        <div className='bottom-btn-block'>
          <Button
            sx={{ marginLeft: 3 }}
            onClick={() => {
              setSubmitted(true);
              formik.handleSubmit();
            }}
            className='default-btn bg-btn'>
            Next
          </Button>
        </div>
      )}
      {showSkillsDialog && (
        <AddSkills
          handleResponse={(flag) => {
            if (flag === 'success') {
              setShowSkillsDialog(false);
            } else if (flag === 'error') {
              setShowToast(true);
              setToastMsg({
                severity: 'error',
                msg: 'Something went wrong.!',
              });
            } else if (flag === 'close') {
              setShowSkillsDialog(false);
            }
          }}
        />
      )}
      <Notify open={showToast} severity={toastMsg.severity} onClose={() => setShowToast(false)}>
        <span>{toastMsg.msg}</span>
      </Notify>
    </>
  );
};
export default SjdDetailsForm;
