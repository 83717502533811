import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { isPermissions, isUserRoleIn } from '../../common/commonUtil';
import { MODULE, ROLES, SubModuleTypes } from '../../constants/common';

interface Props {
  selectedTab: (selecteTab: string) => void;
}

const LmsHeader = ({ selectedTab }: Props) => {
  const [selectedType, setSelectedType] = useState(() => {
    const hasDashboard = isPermissions(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD);
    const hasLmsReport = isPermissions(MODULE.LMS, SubModuleTypes.LMS_REPORT);
    const hasCourseCatalogue = isPermissions(MODULE.LMS, SubModuleTypes.COURSE_CATALOGUE);
    const hasAdminRole = isUserRoleIn([ROLES.ADMIN]);
    if (!isUserRoleIn([ROLES.MANAGER])) {
      return hasAdminRole && !hasLmsReport
        ? hasDashboard
          ? 'Dashboard'
          : hasCourseCatalogue
          ? 'Course Catalogue'
          : null
        : 'Lms Report';
    } else {
      return isUserRoleIn([ROLES.MANAGER]) && !hasDashboard
        ? hasCourseCatalogue
          ? 'Course Catalogue'
          : hasLmsReport
          ? 'Lms Report'
          : null
        : 'Dashboard';
    }
  });

  return (
    <div style={{ height: '30px' }}>
      <Tabs
        value={selectedType}
        onChange={(_e, v) => {
          setSelectedType(v);
          selectedTab(v);
        }}>
        {isPermissions(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD) && (
          <Tab className='q-tab' label={'Dashboard'} value={'Dashboard'} />
        )}
        {isPermissions(MODULE.LMS, SubModuleTypes.COURSE_CATALOGUE) && (
          <Tab className='q-tab' label={'Course Catalogue'} value={'Course Catalogue'} />
        )}
        {isPermissions(MODULE.LMS, SubModuleTypes.LMS_REPORT) && (
          <Tab className='q-tab' label={'LMS Report'} value={'Lms Report'} />
        )}
      </Tabs>
    </div>
  );
};

export default LmsHeader;
