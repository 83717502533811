import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState, useEffect } from 'react';
import { getNameFromId } from '../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';
import { Loader } from '../common/loader';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

interface Props {
  initialData: any;
}

const SourceChannelChart = ({ initialData }: Props) => {
  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ff0000', '#ffbdc8'];

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
    } else {
      setLoading(false);
      const d: any[] = [];
      Object.keys(initialData).forEach((data) => {
        d.push({
          name: getNameFromId(Number(data), LookupTypes.SOURCE_CHANNEL),
          value: initialData[data],
        });
      });
      setData(d);
    }
  }, [initialData]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    _index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='source-channel-tooltip'>
          <div style={{ backgroundColor: `${payload[0].payload.fill}` }}></div>
          <p className='label'>{`${payload[0].name}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <Stack spacing={1}>
        <Typography variant='h6' fontWeight={700}>
          Source Channel
        </Typography>
      </Stack>
      {loading && <Loader />}
      {!loading && (
        <>
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '265px' }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={data}
                  dataKey='value'
                  nameKey='name'
                  cx='50%'
                  cy='50%'
                  outerRadius={100}
                  fill='#8884d8'
                  minAngle={10}
                  labelLine={false}
                  legendType='line'
                  label={renderCustomizedLabel}>
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {data.map((d, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '10px',
                  marginBottom: '5px',
                }}>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    background: colors[index % colors.length],
                    marginRight: '5px',
                  }}></div>
                <span>{d.name}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SourceChannelChart;
