import React from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { MenuItem, Select } from '@mui/material';
 import { DatePicker } from '@mui/x-date-pickers';
 
const OpenPositions = () => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ff0000'];

  const data: any[] = [
    { name: 'Accounts', value: 11, color: '#8884d8' },
    { name: 'IT', value: 9, color: '#83a6ed' },
    { name: 'Marketing', value: 15, color: '#8dd1e1' },
    { name: 'Operations', value: 3, color: '#ffc658' },
    { name: 'HR', value: 1, color: '#ff8042' },
    { name: 'Analytics', value: 11, color: '#FFBB28' },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, value, index }: any) => {
    const bendPointX = cx + (outerRadius + 30) * Math.cos(-midAngle * RADIAN);
    const bendPointY = cy + (outerRadius + 30) * Math.sin(-midAngle * RADIAN);

    const labelX = bendPointX + (bendPointX > cx ? 30 : -30);
    const labelY = bendPointY;

    return (
      <>
       <div
            style={{
              flex: 1,
              marginTop: '20px',
              marginBottom: '25px',
              height: '1.875rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div
              style={{
                display: 'flex',
              }}>
              <div>
                <Select
                  sx={{
                    width: '182px',
                    height: '30px',
                    marginRight: '10px',
                    marginLeft: '26.61px',
                  }}
                  size='small'
                  fullWidth
                  labelId='domain'
                  name='domain'
                  id='demo-simple-select'>
                  <MenuItem key={0}>All Job Defintions</MenuItem>
                  <MenuItem>Select</MenuItem>
                  <MenuItem>Select</MenuItem>
                </Select>
              </div>
              <div>
                <Select
                  sx={{ width: '135px', height: '30px', marginRight: '12px' }}
                  size='small'
                  fullWidth
                  labelId='domain'
                  name='domain'
                  id='demo-simple-select'>
                  <MenuItem key={0}>All Recruiters</MenuItem>
                  <MenuItem>Select</MenuItem>
                  <MenuItem>Select</MenuItem>
                </Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '9.49px' }}>
                <span>Start Date</span>
                <DatePicker className='date' />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>End Date</span>
                <DatePicker className='date' />
              </div>
            </div>
            <div style={{ height: '1.875rem' }}>
              <span
                style={{
                  fontSize: '1rem',
                  color: '#1976D2',
                  fontWeight: '400',
                  marginRight: '20px',
                  textDecoration: 'underline',
                }}>
                View All
              </span>
            </div>
          </div>
        <line
          x1={cx + outerRadius * Math.cos(-midAngle * RADIAN)}
          y1={cy + outerRadius * Math.sin(-midAngle * RADIAN)}
          x2={bendPointX}
          y2={bendPointY}
          stroke={data[index].color}
          strokeWidth={2}
        />
        <line
          x1={bendPointX}
          y1={bendPointY}
          x2={labelX}
          y2={labelY}
          stroke={data[index].color}
          strokeWidth={2}
        />
        <text
          x={labelX}
          y={labelY}
          fill='black'
          textAnchor={labelX > cx ? 'start' : 'end'}
          dominantBaseline='central'>
          {`${value} post(s)`}
        </text>
      </>
    );
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginLeft: '2.69rem', marginTop: '7.19rem' }}>
        <PieChart width={600} height={450}>
          <Pie
            className='piechart'
            data={data}
            cx={300}
            cy={200}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={150}
            fill='#8884d8'
            dataKey='value'>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
      <div
        style={{
          marginBottom: '10px',
        }}>
        {data.map((d, index) => (
          <React.Fragment key={index}>
            {index < 3 && (
              <div style={{ alignItems: 'center', marginRight: '10px', display: 'flex' }}>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    background: COLORS[index % COLORS.length],
                    marginRight: '5px',
                  }}></div>
                <span>{d.name}</span>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
export default OpenPositions;
