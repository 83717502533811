import axiosClient from '../common/axiosClient';
import { isUserRole } from '../common/commonUtil';
import globalObject from '../common/global-variables';
import { ROLES } from '../constants/common';

export async function getDashboardData(count: any, fromDate: any, toDate: any) {
  let params: any = { count };
  if (!isUserRole(ROLES.ADMIN)) {
    params = {
      ...params,
      userId: globalObject.userObject.id,
    };
  }
  if (fromDate && toDate) {
    params = {
      ...params,
      fromDate,
      toDate,
    };
  }
  return axiosClient.get('/dashboard', {
    params,
  });
}

export async function getQcRatedCandidates(fromDate?: any, toDate?: any) {
  let params = {};
  if (!isUserRole(ROLES.ADMIN)) {
    params = {
      userId: globalObject.userObject.id,
    };
  }
  if (fromDate && toDate) {
    params = {
      ...params,
      fromDate,
      toDate,
    };
  }
  return axiosClient.get('/dashboard/qc-rated', {
    params,
  });
}

export async function getActiveInterviews(fromDate?: any, toDate?: any) {
  let params = {};
  if (!isUserRole(ROLES.ADMIN)) {
    params = {
      userId: globalObject.userObject.id,
    };
  }
  if (fromDate && toDate) {
    params = {
      ...params,
      fromDate,
      toDate,
    };
  }
  return axiosClient.get('/dashboard/interview', {
    params,
  });
}

export async function getAuditCandidates() {
  return axiosClient.get('/dashboard/audit');
}

export async function getCandidateCountBySourceChannel() {
  return axiosClient.get('/dashboard/source-channel');
}

export async function getCandidateCountByStatus(fromDate?: any, toDate?: any) {
  let params = {};
  if (!isUserRole(ROLES.ADMIN)) {
    params = {
      userId: globalObject.userObject.id,
    };
  }
  if (fromDate && toDate) {
    params = {
      ...params,
      fromDate,
      toDate,
    };
  }
  return axiosClient.get('/dashboard/candidate-status', {
    params,
  });
}

export async function getSjdCountByStatus() {
  let params = {};
  if (!isUserRole(ROLES.ADMIN)) {
    params = {
      userId: globalObject.userObject.id,
    };
  }
  return axiosClient.get('/dashboard/sjd-status', {
    params,
  });
}

export async function getSourcingTrend(count: any) {
  return axiosClient.get('/dashboard/sourcing-trend', {
    params: {
      count,
    },
  });
}

export async function getRatingTrend(count: any) {
  return axiosClient.get('/dashboard/rating-trend', {
    params: {
      count,
    },
  });
}

export async function getMyActiveInterviews() {
  return axiosClient.get('/dashboard/interview/me');
}

export async function getNotification() {
  return axiosClient.get('/notification');
}
