import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { saveSkills } from '../../service/lookupService';
import { useState } from 'react';

interface Props {
  handleResponse: (flag: 'success' | 'error' | 'close') => void;
}

const AddSkills = ({ handleResponse }: Props) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: (_value) => {
      save();
    },
  });

  const save = () => {
    setLoading(true);
    const names: string[] = formik.values.name.split(',');
    const req: any[] = [];
    names.forEach((name) => {
      req.push({
        name: name.trim(),
      });
    });
    saveSkills(req)
      .then((_resp) => {
        handleResponse('success');
      })
      .catch((_err) => {
        handleResponse('error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth='xs'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Add Skills</DialogTitle>
        <DialogContent>
          <InputLabel>For multiple values, seperate them using comma</InputLabel>
          <TextField
            value={formik.values.name}
            fullWidth
            id='name'
            size='small'
            name='name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className='default-btn'
            onClick={() => {
              handleResponse('close');
            }}>
            Cancel
          </Button>
          <Button
            className='default-btn bg-btn'
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={!formik.values.name || formik.values.name.trim() == ''}
            autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default AddSkills;
