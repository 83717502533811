import { Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { getIdFromName, getRole } from '../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';
import { ROLES } from '../../constants/common';
import { UserDetails } from '../../models/user.model';
import { getMyTeam, addMyTeam, removeMyTeam } from '../../service/teamService';
import { getAllUsers } from '../../service/userService';

const MyTeam = () => {
  const [team, setTeam] = useState([] as any);
  const [recruiters, setRecruiters] = useState([] as any);
  const [selectedRecuiter, setSelectedRecruiter] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const HEADERS = ['Name', 'Email', 'Role', 'Action'];
  useEffect(() => {
    fetchTeam();
    getAllUsers(getIdFromName(ROLES.RECRUITER, LookupTypes.ROLE))
      .then((resp) => {
        setRecruiters(resp.data);
      })
      .catch((_err) => setRecruiters([]));
  }, []);
  const fetchTeam = () => {
    getMyTeam()
      .then((resp) => {
        setTeam(resp.data);
      })
      .catch((_err) => setTeam([]));
  };
  const addTeam = async () => {
    setLoading(true);
    await addMyTeam(selectedRecuiter.id).catch((_err) => {});
    setLoading(false);
    fetchTeam();
  };
  const removeTeam = async (user: UserDetails) => {
    setLoading(true);
    await removeMyTeam(user.id as number);
    setLoading(false);
    fetchTeam();
  };
  return (
    <div>
      <Box>
        <h3>Manage your team</h3>
      </Box>
      <Box sx={{ marginBottom: '10px', display: 'flex' }}>
        <div style={{ width: '300px' }}>
          <Autocomplete
            id='combo-box'
            disablePortal
            options={recruiters}
            size='small'
            sx={{ width: 300 }}
            getOptionLabel={(option: UserDetails) => option.firstName + ' ' + option.lastName}
            renderInput={(params) => (
              <TextField sx={{ background: 'white' }} {...params} label='Select recruiter' />
            )}
            onChange={(val, newVal) => setSelectedRecruiter(newVal)}
          />
        </div>
        <Tooltip title='select a recruiter then click to add in your team'>
          <Button
            sx={{ marginLeft: 5 }}
            onClick={() => {
              addTeam();
            }}
            className='default-btn'>
            Add to my team
          </Button>
        </Tooltip>
      </Box>
      <TableContainer sx={{ background: 'white' }}>
        <Table>
          <TableHead>
            <TableRow>
              {HEADERS.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {team.map((item: any) => (
              <TableRow key={item.id}>
                <TableCell>{item.firstName + ' ' + item.lastName}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{getRole(item)}</TableCell>
                <TableCell>
                  <Tooltip title='Remove'>
                    <IconButton onClick={() => removeTeam(item)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};
export default MyTeam;
