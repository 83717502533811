import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import ManageUsers from './manage-users';
import { isPermissions } from '../../common/commonUtil';
import { SupervisedUserCircleRounded } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import MyTeam from './my-team';
import { MODULE, SubModuleTypes } from '../../constants/common';
import GlobalSettings from './global-settings';
import KeyIcon from '@mui/icons-material/Key';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SettingsIcon from '@mui/icons-material/Settings';
import Profile from './profile';

const SETTING_TABS = {
  USERS: 'Users',
  PROFILE: 'Profile',
  MY_TEAM: 'My Team',
  GLOBAL_SETTING: 'Global Settings',
};

const UserSettings = () => {
  const [seletedTab, setSelectedTab] = useState('');
  const handleClick = (tab: string) => {
    setSelectedTab(tab);
  };

  useEffect(() => setSelectedTab(SETTING_TABS.PROFILE), []);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <nav aria-label='user settings'>
            <List>
              <ListItem
                disablePadding
                onClick={() => handleClick(SETTING_TABS.PROFILE)}
                selected={seletedTab == SETTING_TABS.PROFILE}
                sx={{ borderBottom: '1px dotted #888' }}>
                <ListItemButton>
                  <ListItemIcon>
                    <KeyIcon />
                  </ListItemIcon>
                  <ListItemText primary={SETTING_TABS.PROFILE} />
                </ListItemButton>
              </ListItem>
              {isPermissions(MODULE.SETTINGS, SubModuleTypes.USERS) && (
                <ListItem
                  disablePadding
                  selected={seletedTab == SETTING_TABS.USERS}
                  onClick={() => handleClick(SETTING_TABS.USERS)}
                  sx={{ borderBottom: '1px dotted #888' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <SupervisedUserCircleRounded />
                    </ListItemIcon>
                    <ListItemText primary={SETTING_TABS.USERS} />
                  </ListItemButton>
                </ListItem>
              )}
              {isPermissions(MODULE.SETTINGS, SubModuleTypes.MY_TEAMS) && (
                <ListItem
                  disablePadding
                  onClick={() => handleClick(SETTING_TABS.MY_TEAM)}
                  selected={seletedTab == SETTING_TABS.MY_TEAM}
                  sx={{ borderBottom: '1px dotted #888' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <Diversity3Icon />
                    </ListItemIcon>
                    <ListItemText primary={SETTING_TABS.MY_TEAM} />
                  </ListItemButton>
                </ListItem>
              )}
              {isPermissions(MODULE.SETTINGS, SubModuleTypes.GLOBAL_SETTINGS) && (
                <ListItem
                  disablePadding
                  selected={seletedTab == SETTING_TABS.GLOBAL_SETTING}
                  onClick={() => handleClick(SETTING_TABS.GLOBAL_SETTING)}
                  sx={{ borderBottom: '1px dotted #888' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={SETTING_TABS.GLOBAL_SETTING} />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </nav>
        </Grid>
        <Grid item xs={10}>
          {seletedTab == SETTING_TABS.USERS && <ManageUsers />}
          {seletedTab == SETTING_TABS.MY_TEAM && <MyTeam />}
          {seletedTab == SETTING_TABS.PROFILE && <Profile />}
          {seletedTab == SETTING_TABS.GLOBAL_SETTING && <GlobalSettings />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserSettings;
