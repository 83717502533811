import PageHeader from '../common/page-header/page-header';
import './dashboard.scss';
import { Paper } from '@mui/material';
import QcRatedCandidatesTable from './qc-rated-table';
import ActiveInterviews from './active-interview';
import SourceChannelChart from './source-channel';
import SourcingTrendChart from './sourcing-trend';
import SjdStatusChart from './sjd-status';
import { isPermissions } from '../../common/commonUtil';
import InterviewScheduleTable from './interview-schedule-table';
import AuditCandidatesTable from './audit-candidates-table';
import CandidateCountChart from './candidate-status';
import CandidateCountFunnelChart from './candidate-count-funnel-chart';
import RatingTrendChart from './rating-trend';
import { useEffect, useState } from 'react';
import { getDashboardData } from '../../service/dashboardService';
import CandidatesPieChart from './candidate-pie-status';
import dayjs from 'dayjs';
import { MODULE, SubModuleTypes } from '../../constants/common';

const Dashboard = () => {
  const [data, setData] = useState({} as any);

  useEffect(() => {
    getDashboardData(
      30,
      dayjs(dayjs()).subtract(15, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
    )
      .then((resp) => setData(resp.data))
      .catch((_err) =>
        setData({
          sjdCountByStatus: {},
          activeInterviews: [],
          qcRatedCandidates: [],
          candidateCountBySourceChannel: {},
          auditCandidates: [],
          sourcingTrends: [],
          ratingTrends: [],
          candidateCountByStatus: {},
          candidateCountBySubStatus: {},
        }),
      );
  }, []);
  return (
    <>
      <PageHeader title='Dashboard'></PageHeader>
      <div className='dashboard'>
        <div className='left-section'>
          <>
            {isPermissions(MODULE.DASHBOARD, SubModuleTypes.CANDIDATE_SUB_STATUS) && (
              <Paper sx={{ padding: [5, 2], marginBottom: '10px' }}>
                <CandidatesPieChart initialData={data.candidateCountBySubStatus} />
              </Paper>
            )}
            {isPermissions(MODULE.DASHBOARD, SubModuleTypes.JOB_DEFINATION_OVERVIEW) && (
              <Paper sx={{ padding: [5, 2], marginBottom: '10px' }}>
                <SjdStatusChart initialData={data.sjdCountByStatus} />
              </Paper>
            )}
            {isPermissions(MODULE.DASHBOARD, SubModuleTypes.INTERVIEW_SCHEDULES) && (
              <Paper sx={{ padding: [5, 2] }}>
                <ActiveInterviews />
              </Paper>
            )}
          </>
        </div>
        <div className='middle-section'>
          {isPermissions(MODULE.DASHBOARD, SubModuleTypes.CANDIDATE_FUNNEL_CHART) && (
            <Paper sx={{ padding: [5, 2], marginBottom: '10px' }}>
              <CandidateCountFunnelChart initialData={data.candidateCountByStatus} />
            </Paper>
          )}
          {isPermissions(MODULE.DASHBOARD, SubModuleTypes.AWAITING_PROFILER_RATING) && (
            <Paper sx={{ padding: [5, 2], marginBottom: '10px' }}>
              <QcRatedCandidatesTable initialData={data.qcRatedCandidates} />
            </Paper>
          )}
          {isPermissions(MODULE.DASHBOARD, SubModuleTypes.YOUR_INTERVIEWS) && (
            <Paper sx={{ padding: [5, 2], marginBottom: '10px' }}>
              <InterviewScheduleTable />
            </Paper>
          )}
          {isPermissions(MODULE.DASHBOARD, SubModuleTypes.CANDIDATE_COUNT_BY_STATUS) && (
            <Paper sx={{ padding: [5, 2], marginBottom: '10px' }}>
              <CandidateCountChart initialData={data.candidateCountByStatus} />
            </Paper>
          )}
          {isPermissions(MODULE.DASHBOARD, SubModuleTypes.CANDIDATES_TO_AUDIT) && (
            <Paper sx={{ padding: [5, 2], marginBottom: '10px' }}>
              <AuditCandidatesTable initialData={data.auditCandidates} />
            </Paper>
          )}
          {isPermissions(MODULE.DASHBOARD, SubModuleTypes.SOURCING_AUDIT_TREND) && (
            <Paper sx={{ padding: [5, 2] }}>
              <SourcingTrendChart initialData={data.sourcingTrends} />
            </Paper>
          )}
        </div>
        <div className='right-section'>
          {isPermissions(MODULE.DASHBOARD, SubModuleTypes.SOURCE_CHANNEL) && (
            <Paper sx={{ padding: [5, 2], marginBottom: '10px' }}>
              <SourceChannelChart initialData={data.candidateCountBySourceChannel} />
            </Paper>
          )}
          {isPermissions(MODULE.DASHBOARD, SubModuleTypes.AVERAGE_RATING) && (
            <Paper sx={{ padding: [5, 2] }}>
              <RatingTrendChart initialData={data.ratingTrends} />
            </Paper>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
