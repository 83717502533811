import { Button, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import PageHeader from '../common/page-header/page-header';
import './reporting.scss';
import { useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import { getReport, getReportExcel } from '../../service/reportService';
import { SourcerReport } from './SourcerReport';
import { Loader } from '../common/loader';
import DownloadIcon from '@mui/icons-material/Download';
import { AuditReport } from './auditReport';
import { isUserRole } from '../../common/commonUtil';
import { ROLES } from '../../constants/common';
import globalObject from '../../common/global-variables';
import { LookupTypes } from '../../common/lookupTypes';

const Reporting = () => {
  const [reportData, setReportData] = useState(undefined as any);
  const [rowData, setRowData] = useState(undefined as any);
  const [submitted, setSubmitted] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const reportValidationSchema = yup.object({
    fromDate: yup.date().required('Please select from date'),
    toDate: yup
      .date()
      .required('Please select to date')
      .min(yup.ref('fromDate'), 'To date should be greater or equal to from date'),
  });

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  const formik = useFormik({
    initialValues: {
      reportType: 'sourcerReport',
      fromDate: '',
      toDate: '',
      operations: -1,
    },
    validationSchema: reportValidationSchema,
    onSubmit: () => {
      setSubmitted(true);
      setLoading(true);
      const req: any = { ...formik.values };
      if (req.operations == -1) delete req.operations;
      getReport(req)
        .then((resp) => {
          setReportData(resp.data.result);
          setRowData(resp.data.result);
        })
        .catch((_err) => {})
        .finally(() => setLoading(false));
    },
  });

  const resetResults = () => {
    setReportData(undefined);
    setRowData(undefined);
    // formik.setFieldValue('operations', -1);
  };

  const downloadReport = () => {
    setSubmitted(true);
    formik.validateForm(formik.values).then((validationResp) => {
      if (Object.keys(validationResp).length == 0) {
        setSubmitted(true);
        setExcelLoading(true);
        const req: any = { ...formik.values };
        if (req.operations == -1) delete req.operations;
        getReportExcel(req)
          .then((resp) => {
            fetch(`data:application/octet-stream;base64,${resp.data}`)
              .then((res) => {
                return res.arrayBuffer();
              })
              .then((buf) => {
                setExcelLoading(false);
                const element = document.createElement('a');
                element.href = URL.createObjectURL(new Blob([new Uint8Array(buf)]));
                element.download = formik.values.reportType + '_' + Date.now() + '.xls';
                document.body.appendChild(element);
                element.click();
              })
              .catch(() => setExcelLoading(false));
          })
          .catch((_err) => {})
          .finally(() => setExcelLoading(false));
      }
    });
  };

  return (
    <div>
      <PageHeader title='Reporting'></PageHeader>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ width: 'fit-content' }}>
            <div style={{ display: 'flex' }}>
              <div className='report-field'>
                <InputLabel id='reportTypeLabel'>Select Report Type</InputLabel>
                <Select
                  name='reportType'
                  id='reportType'
                  labelId='reportTypeLabel'
                  fullWidth
                  size='small'
                  onChange={(e) => {
                    resetResults();
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  defaultValue='sourcerReport'
                  displayEmpty>
                  <MenuItem disabled defaultValue='Select Report Type'>
                    Select Report Type
                  </MenuItem>
                  <MenuItem value='sourcerReport'>Sourcer Report</MenuItem>
                  {isUserRole(ROLES.ADMIN) && <MenuItem value='auditReport'>Audit Report</MenuItem>}
                </Select>
                <div className='mt-0 form-err-msg'>{getError(formik, 'reportType')}</div>
              </div>
              <div className='report-field'>
                <InputLabel id='operations'>Operations</InputLabel>
                <Select
                  size='small'
                  fullWidth
                  labelId='operations'
                  value={formik.values.operations}
                  name='operations'
                  onChange={(e) => {
                    resetResults();
                    formik.handleChange(e);
                  }}
                  disabled={formik.values.reportType != 'sourcerReport'}
                  id='demo-simple-select'>
                  <MenuItem value={-1} selected>
                    All Operations
                  </MenuItem>
                  {globalObject.lookupvalues[LookupTypes.OPERATION].map(
                    (operation: any, index: number) => (
                      <MenuItem key={index} value={operation.id}>
                        {operation.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </div>
              <div className='report-field'>
                <InputLabel id='reportTypeLabel'>From</InputLabel>
                <DatePicker
                  className='date-picker'
                  value={formik.values.fromDate ? dayjs(formik.values.fromDate) : ''}
                  format='DD/MM/YYYY'
                  onChange={(value) => {
                    resetResults();
                    formik.setFieldValue(
                      'fromDate',
                      dateFormat(value?.toString(), 'isoDateTime'),
                      true,
                    );
                  }}
                />
                <div className='mt-0 form-err-msg'>{getError(formik, 'fromDate')}</div>
              </div>
              <div className='report-field'>
                <InputLabel id='reportTypeLabel'>To</InputLabel>
                <DatePicker
                  className='date-picker'
                  value={formik.values.toDate ? dayjs(formik.values.toDate) : ''}
                  format='DD/MM/YYYY'
                  onChange={(value) => {
                    resetResults();
                    formik.setFieldValue(
                      'toDate',
                      dateFormat(value?.toString(), 'isoDateTime'),
                      true,
                    );
                  }}
                />
                <div className='mt-0 form-err-msg'>{getError(formik, 'toDate')}</div>
              </div>
              <Button
                sx={{ marginTop: '20px', marginRight: '20px' }}
                type='submit'
                className='grid-btn'>
                Generate Report
              </Button>
              <div>
                {!excelLoading && (
                  <Button
                    disabled={
                      excelLoading ||
                      !reportData ||
                      !(Array.isArray(reportData)
                        ? reportData.length
                        : Object.keys(reportData).length)
                    }
                    sx={{ marginTop: '20px' }}
                    className='grid-btn'
                    onClick={downloadReport}>
                    {<DownloadIcon />}
                  </Button>
                )}
                {excelLoading && (
                  <div className='loader-button'>
                    <Loader />
                  </div>
                )}
              </div>
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={{ marginTop: '20px', marginRight: '20px' }}
                type='submit'
                className='grid-btn'>
                Generate Report
              </Button>
              <div>
                {!excelLoading && (
                  <Button
                    endIcon={<DownloadIcon />}
                    disabled={
                      excelLoading ||
                      !reportData ||
                      !(Array.isArray(reportData)
                        ? reportData.length
                        : Object.keys(reportData).length)
                    }
                    sx={{ marginTop: '20px' }}
                    className='grid-btn'
                    onClick={downloadReport}>
                    Download
                  </Button>
                )}
                {excelLoading && (
                  <div className='loader-button'>
                    <Loader />
                  </div>
                )}
              </div>
            </div> */}
          </div>
        </form>
      </Paper>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        {loading && <Loader />}
        {rowData && formik.values.reportType == 'sourcerReport' && <SourcerReport data={rowData} />}
        {rowData && formik.values.reportType == 'auditReport' && <AuditReport data={rowData} />}
      </Paper>
    </div>
  );
};

export default Reporting;
