import { useState, useEffect } from 'react';
import { Loader } from '../common/loader';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Stack, styled } from '@mui/system';
import useNavigate from '../../common/useNavigate';
import dateFormat from 'dateformat';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getNameFromId } from './../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';
import globalObject from '../../common/global-variables';

interface Props {
  initialData: any;
}

const AuditCandidatesTable = ({ initialData }: Props) => {
  const [data, setData] = useState([] as any[]);
  const [filteredData, setFilteredData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc' as 'asc' | 'desc');
  const [navigate] = useNavigate();
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState('0');
  const [operationsId, setOperationsId] = useState('0');
  const [startDate, setStartDate] = useState(dayjs(''));
  const [endDate, setEndDate] = useState(dayjs(''));

  useEffect(() => {
    let d = data.sort(function (a: any, b: any) {
      return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
    });
    if (startDate.isValid() && endDate.isValid()) {
      d = d.filter(
        (e) =>
          new Date(e.createdOn) >= startDate.toDate() && new Date(e.createdOn) <= endDate.toDate(),
      );
    }

    if (client != '0') d = d.filter((f: any) => f.clientId == client);

    if (operationsId != '0') d = d.filter((f: any) => f.operationsId == operationsId);

    setFilteredData(d);
  }, [startDate, endDate, client, operationsId]);

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
    } else {
      setLoading(false);
      const d = initialData.sort(function (a: any, b: any) {
        return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
      });
      setData(d);
      setFilteredData(d);
      setClients(Array.from(new Set(initialData.map((i: any) => i.clientId))));
    }
  }, [initialData]);

  const handleSort = () => {
    const flag = sortOrder == 'asc' ? 1 : -1;
    setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc');
    const d = filteredData.sort(function (a: any, b: any) {
      return (new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()) * flag;
    });
    setFilteredData(d);
  };

  const redirectToCandidatePage = (data: any) => {
    navigate(
      [
        {
          label: 'Job Definitions',
          link: '/source-job-definition',
        },
        {
          label: `${data['sjdId']} - ${data['sjdName']}`,
          link: `/source-job-definition/${btoa(data['sjdId'])}`,
        },
        {
          label: `${data['candidateName']}`,
          link: `/source-job-definition/candidate/${btoa(data['sjdId'])}/${btoa(
            data['candidateId'],
          )}`,
        },
      ],
      true,
    );
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F9F9F9',
      borderBottom: '2px solid #DEDEDE',
    },
  }));
  return (
    <>
      <Stack spacing={1}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
            marginRight: '5px',
          }}>
          <Typography variant='h6' fontWeight={700}>
            Candidates to audit
          </Typography>
          <div
            style={{
              display: 'flex',
            }}>
            <DatePicker
              sx={{ marginRight: '15px' }}
              className='date-picker-funnel'
              format='DD-MM-YYYY'
              label='From'
              value={startDate.isValid() ? dayjs(startDate) : dayjs(dayjs(''))}
              onChange={(value) => {
                setStartDate(dayjs(value));
              }}
            />
            <div style={{ display: 'flex', gap: '1rem' }}>
              <DatePicker
                className='date-picker-funnel'
                format='DD-MM-YYYY'
                label='To'
                value={endDate.isValid() ? dayjs(endDate) : dayjs('')}
                onChange={(value: any) => {
                  setEndDate(dayjs(value).endOf('day'));
                }}
              />
              <select
                className='audit-select'
                value={operationsId}
                onChange={(e: any) => {
                  setOperationsId(e.target.value);
                }}>
                <option value={0}>Operations</option>
                {globalObject.lookupvalues[LookupTypes.OPERATION].map((c: any, i: any) => (
                  <option key={i} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
            <select
              className='audit-select'
              value={client}
              style={{
                marginRight: '0px',
              }}
              onChange={(e: any) => {
                setClient(e.target.value);
              }}>
              <option value={0}>Client</option>
              {clients.map((c, i) => (
                <option key={i} value={c}>
                  {getNameFromId(c, LookupTypes.CLIENT)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Stack>
      <TableContainer className='qc-table'>
        <Table sx={{ background: 'white' }} size='small'>
          <TableHead style={{ position: 'sticky', top: '0' }}>
            <TableRow>
              <StyledTableCell>Candidate</StyledTableCell>
              <StyledTableCell>Source Definition</StyledTableCell>
              <StyledTableCell>Recruiter</StyledTableCell>
              <StyledTableCell onClick={() => handleSort()}>
                <div style={{ display: 'flex', cursor: 'pointer' }}>
                  Added On
                  {sortOrder == 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={4} sx={{ color: 'grey.500' }}>
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              filteredData.map((d, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link sx={{ cursor: 'pointer' }} onClick={() => redirectToCandidatePage(d)}>
                      {d.candidateName}
                    </Link>
                  </TableCell>
                  <TableCell>{d.sjdName}</TableCell>
                  <TableCell>{d.recruiterName}</TableCell>
                  <TableCell>{dateFormat(new Date(d.createdOn), 'dd/mm/yyyy hh:MM TT')}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AuditCandidatesTable;
