/* eslint-disable @typescript-eslint/no-unused-vars */
import PageHeader from './../common/page-header/page-header';
import {
  Paper,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  Button,
  Backdrop,
  CircularProgress,
  AlertColor,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getSkills } from '../../service/lookupService';
import {
  addQuestion,
  addQuestionnaire,
  deleteQuestion,
  getQuestionnaireById,
  getQuestionsByType,
  updateQuestionnaire,
} from '../../service/questionnaireService';
import globalObject from '../../common/global-variables';
import { LookupTypes } from '../../common/lookupTypes';
import { BreadCrumbConfig, getIdFromName } from './../../common/commonUtil';
import Autocomplete from '@mui/material/Autocomplete';
import './questionnaire.scss';
import { getNameFromId } from '../../common/commonUtil';
import { useLocation } from 'react-router-dom';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import useNavigate from './../../common/useNavigate';
import Notify from '../common/notify';
import BorderColorIcon from '@mui/icons-material/BorderColor';

function useQuery(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const QuestionnaireCreate = () => {
  const [navigate, , goBackOneStep] = useNavigate();
  const [skills, setSkills] = useState([] as any[]);
  const [newQuestions, setNewQuestions] = useState([] as any[]);
  const [questions, setQuestions] = useState([] as any[]);
  const technicalTypeId = getIdFromName('Technical', LookupTypes.QUESTIONNAIRE_TYPE);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionErrors, setQuestionErrors] = useState({
    question: '',
    answerType: '',
  });
  const [questionnaireErrors, setQuestionnaireErrors] = useState({
    name: '',
    type: '',
    skills: '',
    questions: '',
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  const [question, setQuestion] = useState('');
  const [answerType, setAnswerType] = useState('');
  const [required, setRequired] = useState(false);
  const [clone, setClone] = useState(true);
  const [edit, setEdit] = useState(true);

  const [questionnaire, setQuestionnaire] = useState({
    id: '',
    name: '',
    questionnaireTypeId: '',
    skills: [],
    questions: [],
    sjdId: [],
  } as {
    id: '';
    name: string;
    questionnaireTypeId: string;
    skills: any[];
    questions: any[];
    sjdId: any[];
  });

  const query: URLSearchParams = useQuery();
  const id = query.get('id') || undefined;

  useEffect(() => {
    setLoading(true);
    getSkills()
      .then((resp) => setSkills(resp.data))
      .catch((_err) => setSkills([]))
      .finally(() => setLoading(false));

    if (id) {
      setClone(false);
      setEdit(false);
      setLoading(true);
      getQuestionnaireById(atob(id))
        .then(({ data }: { data: typeof questionnaire }) => {
          setQuestionnaire(data);
          loadQuestions(data.questionnaireTypeId, data.skills, data.questions);
        })
        .catch((_err) => {
          setToastMsg({
            severity: 'error',
            msg: 'Something went wrong. Please try later!',
          });
          setShowToast(true);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const handleTypeChange = (type: any) => {
    setQuestionnaire({
      ...questionnaire,
      questionnaireTypeId: type,
      skills: [],
    });
    setQuestions([]);
    if (type != technicalTypeId) {
      loadQuestions(type, []);
    }
  };

  const handleSkillsChange = (val: any[]) => {
    setQuestionnaire(() => {
      return {
        ...questionnaire,
        skills: val,
      };
    });
    setQuestions(() => {
      return [];
    });
    if (val && val.length) loadQuestions(technicalTypeId, [...val]);
  };

  const validateQuestionnaire = (): boolean => {
    let isValid = true;
    let errors = {} as any;
    const { name, questionnaireTypeId, skills } = questionnaire;
    if (!name || name === '') {
      isValid = false;
      errors = {
        ...errors,
        name: 'Please enter a questionnaire name',
      };
    }

    if (!questionnaireTypeId || questionnaireTypeId === '') {
      isValid = false;
      errors = {
        ...errors,
        type: 'Please select a questionnaire type',
      };
    }

    if (questionnaireTypeId === technicalTypeId && skills.length == 0) {
      isValid = false;
      errors = {
        ...errors,
        skills: 'Please select atleast one skill',
      };
    }

    if (newQuestions.length == 0 && questions.filter((q: any) => q.checked).length == 0) {
      isValid = false;
      errors = {
        ...errors,
        questions: 'Please select atleast one question to proceed',
      };
    }

    if (!isValid) {
      setQuestionnaireErrors({
        ...errors,
      });
    } else {
      setQuestionnaireErrors({} as any);
    }
    return isValid;
  };

  const saveQuestionnaire = () => {
    setLoading(true);
    const q = {
      ...questionnaire,
      questions: [...newQuestions, ...questions.filter((q: any) => q.checked)],
    };

    const promise: Promise<any> = questionnaire.id ? updateQuestionnaire(q) : addQuestionnaire(q);

    promise
      .then((resp) => {
        setLoading(false);

        const existingConfig = localStorage.getItem('breadcrumb-config');
        if (existingConfig) {
          goBackOneStep();
        } else {
          navigate(
            [
              {
                label: 'Questionnaire',
                link: '/questionnaire',
              },
            ],
            true,
          );
        }
      })
      .catch((_err) => {
        setLoading(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const validateQuestion = () => {
    let isValid = true;
    let errors = {} as any;
    if (!question || question === '') {
      isValid = false;
      errors = {
        ...errors,
        question: 'Please enter a question',
      };
    }

    if (!answerType || answerType === '') {
      isValid = false;
      errors = {
        ...errors,
        answerType: 'Please select an answer type',
      };
    }

    if (!isValid) {
      setQuestionErrors({
        ...errors,
      });
    } else {
      setQuestionErrors({} as any);
    }
    return isValid;
  };

  const saveQuestion = () => {
    setLoading(true);
    const q = {
      question,
      required,
      answerTypeId: answerType,
    };
    addQuestion(q)
      .then((resp) => {
        setLoading(false);
        setQuestion('');
        setAnswerType('');
        setRequired(false);
        setNewQuestions([...newQuestions, { ...q, id: resp.data.result }]);

        setToastMsg({
          severity: 'success',
          msg: 'Question added successfully',
        });
        setShowToast(true);
      })
      .catch((_err) => {
        setLoading(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const deleteNewQuestion = (id: string) => {
    setLoading(true);
    deleteQuestion(id)
      .then((resp) => {
        setLoading(false);
        setNewQuestions([...newQuestions.filter((q) => q.id != id)]);

        setToastMsg({
          severity: 'success',
          msg: 'Question removed successfully',
        });
        setShowToast(true);
      })
      .catch((_err) => {
        setLoading(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const handleQuestionSelect = (index: any, checked: boolean, selectAll: boolean) => {
    if (selectAll) {
      const q: any[] = [];
      questions.forEach((el: any) => {
        q.push({
          ...el,
          checked: checked,
        });
      });
      setQuestions([...q]);
    } else {
      const q = questions.splice(index, 1);
      questions.splice(index, 0, {
        ...q[0],
        checked: checked,
      });
      setQuestions([...questions]);
    }
  };

  const loadQuestions = (type: string, skills: any[], existingQuestions?: any[]) => {
    setLoading(true);
    getQuestionsByType(type, skills)
      .then(({ data }: { data: any[] }) => {
        setLoading(false);
        if (existingQuestions && existingQuestions.length > 0) {
          const existingIds: any[] = existingQuestions.map((q) => q.id);
          const updated: any[] = [];
          data.forEach((d) => {
            updated.push({ ...d, checked: existingIds.includes(d.id) });
          });
          setQuestions(updated);
        } else {
          setQuestions(data);
        }
      })
      .catch((_err) => {
        setLoading(false);
      });
  };

  const cloneQuestionnaire = () => {
    setLoading(true);
    setQuestionnaire({
      ...questionnaire,
      id: '',
      name: '',
    });
    setClone(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      <PageHeader title='Add Questionnaire'></PageHeader>
      <Paper sx={{ padding: [5, 2], boxShadow: 'none' }}>
        <div>
          <div className='row'>
            <div className='w-25 padding'>
              <InputLabel className='field-label' id='name'>
                Questionnaire Name
              </InputLabel>
              <TextField
                value={questionnaire.name}
                onChange={(e) => setQuestionnaire({ ...questionnaire, name: e.target.value })}
                fullWidth
                id='name'
                size='small'
                name='name'
                disabled={!clone}
              />
              <div className='mt-0 form-err-msg'>{questionnaireErrors.name}</div>
            </div>
            <div className='w-25 padding'>
              <InputLabel className='field-label' id='type'>
                Questionnaire Type
              </InputLabel>
              <Select
                size='small'
                fullWidth
                labelId='type'
                value={questionnaire.questionnaireTypeId}
                name='type'
                onChange={(e) => handleTypeChange(e.target.value)}
                id='demo-simple-select'
                disabled={!clone}>
                <MenuItem disabled selected>
                  Select
                </MenuItem>
                {globalObject.lookupvalues[LookupTypes.QUESTIONNAIRE_TYPE].map(
                  (type: any, index: number) => (
                    <MenuItem key={index} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ),
                )}
              </Select>
              <div className='mt-0 form-err-msg'>{questionnaireErrors.type}</div>
            </div>
            <div className='w-25 padding'>
              {questionnaire.questionnaireTypeId == technicalTypeId && (
                <>
                  <InputLabel className='field-label' id='type'>
                    Skills
                  </InputLabel>
                  <div className='skill-autocomplete'>
                    <Autocomplete
                      multiple
                      id='combo-box-demo'
                      options={skills}
                      sx={{ width: 300 }}
                      size='small'
                      limitTags={2}
                      getOptionLabel={(option: any) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      filterSelectedOptions
                      renderInput={(params) => <TextField {...params} label='' />}
                      defaultValue={questionnaire.skills}
                      onChange={(_e, val) => {
                        handleSkillsChange(val);
                      }}
                      disabled={!clone}
                    />
                    <div className='mt-0 form-err-msg'>{questionnaireErrors.skills}</div>
                  </div>
                </>
              )}
            </div>
            <div className='w-25 padding clone-icon'>
              {!clone && !edit && (
                <Tooltip title='clone'>
                  <FileCopyIcon onClick={() => cloneQuestionnaire()} fontSize='small' />
                </Tooltip>
              )}
              {!clone && !edit && (
                <>
                  {!questionnaire?.sjdId?.length && (
                    <Tooltip title='edit'>
                      <BorderColorIcon
                        sx={{ marginLeft: '10px' }}
                        onClick={() => setEdit(!edit)}
                        fontSize='small'
                      />
                    </Tooltip>
                  )}
                  {questionnaire?.sjdId?.length > 0 && (
                    <Tooltip title='Cannot edit questionnaire as it is used in other SJD'>
                      <BorderColorIcon
                        sx={{ marginLeft: '10px', color: '#9E9FA0', cursor: 'default' }}
                        fontSize='small'
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          </div>
          {(clone || edit) && (
            <div style={{ padding: '20px 0px' }}>
              <Typography variant='h6' className='sub-title'>
                Add Question
              </Typography>
              <div className='row'>
                <div className='w-50' style={{ padding: '0px 0px 0px 20px' }}>
                  <InputLabel className='field-label' id='question'>
                    Question
                  </InputLabel>
                  <TextField
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    fullWidth
                    id='question'
                    size='small'
                    name='question'
                  />
                  <div className='mt-0 form-err-msg'>{questionErrors.question}</div>
                </div>
                <div className='w-23' style={{ padding: '0px 20px 0px 40px' }}>
                  <InputLabel className='field-label' id='answerType'>
                    Answer Type
                  </InputLabel>
                  <Select
                    size='small'
                    fullWidth
                    labelId='answerType'
                    value={answerType}
                    name='answerType'
                    onChange={(e) => setAnswerType(e.target.value)}
                    id='demo-simple-select'>
                    <MenuItem disabled selected>
                      Select
                    </MenuItem>
                    {globalObject.lookupvalues[LookupTypes.ANSWER_TYPE].map(
                      (type: any, index: number) => (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                  <div className='mt-0 form-err-msg'>{questionErrors.answerType}</div>
                </div>
                <div className='w-23 padding'>
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingTop: '20px' }}>
                      <Checkbox
                        checked={required}
                        onChange={(e) => setRequired(e.target.checked)}
                      />
                      <span className='field-label'>Required</span>
                    </div>
                    <div style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                      <Button
                        className='default-btn bg-btn'
                        onClick={() => {
                          if (validateQuestion()) {
                            saveQuestion();
                          }
                        }}>
                        Add
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {newQuestions.length > 0 && (
            <div style={{ padding: '20px 0px' }}>
              <Typography variant='h6' className='sub-title'>
                Newly Added Questions
              </Typography>
              <div
                className='mt-0 form-err-msg'
                style={{ paddingLeft: '20px', marginBottom: '10px' }}>
                {questionnaireErrors.questions}
              </div>
              <div>
                <table className='questions-table'>
                  <tbody>
                    {/* <tr>
                      <td>
                        <Checkbox
                          onChange={(e) => handleQuestionSelect(0, e.target.checked, true)}
                          disabled={!clone}
                        />
                      </td>
                      <td>Select all</td>
                      <td></td>
                    </tr> */}
                    {newQuestions.map((question: any, index: number) => (
                      <tr key={index}>
                        <td>{<Checkbox checked={true} style={{ display: 'none' }} />}</td>
                        <td>
                          <p>
                            {question.question}
                            {question.required ? ' *' : ''}
                          </p>
                          <span>
                            {getNameFromId(question.answerTypeId, LookupTypes.ANSWER_TYPE)}
                          </span>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <DeleteRoundedIcon
                            sx={{ color: '#a72037', cursor: 'pointer' }}
                            onClick={() => deleteNewQuestion(question.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div style={{ padding: '20px 0px' }}>
            <Typography variant='h6' className='sub-title'>
              Questions
            </Typography>
            <div
              className='mt-0 form-err-msg'
              style={{ paddingLeft: '20px', marginBottom: '10px' }}>
              {questionnaireErrors.questions}
            </div>
            <div>
              <table className='questions-table existing-questions'>
                <tbody>
                  <tr>
                    <td>
                      <Checkbox
                        onChange={(e) => handleQuestionSelect(0, e.target.checked, true)}
                        disabled={!clone}
                      />
                    </td>
                    <td>Select all</td>
                    <td></td>
                  </tr>
                  {questions.map((question: any, index: number) => (
                    <>
                      {(clone || edit || question.checked) && (
                        <tr key={index}>
                          <td>
                            <Checkbox
                              checked={question.checked}
                              onChange={(e) => handleQuestionSelect(index, e.target.checked, false)}
                              disabled={!clone && !edit}
                            />
                          </td>
                          <td>
                            <p>
                              {question.question}
                              {question.required ? ' *' : ''}
                            </p>
                            <span>
                              {getNameFromId(question.answerTypeId, LookupTypes.ANSWER_TYPE)}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {(clone || edit) && (
            <div className='bottom-btn-block'>
              <Button
                sx={{ marginLeft: 3 }}
                className='default-btn bg-btn'
                onClick={() => {
                  if (validateQuestionnaire()) {
                    // setShowAlert(true);
                    saveQuestionnaire();
                  }
                }}>
                Submit
              </Button>
            </div>
          )}
        </div>
      </Paper>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Notify open={showToast} severity={toastMsg.severity} onClose={() => setShowToast(false)}>
        <span>{toastMsg.msg}</span>
      </Notify>
      <Dialog
        open={showAlert}
        fullWidth={true}
        maxWidth='xs'
        onClose={() => setShowAlert(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Questionnaire once created cannnot be modified. Do you wish to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className='default-btn'
            onClick={() => {
              setShowAlert(false);
            }}>
            No
          </Button>
          <Button
            className='default-btn bg-btn'
            onClick={() => {
              setShowAlert(false);
              saveQuestionnaire();
            }}
            autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuestionnaireCreate;
