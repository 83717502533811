import { Check } from '@mui/icons-material';
import { StepConnector, stepConnectorClasses, StepIconProps, styled } from '@mui/material';
export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 14px)',
    right: 'calc(50% + 14px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#fff',
    border: '6px solid #A72037',
    background: '#FFFFFF',
    borderRadius: '50%',
    width: 15,
    height: 15,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#FFFFFF',
    zIndex: 1,
    background: '#A72037',
    borderRadius: '50%',
    width: 22,
    height: 22,
    padding: 4,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

export function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </ColorlibStepIconRoot>
  );
}
