import { Download } from '@mui/icons-material';
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputLabel,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { getResume, searchCandidate } from '../../service/candidateService';
import { getSkills } from '../../service/lookupService';
import LocationAutoComplete from '../common/location-autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import AddLinkIcon from '@mui/icons-material/AddLink';
import './candidate-search.scss';
interface Props {
  isModal?: boolean;
  modalClose?: () => void;
  tagCandidate?: (id: any) => void;
}
const CandidateSearch = ({
  isModal = false,
  modalClose = () => {},
  tagCandidate = () => {},
}: Props) => {
  const [skills, setSkills] = useState([] as any[]);
  const [candidates, setCandidates] = useState([] as any[]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const HEADERS = [
    'Name',
    'Job Definition',
    'Email',
    'Phone no.',
    'Location',
    'Experience (years)',
    'Skills',
    'Action',
  ];
  const formik = useFormik({
    initialValues: {
      candidateName: '',
      skills: [] as any[],
      minExp: '',
      maxExp: '',
      location: '',
    },
    onSubmit: () => {
      setLoading(true);
      searchCandidate(formik.values)
        .then((resp) => {
          setCandidates(resp.data);
          setLoading(false);
        })
        .catch((_err) => {
          setLoading(false);
        });

      setPage(0);
    },
    validate: (values) => {
      if (
        values.candidateName == '' &&
        values.skills.length == 0 &&
        values.minExp == '' &&
        values.maxExp == '' &&
        values.location == ''
      ) {
        return {
          candidateName: 'false',
        };
      }
    },
  });
  useEffect(() => {
    getSkills()
      .then((resp) => setSkills(resp.data))
      .catch((_err) => setSkills([]));
  }, []);
  const setLocationCallback = (loc?: string) => {
    formik.setFieldValue('location', loc);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const getSkillsStr = (str: string): string => {
    return str && str.length > 20 ? str.slice(0, 20).concat('...') : str;
  };

  const onDownloadClick = (resumeId: string, name: string, isNewIndex: boolean) => {
    setLoading(true);
    getResume(resumeId, isNewIndex)
      .then((resp) => {
        console.log('Response:', resp);

        // Extract file content and name from response
        const base64Content = resp.data;
        const fileName = resp.data.fileName || 'document.pdf';

        try {
          // Decode base64 content
          const byteCharacters = atob(base64Content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Determine the file type by checking the file extension
          const fileType = fileName?.split('.').pop()?.toLowerCase();
          let mimeType = 'application/octet-stream';

          if (fileType === 'pdf') {
            mimeType = 'application/pdf';
          } else if (fileType === 'doc' || fileType === 'docx') {
            mimeType = 'application/msword';
          }

          // Create a Blob from the byte array
          const blob = new Blob([byteArray], { type: mimeType });

          // Create a link element and trigger download
          const element = document.createElement('a');
          element.href = URL.createObjectURL(blob);
          element.download = name.replace(/ /g, '_') + '_' + Date.now() + `.${fileType}`;
          document.body.appendChild(element);
          element.click();

          // Clean up
          document.body.removeChild(element);
          URL.revokeObjectURL(element.href);

          setLoading(false);
        } catch (error) {
          console.error('Error processing file:', error);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching resume:', error);
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography className='header-name'>Candidate Search</Typography>
        {isModal && <CloseIcon onClick={modalClose} sx={{ cursor: 'pointer' }} />}
      </div>
      <Paper elevation={0} className='search-box'>
        <form style={{ display: 'flex' }}>
          <div className='search-form'>
            <span>
              <InputLabel className='field-label' id='candidateName'>
                Candidate Name/Email
              </InputLabel>
              <TextField
                fullWidth
                value={formik.values.candidateName}
                onChange={formik.handleChange}
                id='candidateName'
                size='small'
                name='candidateName'
                sx={{ width: '14.3vw' }}
              />
            </span>
            <span>
              <InputLabel className='field-label' id='combo-box-demo'>
                Skills
              </InputLabel>
              <div style={{ width: 'auto', minWidth: '200px' }}>
                <Autocomplete
                  multiple
                  id='combo-box-demo'
                  options={skills}
                  sx={{ width: '14.3vw' }}
                  size='small'
                  limitTags={1}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  renderInput={(params) => <TextField {...params} />}
                  value={formik.values.skills}
                  onChange={(_e, val) => formik.setFieldValue('skills', val)}
                />
              </div>
            </span>
            <div className='exp-field'>
              <span>
                <InputLabel className='field-label' id='minExp'>
                  Min Exp
                </InputLabel>
                <TextField
                  fullWidth
                  value={formik.values.minExp}
                  onChange={formik.handleChange}
                  id='minExp'
                  size='small'
                  name='minExp'
                  style={{ width: '6.25vw' }}
                />
              </span>
              to
              <span>
                <InputLabel className='field-label' id='maxExp'>
                  Max Exp
                </InputLabel>
                <TextField
                  fullWidth
                  value={formik.values.maxExp}
                  onChange={formik.handleChange}
                  id='maxExp'
                  size='small'
                  name='maxExp'
                  style={{ width: '6.25vw' }}
                />
              </span>
            </div>
            <span>
              <InputLabel className='field-label' id='location'>
                Location
              </InputLabel>
              <div style={{ width: '200px' }}>
                <LocationAutoComplete
                  onSelect={setLocationCallback}
                  initialValue={formik.values.location}></LocationAutoComplete>
              </div>
            </span>
            <div className='btn-field'>
              <Button
                onClick={(values: any) => formik.handleSubmit(values)}
                className='default-btn bg-btn'
                style={{ height: '65%' }}>
                Search
              </Button>
              <Button
                className='default-btn'
                style={{ height: '65%', width: '110px' }}
                onClick={(e: any) => formik.handleReset(e)}>
                Clear All
              </Button>
            </div>
          </div>
        </form>
        {formik.errors?.candidateName && (
          <div className='error-msg'>Please enter atleast one search criteria.</div>
        )}
      </Paper>
      {candidates.length > 0 ? (
        <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
          <Table sx={{ minWidth: 650, margin: 'auto' }} aria-label='simple table' size='small'>
            <TableHead sx={{ background: 'rgb(224 224 224)' }}>
              <TableRow>
                {HEADERS.map((userHeader) => (
                  <TableCell key={userHeader} sx={{ fontWeight: 1000 }}>
                    {userHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? candidates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : candidates
              ).map((candidate) => (
                <TableRow
                  key={candidate._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {candidate.fields['data.sjds'] && candidate.fields['data.sjds'].length > 0 ? (
                      <Link
                        href={`/source-job-definition/candidate/${btoa(
                          candidate.fields['data.sjds']?.[0].id,
                        )}/${btoa(candidate.fields['data.candidateId'])}`}
                        target='_blank'>
                        {candidate.fields['data.name']?.[0]}
                      </Link>
                    ) : (
                      candidate.fields['data.name']?.[0]
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.fields['data.sjds']?.map((sjd: any) => {
                      return (
                        <div key={sjd.id}>
                          <Tooltip title={sjd.name}>
                            <Link
                              href={`/source-job-definition/${btoa(sjd.id)}`}
                              target='_blank'>{`${sjd.id} - ${
                              sjd.name.length > 20 ? sjd.name.slice(0, 19) + '...' : sjd.name
                            }`}</Link>
                          </Tooltip>
                        </div>
                      );
                    })}
                  </TableCell>
                  <TableCell>{candidate.fields['data.email']?.[0]}</TableCell>
                  <TableCell>{candidate.fields['data.phoneNumber']?.[0]}</TableCell>
                  <TableCell>{candidate.fields['data.location']?.[0]}</TableCell>
                  <TableCell>{candidate.fields['data.experience']?.[0]}</TableCell>
                  <TableCell>
                    <Tooltip title={candidate.fields['data.skills']?.join(', ')}>
                      <Typography>
                        {getSkillsStr(candidate.fields['data.skills']?.join(', '))}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title='Download Resume'>
                        <IconButton
                          onClick={() =>
                            onDownloadClick(
                              candidate._id,
                              candidate.fields['data.name'][0],
                              candidate.isNewIndex,
                            )
                          }>
                          <Download sx={{ color: '#a72037' }} />
                        </IconButton>
                      </Tooltip>
                      {isModal && candidate.fields['data.candidateId'] && (
                        <>
                          <Tooltip title='Tag candidate to SJD'>
                            <IconButton>
                              <AddLinkIcon
                                sx={{ color: '#a72037' }}
                                onClick={() => setShowConfirmationDialog(true)}
                              />
                            </IconButton>
                          </Tooltip>
                          {showConfirmationDialog && (
                            <Dialog
                              open={showConfirmationDialog}
                              onClose={() => setShowConfirmationDialog(false)}>
                              <DialogTitle id='alert-dialog-title'>Confirm</DialogTitle>
                              <DialogContent>
                                <DialogContentText id='alert-dialog-description'>
                                  Are you sure you want to add the candidate to this SJD?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={() => setShowConfirmationDialog(false)}>
                                  Cancel
                                </Button>
                                <Button onClick={() => tagCandidate(candidate._id)}>
                                  Add Candidate
                                </Button>
                              </DialogActions>
                            </Dialog>
                          )}
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  count={candidates.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className='pagination'
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <span style={{ marginLeft: '20px' }}>No results</span>
      )}
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </React.Fragment>
  );
};

export default CandidateSearch;
