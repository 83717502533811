import { MenuItem, Select, ListItemText } from '@mui/material';
import { useState } from 'react';

interface SortOptions {
  label: string;
  value: number;
  asc: boolean;
  type: 'string' | 'date' | 'numeric';
  fieldName: string;
}

interface Props {
  data: any[];
  sortingData: (value: any[]) => void;
}

const TadTableSorting = ({ data, sortingData }: Props) => {
  const [sortOption, setSortOption] = useState<number | null>(null);

  const sortingOptions: SortOptions[] = [
    {
      label: 'A to Z',
      value: 0,
      asc: true,
      type: 'string',
      fieldName: 'name',
    },
    {
      label: 'Z to A',
      value: 1,
      asc: false,
      type: 'string',
      fieldName: 'name',
    },
    {
      label: 'Created On',
      value: 2,
      asc: true,
      type: 'date',
      fieldName: 'createdOn',
    },
    {
      label: 'Last Modified',
      value: 3,
      asc: true,
      type: 'date',
      fieldName: 'modifiedOn',
    },
  ];

  const sort = (fieldName: string, type: 'string' | 'date' | 'numeric', asc: boolean) => {
    function compare(a: any, b: any) {
      if (type !== 'numeric') {
        const nameA = type === 'string' ? a[fieldName]?.toLowerCase() : new Date(a[fieldName]);
        const nameB = type === 'string' ? b[fieldName]?.toLowerCase() : new Date(b[fieldName]);
        if (nameA < nameB) return asc ? -1 : 1;
        if (nameA > nameB) return asc ? 1 : -1;
      } else {
        const nameA = a[fieldName];
        const nameB = b[fieldName];
        if (!nameA && !nameB) return 0;
        else if (!nameA) return 1;
        else if (!nameB) return -1;
        return asc ? nameA - nameB : nameB - nameA;
      }
    }
    return [...data].sort(compare as any);
  };

  const handleSorting = (index: number) => {
    const selectedOption = sortingOptions.find((option) => option.value === index);
    if (selectedOption) {
      sortingData(sort(selectedOption.fieldName, selectedOption.type, selectedOption.asc));
    }
  };

  return (
    <div>
      <Select
        className='inputFeilds'
        sx={{ width: '9.375rem', height: '2.5rem' }}
        labelId='sort-by-label'
        id='sort-by-select'
        value={sortOption}
        onChange={(e) => {
          const selectedIndex = e.target.value as number;
          setSortOption(selectedIndex);
          handleSorting(selectedIndex);
        }}
        displayEmpty
        renderValue={(selected) => {
          if (selected === null) {
            return <span className='status-box'>Sort By</span>;
          }
          return (
            <span className='status-box'>
              {sortingOptions.find((opt) => opt.value === selected)?.label}
            </span>
          );
        }}>
        {sortingOptions.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ padding: '2px 10px 2px 10px' }}>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default TadTableSorting;
