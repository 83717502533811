import { AlertTitle, Button, FormControl, FormHelperText, Input, InputLabel } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import { changePassword } from '../../service/securityService';
import Notify from '../common/notify';

const ChangePassword = () => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const [showAlert, setShowAert] = useState(false);
  const PASSWD_NOT_MATCH = 're-entered password did not match';
  const PASSWD_LENGTH = 'password length should be atleast 6 character';
  const navigate = useNavigate();

  const handleP1Change = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword1(event.target.value);
    event.target.value.length < 6 ? setError(PASSWD_LENGTH) : setError('');
  };
  const handleP2Change = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword2(event.target.value);
    password1 !== event.target.value ? setError(PASSWD_NOT_MATCH) : setError('');
  };
  const handleSubmit = () => {
    if (!error) {
      changePassword(password1)
        .then((resp) => {
          if (resp.status == 200) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshtoken');
            setPassword1('');
            setPassword2('');
            setShowAert(true);
          }
        })
        .catch((_err) => {});
    }
  };
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAert(false);
    navigate('/login');
  };

  return (
    <>
      <Box sx={{ width: 200, marginLeft: 5 }}>
        <Box>
          <h3>Change Password</h3>
        </Box>
        <FormControl error={!!error} variant='standard' sx={{ marginBottom: 2 }}>
          <InputLabel>Enter new password</InputLabel>
          <Input
            type='password'
            value={password1}
            onChange={(event) => handleP1Change(event)}></Input>
        </FormControl>
        <FormControl error={!!error} variant='standard' sx={{ marginBottom: 2 }}>
          <InputLabel>Re-enter new password</InputLabel>
          <Input
            type='password'
            value={password2}
            onChange={(event) => handleP2Change(event)}></Input>
          {!!error && <FormHelperText id='component-error-text'>{error}</FormHelperText>}
        </FormControl>
        <Button className='bg-btn' onClick={() => handleSubmit()}>
          Submit
        </Button>
        <Notify open={showAlert} severity='success' onClose={handleClose}>
          <span>
            {' '}
            <AlertTitle>Success</AlertTitle>
            Password updated successfully — <strong>Logging out!</strong>
          </span>
        </Notify>
      </Box>
    </>
  );
};
export default ChangePassword;
