import axiosClient from '../common/axiosClient';

export async function getMyTeam() {
  return axiosClient.get('/team');
}

export async function addMyTeam(id: number) {
  return axiosClient.post('/team', [id]);
}

export async function removeMyTeam(id: number) {
  return axiosClient.delete('/team', { params: { memberId: id } });
}
