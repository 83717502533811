import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { getMyTeam } from '../../../service/teamService';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { HiringTeam } from '../../../models/sjd-form.model';
import { getAllUsers } from '../../../service/userService';
import {
  getIdFromName,
  getRole,
  isPermissionsDelete,
  isPermissionsWrite,
  toUpperFirstChar,
} from './../../../common/commonUtil';
import '../source-job-definition.scss';
import SectionHeaderWithEdit from '../../common/section-heading';
import { MODULE, ROLES, SubModuleTypes } from '../../../constants/common';
import { LookupTypes } from '../../../common/lookupTypes';

interface HiringTeamProps {
  save: (values: HiringTeam) => void;
  previous?: () => void;
  mode?: 'create-page' | 'details-page';
  values: HiringTeam;
}

const HiringTeamForm = ({
  save = () => {},
  previous = () => {},
  mode = 'create-page',
  values = { hiringTeam: [] },
}: HiringTeamProps) => {
  const [team, setTeam] = useState([] as any[]);
  const [evaluators, setEvaluators] = useState([] as any[]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([] as any[]);
  const [filteredOptions, setFilteredOptions] = useState([] as any[]);
  useEffect(() => {
    setSelectedUsers(values.hiringTeam || []);
    getMyTeam()
      .then((resp) => {
        setTeam(resp.data);
        setOptions(resp.data);
        setFilteredOptions(resp.data);
      })
      .catch((_err) => setTeam([]));
    // Call API to get evaluators
    getAllUsers(getIdFromName(ROLES.EVALUATOR, LookupTypes.ROLE))
      .then((resp) => {
        setEvaluators(resp.data);
      })
      .catch((_err) => setEvaluators([]));
  }, []);
  const [role, setRole] = useState('recruiter');
  const [selectedUsers, setSelectedUsers] = useState([] as any[]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [user, setUser] = useState({} as any);
  const [edit, setEdit] = useState(mode === 'create-page');
  const headers = ['Name', 'Email', 'Role', 'Mobile', 'Action'];
  const [error, setError] = useState(false);

  const userSelected = (value: any) => {
    setSelectedUser(value);
    setError(false);
    if (
      value &&
      (!selectedUsers || selectedUsers.filter((user) => user.id == value.id).length == 0)
    ) {
      setUser(value);
    } else {
      setUser({});
    }
  };

  const roleSelected = (value: string) => {
    setUser({});
    setSelectedUser(null);
    setError(false);
    setRole(value);
    if (value == 'recruiter') {
      setOptions(team);
      setFilteredOptions(team);
    } else {
      setOptions(evaluators);
      setFilteredOptions(evaluators);
    }
  };

  useEffect(() => {
    const filteredOption = options.filter((e) =>
      (e.firstName + ' ' + e.lastName).toLowerCase().includes(inputValue.toLowerCase()),
    );
    setFilteredOptions(filteredOption);
  }, [inputValue]);

  const addUser = () => {
    if (Object.keys(user).length !== 0) {
      setError(false);
      setSelectedUsers([...selectedUsers, { ...user, role: toUpperFirstChar(role) }]);
      setUser({});
    } else if (selectedUser !== null) {
      setError(true);
    }
  };

  const addAll = () => {
    const selctedIds = selectedUsers.map((user) => user.id);
    const newUsers = options.filter((option) => !selctedIds.includes(option.id));
    setSelectedUsers([...selectedUsers, ...newUsers]);
  };

  const removeUser = (id: number) => {
    setSelectedUsers(selectedUsers.filter((user) => user.id != id));
  };

  return (
    <div>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        {mode === 'details-page' && (
          <SectionHeaderWithEdit
            text='Hiring Team'
            edit={edit}
            hideIcon={!isPermissionsWrite(MODULE.SJD, SubModuleTypes.JD_HIRING_TEAM)}
            click={() => {
              if (edit) {
                save({ hiringTeam: selectedUsers });
              }
              setEdit(!edit);
            }}
          />
        )}
        {edit && (
          <Grid
            marginTop={5}
            display='flex'
            width='100%'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'>
            <div style={{ display: 'flex' }}>
              <div>
                <Select
                  className='select-field role-select'
                  placeholder='Role'
                  name='role'
                  id='role'
                  value={role}
                  onChange={(e) => roleSelected(e.target.value)}>
                  <MenuItem selected value='recruiter'>
                    Recruiter
                  </MenuItem>
                  <MenuItem value='evaluator'>Evaluator</MenuItem>
                </Select>
              </div>
              <div style={{ width: '300px' }}>
                {/* <Select
                  id='combo-box-demo'
                  fullWidth
                  sx={{ height: '39px' }}
                  onChange={(val) => {
                    userSelected(val.target.value);
                  }}
                  value={selectedUser}>
                  {options.flatMap((option) => (
                    <MenuItem value={option}>{option.firstName + ' ' + option.lastName}</MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  id='combo-box-demo'
                  size='small'
                  getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                  filterOptions={(x) => x}
                  options={filteredOptions}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={selectedUser}
                  isOptionEqualToValue={(option, value) =>
                    option.firstName + ' ' + option.lastName === value
                  }
                  noOptionsText='No Evaluators'
                  onChange={(_e, val) => userSelected(val)}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Start typing to search'
                      label=''
                      fullWidth
                    />
                  )}
                />
              </div>
              <Button className='default-btn' sx={{ marginLeft: 3 }} onClick={addUser}>
                Add
              </Button>
              {role == ROLES.RECRUITER && (
                <Tooltip title='Add All Recruiter in your team' placement='top'>
                  <Button className='default-btn' sx={{ marginLeft: 3 }} onClick={addAll}>
                    Add-All
                  </Button>
                </Tooltip>
              )}
            </div>
            {error && (
              <span
                style={{
                  color: 'red',
                  marginLeft: role === 'recruiter' ? '-133px' : '-28px',
                  fontSize: '15px',
                }}>
                {`${role} already exists`}
              </span>
            )}
          </Grid>
        )}
      </Paper>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        <div style={{ marginTop: '10px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header: any, index: number) => (
                  <TableCell align='left' key={index}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedUsers &&
                selectedUsers.map((user: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{user.firstName + ' ' + user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{getRole(user)}</TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                    <TableCell>
                      <IconButton
                        disabled={!isPermissionsDelete(MODULE.SJD, SubModuleTypes.JD_HIRING_TEAM)}>
                        <span
                          className={edit ? 'delete-icon' : 'delete-icon-disabled'}
                          onClick={() => {
                            if (edit) removeUser(user.id);
                          }}>
                          <DeleteRoundedIcon
                            className={
                              isPermissionsDelete(MODULE.SJD, SubModuleTypes.JD_HIRING_TEAM)
                                ? 'delete-icon'
                                : 'delete-icon-disabled'
                            }
                          />
                        </span>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
      {mode === 'create-page' && (
        <div className='bottom-btn-block'>
          <Button sx={{ marginRight: 3 }} onClick={() => previous()} className='default-btn bg-btn'>
            Previous
          </Button>
          <Button
            sx={{ marginLeft: 3 }}
            onClick={() => save({ hiringTeam: selectedUsers })}
            className='default-btn bg-btn'>
            Next
          </Button>
        </div>
      )}
    </div>
  );
};
export default HiringTeamForm;
