import {
  Paper,
  Rating,
  Typography,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from '@mui/material';
import { CandidateDetailsModel } from '../../../models/candidate-form.model';
import React, { useState, useEffect } from 'react';
import {
  filterCommentsFromEvents,
  filterSjdInfo,
  isPermissionsSubModule,
  isPermissionsWrite,
  isUserRole,
} from '../../../common/commonUtil';
import dateFormat from 'dateformat';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addCommentsToCandidate, updateCandidateRating } from '../../../service/candidateService';
import { INTERVIEW_RESULT, MODULE, ROLES, SubModuleTypes } from '../../../constants/common';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import DownloadIcon from '@mui/icons-material/Download';

interface Props {
  candidate: CandidateDetailsModel;
  sjdId: any;
  setLoading: (isLoading: boolean) => void;
  reloadCandidate: () => void;
  alert: (msg: string, success: boolean) => void;
}

const RatingsAndFeedback = ({ candidate, sjdId, setLoading, reloadCandidate, alert }: Props) => {
  const [sjdCandidateInfo, setSjdCandidateInfo] = useState({
    sjdId: '',
    candidateId: '',
    candidateStatusId: '',
    qcRating: '',
    profilerRating: '',
  } as any);

  const [showAlert, setShowAlert] = useState(false);
  const [ratingType, setRatingType] = useState('');
  const [rating, setRating] = useState();
  const [qcTooltip, setQcTooltip] = useState(0);
  const [profilerTooltip, setProfilerTooltip] = useState(0);

  const updateComments = (value: any) => {
    setLoading(true);
    addCommentsToCandidate({
      sjdId,
      candidateId: candidate.id,
      description: value.comment,
    })
      .then((_resp) => {
        reloadCandidate();
        alert('Comment added successfully', true);
        formik.resetForm({
          values: { comment: '' },
        });
      })
      .catch((_err) => {
        alert('Something went wrong. Please try later!', false);
      })
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: Yup.object().shape({
      comment: Yup.string()
        .required('Please enter a comment')
        .max(500, 'Comments should not exceed 500 characters'),
    }),
    onSubmit: (val) => {
      updateComments(val);
    },
  });

  useEffect(() => {
    if (candidate.sjdCandidateInfo) {
      const info = filterSjdInfo(candidate.sjdCandidateInfo, sjdId);
      setSjdCandidateInfo({ ...info });
    }
  }, [candidate]);

  const updateRating = (value: any, type: string) => {
    setRatingType((_prev) => {
      return type;
    });
    setRating((_prev) => {
      return value;
    });
    if (value < 3) {
      setShowAlert(true);
    } else {
      saveRating(value, type);
    }
  };

  const saveRating = (val?: any, type?: string) => {
    setLoading(true);
    const req = { ...sjdCandidateInfo };
    const t = type || ratingType;
    req[t] = val || rating;
    updateCandidateRating(req, t)
      .then((_resp) => {
        reloadCandidate();
        alert('Ratings updated successfully', true);
      })
      .catch((_err) => {
        alert('Something went wrong. Please try later!', false);
      })
      .finally(() => setLoading(false));
  };

  function handleFeedbackDownload() {
    const doc = new jsPDF();
    let finalY = 10;
    candidate.interview?.map((v) => {
      if (v.feedback) {
        const data = [
          ['Candidate Name', candidate.name],
          ['Candidate Email', candidate.email],
          ['Candidate Experience (years)*', candidate.experience],
          ['', ''],
          ['Evaluator Name', v.evaluator.firstName + ' ' + v.evaluator.lastName],
          ['Evaluator Email', v.evaluator.email],
          ['', ''],
          ['Interview Date', dateFormat(new Date(v.startTime), 'dd/mm/yyyy')],
          ['', ''],
          ['Evaluator Comments', v.feedback],
          ['', ''],
          ['Result', v.interviewResult],
        ];
        autoTable(doc, {
          head: [
            [
              {
                content: 'Interview Feedback - ' + v.interviewLevel,
                colSpan: 2,
                styles: { halign: 'center' },
              },
            ],
          ],
          body: data,
          theme: 'grid',
          bodyStyles: { minCellWidth: 50 },
          margin: { top: 10 },
          startY: finalY,
          tableLineWidth: 1,
          didParseCell: function (data) {
            if (data.row.index === 11 && data.column.index === 1) {
              data.cell.styles.textColor =
                data.cell.raw === INTERVIEW_RESULT.REJECTED ? [231, 76, 60] : [40, 170, 100];
              data.cell.styles.fontStyle = 'bold';
            }
          },
        });
        if (v.assessedSkills) {
          const skillsData: any[][] = [];
          const skills: any[] = JSON.parse(v.assessedSkills);
          skills.forEach((skill) => {
            skillsData.push([skill.skill, skill.rating]);
          });

          autoTable(doc, {
            head: [
              [
                {
                  content: 'Skill Level Rating (out of 5)',
                  colSpan: 2,
                  styles: { halign: 'center' },
                },
              ],
            ],
            body: skillsData,
            theme: 'grid',
            margin: { top: 10 },
            bodyStyles: { minCellWidth: 30 },
            tableLineWidth: 1,
            tableWidth: 'wrap',
          });
        }
        finalY = finalY + 300;
      }
    });
    doc.save(`${candidate.name}_interview_feedback.pdf`);
  }

  function checkfeedback() {
    const cand = candidate.interview;
    if (cand) {
      for (let i = 0; i < cand.length; i++) if (cand[i].feedback) return true;
    }
    return false;
  }

  return (
    <>
      <div>
        {isPermissionsSubModule(MODULE.SJD, SubModuleTypes.CANDIDATE_RESUME_AND_FEEDBACK) && (
          <div style={{ marginRight: '15px' }}>
            <div>
              <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
                <Typography sx={{ fontWeight: 500 }}>Ratings</Typography>
                <div style={{ display: 'flex', alignItems: 'stretch' }}>
                  <div
                    style={{
                      width: '50%',
                      marginRight: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '0px 10px',
                    }}
                    className='rating-div'>
                    <p>QC Rating</p>
                    <Tooltip title={qcTooltip}>
                      <Rating
                        name='simple-controlled'
                        precision={0.1}
                        size='large'
                        value={Number(sjdCandidateInfo.qcRating)}
                        disabled={!isUserRole(ROLES.RECRUITER) && !isUserRole(ROLES.LEAD)}
                        onChange={(_e, v) => updateRating(v, 'qcRating')}
                        onChangeActive={(_e, v) => setQcTooltip(v)}
                      />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      width: '50%',
                      marginLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '0px 10px',
                    }}
                    className='rating-div'>
                    <p>Profiler Rating</p>
                    <Tooltip title={profilerTooltip}>
                      <Rating
                        name='simple-controlled'
                        precision={0.1}
                        size='large'
                        value={Number(sjdCandidateInfo.profilerRating)}
                        disabled={!isUserRole(ROLES.LEAD)}
                        onChange={(_e, v) => updateRating(v, 'profilerRating')}
                        onChangeActive={(_e, v) => setProfilerTooltip(v)}
                      />
                    </Tooltip>
                  </div>
                </div>
              </Paper>
            </div>
            <div>
              <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
                <Typography sx={{ fontWeight: 500 }}>Add Comments</Typography>
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <textarea
                      className='textarea-field'
                      name='comment'
                      id='role-responsibilities-block'
                      onChange={formik.handleChange}
                      value={formik.values.comment}
                      onBlur={formik.handleBlur}
                      rows={4}
                      style={{ width: '95%' }}></textarea>
                    {formik.touched['comment'] && formik.errors['comment'] && (
                      <div className='mt-0 form-err-msg'>{formik.errors['comment']}</div>
                    )}
                  </form>
                </div>
                <div className='btn-block' style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    className='bg-btn comment-btn'
                    disabled={
                      !formik.dirty ||
                      Object.keys(formik.errors).length > 0 ||
                      !isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_RESUME_AND_FEEDBACK)
                    }
                    onClick={() => updateComments(formik.values)}>
                    Submit
                  </Button>
                </div>
                {filterCommentsFromEvents(candidate.events).map((event, index) => (
                  <div key={index} className='comment'>
                    <p>
                      <span>{event.createdBy}: </span>
                      {event.description}
                    </p>
                    {event.createdOn && (
                      <p>{dateFormat(new Date(event.createdOn), 'dd/mm/yyyy hh:MM TT')}</p>
                    )}
                  </div>
                ))}
              </Paper>
            </div>
          </div>
        )}
        {isUserRole(ROLES.EVALUATOR, true) && (
          <div style={{ width: '98%' }}>
            <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
              <Typography sx={{ fontWeight: 500, textAlign: 'left' }}>
                <span style={{ color: 'rgba(86, 85, 85, 0.8705882353)' }}> Candidate Name</span>:{' '}
                {candidate.name}
              </Typography>
            </Paper>
          </div>
        )}
        <div style={{ marginRight: '15px' }}>
          <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 500 }}>Interview Feedback</Typography>
              {checkfeedback() && (
                <Tooltip title='Download all feedback'>
                  <DownloadIcon
                    sx={{ color: '#A72037', cursor: 'pointer' }}
                    onClick={handleFeedbackDownload}
                  />
                </Tooltip>
              )}
            </div>
            {candidate.interview?.map(
              (v, index) =>
                v.feedback && (
                  <React.Fragment key={index}>
                    <div style={{ marginTop: '10px' }}>
                      <table className='feedback-table'>
                        <tbody>
                          <tr>
                            <td className='feedback'>
                              <p>Feedback by</p>
                              <span>{`${v.evaluator.firstName} ${v.evaluator.lastName}`}</span>
                            </td>
                            <td className='feedback'>
                              <p>Interview Date</p>
                              <span>{dateFormat(new Date(v.startTime), 'dd/mm/yyyy')}</span>
                            </td>
                            <td className='feedback'>
                              <p>Interview Level</p>
                              <span>{v.interviewLevel}</span>
                            </td>
                            <td className='feedback'>
                              <p>Result: </p>
                              <span>{v.interviewResult}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <span className='feedback-msg'>
                      <span style={{ display: 'block' }}>
                        <b>Comments: </b>
                        {v.feedback}
                      </span>
                      <br />

                      <span style={{ display: 'block' }}>
                        <b>Skills: </b>
                        {v.assessedSkills ? (
                          JSON.parse(v.assessedSkills).map((skill: any, index: any) => (
                            <span key={index}>
                              {skill.skill} ({skill.rating}) ,
                            </span>
                          ))
                        ) : (
                          <span> NA </span>
                        )}
                      </span>
                      <br />
                      <span>
                        <b>Recording: </b>
                        {v.recording.startsWith('http') ? (
                          <Link href={v.recording} target='_blank'>
                            Link
                          </Link>
                        ) : (
                          <span> NA </span>
                        )}
                      </span>
                    </span>
                  </React.Fragment>
                ),
            )}
          </Paper>
        </div>
      </div>
      <Dialog
        open={showAlert}
        fullWidth={true}
        maxWidth='xs'
        onClose={() => setShowAlert(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Rating a candidate below <strong>3</strong> will move the candidate to{' '}
            <strong>Rejected</strong> status.
            <br />
            Do you wish to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className='default-btn'
            onClick={() => {
              setShowAlert(false);
            }}>
            Cancel
          </Button>
          <Button
            className='default-btn bg-btn'
            onClick={() => {
              setShowAlert(false);
              saveRating();
            }}
            autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RatingsAndFeedback;
