/* eslint-disable camelcase */
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useState } from 'react';
import { SjdModel, SourcingStrategy } from '../../models/sjd-form.model';
import { ColorlibConnector, ColorlibStepIcon } from '../common/horizontal-stepper';
import SjdDetailsForm from './forms/sjd-details-form';
import InterviewProcess from './forms/interview-process';
import SourcingStrategyForm from './forms/sourcing-strategy-form';
import HiringTeamForm from './forms/hiring-team-form';
import { callRecommendationsApi, createSjd } from '../../service/sjdService';
import PageHeader from '../common/page-header/page-header';
import globalObject from '../../common/global-variables';
import useNavigate from './../../common/useNavigate';
import Notify from '../common/notify';
import { LookupTypes } from '../../common/lookupTypes';

const SourceJobDefinitionCreate = () => {
  const [navigate, , goBackOneStep] = useNavigate();
  type AlertColor = 'success' | 'info' | 'warning' | 'error';
  const steps = ['SJD Details', 'Sourcing Strategy', 'Hiring Team', 'Interview Process'];
  const [activeStep, setActiveStep] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [sjd, setSjd] = useState({} as any);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [formValue, setFormValue] = useState({
    details: {},
    sourcingDetails: {},
    hiringTeam: {},
    interviewProcessDetails: {},
  } as SjdModel);

  const appendFieldValues = (values: any, formName: string) => {
    const updatedValue = formValue;
    updatedValue[formName as keyof typeof formValue] = values;
    setFormValue(updatedValue);
  };

  const loadRecommendations = async (values: any): Promise<boolean> => {
    const required_skills: any = {};
    const skills: any[] = [];
    values.skills.forEach((s: any) => {
      if (s.minExperience) {
        required_skills[s.skill.name] = s.minExperience;
      } else {
        skills.push(s.skill.name);
      }
    });
    setShowOverlay(true);
    await callRecommendationsApi({ required_skills, skills })
      .then((resp) => {
        const sourcingStrategy: SourcingStrategy = {};

        const companies = resp.data.companies;
        if (companies && companies.length > 0) {
          sourcingStrategy.targetCompanies = companies
            .map((c: any) => {
              return Object.keys(c)
                .map((k) => {
                  return k;
                })
                .join(',');
            })
            .join(',');
        }

        const temp = resp.data.portal;
        if (temp && temp.length > 0) {
          const portals: string[] = temp.map((t: any) => {
            return t[0].toLowerCase();
          });
          sourcingStrategy.sourcingChannels = globalObject.lookupvalues[
            LookupTypes.SOURCE_CHANNEL
          ].filter((channel: any) => {
            {
              return portals.includes(channel.name.toLowerCase());
            }
          });
        }

        setFormValue((prev) => {
          return {
            ...prev,
            sourcingDetails: sourcingStrategy,
          };
        });
      })
      .catch((_err) => {})
      .finally(() => {
        setShowOverlay(false);
        setActiveStep(activeStep + 1);
      });
    return new Promise((resolve, _reject) => resolve(true));
  };

  const saveSjd = (values: any, formName: string) => {
    setShowOverlay(true);
    appendFieldValues(values, formName);
    let request: any = {};
    Object.keys(formValue).forEach((key, _i) => {
      request = { ...request, ...formValue[key as keyof typeof formValue] };
    });
    const team = [...request.hiringTeam, { id: globalObject.userObject.id }];
    request.hiringTeam = team;
    createSjd(request)
      .then((_resp) => {
        setShowOverlay(false);
        setToastMsg({
          severity: 'success',
          msg: 'Job Definition created successfully',
        });
        setShowToast(true);
        // goBackOneStep();
        setSjd(_resp.data);
        setShowDialog(true);
      })
      .catch((_err) => {
        setShowOverlay(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const tagQuestionnaire = () => {
    navigate(
      [
        { label: 'Job Definitions', link: '/source-job-definition' },
        { label: `${sjd.id} - ${sjd.name}`, link: `/source-job-definition/${btoa(sjd.id)}` },
        { label: 'Questionnaire', link: `/questionnaire?sjd=${sjd.id}` },
      ],
      true,
    );
  };

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  return (
    <>
      <PageHeader title='Source Job Definition Create'></PageHeader>
      <Paper className='stepper-block'>
        <Stepper
          className='steps'
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <span className={index === activeStep ? 'step-label-selected' : 'step-label'}>
                  {label}
                </span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
      {activeStep === 0 && (
        <SjdDetailsForm
          values={formValue.details}
          save={(values) => {
            appendFieldValues(values, 'details');
            return loadRecommendations(values);
          }}
        />
      )}
      {activeStep === 1 && (
        <SourcingStrategyForm
          values={formValue.sourcingDetails}
          previous={() => setActiveStep(activeStep - 1)}
          save={(values) => {
            appendFieldValues(values, 'sourcingDetails');
            setActiveStep(activeStep + 1);
            return new Promise((resolve, _reject) => resolve(true));
          }}
        />
      )}
      {activeStep === 2 && (
        <HiringTeamForm
          values={formValue.hiringTeam}
          previous={() => setActiveStep(activeStep - 1)}
          save={(values) => {
            appendFieldValues(values, 'hiringTeam');
            setActiveStep(activeStep + 1);
          }}
        />
      )}
      {activeStep === 3 && (
        <InterviewProcess
          values={formValue.interviewProcessDetails}
          previous={() => setActiveStep(activeStep - 1)}
          save={(values) => {
            saveSjd(values, 'interviewProcessDetails');
            return new Promise((resolve, _reject) => resolve(true));
          }}
        />
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showOverlay}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Notify open={showToast} severity={toastMsg.severity} onClose={handleToastClose}>
        <span>{toastMsg.msg}</span>
      </Notify>
      {showDialog && (
        <Dialog
          maxWidth='md'
          open={showDialog}
          onClose={() => {}}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>Link Questionnaire?</DialogTitle>
          <DialogContent sx={{ padding: '0px 25px' }}>
            SJD created successfully. Do you wish to add questionnaire to this SJD now?
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ marginRight: '10px', marginBottom: '10px' }}
              className='default-btn'
              onClick={() => {
                setShowDialog(false);
                goBackOneStep();
              }}>
              I&apos;ll do it later
            </Button>
            <Button
              sx={{ marginRight: '10px', marginBottom: '10px' }}
              className='default-btn bg-btn'
              onClick={() => tagQuestionnaire()}
              autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default SourceJobDefinitionCreate;
