import axiosClient from '../common/axiosClient';

export async function postCertificationDetails(request: any) {
  console.log(request);
  return axiosClient.post('/certification', request);
}

export async function getCertificationDetails(employeeId: any) {
  const params = employeeId ? { employeeId } : {};
  return axiosClient.get('/certification', { params });
}

export async function getCertificationArea() {
  return axiosClient.get('/certification/area');
}

export async function updateCertificationDetails(request: any) {
  return axiosClient.patch('/certification', request);
}

export async function getCertificationById(certificationId: any) {
  return axiosClient.get(`/certification/${certificationId}`);
}
