const globalObject: {
  properties: any;
  userObject: any;
  lookupvalues: any;
  permissions: any;
} = {
  properties: undefined,
  userObject: {},
  lookupvalues: {},
  permissions: {},
};

export default globalObject;
