import { Paper, Input, Grid } from '@mui/material';
import SectionHeaderWithEdit from '../../common/section-heading';
import { useState, useEffect } from 'react';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { getResume } from '../../../service/candidateService';
import { isUserRole } from '../../../common/commonUtil';
import { ROLES } from '../../../constants/common';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

interface Props {
  candidateYetToBeAccepted?: boolean;
  next?: () => void;
  mode?: 'create-page' | 'details-page';
  file?: any;
  resumeId?: any;
  isMigrated?: boolean;
  handleResumeUpload: (file: any) => void;
  submit?: () => void;
  resumeDialog?: (dialog: boolean) => void;
}
const UploadResumeForm = ({
  candidateYetToBeAccepted,
  mode = 'create-page',
  handleResumeUpload,
  resumeId,
  isMigrated = false,
  submit = () => {},
  resumeDialog,
}: Props) => {
  const [edit, setEdit] = useState(mode == 'create-page');

  useEffect(() => {
    if (resumeId) {
      getResume(resumeId, !isMigrated)
        .then((resp) => {
          const base64Content = resp.data.fileContent || resp.data; // Assuming resp.data might have fileContent or the base64 content directly
          const fileName = resp.data.fileName || 'resume.pdf'; // Default name if fileName is not provided

          try {
            // Decode base64 content
            const byteCharacters = atob(base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Determine the file type by checking the file extension
            const fileType = fileName?.split('.').pop()?.toLowerCase();
            let mimeType = 'application/octet-stream';

            if (fileType === 'pdf') {
              mimeType = 'application/pdf';
            } else if (fileType === 'doc' || fileType === 'docx') {
              mimeType = 'application/msword';
            }

            // Create a Blob from the byte array
            const blob = new Blob([byteArray], { type: mimeType });

            // Create a File object from the Blob and pass it to handleResumeUpload
            const file = new File([blob], fileName, { type: mimeType });
            handleResumeUpload(file);
          } catch (error) {
            console.error('Error processing file:', error);
          }
        })
        .catch((error) => {
          console.error('Error fetching resume:', error);
        });
    }
  }, []);

  return (
    <div className='candidate'>
      <Paper sx={{ marginTop: 1, padding: [2, 2], boxShadow: 'none' }}>
        {mode == 'details-page' && (
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '98%' }}>
              <SectionHeaderWithEdit
                text='Resume'
                edit={edit}
                hideIcon={
                  candidateYetToBeAccepted == true ||
                  (!isUserRole(ROLES.LEAD) && !isUserRole(ROLES.RECRUITER))
                }
                click={() => {
                  if (edit) {
                    submit();
                  }
                  setEdit(!edit);
                }}
              />
            </div>
            <div style={{ width: '2%', marginLeft: '10px' }}>
              <OpenInFullIcon
                sx={{
                  fontSize: 'medium',
                  verticalAlign: '-3px',
                  cursor: 'pointer',
                  color: '#a72037',
                }}
                onClick={() => {
                  if (resumeDialog) resumeDialog(true);
                }}></OpenInFullIcon>
            </div>
          </div>
        )}
        {edit && (
          <Grid display='flex' alignItems='center' justifyContent='center'>
            <Input
              type='file'
              className='file-field'
              inputProps={{ accept: '.pdf, .docx' }}
              onChange={(e: any) => {
                handleResumeUpload(e.target.files[0]);
              }}
            />
          </Grid>
        )}
      </Paper>
    </div>
  );
};

export default UploadResumeForm;
