import { MenuItem, Select, Box } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const HiredByMonth = () => {
  const data = [
    { name: 'Jan', value: 0 },
    { name: 'Feb', value: 400 },
    { name: 'Mar', value: 500 },
    { name: 'Apr', value: 600 },
    { name: 'May', value: 700 },
    { name: 'Jun', value: 800 },
  ];

  const COLORS = ['#0088FE'];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '-40px',
          marginBottom: '25px',
          padding: '0 20px',
          maxWidth: '1000px',
          marginLeft: '10px',
          marginRight: 'auto',
          gap: '1rem',
          flexWrap: 'wrap',
        }}>
        <Box display='flex' gap='1rem'>
          <Select
            sx={{ width: '182px', height: '30px' }}
            size='small'
            labelId='year-select'
            name='year'
            defaultValue={2024}
          >
            <MenuItem value={2024}>Today</MenuItem>
            <MenuItem value={2023}>Tomorrow</MenuItem>
          </Select>

          <Select
            sx={{ width: '182px', height: '30px' }}
            size='small'
            labelId='job-def-select'
            name='jobDefinition'
            defaultValue='Job Definition'
          >
            <MenuItem value='Job Definition'>Department</MenuItem>
          </Select>
        </Box>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '7.19rem',
          height: '450px',
          position: 'relative',
        }}>
        <ResponsiveContainer width='80%' height='100%'>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type='linear'
              dataKey='value'
              stroke={COLORS[0]}
              fill={COLORS[0]}
              fillOpacity={0.3}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default HiredByMonth;
