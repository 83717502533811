import { Paper, InputLabel, TextField, Select, MenuItem, Button, IconButton } from '@mui/material';
import SectionHeaderWithEdit from './../../common/section-heading';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import CandidateDataTable, { HeaderProps } from './candidate-data-table';
import {
  deleteInterviewData,
  scheduleInterview,
  updateInterviewDetails,
} from '../../../service/candidateService';
import { AxiosError, AxiosResponse } from 'axios';
import { isPermissionsDelete, isPermissionsWrite } from '../../../common/commonUtil';
import { MODULE, ROLES, SubModuleTypes } from './../../../constants/common';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ConfirmationDialog from '../../common/confirmation-dialog';
import globalObject from '../../../common/global-variables';
import { refreshToken } from '../../../service/securityService';
import { getIdFromName } from './../../../common/commonUtil';
import { LookupTypes } from '../../../common/lookupTypes';
import useNavigate from '../../../common/useNavigate';
// import { redirect } from 'react-router-dom';

interface Props {
  sjd: any;
  candidate: any;
  setLoading: (isLoading: boolean) => void;
  reloadCandidate: () => void;
  alert: (msg: string, success: boolean) => void;
}

const ScheduleInterview = ({ sjd, candidate, setLoading, reloadCandidate, alert }: Props) => {
  const [edit, setEdit] = useState(
    isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_INTERVIEW),
  );
  const [evaluators, setEvaluators] = useState([] as any[]);
  const [interviews, setInterviews] = useState([] as any[]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [interviewToDelete, setInterviewToDelete] = useState<any>(null);
  const [navigate] = useNavigate();

  const ONLINE = 'Online';
  const OFFLINE = 'Face to face';
  const validationSchema = Yup.object().shape({
    interviewLevel: Yup.string().required(),
    evaluator: Yup.object().required(),
    startTime: Yup.string().required(),
    isOnlineMeeting: Yup.boolean().required(),
  });
  const formik = useFormik({
    initialValues: {
      interviewLevel: '',
      evaluator: '',
      startTime: '',
      endTime: '',
      isExisting: false,
      isOnlineMeeting: undefined,
    },
    onSubmit: () => {
      interviewSchedule(formik.values);
    },
    validationSchema,
  });

  const tableHeaders: HeaderProps[] = [
    {
      name: 'Interview Level',
      key: 'interviewLevel',
    },
    {
      name: 'Mode',
      key: 'mode',
    },
    {
      name: 'Evaluator',
      key: 'evaluatorName',
    },
    {
      name: 'Start Time',
      key: 'startTime',
      type: 'date',
      format: 'dd/mm/yyyy hh:MM TT',
    },
    {
      name: 'End Time',
      key: 'endTime',
      type: 'date',
      format: 'dd/mm/yyyy hh:MM TT',
    },
  ];

  const handleEdit = (index: number) => {
    const obj: any = interviews.splice(index, 1);
    formik.setValues({
      ...obj[0],
      isExisting: true,
    });
  };

  useEffect(() => {
    const e: any[] = [];
    sjd.hiringTeam?.forEach((user: any) => {
      if (user.roles.some((role: any) => role.name === ROLES.EVALUATOR)) {
        e.push(user);
      }
    });
    setEvaluators([...evaluators, ...e]);
  }, [sjd]);

  useEffect(() => {
    const i: any[] = [];
    candidate.interview.map((v: any) => {
      i.push({
        ...v,
        evaluatorName: `${v.evaluator.firstName} ${v.evaluator.lastName}`,
        evaluatorId: v.evaluator.id,
        mode: v.isOnlineMeeting == undefined ? '' : v.isOnlineMeeting ? ONLINE : OFFLINE,
      });
    });
    setInterviews([...i]);
  }, [candidate]);

  const handleStartTimeChange = (value: any) => {
    const startTime = dateFormat(value?.toString(), 'isoDateTime');
    const endTime = dateFormat(dayjs(startTime).add(1, 'hour').toString(), 'isoDateTime');
    formik.setFieldValue('startTime', startTime, true);
    formik.setFieldValue('endTime', endTime, false);
  };

  const isLeadOrRecruiter = (user: any) => {
    const ids: any[] = [
      getIdFromName(ROLES.LEAD, LookupTypes.ROLE),
      getIdFromName(ROLES.RECRUITER, LookupTypes.ROLE),
    ];
    return user.roles.filter((r: any) => ids.includes(r.id)).length > 0;
  };

  const interviewSchedule = (req: any) => {
    setLoading(true);

    const request = {
      ...req,
      sjdId: sjd.id,
      candidateId: candidate.id,
      candidateEmail: candidate.email,
      candidateName: candidate.name,
      recruiterEmail: globalObject.userObject.email,
      recruiterName: globalObject.userObject.firstName + ' ' + globalObject.userObject.lastName,
      hiringTeamEmails: sjd.hiringTeam
        .filter((h: any) => isLeadOrRecruiter(h))
        .map((u: any) => u.email),
    };
    const token = localStorage.getItem('refreshtoken');
    const promise =
      globalObject.properties.enable_microsoft_meeting == 'true'
        ? refreshToken(token || '')
        : new Promise((resolve) => {
            resolve({ data: {} });
          });
    promise
      .then((resp: any) => {
        localStorage.setItem('refreshtoken', resp.data.refresh_token);
        const apiCall: Promise<AxiosResponse<any, any>> = req.isExisting
          ? updateInterviewDetails(request, false, resp.data.access_token)
          : scheduleInterview(request, resp.data.access_token);
        apiCall
          .then((_resp) => {
            reloadCandidate();
            alert('Interview scheduled and e-mail has been sent successfully!', true);
            formik.resetForm();
          })
          .catch((err: AxiosError<{ message?: string }>) => {
            let msg = 'Something went wrong. Please try later!';
            if (err && err.response && err.response.data) msg = err.response.data.message || msg;
            alert(msg, false);
          })
          .finally(() => setLoading(false));
      })
      .catch((_err) => {
        alert('Your session expired. Please login again!', true);
        setTimeout(() => {
          setLoading(false);
          navigate([
            {
              label: 'Login',
              link: '/login',
            },
          ]);
        }, 2000);
      });
  };

  const handleDelete = (index: number) => {
    setInterviewToDelete(interviews[index]);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    if (interviewToDelete) {
      const token = localStorage.getItem('refreshtoken');
      const promise =
        globalObject.properties.enable_microsoft_meeting == 'true'
          ? refreshToken(token || '')
          : new Promise((resolve) => {
              resolve({ data: {} });
            });
      promise
        .then((resp: any) => {
          deleteInterviewData(
            {
              sjdId: interviewToDelete.sjdId,
              candidateId: interviewToDelete.candidateId,
              idToBeDeleted: interviewToDelete.id,
            },
            resp.data.access_token,
          )
            .then((_resp) => {
              const updatedInterviews = [...interviews];
              const index = interviews.indexOf(interviewToDelete);
              if (index !== -1) {
                updatedInterviews.splice(index, 1);
                setInterviews(updatedInterviews);
              }
              alert('Interview schedule deleted successfully', true);
            })
            .catch((_err) => {
              alert('Something went wrong. Please try again later!', false);
            })
            .finally(() => {
              setInterviewToDelete(null);
              setOpenDeleteDialog(false);
            });
        })
        .catch((_err) => {
          alert('Something went wrong. Please try again later!', false);
        });
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
    setInterviewToDelete(null);
  };

  const getActions = (data: any, index: number | undefined) => {
    if (data.feedback) return <>Feedback Submitted</>;

    return (
      <>
        <DriveFileRenameOutlineIcon
          fontSize='small'
          sx={{ color: '#3366CC', cursor: 'pointer' }}
          onClick={() => handleEdit(index || 0)}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <IconButton disabled={!isPermissionsDelete(MODULE.SJD, SubModuleTypes.CANDIDATE_INTERVIEW)}>
          <DeleteIcon
            fontSize='small'
            // sx={{ color: '#A72037', cursor: 'pointer' }}
            onClick={() => handleDelete(index || 0)}
            className={
              isPermissionsDelete(MODULE.SJD, SubModuleTypes.CANDIDATE_INTERVIEW)
                ? 'delete-icon'
                : 'delete-icon-disabled'
            }
          />
        </IconButton>
      </>
    );
  };

  return (
    <div>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        <SectionHeaderWithEdit
          text='Schedule Interview'
          edit={edit}
          hideIcon={true}
          click={() => {
            setEdit(!edit);
          }}
        />

        <CandidateDataTable
          headers={tableHeaders}
          data={interviews}
          readonly={!edit}
          edit={handleEdit}
          getActions={getActions}
          remove={() => {}}></CandidateDataTable>
        {openDeleteDialog && (
          <ConfirmationDialog
            onClose={() => setOpenDeleteDialog(false)}
            title='Confirm'
            content='Are you sure you want to delete this interview schedule?'
            btn1Label='Cancel'
            btn1Action={handleCancelDelete}
            btn2Label='Delete'
            btn2Action={handleConfirmDelete}
          />
        )}
        {edit && (
          <>
            <form style={{ marginBottom: '10px' }}>
              <div className='row'>
                <div className='interview-field' style={{ width: '12%' }}>
                  <InputLabel className='field-label' id='interviewLevel'>
                    Level*
                  </InputLabel>
                  <Select
                    value={formik.values.interviewLevel}
                    fullWidth
                    id='interviewLevel'
                    size='small'
                    name='interviewLevel'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}>
                    {[...Array(sjd.noOfTechnicalInterview)].map((_v, i) => (
                      <MenuItem key={i} value={`Technical-${i + 1}`}>{`Technical-${
                        i + 1
                      }`}</MenuItem>
                    ))}
                    <MenuItem value='HR Interview'>HR Interview</MenuItem>
                  </Select>
                </div>
                <div className='interview-field' style={{ width: '12%' }}>
                  <InputLabel className='field-label' id='interviewMode'>
                    Mode*
                  </InputLabel>
                  <Select
                    value={
                      formik.values.isOnlineMeeting == undefined
                        ? ''
                        : formik.values.isOnlineMeeting
                        ? ONLINE
                        : OFFLINE
                    }
                    fullWidth
                    id='interviewLevel'
                    size='small'
                    name='interviewLevel'
                    onChange={(event) =>
                      formik.setFieldValue(
                        'isOnlineMeeting',
                        event.target.value === ONLINE ? true : false,
                      )
                    }
                    onBlur={formik.handleBlur}>
                    <MenuItem value={ONLINE}>{ONLINE}</MenuItem>
                    <MenuItem value={OFFLINE}>{OFFLINE}</MenuItem>
                  </Select>
                </div>

                <div className='interview-field' style={{ width: '23%' }}>
                  <InputLabel className='field-label' id='evaluator'>
                    Evaluator*
                  </InputLabel>
                  <Autocomplete
                    id='combo-box-demo'
                    options={evaluators}
                    sx={{ width: 300 }}
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option: any) =>
                      option ? option.firstName + ' ' + option.lastName : ''
                    }
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id && option.id == value.id
                    }
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('evaluator', true, true)}
                    value={formik.values.evaluator}
                    renderInput={(params: any) => (
                      <TextField {...params} className='autocomplete' label='' />
                    )}
                    onChange={(_e: any, val: any) => formik.setFieldValue('evaluator', val)}
                  />
                </div>

                <div className='interview-field' style={{ width: '28%', minWidth: '296px' }}>
                  <InputLabel className='field-label' id='startTime'>
                    Start Time*
                  </InputLabel>
                  <DateTimePicker
                    sx={{ width: '100%' }}
                    value={dayjs(formik.values.startTime)}
                    format='DD/MM/YYYY hh:mm A'
                    className='date-picker'
                    onChange={(value) => {
                      handleStartTimeChange(value);
                    }}
                  />
                </div>

                <div className='interview-field' style={{ width: '28%', minWidth: '296px' }}>
                  <InputLabel className='field-label' id='endTime'>
                    End Time*
                  </InputLabel>
                  <DateTimePicker
                    sx={{ width: '100%' }}
                    value={dayjs(formik.values.endTime)}
                    format='DD/MM/YYYY hh:mm A'
                    className='date-picker'
                    onChange={(value) => {
                      formik.setFieldValue(
                        'endTime',
                        dateFormat(value?.toString(), 'isoDateTime'),
                        true,
                      );
                    }}
                  />
                </div>
              </div>
            </form>
            <div className='bottom-btn-block'>
              <Button
                sx={{ marginLeft: 3, marginBottom: '5px' }}
                disabled={
                  !formik.dirty ||
                  Object.keys(formik.errors).length > 0 ||
                  !isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_INTERVIEW)
                }
                onClick={() => {
                  formik.handleSubmit();
                }}
                className='default-btn bg-btn'>
                Schedule
              </Button>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default ScheduleInterview;
