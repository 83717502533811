import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { Loader } from '../common/loader';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

interface Props {
  initialData: any;
}

const CandidatesPieChart = ({ initialData }: Props) => {
  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ff0000'];
  // const COLORS = ['#7D9E19', '#584BE8', '#D2EC0E', '#BD89E0', '#B30617'];

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
    } else {
      setLoading(false);
      const statusData = Object.keys(initialData).map((status) => ({
        name: status,
        value: initialData[status],
      }));

      setData(statusData);
    }
  }, [initialData]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    _index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='source-channel-tooltip'>
          <div style={{ backgroundColor: `${payload[0].payload.fill}` }}></div>
          <p className='label'>{`${payload[0].name}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <Stack spacing={1}>
        <Typography variant='h6' fontWeight={700}>
          Candidate Sub Status
        </Typography>
      </Stack>
      {loading && <Loader />}
      {!loading && (
        <>
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '265px' }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={data}
                  dataKey='value'
                  nameKey='name'
                  cx='50%'
                  cy='50%'
                  outerRadius={100}
                  label={renderCustomizedLabel}
                  labelLine={false}
                  minAngle={10}>
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '10px',
              }}>
              {data.map((d, index) => (
                <React.Fragment key={index}>
                  {index < 3 && (
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <div
                        style={{
                          width: '10px',
                          height: '10px',
                          background: COLORS[index % COLORS.length],
                          marginRight: '5px',
                        }}></div>
                      <span>{d.name}</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {data.map((d, index) => (
                <React.Fragment key={index}>
                  {index >= 3 && (
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <div
                        style={{
                          width: '10px',
                          height: '10px',
                          background: COLORS[index % COLORS.length],
                          marginRight: '5px',
                        }}></div>
                      <span>{d.name}</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CandidatesPieChart;
