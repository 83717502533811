import './tad-slider.scss';
import CircleIcon from '@mui/icons-material/Circle';
import { toUpperFirstChar } from './../../../common/commonUtil';
import React, { useState } from 'react';
// import { ReactComponent as CancelIcon } from '../../../assets/sjd/cancel.svg';

interface Props {
  noOfSteps?: number;
  selectedIndex?: number;
  currentStatus?: string;
  sjd: any;
}

const SliderComponent = ({ noOfSteps = 10, selectedIndex = 4, currentStatus = '' }: Props) => {
  const widthOfEachStep = 100 / noOfSteps;
  //   const interviewLevels =
  //     (sjd.isOnlineTest ? 2 : 0) + (sjd.isClientRound ? 2 : 0) + sjd.noOfTechnicalInterview * 2;

  const targetIndexes = [
    {
      index: 1,
      name: 'Profile Upload',
    },
    {
      index: 2,
      name: 'Profiling',
      subStatuses: [{ name: 'QC Rated' }, { name: 'PR Rated' }],
    },
    {
      index: 3,
      name: 'Interview Scheduled',
      subStatuses: [
        { name: 'L1 Scheduled' },
        { name: 'L1 Cleared' },
        { name: 'L1 Rejected' },
        { name: 'L2 Scheduled' },
        { name: 'L2 Cleared' },
        { name: 'L2 Rejected' },
        { name: 'Client Scheduled' },
        { name: 'Client Cleared' },
        { name: 'Client Rejected' },
      ],
    },
    {
      index: 4,
      name: 'HR Discussion',
      subStatuses: [
        { name: 'HR Scheduled' },
        { name: 'HR Cleared' },
        { name: 'HR Rejected' },
        { name: 'HR On Hold' },
      ],
    },
    {
      index: 5,
      name: 'Document Verification',
      subStatuses: [
        { name: 'Verification Started' },
        { name: 'Verification Cleared' },
        { name: 'Verification Rejected' },
      ],
    },
    {
      index: 6,
      name: 'Offer',
      subStatuses: [
        { name: 'Offer Approval Raised' },
        { name: 'Offer Released' },
        { name: 'Offer Accepted' },
        { name: 'Offer Declined' },
        { name: 'Offer On Hold' },
      ],
    },
    {
      index: 7,
      name: 'Joining Status',
      subStatuses: [{ name: 'Joined' }, { name: 'Joining No Show' }, { name: 'Yet to Join' }],
    },
    {
      index: 8,
      name: 'Employee',
    },
  ];

  // State to track which sub-statuses are visible
  const [visibleSubStatuses, setVisibleSubStatuses] = useState<number | null>(null);

  const toggleSubStatuses = (index: number) => {
    setVisibleSubStatuses(visibleSubStatuses === index ? null : index);
  };

  let previousIndex = 0;
  return (
    <>
      <div className='slider'>
        <div className='progress-bar'>
          <div
            className='completed'
            style={{
              width: `${selectedIndex * widthOfEachStep}%`,
              height: '10px',
              background: `${
                currentStatus === 'Rejected' ? '#f13535' : 'var(--Primary-Blue-500, #1976D2)'
              }`,
            }}></div>
          <div
            className='pending'
            style={{ width: `${100 - selectedIndex * widthOfEachStep}%`, height: '10px' }}></div>
        </div>
        <div className='icon-div'>
          {targetIndexes.map((target, index) => {
            const width = (target.index - previousIndex) * widthOfEachStep;
            previousIndex = target.index;
            const iconColor =
              selectedIndex >= target.index ? 'var(--Primary-Blue-500, #1976D2)' : '#ccc';
            return (
              <React.Fragment key={index}>
                <div style={{ width: `${width}%` }}></div>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircleIcon
                      sx={{
                        display: 'block',
                        fontSize: '24px',
                        paddingBottom: '3px',
                        color: iconColor,
                      }}
                    />
                  </div>
                  <div
                    onClick={() => toggleSubStatuses(target.index)}
                    style={{ width: 'max-content' }}>
                    <span
                      style={{
                        fontWeight: '500',
                        color: selectedIndex >= target.index ? '#000' : '#888',
                        cursor: 'pointer',
                      }}>
                      {toUpperFirstChar(target.name)}
                    </span>
                  </div>
                  {/* Conditionally render sub-statuses based on visibility state */}
                  {visibleSubStatuses === target.index && target.subStatuses && (
                    <div
                      className='sub-statuses'
                      style={{
                        marginTop: '10px',
                        position: 'relative',
                        marginLeft: '10px',
                      }}>
                      <div
                        style={{
                          position: 'absolute',
                          left: '5px',
                          top: '0',
                          bottom: '0',
                          width: '2px',
                          backgroundColor: '#1976D2', // Main vertical pipe color
                        }}
                      />
                      {target.subStatuses.map((subStatus, subIndex) => {
                        const isRejected = subStatus.name.toLowerCase().includes('rejected');
                        const isCleared = subStatus.name.toLowerCase().includes('cleared');

                        const icon = isCleared ? (
                          <CircleIcon
                            style={{ color: '#1976D2', fontSize: '16px', marginLeft: '4px' }}
                          />
                        ) : isRejected ? (
                          <CircleIcon
                            style={{ color: '#f13535', fontSize: '16px', marginLeft: '4px' }}
                          />
                        ) : (
                          <CircleIcon
                            sx={{ color: '#1976D2', fontSize: '16px', marginLeft: '4px' }}
                          />
                        );

                        return (
                          <div
                            key={subIndex}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '14px',
                              color: isRejected ? '#f13535' : isCleared ? '#1976D2' : '#666',
                              marginBottom: '8px',
                            }}>
                            {/* Vertical line for sub-status */}
                            <div
                              style={{
                                width: '2px',
                                height: '16px',
                                backgroundColor: '#1976D2', // Sub-status vertical pipe color
                                marginRight: '8px',
                              }}
                            />
                            <span style={{ flexGrow: 1 }}>{subStatus.name}</span>
                            {icon}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </React.Fragment>
            );
          })}
          <div style={{ width: `${(noOfSteps - previousIndex) * widthOfEachStep}%` }}></div>
        </div>
      </div>
    </>
  );
};

export default SliderComponent;
