import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  // InputAdornment,
  List,
  ListItem,
  MenuItem,
  // OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Select,
  Typography,
  FormGroup,
  // ListItemIcon,
} from '@mui/material';
import { ReactComponent as AddIcon } from '../../assets/dashboard-assets/add.svg';
import { ReactComponent as CloseIcon } from '../../assets/sjd/close.svg';
import './new-dashboard.scss';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
// import globalObject from '../../../common/global-variables';
// import { LookupTypes } from '../../../common/lookupTypes';
import { ReactComponent as DropDownArrow } from '../../assets/dashboard-assets/dropdown.svg';
import GeneralInsightsIcon from '../../assets/dashboard-assets/todo-general-insights.svg';
import RatingsPerformanceIcon from '../../assets/dashboard-assets/todo-star-outline.svg';
import FollowupsIcon from '../../assets/dashboard-assets/todo-flag.svg';
import CandidateInterviewsIcon from '../../assets/dashboard-assets/todo-candidate-interview.svg';
import JobDefinitionsIcon from '../../assets/dashboard-assets/todo-jobdefinition.svg';
import MessagesFeedbacksIcon from '../../assets/dashboard-assets/todo-text-msg.svg';
import ImportantIcon from '../../assets/dashboard-assets/todo-report_gmailerrorred.svg';
import EmailTemplateIcon from '../../assets/dashboard-assets/todo-mail.svg';
import AwaitingIcon from '../../assets/dashboard-assets/todo-awaiting.svg';
import ChartsIcon from '../../assets/dashboard-assets/todo-insert_chart.svg';
import CandidateIcon from '../../assets/dashboard-assets/todo-supervisor_account.svg';
import OrganizationIcon from '../../assets/dashboard-assets/todo-account_balance.svg';
import AttachFileIcon from '../../assets/dashboard-assets/todo-attach_file.svg';
import ContactSupportIcon from '../../assets/dashboard-assets/todo-contact_support.svg';
import EducactionIcon from '../../assets/dashboard-assets/todo-education.svg';
import SuccessIcon from '../../assets/dashboard-assets/todo-thumb_up.svg';
import TextEditorField from '../../components/common/text-editor-field';
import { ReactComponent as CheckCircleIcon } from '../../assets/dashboard-assets/check_circle_outline.svg';
import { ReactComponent as StarIcon } from '../../assets/dashboard-assets/star_outline.svg';
import { ReactComponent as MoreVertIcon } from '../../assets/dashboard-assets/more_vert.svg';

const colorMapping: { [key: string]: string } = {
  High: '#F4C1CB',
  Medium: '#FEE6B8',
  Low: '#C7E8C5',
};

const taskDefinitions = [
  { label: 'General Insights (Default)', image: GeneralInsightsIcon },
  { label: 'Ratings / Performance', image: RatingsPerformanceIcon },
  { label: 'Followups', image: FollowupsIcon },
  { label: 'Candidate Interviews / Calendar / Schedule', image: CandidateInterviewsIcon },
  { label: 'Job Definitions / Documents / Offers', image: JobDefinitionsIcon },
  { label: 'Messages / Feedbacks', image: MessagesFeedbacksIcon },
  { label: 'Important', image: ImportantIcon },
  { label: 'Email / Template', image: EmailTemplateIcon },
  { label: 'Awaiting', image: AwaitingIcon },
  { label: 'Reports / Charts', image: ChartsIcon },
  { label: 'Candidates', image: CandidateIcon },
  { label: 'Employement / Orginisation', image: OrganizationIcon },
  { label: 'Attachments', image: AttachFileIcon },
  { label: 'Questionnaires', image: ContactSupportIcon },
  { label: 'Education', image: EducactionIcon },
  { label: 'Success / Completed', image: SuccessIcon },
];

// const statusOptions = (globalObject.lookupvalues?.[LookupTypes.PRIORITY] || []).map(
//   (status: any) => ({
//     ...status,
//     color: colorMapping[status.name] || null,
//   }),
// );

const ToDo = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [startDateTime, setStartDateTime] = useState('');
  const [dueDateTime, setDueDateTime] = useState('');
  // const [selectedStatus, setSelectedStatus] = useState(-1);
  const [selectTaskDefinition, setSelectTaskDefinition] = useState<string | null>(null);
  const [description, setDescription] = useState('');

  const handleSelectChange = (event: any) => {
    setSelectTaskDefinition(event?.target.value);
  };

  const handleStartDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDateTime(event.target.value);
  };

  const handleDueDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDueDateTime(event.target.value);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = Array.from(event.target.files || []);
    setAttachments((prevAttachments) => [...prevAttachments, ...fileList]);
  };

  const removeAttachment = (index: any) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };

  const DrawerList = (
    <Box
      role='presentation'
      sx={{ width: '43.75rem', height: '64rem' }}
      // onClick={() => setMenuOpen(false)}
    >
      <List sx={{ padding: '3.19rem 1.88rem 0rem 1.69rem' }}>
        <ListItem
          disablePadding
          sx={{ marginBottom: '1.19rem', cursor: 'pointer', fontWeight: 400 }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              gap: '18.12rem',
            }}>
            <div>
              <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>New Task</Typography>
            </div>
            <div>
              <CloseIcon onClick={() => setMenuOpen(false)} />
            </div>
          </div>
        </ListItem>
        <div
          style={{
            gap: '24.56rem',
            alignItems: 'center',
            marginBottom: '2.5rem',
          }}>
          <hr style={{ background: '#868686' }} />
        </div>
        <div>
          <div style={{ marginBottom: '1.87rem', gap: '0.44rem' }}>
            <Typography>Task Name *</Typography>
            <div>
              <TextField
                style={{ borderRadius: '0.3125rem' }}
                className='inputFeilds'
                sx={{
                  width: '40.3125rem',
                }}></TextField>
            </div>
          </div>
          <div
            style={{
              borderRadius: '0.625rem',
              width: '7.875rem',
              gap: '0.44rem',
              marginBottom: '1.17rem',
            }}>
            <Typography>Task Type</Typography>

            <RadioGroup
              row
              style={{ flexWrap: 'nowrap' }}
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              // value={value}
              // onChange={handleChange}
            >
              <FormControlLabel
                className='raido-color'
                value='task'
                control={<Radio />}
                label='Task'
              />
              <FormControlLabel
                className='raido-color'
                value='meeting'
                control={<Radio />}
                label='Metting'
              />
            </RadioGroup>
          </div>
          <div style={{ marginBottom: '1.87rem', gap: '0.44rem' }}>
            <div style={{ width: '319px' }}>
              <Typography>Task Definition</Typography>
            </div>
            <div>
              <Select
                className='inputFields'
                sx={{
                  width: '40.3125rem',
                  height: '2.5rem',
                  marginRight: '10px',
                }}
                IconComponent={() => (
                  <DropDownArrow
                    style={{
                      marginRight: '5px',
                      width: '0.9375rem',
                      height: '0.9375rem',
                    }}
                  />
                )}
                size='small'
                value={selectTaskDefinition}
                onChange={handleSelectChange}
                displayEmpty
                renderValue={(selected) => {
                  const selectedTaskDefinition = taskDefinitions.find(
                    (task) => task.label === selected,
                  );
                  return selectedTaskDefinition ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={selectedTaskDefinition.image}
                        alt={selectedTaskDefinition.label}
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          marginRight: '10px',
                          paddingTop: '1px',
                          color: '#323232',
                          backgroundColor: '#E6E7E8',
                        }}
                      />
                      <Typography>{selectedTaskDefinition.label}</Typography>
                    </div>
                  ) : (
                    'None'
                  );
                }}>
                {taskDefinitions.map((task: any, index: number) => (
                  <MenuItem key={index} value={task.label}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={task.image}
                        alt={task.label}
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          marginRight: '10px',
                          paddingTop: '1px',
                          color: '#323232',
                          backgroundColor: '#E6E7E8',
                        }}
                      />
                      <Typography>{task.label}</Typography>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div
            style={{
              marginBottom: '1.87rem',
              gap: '0.44rem',
            }}>
            <div style={{ width: '319px' }}>
              <Typography>Add Description</Typography>
            </div>
            <div style={{ height: '200px' }}>
              <TextEditorField
                width='40.4375rem'
                value={description}
                selectedValue={(value) => setDescription(value)}
              />
            </div>
          </div>
          <div style={{ marginBottom: '1.87rem', gap: '0.44rem' }}>
            <Typography>Attachments</Typography>
            <label
              htmlFor='file-upload'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '2.5rem',
                padding: '0 1rem',
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                cursor: 'pointer',
                color: 'rgba(0, 0, 0, 0.6)',
              }}>
              <Typography>Drop files or add attachments here</Typography>
              <Typography>Maximum 10 files</Typography>
              <input
                id='file-upload'
                type='file'
                className='inputFeilds'
                multiple
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
            </label>

            {attachments.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
                {attachments.map((file: any, index: number) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Typography>{file.name}</Typography>
                    <Button onClick={() => removeAttachment(index)}>Remove</Button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            style={{
              marginBottom: '1.87rem',
              gap: '0.44rem',
            }}>
            <div style={{ width: '319px' }}>
              <Typography>Activity related to</Typography>
            </div>
            <div>
              <Select
                className='inputFeilds'
                sx={{
                  width: '40.3125rem',
                  height: 'var(--Font-size-h2, 2.5rem)',
                  marginRight: '10px',
                }}
                IconComponent={() => (
                  <DropDownArrow
                    style={{
                      marginRight: '5px',
                      width: '0.9375rem',
                      height: '0.9375rem',
                    }}
                  />
                )}
                size='small'
                labelId='jobDefinitions'
                name='jobDefinitions'
                id='jobDefinitions-select'>
                <MenuItem key={0}>Job Definitions</MenuItem>
                <MenuItem>Select</MenuItem>
                <MenuItem>Select</MenuItem>
              </Select>
            </div>
          </div>

          <div style={{ marginBottom: '1.87rem', gap: '0.44rem' }}>
            <div>
              <div style={{ display: 'flex', gap: '10.313rem  ' }}>
                <Typography>Start Date & Time</Typography>
                <Typography>Due Date & Time</Typography>
              </div>
              <div style={{ display: 'flex', gap: '20px' }}>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <TextField
                    type='date'
                    value={startDateTime}
                    onChange={handleStartDateTimeChange}
                    className='inputFeilds'
                    sx={{ width: '9.563rem' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    type='time'
                    value={startDateTime}
                    onChange={handleStartDateTimeChange}
                    //  sx={{ width: '9.563rem', height:'43px' }}
                    className='inputFeilds'
                    sx={{ width: '6.875rem' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <TextField
                    type='date'
                    value={dueDateTime}
                    onChange={handleDueDateTimeChange}
                    // sx={{ width: '40.3125rem' }}
                    className='inputFeilds'
                    sx={{ width: '9.563rem' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    type='time'
                    value={dueDateTime}
                    onChange={handleDueDateTimeChange}
                    // sx={{ width: '40.3125rem' }}
                    className='inputFeilds'
                    sx={{ width: '6.875rem' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: '1.87rem', gap: '0.44rem' }}>
            <div style={{ width: '319px' }}>
              <Typography>Priority</Typography>
            </div>
            <div>
              <Select
                className='inputFeilds'
                sx={{
                  width: '40.3125rem',
                  height: 'var(--Font-size-h2, 2.5rem)',
                  marginRight: '10px',
                }}
                IconComponent={() => (
                  <DropDownArrow
                    style={{
                      marginRight: '5px',
                      width: '0.9375rem',
                      height: '0.9375rem',
                    }}
                  />
                )}>
                {Object.entries(colorMapping).map(([priority, color], index) => (
                  <MenuItem key={index} value={priority}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        backgroundColor: color,
                        marginRight: '8px',
                      }}></span>
                    {priority}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ marginBottom: '1.87rem', gap: '0.44rem' }}>
            <Typography>Associate a member</Typography>
            <div>
              <TextField
                className='inputFeilds'
                placeholder='type @ mention name here'
                sx={{
                  width: '40.3125rem',
                }}></TextField>
            </div>
          </div>
          <div style={{ marginBottom: '1.87rem', gap: '0.44rem' }}>
            <div>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label='Set Reminder' />
              </FormGroup>
            </div>
          </div>
          <div
            style={{
              marginBottom: '1.87rem',
              gap: '0.44rem',
            }}>
            <div style={{ width: '319px' }}>
              <Typography>Reminder Type</Typography>
            </div>
            <div>
              <Select
                className='inputFeilds'
                sx={{
                  width: '40.3125rem',
                  height: 'var(--Font-size-h2, 2.5rem)',
                  marginRight: '10px',
                }}
                IconComponent={() => (
                  <DropDownArrow
                    style={{
                      marginRight: '5px',
                      width: '0.9375rem',
                      height: '0.9375rem',
                    }}
                  />
                )}
                size='small'
                labelId='jobDefinitions'
                name='jobDefinitions'
                id='jobDefinitions-select'>
                <MenuItem key={0}>All Job Definitions</MenuItem>
                <MenuItem>Daily</MenuItem>
                <MenuItem>Month</MenuItem>
              </Select>
            </div>
          </div>
          <div
            style={{
              marginBottom: '1.87rem',
              gap: '0.44rem',
            }}>
            <div>
              <Typography>Select Time</Typography>
            </div>
            <div>
              <TextField
                type='time'
                value={dueDateTime}
                onChange={handleDueDateTimeChange}
                // sx={{ width: '40.3125rem' }}
                className='inputFeilds'
                sx={{ width: '8.275rem' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          <div
            style={{
              gap: '24.56rem',
              alignItems: 'center',
              marginBottom: '1.17rem',
            }}>
            <hr style={{ background: '#868686' }} />
          </div>
          <div
            style={{
              gap: '20px',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '1.7rem',
            }}>
            <Button
              className='button'
              sx={{
                width: '7.375rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
              }}>
              Cancel
            </Button>
            <Button
              className='button'
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '1rem',
                width: '7.375rem',
                height: '2.5rem',
                marginRight: '10px',
                color: 'white !important',
                background: '#2D7DFE !important',
              }}>
              {/* <FantacyIcon
                className='fantacy-icon'
                style={{ marginRight: '7px', marginLeft: '5px' }}
              /> */}
              Add
            </Button>
          </div>
        </div>

        {/* {tabs.map((text, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ marginBottom: '2rem', cursor: 'pointer', fontWeight: 400 }}
            onClick={() => handleMenuClick(text)}>
            <ListItemIcon>
              <img
                style={{ width: '1.5rem', height: '1.5rem', flexShrink: '0px' }}
                src={text.icon}
                alt='Search Icon'
              />
            </ListItemIcon>
            <ListItemText primary={text.label} />
          </ListItem>
        ))} */}
      </List>
      <Divider />
    </Box>
  );

  return (
    <>
      <Paper sx={{ height: '100%', width: '100%' }}>
        <div className='first-div-header'>
          <div
            className='first-div-header-left'
            style={{ display: 'flex', marginLeft: '2%', gap: '0.5rem' }}>
            <CheckCircleIcon />
            <span>To Do</span>
          </div>
          <div className='first-div-header-right'>
            <IconButton className='icon-btn'>
              <AddIcon onClick={() => setMenuOpen(true)} />
            </IconButton>
            <MoreVertIcon />
          </div>
          <Drawer
            sx={{ width: '21.0625rem', height: '64rem', flexShrink: 0 }}
            anchor='right'
            open={menuOpen}
            onClose={() => setMenuOpen(false)}>
            {DrawerList}
          </Drawer>
        </div>
        <hr style={{ color: '#C1C0C0', marginBlockEnd: '0em', marginBlockStart: '0em' }}></hr>
        <div style={{ padding: '20px' }}>
          <div style={{ display: 'flex', gap: '1rem', marginBottom: '10px', alignItems: 'center' }}>
            <StarIcon
              style={{
                background: '#8CD6FF',
                width: '1.875rem',
                height: '1.875rem',
              }}
            />
            <span>Awaiting Profiler Rating for the following candidate profiles (18)</span>
          </div>
        </div>
      </Paper>
    </>
  );
};
export default ToDo;
