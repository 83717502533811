import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import * as Yup from 'yup';
import { visuallyHidden } from '@mui/utils';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  getAllProgramLevels,
  getAllTechStackByLevelId,
  getAllTechStackCoursesById,
  getEmployeeCourseDetails,
} from '../../service/EmployeeService';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import dateFormat from 'dateformat';
import { tagLevelTechStackToBulkEmployees } from '../../service/tempInternService';
import globalObject from '../../common/global-variables';
import { Form, useNavigate } from 'react-router-dom';
import Notify from '../common/notify';
import { Search } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';

const AssignCourses = () => {
  const navigate = useNavigate();
  const HEADERS = [
    { id: 'employeeName', label: 'Employee Name' },
    { id: 'employeeId', label: 'Employee Id' },
    { id: 'email', label: 'Email' },
    { id: 'level', label: 'Level' },
  ];

  const DuplicateCourseHeaders = [
    { id: 'userName', label: 'Employee Name' },
    { id: 'CourseName', label: 'Course Name' },
    { id: 'percentage', label: 'Percentage(%)' },
    { id: 'techStack', label: 'TechStack' },
    { id: 'provider', label: 'Provider' },
  ];

  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [levelId, setLevelId] = useState(0);
  const [levels, setLevels] = useState([] as any);
  const [techStacks, setTechStacks] = useState([] as any[]);
  const [selectAll, setSelectAll] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([] as any[]);
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  type Order = 'asc' | 'desc';
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof any>('');
  const [header, setHeader] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState(filteredEmployees);
  const [duplicateAssignedCourses, setDuplicateAssignedCourses] = useState([] as any[]);
  const [flag, setFlag] = useState(false);

  const [allCourses, setAllCourses] = useState([] as any[]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAssignCourseSearch = (searchedVal: string) => {
    const lowerCaseSearchedVal = searchedVal.toLowerCase();

    const filteredRows = filteredEmployees.filter((row: any) => {
      const { employeeId, employeeName, email } = row;

      const employeeIdMatch = employeeId
        ? String(employeeId).toLowerCase().includes(lowerCaseSearchedVal)
        : false;
      const employeeNameMatch = employeeName
        ? employeeName.toLowerCase().includes(lowerCaseSearchedVal)
        : false;
      const emailMatch = email ? email.toLowerCase().includes(lowerCaseSearchedVal) : false;

      return employeeIdMatch || employeeNameMatch || emailMatch;
    });

    setRows(filteredRows);
    handleChangePage(null, 0);
  };

  const assignCoursesSchema = Yup.object().shape({
    techStack: Yup.string().required('Tech stack is required'),
    courses: Yup.array().required('Courses are required'),
    startDate: Yup.date().required('Please select start date'),
    endDate: Yup.date()
      .required('Please select end date')
      .min(Yup.ref('startDate'), 'End date should be greater or equal to start date'),
  });

  type Course = {
    id: number;
    courseName: string;
    courseUrl?: string;
    courseDescription?: string;
    providerId?: number | null;
  };

  const formik = useFormik({
    initialValues: {
      level: '',
      techStack: '',
      courses: [] as Course[],
      startDate: '',
      endDate: '',
    },
    validationSchema: assignCoursesSchema,
    onSubmit: () => {
      bulkAssign();
    },
    onReset: () => {
      setFilteredEmployees([]);
      formik.resetForm();
    },
  });

  const getError = (formik: any, fieldName: string) => {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  };

  const handleCheckboxChange = (employeeId: number) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(employeeId)
        ? prevSelected.filter((id) => id !== employeeId)
        : [...prevSelected, employeeId],
    );
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelectedEmployees(filteredEmployees.map((employee) => employee.employeeId));
    } else {
      setSelectedEmployees([]);
    }
  };

  useEffect(() => {
    getAllProgramLevels().then((res) => setLevels(res.data));
    getAllTechStackByLevelId(levelId).then((res) => setTechStacks(res.data));
  }, [levelId]);

  const handleUserTableData = (values: any) => {
    const filtered = filteredEmployees.filter((employee) => {
      if (values.level && values.level !== '' && employee.level !== values.level) {
        return false;
      }
      return true;
    });
    setFilteredEmployees(filtered);
  };

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const bulkAssign = () => {
    tagLevelTechStackToBulkEmployees({
      levelId: levelId,
      managerId: globalObject.userObject.id,
      techStackId: formik.values.techStack,
      courseId: formik.values.courses && formik.values.courses.map((course: any) => course.id),
      employeeIds: selectedEmployees,
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
    })
      .then((resp) => {
        const result: any[] = resp.data.result;
        setShowToast(areAllCoursesAssigned(formik.values.courses, result));
        result.length == 0 && setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Courses assigned successfully.',
        });
        if (result.length > 0) {
          setFlag(true);
          setDuplicateAssignedCourses(result);
        } else {
          setDialogOpen(false);
          setTimeout(() => {
            window.location.reload();
          }, 50);
        }
      })
      .catch((_err) => {
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!.',
        });
      });
  };

  function areAllCoursesAssigned(courses: Course[], duplicateAssignedCourses: any) {
    for (const userId of selectedEmployees) {
      const assignedCoursesForUser = duplicateAssignedCourses.find(
        (assignedCourse: any) => assignedCourse.userId === userId,
      );
      if (!assignedCoursesForUser) {
        return true;
      }
    }
    const userCourseMap = new Map<number, Set<number>>();
    duplicateAssignedCourses.forEach((employee: any) => {
      if (!userCourseMap.has(employee.userId)) {
        userCourseMap.set(employee.userId, new Set<number>());
      }
      userCourseMap.get(employee.userId)?.add(employee.courseId);
    });
    for (const course of courses) {
      for (const assignedCourse of duplicateAssignedCourses) {
        if (!userCourseMap.get(assignedCourse.userId)?.has(course.id)) {
          return true;
        }
      }
    }
    return false;
  }

  const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const sortTableData = (array: any[]) => {
    const flag = order === 'desc' ? -1 : 1;

    return array.sort((a, b) => {
      if (orderBy === 'employeeId') {
        return a[orderBy] > b[orderBy] ? flag * 1 : a[orderBy] < b[orderBy] ? flag * -1 : 0;
      }

      const aValue = a[orderBy]?.toLowerCase() || '';
      const bValue = b[orderBy]?.toLowerCase() || '';

      return aValue > bValue ? flag * 1 : aValue < bValue ? flag * -1 : 0;
    });
  };

  const handleRequestSort = (property: keyof any, isAsc?: boolean) => {
    if (isAsc !== undefined) {
      setOrder(isAsc ? 'asc' : 'desc');
    } else {
      setOrder((prevOrder) => (orderBy === property && prevOrder === 'asc' ? 'desc' : 'asc'));
    }
    setOrderBy(property);
  };

  const disablePastEndDate = (date: any) => {
    if (!formik.values.startDate) return false;
    return dayjs(date).isBefore(dayjs(formik.values.startDate));
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography className='header-name'>Assign Courses</Typography>
      </div>
      <Paper elevation={0} className='search-box' style={{ height: '100%', padding: '49px' }}>
        <div
          className='search-form'
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignContent: 'space-between',
          }}>
          <div style={{ display: 'flex', gap: '30px' }}>
            <div>
              <InputLabel className='field-label'>Level</InputLabel>
              <Select
                style={{ width: '150px' }}
                value={levelId}
                onChange={(e: any) => setLevelId(e.target.value)}>
                {levels.map((courses: any) => (
                  <MenuItem key={courses.id} value={courses.id}>
                    {courses.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div
              className='btn-field'
              style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'center' }}>
              <Button
                onClick={(values: any) => {
                  getEmployeeCourseDetails(levelId).then((res) => {
                    setFilteredEmployees(res.data);
                    setRows(res.data);
                  });
                  handleUserTableData(values);
                }}
                className='default-btn bg-btn'
                style={{ height: '50%' }}>
                Search
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <OutlinedInput
              id='table-search'
              type='text'
              placeholder='Search...'
              className='search-bar'
              onChange={(event) => handleAssignCourseSearch(event.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <Search />
                </InputAdornment>
              }
            />
          </div>
        </div>
        <TableContainer component={Paper} sx={{ marginTop: '40px' }}>
          <Table sx={{ minWidth: 650, margin: 'auto' }} aria-label='simple table' size='small'>
            <TableHead sx={{ background: 'rgb(224 224 224)' }}>
              <TableRow>
                <TableCell style={{ display: 'flex', alignItems: 'center', width: '10px' }}>
                  <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
                </TableCell>
                {HEADERS.map((headCell, index) => (
                  <TableCell key={index} sx={{ fontWeight: 1000 }}>
                    {headCell.label}
                    <TableSortLabel
                      active={orderBy === headCell.id || header === headCell.label}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.id)}
                      onMouseEnter={() => {
                        setHeader(headCell.label);
                      }}
                      onMouseLeave={() => {
                        setHeader('');
                      }}>
                      {orderBy === headCell.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                sortTableData(rows)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((employee: any, index: number) => (
                    <TableRow key={employee.employeeId || index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedEmployees.includes(employee.employeeId)}
                          onChange={() => handleCheckboxChange(employee.employeeId)}
                        />
                      </TableCell>
                      <TableCell>{employee.employeeName}</TableCell>
                      <TableCell>{employee.employeeCode}</TableCell>
                      <TableCell>{employee.email}</TableCell>
                      <TableCell>{employee.level}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                    No records to display
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TablePagination
            sx={{ marginLeft: 'auto' }}
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={filteredEmployees.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className='pagination'
          />
        </div>
        <div style={{ marginTop: '30px' }}>
          <div>
            <Button
              className='default-btn bg-btn'
              style={{ height: '55%' }}
              disabled={selectedEmployees.length === 0}
              onClick={() => {
                formik.setErrors({});
                setDialogOpen(true);
              }}>
              Assign
            </Button>
            <Form
              onSubmit={(values) => {
                setSubmitted(true);
                formik.handleSubmit(values);
                formik.resetForm();
                setDialogOpen(false);
              }}>
              <Dialog open={dialogOpen} maxWidth='lg' onClose={() => setDialogOpen(false)}>
                <DialogTitle>Courses Assign</DialogTitle>
                <DialogContent sx={{ paddingBottom: '0px' }}>
                  <div style={{ display: 'flex', gap: '20px' }}>
                    <div style={{ flex: 1 }}>
                      <InputLabel className='report-field' id='tech-stack'>
                        Tech Stack
                      </InputLabel>
                      <div style={{ width: 'auto', minWidth: '200px' }}>
                        <Select
                          id='tech-stack'
                          style={{ width: '90%' }}
                          name='tech-stack'
                          value={formik.values.techStack}
                          onChange={(e: any) => {
                            setFlag(false);
                            setDuplicateAssignedCourses([]);
                            formik.setFieldValue('courses', [] as any[]);
                            getAllTechStackCoursesById(e.target.value, null, levelId).then((res) =>
                              setAllCourses(res.data),
                            );
                            formik.setFieldValue('techStack', e.target.value);
                          }}>
                          {techStacks.map((courses: any) => (
                            <MenuItem key={courses.id} value={courses.id}>
                              {courses.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <div className='mt-0 form-err-msg'>{getError(formik, 'techStack')}</div>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <InputLabel className='report-field' id='combo-box-demo'>
                        Course
                      </InputLabel>
                      <div style={{ width: '180px' }}>
                        <Autocomplete
                          multiple
                          id='combo-box-demo'
                          options={allCourses}
                          sx={{ width: '100%' }}
                          size='small'
                          limitTags={1}
                          getOptionLabel={(option) => option.courseName || ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          filterSelectedOptions
                          value={formik.values.courses}
                          renderInput={(params) => <TextField {...params} label='' />}
                          onChange={(_e, val) => {
                            setFlag(false);
                            setDuplicateAssignedCourses([]);
                            formik.setFieldValue('courses', val);
                          }}
                          disabled={formik.values.techStack === '' ? true : false}
                        />
                        <div
                          className='mt-0 form-err-msg'
                          style={{ marginTop: '-10px', marginBottom: '10px' }}>
                          {getError(formik, 'courses')}
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <InputLabel id='start-date'>Start Date</InputLabel>
                      <DatePicker
                        disablePast
                        sx={{ width: '100%' }}
                        className='date-picker-lms'
                        value={dayjs(formik.values.startDate)}
                        format='DD/MM/YYYY'
                        onChange={(value) =>
                          formik.setFieldValue(
                            'startDate',
                            dateFormat(value?.toString(), 'isoDateTime'),
                          )
                        }
                      />
                      <div className='mt-0 form-err-msg'>{getError(formik, 'startDate')}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <InputLabel id='end-date'>End Date</InputLabel>
                      <DatePicker
                        shouldDisableDate={disablePastEndDate}
                        sx={{ width: '100%' }}
                        className='date-picker-lms'
                        value={dayjs(formik.values.endDate)}
                        format='DD/MM/YYYY'
                        onChange={(value) =>
                          formik.setFieldValue(
                            'endDate',
                            dateFormat(value?.toString(), 'isoDateTime'),
                          )
                        }
                      />
                      <div className='mt-0 form-err-msg'>{getError(formik, 'endDate')}</div>
                    </div>
                  </div>
                  {duplicateAssignedCourses.length > 0 && (
                    <div>
                      <DialogContentText
                        sx={{
                          display: 'flex',
                          fontSize: 'small',
                          color: 'red',
                          paddingTop: '10px',
                        }}>
                        <ErrorIcon fontSize='small' sx={{ color: 'red', marginRight: '3px' }} />
                        <span>
                          Below list of courses are skipped as they are already assigned !!.
                        </span>
                      </DialogContentText>
                      <TableContainer sx={{ marginTop: '20px' }}>
                        <Table
                          sx={{ minWidth: 650, margin: 'auto' }}
                          aria-label='simple table'
                          size='small'>
                          <TableHead
                            sx={{
                              background: 'rgb(224 224 224)',
                              border: '2px solid rgba(224, 224, 224, 1)',
                            }}>
                            <TableRow>
                              {DuplicateCourseHeaders.map((headCell, index) => (
                                <TableCell key={index} sx={{ fontWeight: 1000 }}>
                                  {headCell.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {duplicateAssignedCourses
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((employee: any, index: number) => (
                                <TableRow
                                  sx={{
                                    backgroundColor: 'rgba(255, 0, 0, 0.05)',
                                    border: '2px solid rgba(255, 0, 0, 0.3)',
                                  }}
                                  key={employee.employeeId || index}>
                                  <TableCell>{employee.userName}</TableCell>
                                  <TableCell>{employee.courseName}</TableCell>
                                  <TableCell>{employee.percentage}</TableCell>
                                  <TableCell>{employee.techStack}</TableCell>
                                  <TableCell>{employee.provider}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {duplicateAssignedCourses.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TablePagination
                            sx={{ marginLeft: 'auto' }}
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={duplicateAssignedCourses.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            className='pagination'
                          />
                        </div>
                      )}
                    </div>
                  )}
                </DialogContent>
                <DialogActions sx={{ paddingRight: '18px' }}>
                  <Button
                    sx={{ color: '#a72037' }}
                    onClick={() => {
                      setDialogOpen(false);
                      setDuplicateAssignedCourses([]);
                      formik.setFieldValue('startDate', '');
                      formik.setFieldValue('endDate', '');
                      formik.setFieldValue('techStack', '');
                      formik.setFieldValue('courses', []);
                    }}>
                    Cancel
                  </Button>
                  <Button
                    className='default-btn bg-btn'
                    disabled={flag}
                    onClick={() => {
                      setSubmitted(true);
                      formik.handleSubmit();
                      formik.resetForm();
                    }}>
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          </div>
          <Button
            className='default-btn bg-btn'
            style={{ height: '65%', marginRight: '30px' }}
            onClick={() => navigate('/lms')}>
            Cancel
          </Button>
        </div>
      </Paper>
      <Notify open={showToast} severity={toastMsg.severity} onClose={handleToastClose}>
        <span>{toastMsg.msg}</span>
      </Notify>
    </React.Fragment>
  );
};

export default AssignCourses;
