import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
  TablePagination,
  AlertColor,
  Backdrop,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Tab,
  Tabs,
} from '@mui/material';
import { useEffect, useState } from 'react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EditIcon from '@mui/icons-material/Edit';
import {
  getInternCandidates,
  getStatusCount,
  updateInternDetails,
  uploadInternDocument,
} from '../../service/tempInternService';
import globalObject from '../../common/global-variables';
import dateFormat from 'dateformat';
import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import exportIcon from '../../assets/csvexport.png';
import youtubeIcon from '../../assets/youtube.png';
import udemyIcon from '../../assets/udemy.png';
import ptgIcon from '../../assets/ptg.png';
import courseraIcon from '../../assets/coursera.png';
import { visuallyHidden } from '@mui/utils';
import { Add, Search } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  getCertificationArea,
  getCertificationDetails,
  postCertificationDetails,
} from '../../service/certificationService';
import Notify from '../common/notify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form } from 'react-router-dom';
import { ReactComponent as CompletedIcon } from '../../assets/courses completed.svg';
import { ReactComponent as YetToStartIcon } from '../../assets/courses yet to start.svg';
import { ReactComponent as InProgressIcon } from '../../assets/courses in progress.svg';
import dayjs from 'dayjs';
import { isPermissionsWrite } from '../../common/commonUtil';
import { MODULE, SubModuleTypes } from '../../constants/common';

const LmsInternDashboard = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [list, setList] = useState([] as any);
  const [statusCompletedCount, setStatusCompletedCount] = useState(0);
  const [statusInProgressCount, setStatusInProgressCount] = useState(0);
  const [statusYetToStartCount, setStatusYetToStartCount] = useState(0);
  const [percentage, setPercentage] = useState<number>(0);
  const [courseId, setCourseId] = useState(0);
  const [managerId, setManagerId] = useState(0);
  type Order = 'asc' | 'desc';
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof any>('');
  const [header, setHeader] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [certificates, setCertificates] = useState([] as any[]);
  const [certificateDialogOpen, setCertificateDialogOpen] = useState(false);
  const [certificationAreas, setCertificationAreas] = useState([] as any);
  const [showToast, setShowToast] = useState(false);
  const [filteredList, setFilteredList] = useState([] as any[]);
  const [filterSearchList, setFilterSearchList] = useState([] as any[]);
  type subTabType = 'yet to start' | 'in progress' | 'completed';
  const selectedTabType: any = localStorage.getItem('selectedSubType')
    ? localStorage.getItem('selectedSubType')
    : 'yet to start';
  const [selectedSubType, setSelectedSubType] = useState<subTabType>(selectedTabType);
  const [statusSearch, setStatusSearch] = useState(selectedSubType);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [loader, setLoader] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [providers, setProviders] = useState<{ [key: number]: string }>({});

  const headers = [
    { id: 'courseName', label: 'Course' },
    { id: 'courseUrl', label: 'Course URL' },
    { id: 'startDate', label: 'Start Date' },
    { id: 'endDate', label: 'Target End Date' },
    { id: 'actualEndDate', label: 'Actual End Date' },
    { id: 'percentage', label: 'Completed %' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions' },
  ];

  const handleTabChange = (newValue: subTabType) => {
    setStatusSearch(newValue);
    setSelectedSubType(newValue);
    setPage(0);
  };

  const handleIcons = (provider: string) => {
    switch (provider) {
      case 'Udemy':
        return udemyIcon;
      case 'Internal':
        return ptgIcon;
      case 'Coursera':
        return courseraIcon;
      default:
        return youtubeIcon;
    }
  };

  const handleCourseSearch = (searchedVal: string) => {
    const filteredRows = filteredList.filter((row: any) => {
      return Object.keys(row)
        .filter((k) => k !== 'modifiedOn')
        .some((k) => {
          if (
            typeof row[k] === 'number' &&
            (k === 'percentage' || k === 'courseId' || k === 'managerId' || k === 'userId')
          ) {
            return String(row[k]).toLowerCase().includes(searchedVal.toLowerCase());
          } else if (
            typeof row[k] === 'number' &&
            (k === 'startDate' || k === 'endDate' || k === 'actualEndDate')
          ) {
            const dateStr = new Date(row[k]).toLocaleDateString();
            return dateStr.toLowerCase().includes(searchedVal.toLowerCase());
          } else if (row[k] === null) {
            return false;
          } else {
            return String(row[k]).toLowerCase().includes(searchedVal.toLowerCase());
          }
        });
    });
    setFilterSearchList(filteredRows);
    handleChangePage(null, 0);
  };
  useEffect(() => {
    const statusFilterData = list.filter((item: any) =>
      item.status.toLowerCase().includes(statusSearch.toLowerCase()),
    );
    setFilterSearchList(statusFilterData);
    setFilteredList(statusFilterData);
  }, [statusSearch]);

  useEffect(() => {
    localStorage.setItem('selectedSubType', selectedSubType);
  }, [selectedSubType]);

  useEffect(() => {
    getStatusCount(globalObject.userObject.id).then((res) => {
      const internStatus = res.data;
      internStatus.forEach((countStatus: any) => {
        if (countStatus.status === 'completed') {
          setStatusCompletedCount(countStatus.count);
        }
        if (countStatus.status === 'in progress') {
          setStatusInProgressCount(countStatus.count);
        }
        if (countStatus.status === 'yet to start') {
          setStatusYetToStartCount(countStatus.count);
        }
      });
    });

    getInternCandidates(globalObject.userObject.id).then((res) => {
      setList(res.data);
      setFilteredList(
        res.data.filter((item: any) => item.status.toLowerCase().includes(selectedTabType)),
      );
      setFilterSearchList(
        res.data.filter((item: any) => item.status.toLowerCase().includes(selectedTabType)),
      );
      const providerMap: { [key: number]: string } = {};
      res.data.forEach((item: any) => {
        providerMap[item.courseUrl] = item.provider;
      });
      setProviders(providerMap);
    });
  }, []);

  useEffect(() => {
    getCertificationArea().then((res: { data: any }) => setCertificationAreas(res.data));
    getCertificationDetails(globalObject.userObject.id).then((res) => setCertificates(res.data));
  }, []);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getUpdatedStatus = () => {
    if (percentage === 100) {
      return 'completed';
    } else if (percentage === 0) {
      return 'yet to start';
    } else {
      return 'in progress';
    }
  };

  const UploadEmployeeCertification = () => {
    setLoader(true);
    postCertificationDetails({
      employeeId: globalObject.userObject.id,
      certificationName: formik.values.certificationName,
      certificationAreaId: formik.values.certificationArea,
      completeDate: formik.values.completedDate,
      expiryDate: formik.values.expiryDate,
      examCode: formik.values.examCode,
      certificationNumber: formik.values.certificationNumber,
    })
      .then((_res) => {
        setLoader(false);
        setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Certification details uploaded successfully.',
        });
        setCertificateDialogOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: `${err.response.data.message}`,
        });
      });
  };

  const updateInternCourseDetails = () => {
    updateInternDetails({
      internId: globalObject.userObject.id,
      courseId: courseId,
      percentage: percentage,
      status: getUpdatedStatus(),
    })
      .then((res) => {
        console.log(res);
        setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Course details updated successfully.',
        });
        setDialogOpen(false);
        localStorage.setItem('selectedSubType', selectedSubType);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
      });
  };

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  function handleExport() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'Employee_Course_Details';
    const excelDataCandidates: any[] = filteredList.map((c: any) => ({
      course: c.courseName,
      courceUrl: c.courseUrl,
      'start date': dayjs(c.startDate).format('DD-MMM-YYYY'),
      'target end date': dayjs(c.endDate).format('DD-MMM-YYYY'),
      'actual end date': dayjs(c.actualEndDate).format('DD-MMM-YYYY'),
      Percentage: c.percentage,
      Status: c.status,
    }));
    const ws = XLSX.utils.json_to_sheet(excelDataCandidates);
    const range = XLSX.utils.decode_range(String(ws['!ref']));
    const columns = [2, 3, 4, 5];
    if (filteredList.length != 0) {
      ws['!cols'] = Array(Object.keys(excelDataCandidates[0]).length).fill({ wpx: 150 });
      for (let col = range.s.c; col <= range.e.c; col++) {
        const headerCellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        ws[headerCellAddress].s = {
          font: {
            bold: true,
          },
          alignment: {
            horizontal: 'center',
          },
        };
        for (let row = range.s.r + 1; row <= range.e.r; row++) {
          if (columns.includes(col)) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            ws[cellAddress].s = {
              alignment: {
                horizontal: 'center',
                vertical: 'center',
              },
            };
          }
        }
      }
    }
    const wb = {
      Sheets: { candidates: ws },
      SheetNames: ['candidates'],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const cardStyles = (isSelected: boolean) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexBasis: 'calc(100% / 3)',
    flexGrow: 1,
    color: 'black',
    backgroundColor: 'white',
    marginTop: '20px',
    borderRadius: '8px',
    border: isSelected ? '2px solid #a72037' : 'none',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
    transition: '0.3s',
  });
  const tabStyles = (isSelected: boolean) => ({
    height: '10px',
    backgroundColor: isSelected ? 'white' : '',
    borderBottom: !isSelected ? '1px solid black' : '',
    fontSize: 'small',
    fontWeight: 'bold',
    marginRight: '4px',
    color: isSelected ? '#a72037' : 'black',
    boxShadow: isSelected ? '0 4px 8px 0 rgba(0,0,0,0.1)' : '',
    borderRadius: isSelected ? '5px' : '',
  });

  const handleRequestSort = (property: keyof any, _isAsc?: boolean) => {
    if (_isAsc !== undefined) setOrder(_isAsc ? 'asc' : 'desc');
    else {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
    }
    setOrderBy(property);
  };

  const sortTableData = (array: any) => {
    const flag = order === 'desc' ? -1 : 1;
    if (orderBy === 'startDate')
      return array.sort((a: any, b: any) =>
        new Date(a['startDate']).getTime() > new Date(b['stardDate']).getTime()
          ? flag * 1
          : new Date(b['startDate']).getTime() > new Date(a['startDate']).getTime()
          ? flag * -1
          : 0,
      );
    if (orderBy === 'endDate')
      return array.sort((a: any, b: any) =>
        new Date(a['endDate']).getTime() > new Date(b['endDate']).getTime()
          ? flag * 1
          : new Date(b['endDate']).getTime() > new Date(a['endDate']).getTime()
          ? flag * -1
          : 0,
      );
    if (orderBy === 'actualEndDate')
      return array.sort((a: any, b: any) =>
        new Date(a['endDate']).getTime() > new Date(b['endDate']).getTime()
          ? flag * 1
          : new Date(b['endDate']).getTime() > new Date(a['endDate']).getTime()
          ? flag * -1
          : 0,
      );
    if (orderBy === 'percentage')
      return array.sort((a: any, b: any) =>
        new Date(a['percentage']).getTime() > new Date(b['percentage']).getTime()
          ? flag * 1
          : new Date(b['percentage']).getTime() > new Date(a['percentage']).getTime()
          ? flag * -1
          : 0,
      );
    return array.sort((a: any, b: any) =>
      a[orderBy] && a[orderBy].toLowerCase() > b[orderBy] && b[orderBy].toLowerCase()
        ? flag * 1
        : b[orderBy] && b[orderBy].toLowerCase() > a[orderBy] && a[orderBy].toLowerCase()
        ? flag * -1
        : 0,
    );
  };

  const certificationSchema = Yup.object().shape({
    certificationName: Yup.string().required('CertificateName is required'),
    certificationArea: Yup.string().required('CertificationArea is required'),
    completedDate: Yup.date().required('CompleteDate is required'),
    expiryDate: Yup.date().required('ExpiryDate is required'),
    examCode: Yup.string().required('Exam Code is required'),
    certificationNumber: Yup.string().required('CertificateNumber is Required'),
  });

  const formik = useFormik({
    initialValues: {
      certificationName: '',
      certificationArea: '',
      completedDate: '',
      expiryDate: '',
      examCode: '',
      certificationNumber: '',
    },
    validationSchema: certificationSchema,
    onSubmit: () => {
      UploadEmployeeCertification();
    },
    onReset: () => {
      formik.resetForm();
    },
  });

  interface FormValues {
    documentName: string;
    attachment: File | undefined;
  }

  const documentFormik = useFormik<FormValues>({
    initialValues: {
      documentName: '',
      attachment: undefined,
    },
    onSubmit: (values) => {
      upload(values);
      documentFormik.resetForm();
      documentFormik.setFieldValue('attachement', undefined);
    },
    validate: (values) => {
      const errors: { documentName?: string; attachment?: string } = {};
      if (!values.documentName || values.documentName == '')
        errors['documentName'] = 'Document name is required';
      else if (values.documentName.length > 200)
        errors['documentName'] = 'Document Name should not exceed 200 characters';
      if (!values.attachment) {
        errors['attachment'] = 'Please attach a document';
      } else {
        const allowedExtensions = ['pdf', 'doc', 'docx', 'txt'];
        const fileExtension = values.attachment.name.split('.').pop()?.toLowerCase();

        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
          errors['attachment'] = 'Please attach a document';
        }
      }
      return errors;
    },
  });

  const getError = (formik: any, fieldName: string) => {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  };

  const upload = (values: any) => {
    uploadInternDocument(
      values.attachment,
      globalObject.userObject.id,
      courseId,
      managerId,
      values.documentName,
    )
      .then((_resp) => {
        alert('Document added successfully');
      })
      .catch((_err) => {
        alert('Something went wrong. Please try later!');
      })
      .finally(() => setDocumentDialogOpen(false));
  };

  return (
    <Grid style={{ width: '100%' }}>
      <Grid item sx={{ display: 'flex', padding: '10px', gap: '2rem' }}>
        <Card style={cardStyles(statusSearch === 'completed')}>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              flexDirection: 'column',
              padding: '20px',
            }}>
            <Typography variant='h5' component='div'>
              Courses Completed
            </Typography>
            <Typography variant='h3' component='div'>
              {statusCompletedCount}
            </Typography>
          </div>
          <div style={{ alignContent: 'center' }}>
            <CompletedIcon style={{ padding: '20px' }} />
          </div>
        </Card>

        <Card style={cardStyles(statusSearch === 'in progress')}>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              flexDirection: 'column',
              padding: '20px',
            }}>
            <Typography variant='h5' component='div'>
              Courses In Progress
            </Typography>
            <Typography variant='h3' component='div'>
              {statusInProgressCount}
            </Typography>
          </div>
          <div style={{ alignContent: 'center' }}>
            <InProgressIcon style={{ padding: '20px' }} />
          </div>
        </Card>

        <Card style={cardStyles(statusSearch === 'yet to start')}>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              flexDirection: 'column',
              padding: '20px',
            }}>
            <Typography variant='h5' component='div'>
              Courses Yet To Start
            </Typography>
            <Typography variant='h3' component='div'>
              {statusYetToStartCount}
            </Typography>
          </div>
          <div style={{ alignContent: 'center' }}>
            <YetToStartIcon style={{ padding: '20px' }} />
          </div>
        </Card>
      </Grid>
      <div style={{ fontSize: 'large', fontWeight: 'bold', marginBottom: '10px' }}>
        <span>Courses</span>
      </div>
      <div>
        <Tabs
          className='sub-tabs'
          sx={{ borderBottom: '1px solid black' }}
          value={selectedSubType}
          onChange={(_e, v) => {
            handleTabChange(v);
          }}>
          <Tab
            className='sub-tab'
            style={tabStyles(selectedSubType == 'yet to start')}
            label={'YET TO START'}
            value={'yet to start'}
          />
          <Tab
            className='sub-tab'
            style={tabStyles(selectedSubType == 'in progress')}
            label={'IN PROGRESS'}
            value={'in progress'}
          />
          <Tab
            className='sub-tab'
            style={tabStyles(selectedSubType == 'completed')}
            label={'COMPLETED'}
            value={'completed'}
          />
        </Tabs>
      </div>
      <div
        style={{
          marginTop: '20px',
          marginRight: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '1rem',
          alignItems: 'center',
        }}>
        <div>
          <Tooltip title={'export to CSV'}>
            <IconButton
              style={{ fontSize: 'small', color: '#a72037', borderRadius: '20px', gap: '0.5rem' }}
              onClick={handleExport}>
              <img src={exportIcon} />
              <span style={{ fontWeight: 'bold' }}>Export CSV</span>
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <OutlinedInput
            id='table-search'
            type='text'
            placeholder='Course'
            className='search-bar'
            sx={{ margin: '0', height: '30px', borderRadius: '10px', backgroundColor: 'white' }}
            onChange={(e) => {
              handleCourseSearch(e.target.value);
            }}
            endAdornment={
              <InputAdornment position='end'>
                <Search />
              </InputAdornment>
            }
          />
        </div>
        <div
          style={{
            justifyContent: 'flex-end',
          }}>
          <Tooltip slot='top' title={'Upload Certificate'}>
            <Button
              className='bg-btn'
              sx={{ color: '#a72037', gap: '2px' }}
              onClick={() => setCertificateDialogOpen(true)}
              disabled={!isPermissionsWrite(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD)}>
              <Add />
              <span style={{ paddingTop: '3px' }}>Certificates</span>
            </Button>
          </Tooltip>
        </div>
      </div>
      <Grid item style={{ marginTop: '20px' }}>
        <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
          <Table sx={{ minWidth: 650, margin: 'auto' }} aria-label='simple table' size='small'>
            <TableHead sx={{ background: 'rgb(224 224 224)' }}>
              <TableRow>
                {headers.map((headCell, index) => (
                  <TableCell key={index}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        {headCell.label}
                        {headCell.label === 'Course'}
                        {headCell.label === 'Start Date' ||
                          headCell.label === 'Target End Date' ||
                          headCell.label === 'Actual End Date'}
                      </div>
                      <div
                        onClick={() => handleRequestSort(headCell.id)}
                        onMouseEnter={() => {
                          setHeader(headCell.label);
                        }}
                        onMouseLeave={() => {
                          setHeader('');
                        }}>
                        {headCell.label != 'Actions' && headCell.label != 'Status' && (
                          <TableSortLabel
                            active={orderBy === headCell.id || header === headCell.label}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => handleRequestSort(headCell.id)}>
                            {orderBy === headCell.id ? (
                              <Box component='span' sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        )}
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterSearchList.length > 0 ? (
                sortTableData(filterSearchList)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((name: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{name.courseName}</TableCell>
                      <TableCell sx={{ paddingLeft: '40px' }}>
                        <a href={name.courseUrl} target='_blank' rel='noopener noreferrer'>
                          <img src={handleIcons(providers[name.courseUrl])} alt='Course Icon' />
                        </a>
                      </TableCell>
                      <TableCell>{dayjs(name.startDate).format('DD-MMM-YYYY')}</TableCell>
                      <TableCell>{dayjs(name.endDate).format('DD-MMM-YYYY')}</TableCell>
                      <TableCell>
                        {name.actualEndDate && dayjs(name.actualEndDate).format('DD-MMM-YYYY')}
                      </TableCell>
                      <TableCell sx={{ paddingLeft: '40px' }}>{name.percentage}%</TableCell>
                      <TableCell>{name.status}</TableCell>
                      <TableCell>
                        <IconButton
                          style={{
                            color: name.status === 'completed' ? 'gray' : '#a72037',
                          }}
                          disabled={
                            !isPermissionsWrite(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD) ||
                            name.status == 'completed'
                          }
                          onClick={() => {
                            setDialogOpen(true);
                            setCourseId(name.courseId);
                            setPercentage(name.percentage);
                          }}>
                          <EditIcon
                            className={
                              name.status === 'completed' ||
                              isPermissionsWrite(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD)
                                ? 'gray'
                                : 'delete-icon-disabled'
                            }
                          />
                        </IconButton>
                        <Tooltip title='Upload Document'>
                          <IconButton
                            style={{ color: name.status === 'completed' ? 'gray' : '#a72037' }}
                            disabled={
                              !isPermissionsWrite(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD) ||
                              name.status == 'completed'
                            }
                            onClick={() => {
                              setCourseId(name.courseId);
                              setManagerId(name.managerId);
                              setDocumentDialogOpen(true);
                            }}>
                            <UploadFileIcon
                              className={
                                name.status === 'completed' ||
                                isPermissionsWrite(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD)
                                  ? 'gray'
                                  : 'delete-icon-disabled'
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No records to display</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TablePagination
            sx={{ marginLeft: 'auto' }}
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>

      <Dialog fullWidth open={documentDialogOpen} onClose={() => setDocumentDialogOpen(false)}>
        <DialogTitle>Upload Document</DialogTitle>
        <form onSubmit={documentFormik.handleSubmit}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div>
              <InputLabel htmlFor='documentName' className='field-label'>
                Document Name
              </InputLabel>
              <TextField
                style={{ width: '200px' }}
                id='documentName'
                name='documentName'
                size='small'
                value={documentFormik.values.documentName}
                placeholder='Eg: Education, Experience..'
                onBlur={documentFormik.handleBlur}
                onChange={documentFormik.handleChange}
              />
              {documentFormik.touched['documentName'] && documentFormik.errors['documentName'] && (
                <div className='mt-0 form-err-msg'>{documentFormik.errors['documentName']}</div>
              )}
            </div>
            <div>
              <InputLabel htmlFor='browse-document' className='field-label'>
                Browse Document
              </InputLabel>
              <TextField
                inputProps={{
                  accept: '.pdf,.doc,.docx,.txt',
                }}
                style={{ width: '250px' }}
                id='browse-document'
                type='file'
                name='attachment'
                size='small'
                fullWidth
                onChange={(event: any) =>
                  documentFormik.setFieldValue('attachment', event.target.files[0])
                }
              />
              {documentFormik.touched['attachment'] && documentFormik.errors['attachment'] && (
                <div className='mt-0 form-err-msg'>{documentFormik.errors['attachment']}</div>
              )}
            </div>
          </div>
          <div className='center-contents' style={{ marginTop: '20px', marginBottom: '10px' }}>
            <Button className='default-btn bg-btn' type='submit' disabled={!documentFormik.dirty}>
              Upload
            </Button>
          </div>
        </form>
      </Dialog>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Course Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Percentage: {percentage}%</DialogContentText>
          <Slider
            value={percentage}
            onChange={(e, newValue) => setPercentage(newValue as number)}
            aria-labelledby='input-slider'
            valueLabelDisplay='auto'
            step={1}
            min={0}
            max={100}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={updateInternCourseDetails}>Save</Button>
        </DialogActions>
      </Dialog>
      <Form
        onSubmit={(values) => {
          setSubmitted(true);
          formik.handleSubmit(values);
        }}>
        <Dialog
          open={certificateDialogOpen}
          maxWidth='lg'
          onClose={() => {
            setCertificateDialogOpen(false);
          }}>
          <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Add Certification</div>
              <div>
                <Tooltip title={'Upload Certificate File'}>
                  <IconButton
                    sx={{ color: '#a72037' }}
                    onClick={() => setCertificateDialogOpen(true)}>
                    <AttachmentIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              <div>
                <InputLabel className='field-label'>Certification Name</InputLabel>
                <TextField
                  id='certificationName'
                  placeholder='Certification Name'
                  value={formik.values.certificationName}
                  fullWidth
                  size='small'
                  onChange={(e) => {
                    formik.setFieldValue('certificationName', e.target.value);
                  }}
                />
                <div className='mt-0 form-err-msg'>{getError(formik, 'certificationName')}</div>
              </div>
              <div>
                <InputLabel id='certificationArea' className='field-label'>
                  Certification Area
                </InputLabel>
                <Select
                  fullWidth
                  labelId='certificationArea-label'
                  value={formik.values.certificationArea}
                  onChange={(e: any) => {
                    formik.setFieldValue('certificationArea', e.target.value);
                  }}>
                  {certificationAreas.map((area: any) => (
                    <MenuItem key={area.id} value={area.id}>
                      {area.name}
                    </MenuItem>
                  ))}
                </Select>
                <div className='mt-0 form-err-msg'>{getError(formik, 'certificationArea')}</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '2rem' }}>
                <div>
                  <InputLabel id='completedDate' className='field-label'>
                    Completed Date
                  </InputLabel>
                  <DatePicker
                    disablePast
                    value={formik.values.completedDate}
                    onChange={(value) => {
                      formik.setFieldValue(
                        'completedDate',
                        dateFormat(value?.toString(), 'isoDateTime'),
                      );
                    }}
                  />
                  <div className='mt-0 form-err-msg'>{getError(formik, 'completedDate')}</div>
                </div>
                <div>
                  <InputLabel id='expiryDate' className='field-label'>
                    Expiry Date
                  </InputLabel>
                  <DatePicker
                    value={formik.values.expiryDate}
                    onChange={(value) => {
                      formik.setFieldValue(
                        'expiryDate',
                        dateFormat(value?.toString(), 'isoDateTime'),
                      );
                    }}
                  />
                  <div className='mt-0 form-err-msg'>{getError(formik, 'expiryDate')}</div>
                </div>
              </div>
              <div>
                <InputLabel id='examCode' className='field-label'>
                  Exam Code
                </InputLabel>
                <TextField
                  placeholder='Exam Code'
                  value={formik.values.examCode}
                  fullWidth
                  size='small'
                  onChange={(e) => {
                    formik.setFieldValue('examCode', e.target.value);
                  }}
                />
                <div className='mt-0 form-err-msg'>{getError(formik, 'examCode')}</div>
              </div>
              <div>
                <InputLabel id='certificationNumber' className='field-label'>
                  Certification Number
                </InputLabel>
                <TextField
                  placeholder='Certification Number'
                  value={formik.values.certificationNumber}
                  fullWidth
                  size='small'
                  onChange={(e) => {
                    formik.setFieldValue('certificationNumber', e.target.value);
                  }}
                />
                <div className='mt-0 form-err-msg'>{getError(formik, 'certificationNumber')}</div>
              </div>
              <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
                <Table
                  sx={{ minWidth: 650, margin: 'auto' }}
                  aria-label='simple table'
                  size='small'>
                  <TableHead sx={{ background: 'rgb(224 224 224)' }}>
                    <TableRow>
                      <TableCell>Certification Name</TableCell>
                      <TableCell>Tech Stack Area</TableCell>
                      <TableCell>Certification Issue Date</TableCell>
                      <TableCell>Certification Expiry Date</TableCell>
                      <TableCell>Exam Code</TableCell>
                      <TableCell>Certificate Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {certificates.length > 0 ? (
                      certificates.map((cert: any, index: any) => (
                        <TableRow key={index}>
                          <TableCell>{cert.certificationName}</TableCell>
                          <TableCell>
                            {cert.certificationArea ? cert.certificationArea.name : 'N/A'}
                          </TableCell>
                          <TableCell>{dayjs(cert.completedDate).format('DD-MMM-YYYY')}</TableCell>
                          <TableCell>{dayjs(cert.expiryDate).format('DD-MMM-YYYY')}</TableCell>
                          <TableCell>{cert.examCode}</TableCell>
                          <TableCell>{cert.certificationNumber}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>No records to display</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </DialogContent>
          <DialogActions>
            <div>
              {' '}
              <Button
                className='default-btn bg-btn'
                onClick={() => {
                  setCertificateDialogOpen(false);
                }}>
                Cancel
              </Button>
            </div>
            <div>
              <Button className='default-btn bg-btn' onClick={() => formik.handleSubmit()}>
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Notify open={showToast} severity={toastMsg.severity} onClose={handleToastClose}>
          <span>{toastMsg.msg}</span>
        </Notify>
        {loader && (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
      </Form>
    </Grid>
  );
};

export default LmsInternDashboard;
