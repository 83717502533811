import axiosClient from '../common/axiosClient';

export async function postPermissions(
  request: any,
  role?: string,
  moduleId?: any,
  subModuleId?: any,
  isActive?: any,
  subModuleWrite?: any,
  subModuleDelete?: any,
  flag?: any,
) {
  return axiosClient.post('/permission', request, {
    params: { role, moduleId, subModuleId, isActive, subModuleWrite, subModuleDelete, flag },
  });
}

export async function getPermissions(roleId?: any, flag?: any) {
  return axiosClient.get('/permission', { params: { roleId, flag } });
}

export async function getPermissionsForUser(flag: any) {
  return axiosClient.get('/permission/roles', { params: { flag } });
}
