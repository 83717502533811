import {
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import LmsReportPieChart from './lms-report-pieChart';

export const TaskStatusReport = (props: { data: any[] }) => {
  const { data } = props;
  const [filteredData, setFilteredData] = useState({} as any);
  const [empCountObj, setEmpCountObj] = useState({} as any);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pieChartData, setPieChartData] = useState({} as any);
  const [percentageRange, setPercentageRange] = useState<string | null>(null);
  const [searchedVal, setSearchedVal] = useState<string>('');

  useEffect(() => {
    const statusData = data.reduce((acc, current) => {
      const percentage = current.percentage;

      if (percentage >= 0 && percentage <= 25) {
        acc['0%-25%'] = (acc['0%-25%'] || 0) + 1;
      } else if (percentage > 25 && percentage <= 50) {
        acc['25%-50%'] = (acc['25%-50%'] || 0) + 1;
      } else if (percentage > 50 && percentage <= 75) {
        acc['50%-75%'] = (acc['50%-75%'] || 0) + 1;
      } else if (percentage > 75 && percentage <= 100) {
        acc['75%-100%'] = (acc['75%-100%'] || 0) + 1;
      }

      return acc;
    }, {} as any);

    setPieChartData(statusData);
  }, [filteredData]);

  useEffect(() => {
    const filteredRowsWithRange = data.filter((element: any) => {
      const percentage = element.percentage;
      if (percentageRange === '0-25') {
        return percentage >= 0 && percentage <= 25;
      } else if (percentageRange === '25-50') {
        return percentage > 25 && percentage <= 50;
      } else if (percentageRange === '50-75') {
        return percentage > 50 && percentage <= 75;
      } else if (percentageRange === '75-100') {
        return percentage > 75 && percentage <= 100;
      } else {
        return true;
      }
    });

    const groupedData = {} as any;

    filteredRowsWithRange.forEach((item) => {
      const key = item.employeeName;
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(item);

      empCountObj[key] = (empCountObj[key] || 0) + 1;
    });

    let finalFilteredData = groupedData;

    if (searchedVal) {
      const searchParameters = [
        'employeeName',
        'courseName',
        'startDate',
        'endDate',
        'actualEndDate',
        'percentage',
        'status',
        'createdOn',
        'managerName',
        'levelName',
      ];

      const filteredSearchResults: { [key: string]: any[] } = {};

      Object.keys(finalFilteredData).forEach((groupKey) => {
        finalFilteredData[groupKey].forEach((element: any) => {
          for (const key of searchParameters) {
            if (
              typeof element[key] === 'string' &&
              element[key].toLowerCase().includes(searchedVal.toLowerCase())
            ) {
              if (!filteredSearchResults[groupKey]) {
                filteredSearchResults[groupKey] = [];
              }
              filteredSearchResults[groupKey].push(element);
              break;
            } else if (
              typeof element[key] === 'number' &&
              (key === 'startDate' ||
                key === 'endDate' ||
                key === 'actualEndDate' ||
                key === 'createdOn')
            ) {
              const dateStr = dayjs(element[key]).format('DD-MMM-YYYY');
              if (dateStr.toLowerCase().includes(searchedVal.toLowerCase())) {
                if (!filteredSearchResults[groupKey]) {
                  filteredSearchResults[groupKey] = [];
                }
                filteredSearchResults[groupKey].push(element);
                break;
              }
            } else if (element[key] === null) {
              continue;
            } else if (String(element[key]).toLowerCase().includes(searchedVal.toLowerCase())) {
              if (!filteredSearchResults[groupKey]) {
                filteredSearchResults[groupKey] = [];
              }
              filteredSearchResults[groupKey].push(element);
              break;
            }
          }
        });
      });

      finalFilteredData = filteredSearchResults;
    }

    setFilteredData(finalFilteredData);
    setEmpCountObj(empCountObj);
    setPage(0);
  }, [data, percentageRange, searchedVal]);

  const requestSearch = (searchValue: string) => {
    setSearchedVal(searchValue);
  };

  const dueRecords = (data: any) => {
    return data.endDate < dayjs(new Date()) && data.status !== 'completed';
  };

  const Row = ({ employeeData, employeeName }: any) => {
    const [open, setOpen] = useState(false);
    return (
      <React.Fragment>
        <TableRow>
          <TableCell sx={{ textTransform: 'capitalize' }}>{employeeName}</TableCell>
          <TableCell>{employeeData.length}</TableCell>
          <TableCell>
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ margin: '10px 50px 10px 50px' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Table size='small' aria-label='reporting-table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Employee Name</TableCell>
                    <TableCell>Course</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Actual End Date</TableCell>
                    <TableCell>Percentage</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Manager</TableCell>
                    <TableCell>Hired On</TableCell>
                    <TableCell>Level</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeData.map((data: any, index: number) => (
                    <TableRow
                      key={index}
                      style={
                        dueRecords(data)
                          ? {
                              backgroundColor: 'rgba(255, 0, 0, 0.2)',
                              border: '2px solid rgba(255, 0, 0, 0.2)',
                            }
                          : { backgroundColor: 'white' }
                      }>
                      <TableCell>{data.employeeName}</TableCell>
                      <TableCell>{data.courseName}</TableCell>
                      <TableCell>{dayjs(data.startDate).format('DD-MMM-YYYY')}</TableCell>
                      <TableCell>{dayjs(data.endDate).format('DD-MMM-YYYY')}</TableCell>{' '}
                      <TableCell>
                        {' '}
                        {data.actualEndDate
                          ? dayjs(data.actualEndDate).format('DD-MMM-YYYY')
                          : 'N/A'}{' '}
                      </TableCell>
                      <TableCell>{data.percentage}%</TableCell>
                      <TableCell>{data.status}</TableCell>
                      <TableCell>{data.managerName}</TableCell>
                      <TableCell>{dayjs(data.createdOn).format('DD-MMM-YYYY')}</TableCell>
                      <TableCell>{data.levelName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ display: 'flex', gap: '2rem', flex: 1 }}>
      <Paper
        sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none', width: '20%', height: '350px' }}>
        <div>
          <Typography variant='h6' fontWeight={700}>
            Training Status
          </Typography>
          <LmsReportPieChart initialData={pieChartData} />
        </div>
      </Paper>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none', width: '80%' }}>
        <br />
        <div
          className='export-search-block'
          style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <OutlinedInput
            style={{ height: '50px' }}
            id='table-search'
            type='text'
            placeholder='Search...'
            value={searchedVal}
            className='search-bar-report'
            onChange={(event) => requestSearch(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <Search />
              </InputAdornment>
            }
          />
          <div className='report-table-header'>
            <div className='filter-block'>
              <label htmlFor='percentage-range'>Percentage Range:</label>
              <select
                id='percentage-range'
                value={percentageRange || ''}
                onChange={(e) => {
                  setSearchedVal('');
                  setPercentageRange(e.target.value);
                }}>
                <option value=''>All</option>
                <option value='0-25'>0-25%</option>
                <option value='25-50'>25-50%</option>
                <option value='50-75'>50-75%</option>
                <option value='75-100'>75-100%</option>
              </select>
            </div>
          </div>
        </div>
        <TableContainer>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '40%' }}>Employee Name</TableCell>
                <TableCell sx={{ width: '40%' }}>No Of Course Details</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(filteredData).length > 0 &&
                Object.keys(filteredData)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((employeeName: any, index: number) => {
                    const employeeData = filteredData[employeeName];
                    return (
                      <Row key={index} employeeData={employeeData} employeeName={employeeName} />
                    );
                  })}
              {Object.keys(filteredData).length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <span>No results found</span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={Object.keys(filteredData).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
