import { Box, MenuItem, Select } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';

import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface DataItem {
  name: string;

  value: number;

  url: string;
}

const CandidateBySource = () => {
  const rawData: DataItem[] = [
    { name: 'LinkedIn', value: 0.1, url: 'https://www.linkedin.com' },

    { name: 'Indeed', value: 0.89, url: 'https://www.indeed.com' },

    { name: 'Glassdoor', value: 0.5, url: 'https://www.glassdoor.com' },

    { name: 'ZipRecruiter', value: 0.4, url: 'https://www.ziprecruiter.com' },

    { name: 'Monster', value: 0.48, url: 'https://www.monster.com' },

    { name: 'SimplyHired', value: 0.5, url: 'https://www.simplyhired.com' },

    { name: 'CareerBuilder', value: 0.45, url: 'https://www.careerbuilder.com' },

    { name: 'Snagajob', value: 0.3, url: 'https://www.snagajob.com' },
  ];

  const totalValue = rawData.reduce((acc, item) => acc + item.value, 0);

  const data = rawData.map((item) => ({
    ...item,

    value: ((item.value / totalValue) * 100).toFixed(2),
  }));

  const CustomLabel = ({ x, y, fill, value, width }: any) => (
    <text x={x + width + 15} y={y + 5} fontSize='14' fill={fill} textAnchor='start'>
      {value}%
    </text>
  );

  const RenderCustomYAxisTick = ({ x, y, payload }: any) => (
    <text
      x={x - 10}
      y={y + 5}
      fill='#333333'
      textAnchor='end'
      cursor='pointer'
      onClick={() => window.open(payload.value.url, '_blank')}>
      {payload.value}
    </text>
  );

  return (
    <>
      <div style={{ flex: 1, padding: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '-40px',
            marginBottom: '25px',
            padding: '0 20px',
            maxWidth: '1350px',
            marginLeft: '10px',
            marginRight: 'auto',
          }}>
          <Box display='flex' gap='1rem'>
            <Select
              sx={{ width: '135px', height: '30px' }}
              size='small'
              labelId='year-select'
              name='year'
              defaultValue={2024}>
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
            </Select>
            <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
              <span style={{ marginRight: '10px' }}>Start Date</span>
              <DatePicker className='date' />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
              <span style={{ marginRight: '10px' }}>End Date</span>
              <DatePicker className='date' />
            </div>
          </Box>
        </div>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='400px'
          width='100%'
          maxWidth='90%'
          margin='0 auto'
          position='relative'
          padding='10px'
          marginTop='180px'
          marginLeft='-60px'
          boxSizing='border-box'>
          <Box width='100%' height='100%' position='relative' paddingRight='30px'>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                layout='vertical'
                data={data}
                margin={{
                  top: 20,
                  right: 80,
                  left: 100,
                  bottom: 10,
                }}>
                <XAxis type='number' hide={true} />
                <YAxis
                  dataKey='name'
                  type='category'
                  width={200}
                  tick={<RenderCustomYAxisTick />}
                />
                <Tooltip />
                <Bar label={<CustomLabel />} dataKey='value' fill='#007bff' barSize={15}>
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default CandidateBySource;
