import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  Typography,
  InputBase,
  Menu,
  MenuItem,
  ListItemText,
  Radio,
  Select,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as CloseIcon } from '../../assets/sjd/close.svg';
import { ReactComponent as Search } from '../../assets/common-assets/search.svg';
import { ReactComponent as Add } from '../../assets/common-assets/add.svg';
import { ReactComponent as Done } from '../../assets/sjd/done.svg';
import { getInitials, getRole } from '../../../common/commonUtil';
import { getMyTeam } from '../../../service/teamService';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TadHiringTeam } from '../../models/tad-sjd-form-model';
// import { ReactComponent as MoreIcon } from '../../assets/dashboard-assets/more_vert.svg';
import TadTableFilter from '../common/tad-table-filter';
import TadTableSorting from '../common/tad-table-sorting';
import { ReactComponent as PlusIcon } from '../../assets/dashboard-assets/plusIcon.svg';
import { ReactComponent as AndroidPhoneIcon } from '../../assets/sjd/AndroidPhoneIcon.svg';
import { ReactComponent as WorkspaceIcon } from '../../assets/sjd/WorkspaceIcon.svg';
import { ReactComponent as DropDownArrow } from '../../assets/sjd/dropdownarrow.svg';
import { SelectChangeEvent } from '@mui/material/Select';
import { getTadRole } from '../../common/commonUtil';
import useNavigate from '../../../common/useNavigate';

// const headerTextStyle = {
//   color: 'var(--Primary-3, var(--Primary-Dark-grey-500, #333))',
//   fontFamily: 'Roboto',
//   fontSize: '1rem',
//   fontStyle: 'normal',
//   fontWeight: 600,
//   lineHeight: 'normal',
// };

const nameStyle = {
  color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
  fontFamily: 'Roboto',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.5625rem' /* 156.25% */,
  textDecorationLine: 'underline',
};

const emailStyle = {
  color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
  fontFamily: 'Roboto',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
};

const mobileStyle = {
  color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
  fontFamily: 'Roboto',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.5625rem',
  textDecorationLine: 'underline',
};

interface HiringTeamProps {
  previous?: () => void;
  hiringDetails: TadHiringTeam | undefined;
  mode?: 'edit-page' | 'details-page';
  save?: (values: TadHiringTeam) => void;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles?: { name: string }[];
  phoneNumber?: string;
  active?: boolean;
};

const HiringTeam: React.FC<HiringTeamProps> = ({
  previous = () => {},
  hiringDetails,
  save,
  mode = 'edit-page',
}) => {
  const [searchTeam, setSearchTeam] = useState('');
  const [search, setSearch] = useState('');
  const [team, setTeam] = useState([] as any[]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [searchList, setSearchList] = useState<User[]>([]);
  const [filteredTeam, setFilteredTeam] = useState([] as any[]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<string>('All');
  const [navigate] = useNavigate();

  const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setRowsPerPage(newValue);
  };

  const paginatedJobs =
    rowsPerPage === 'All' ? searchList : searchList.slice(0, parseInt(rowsPerPage, 10));

  const totalRows = paginatedJobs.length;
  const startRow = 1;
  const endRow = totalRows > 0 ? totalRows : 0;

  useEffect(() => {
    if (hiringDetails?.hiringTeam) {
      setSelectedUsers(hiringDetails.hiringTeam);
    }
  }, [hiringDetails]);

  useEffect(() => {
    setSearchList(selectedUsers);
  }, [selectedUsers]);

  const headers = [
    { id: 1, label: 'Name' },
    { id: 2, label: 'Role' },
    { id: 3, label: 'Mobile' },
    { id: 4, label: 'Status' },
    { id: 5, label: 'Actions' },
  ];
  const [selectedColumns, setSelectedColumns] = useState<number[]>(
    headers.map((header: any) => header.id),
  );

  const getStatusStyle = (status: boolean) => {
    if (status === true) {
      return {
        width: '3.875rem',
        height: '1.875rem',
        flexShrink: 0,
        borderRadius: '0.3125rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        lineHeight: '1.25rem',
        color: '#1976D2',
        background: 'rgba(122, 224, 139, 0.59)',
        fontWeight: 'bold',
      };
    } else {
      return {
        width: '3.875rem',
        height: '1.875rem',
        flexShrink: 0,
        borderRadius: '0.3125rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        lineHeight: '1.25rem',
        color: 'var(--Primary-Dark-grey-500, #333)',
        background: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
        fontWeight: 400,
      };
    }
  };

  const handleToggle = (roleId?: number | null, search?: string, array?: any, type?: string) => {
    let c = array;
    if (search && search.length) {
      c = c.filter(
        (candidate: any) =>
          (candidate.name && candidate.name.toLowerCase().includes(search.toLowerCase())) ||
          (candidate.email && candidate.email.toLowerCase().includes(search.toLowerCase())),
      );
    }
    if (roleId != null) {
      c = c.filter((team: any) => team.roles.some((team: any) => team.id === roleId));
    }
    type === 'team' ? setFilteredTeam(c) : setSearchList(c);
  };

  useEffect(() => {
    getMyTeam()
      .then((res) => {
        setFilteredTeam(res.data);
        setTeam(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const addUser = (teamMember: any) => {
    const filteredItem = (prevSelected: any[]) => {
      const isSelected = prevSelected.some((user) => user.id === teamMember.id);
      if (isSelected) {
        return prevSelected.filter((user) => user.id !== teamMember.id);
      } else {
        return [...prevSelected, teamMember];
      }
    };
    setSelectedUsers((prevSelected) => filteredItem(prevSelected));
    setSearchList((prevSelected) => filteredItem(prevSelected));
  };

  const DrawerList = (
    <Box role='presentation' sx={{ width: '43.75rem', height: '64rem' }}>
      <List sx={{ padding: '3.19rem 1.88rem 0rem 1.69rem' }}>
        <ListItem
          disablePadding
          sx={{ marginBottom: '1.19rem', cursor: 'pointer', fontWeight: 400 }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              gap: '18.12rem',
            }}>
            <div>
              <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>Add Hiring Team</Typography>
            </div>
            <div>
              <CloseIcon onClick={() => setMenuOpen(false)} />
            </div>
          </div>
        </ListItem>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5%', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.8rem',
                marginLeft: '1.06rem',
              }}>
              <Search />
              <InputBase
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  handleToggle(selectedRole, e.target.value, team, 'team');
                }}
                sx={{ ml: '10px', width: '100%' }}
                placeholder='Search'
                inputProps={{ 'aria-label': 'Search a keyword' }}
              />
            </div>
            <div>
              <Divider sx={{ display: 'flex' }} />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', width: '22%' }}>
            <span style={{ fontSize: '16px' }}>Select Role</span>
            <IconButton
              aria-controls={isOpen ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={isOpen ? 'true' : undefined}
              sx={{ padding: '0px' }}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setIsOpen(true);
              }}>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </div>
        <Menu
          id='basic-menu'
          sx={{ overflow: 'hidden' }}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              overflow: 'hidden',
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <List
            sx={{
              overflowY: 'scroll',
              padding: '8px 11px 20px 9px',
            }}>
            <MenuItem
              onClick={() => {
                setSelectedRole(null), handleToggle(null, search, team, 'team');
              }}>
              <Radio
                checked={selectedRole === null}
                onChange={() => {
                  setSelectedRole(null);
                  handleToggle(null, search, team, 'team');
                }}
              />
              <ListItemText primary='Select All' />
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedRole(3);
                handleToggle(3, search, team, 'team');
              }}>
              <Radio
                checked={selectedRole === 3}
                onChange={() => {
                  setSelectedRole(3);
                  handleToggle(3, search, team, 'team');
                }}
              />
              <ListItemText primary='Recruiter' />
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedRole(4);
                handleToggle(4, search, team, 'team');
              }}>
              <Radio
                checked={selectedRole === 4}
                onChange={() => {
                  setSelectedRole(4);
                  handleToggle(4, search, team, 'team');
                }}
              />
              <ListItemText primary='Evaluator' />
            </MenuItem>
          </List>
        </Menu>

        <div style={{ marginTop: '2.12rem', marginBottom: '1.25rem' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '1.25rem', color: '#000000' }}>
            Team Members
          </Typography>
        </div>
        {filteredTeam.map((teamMember) => (
          <div
            key={teamMember.id}
            style={{
              marginBottom: '0.63rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
              height: '5.4375rem',
            }}>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                marginLeft: '1.25rem',
              }}>
              <div
                className='user-icon'
                style={{ backgroundColor: 'rgb(25, 118, 210)', color: 'white' }}>
                {getInitials(teamMember)}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span
                  className='menuItem'
                  style={{ color: '#1976D2', textDecoration: 'underline' }}>
                  {teamMember.firstName} {teamMember.lastName}
                </span>
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: 'var(--Font-size-P, 1rem)',
                    color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                  }}>
                  {getRole(teamMember)}
                </span>
              </div>
            </div>
            <div style={{ marginRight: '1.25rem' }}>
              <IconButton>
                {selectedUsers.some((user) => user.id === teamMember.id) ? (
                  <Done
                    onClick={() => {
                      addUser(teamMember);
                    }}
                  />
                ) : (
                  <Add
                    onClick={() => {
                      addUser(teamMember);
                    }}
                  />
                )}
              </IconButton>
            </div>
          </div>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <div style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
        {mode === 'edit-page' && (
          <div className='paper' style={{ height: '8.8125rem', width: '100%' }}>
            <Paper className='new-job-requisition' sx={{ width: '100%', height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1.88rem',
                  gap: '7px',
                }}>
                <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                  Hiring Team ({selectedUsers.length})
                </span>
                <span
                  style={{
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                  }}>
                  Outline a comprehensive plan to identify, attract, and engage potential candidates
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginRight: '3.05rem',
                  alignItems: 'center',
                  gap: '2.31rem',
                }}>
                <button
                  onClick={() => setMenuOpen(true)}
                  style={{
                    width: '11.375rem',
                    height: '3.125rem',
                    flexShrink: 0,
                    borderRadius: '0.3125rem',
                    background: 'var(--Primary-Blue-500, #1976D2)',
                    color: '#FFF',
                    border: 'none',
                    fontFamily: 'Roboto',
                    fontSize: '1rem',
                    fontWeight: 500,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 1rem',
                    gap: '0.5rem',
                  }}>
                  <PlusIcon style={{ width: '1.2rem', height: '1.2rem' }} />
                  Add Hiring Team
                </button>
              </div>

              <Drawer
                sx={{ width: '21.0625rem', height: '64rem', flexShrink: 0 }}
                anchor='right'
                open={menuOpen}
                onClose={() => setMenuOpen(false)}>
                {DrawerList}
              </Drawer>
            </Paper>
          </div>
        )}

        <div style={{ maxHeight: '65.75rem', width: '100%' }}>
          <Paper
            className='paper'
            sx={{ width: '100%', height: mode === 'edit-page' ? '100%' : '33.25rem' }}>
            {mode === 'details-page' && (
              <>
                <div className='header-container'>
                  <div className='header-content-title'>
                    <span>Hiring Team</span>
                  </div>
                  {/* <div className='header-content-icon'>
                    <IconButton
                      onClick={() => {
                        edit == false ? setEdit(true) : setEdit(false);
                      }}>
                      <MoreIcon />
                    </IconButton>
                  </div> */}
                </div>
                <hr
                  style={{
                    color: '#C1C0C0',
                    marginBlockEnd: '0em',
                    marginBlockStart: '0em',
                  }}
                />
              </>
            )}
            <div style={{ padding: '2.69rem 1.94rem 2.19rem 1.88rem', flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '3.31rem',
                }}>
                <div style={{ height: '40px', width: '100%' }}>
                  <TextField
                    value={searchTeam}
                    onChange={(e) => {
                      setSearchTeam(e.target.value);
                      handleToggle(null, e.target.value, selectedUsers, 'user');
                    }}
                    placeholder='Search'
                    variant='outlined'
                    sx={{
                      width: 'calc(22.1875rem + 2px)',
                      height: '40px',
                      borderRadius: '0.625rem',
                      border: '1px solid #C1C0C0',
                      background: 'var(--Secondary-colors-White, #FFF)',
                      flexShrink: 0,
                      padding: 0,
                      '& .MuiOutlinedInput-root': {
                        padding: '0 0.5rem',
                        height: '100%',
                        boxSizing: 'border-box',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <IconButton>
                            <SearchIcon
                              sx={{ width: '1.25rem', height: '1.25rem', fill: '#2D7DFE' }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {mode === 'edit-page' && (
                  <div style={{ display: 'flex', alignItems: 'baseline', gap: '15px' }}>
                    <div style={{ height: '40px', width: '100%' }}>
                      <TadTableFilter
                        headers={headers}
                        selectedHeaders={(value) => setSelectedColumns(value)}
                      />
                    </div>
                    <div style={{ height: '40px', width: '100%' }}>
                      <TadTableSorting
                        data={selectedUsers}
                        sortingData={(value) => {
                          setSearchList(value);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <TableContainer style={{ width: '100%', height: '18rem' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {paginatedJobs.length > 0 &&
                        headers
                          .filter((column) => selectedColumns.includes(column.id))
                          .map((column) => <TableCell key={column.id}>{column.label}</TableCell>)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedJobs.length > 0 ? (
                      paginatedJobs.map((user: User) => (
                        <TableRow key={user.id}>
                          {selectedColumns.map((columnId) => {
                            switch (columnId) {
                              case 1:
                                return (
                                  <TableCell key={columnId}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <div
                                        className='user-icon'
                                        style={{
                                          backgroundColor: 'rgb(25, 118, 210)',
                                          color: 'white',
                                        }}>
                                        {getInitials(user)}
                                      </div>
                                      <div>
                                        <div style={nameStyle}>
                                          {`${user.firstName} ${user.lastName}`}
                                        </div>
                                        <div style={emailStyle}>{user.email}</div>
                                      </div>
                                    </div>
                                  </TableCell>
                                );
                              case 2:
                                return <TableCell key={columnId}>{getTadRole(user)}</TableCell>;
                              case 3:
                                return (
                                  <TableCell key={columnId}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                      <AndroidPhoneIcon style={{ marginRight: '8px' }} />
                                      <span style={mobileStyle}>{user.phoneNumber}</span>
                                    </span>
                                  </TableCell>
                                );
                              case 4:
                                return (
                                  <TableCell key={columnId}>
                                    <span style={getStatusStyle(user.active ?? false)}>
                                      {user.active ? 'ACTIVE' : 'INACTIVE'}
                                    </span>
                                  </TableCell>
                                );
                              case 5:
                                return (
                                  <TableCell key={columnId}>
                                    <IconButton>
                                      <MoreVertIcon />
                                    </IconButton>
                                  </TableCell>
                                );
                              default:
                                return null;
                            }
                          })}
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            style={{ textAlign: 'center', padding: 0, border: 0 }}>
                            <WorkspaceIcon
                              style={{
                                width: mode === 'details-page' ? '25%' : '20.875rem',
                                height: mode === 'details-page' ? '100%' : '18.8125rem',
                                flexShrink: 0,
                                objectFit: 'contain',
                                marginTop: mode === 'details-page' ? '0rem' : '7rem',
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            style={{
                              textAlign: 'center',
                              padding: '16px',
                              border: 0,
                            }}>
                            <div
                              style={{
                                color: 'var(--Body-P, var(--Primary-Dark-grey-500, #333))',
                                fontFamily: 'Roboto',
                                fontSize: '1rem',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '1.5625rem',
                                marginTop: '2rem',
                                marginBottom: '2rem',
                                border: 'none',
                                outline: 'none',
                                boxShadow: 'none',
                              }}>
                              No hiring team has been added here.
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}>
                <span style={{ marginRight: '10px' }}>
                  Showing {startRow} - {endRow} of {totalRows}
                </span>
                <label htmlFor='rows-per-page' style={{ marginLeft: '10px', marginRight: '5px' }}>
                  Rows
                </label>
                <Select
                  sx={{
                    alignItems: 'center',
                    '& .MuiSelect-icon': {
                      top: 'calc(50% - 0.3em)',
                    },
                  }}
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                  IconComponent={DropDownArrow}
                  style={{ height: '1.5rem', marginRight: '10px' }}>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value='All'>All</MenuItem>
                </Select>
              </div>
            </div>
            {mode === 'edit-page' && (
              <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Button
                    onClick={() => {
                      navigate([
                        {
                          label: 'JOB DEFINITIONS',
                          link: '/source-job-definition',
                        },
                      ]);
                    }}
                    className='button'
                    sx={{
                      width: '7.375rem',
                      height: '2.5rem',
                      borderRadius: '5px',
                      border: '1px solid #2D7DFE',
                      backgroundColor: '#FFF',
                      color: 'black',
                      textTransform: 'none',
                      fontSize: '1rem',
                      fontWeight: 400,
                    }}>
                    Cancel
                  </Button>

                  <div style={{ display: 'flex', gap: '15px' }}>
                    <Button
                      className='button'
                      onClick={() => {
                        previous();
                      }}
                      sx={{
                        width: '7.375rem',
                        height: '2.5rem',
                        borderRadius: '5px',
                        border: '1px solid #2D7DFE',
                        backgroundColor: '#FFF',
                        color: 'black',
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 400,
                      }}>
                      Previous
                    </Button>
                    <Button
                      className='button'
                      onClick={() => {
                        save !== undefined &&
                          selectedUsers.length > 0 &&
                          save({
                            hiringTeam: selectedUsers,
                          });
                      }}
                      sx={{
                        textTransform: 'none',
                        fontWeight: 400,
                        fontSize: '1rem',
                        width: '7.375rem',
                        height: '2.5rem',
                        color: 'white !important',
                        background: '#2D7DFE !important',
                      }}>
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Paper>
        </div>
      </div>
    </>
  );
};

export default HiringTeam;
