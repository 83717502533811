import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Load English locale
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { IconButton, Typography, Divider, Box, Paper } from '@mui/material';
import './new-dashboard.scss';
import { ReactComponent as EventIcon } from '../../assets/dashboard-assets/event.svg';
import { ReactComponent as MoreVertIcon } from '../../assets/dashboard-assets/more_vert.svg';

dayjs.extend(localizedFormat);

const UpCommingInterviews = () => {
  const formatDate = (date: dayjs.Dayjs | null) => {
    if (!date) return '';
    return date.format('dddd MMMM DD, YYYY');
  };

  const interviews = [
    {
      startTime: '11:00 AM',
      endTime: '12:00 PM',
      type: 'In Person',
      name: 'Ramana Joshi',
      position: 'Senior Developer',
    },
    {
      startTime: '01:00 PM',
      endTime: '01:30 PM',
      type: 'Team Meeting',
      name: 'Ramana Joshi',
      position: 'Senior Developer',
    },
    {
      startTime: '02:00 PM',
      endTime: '02:30 PM',
      type: 'Teams Meeting',
      name: 'Rabindrath Chaterjee',
      position: 'DevOps',
    },
    {
      startTime: '03:00 PM',
      endTime: '03:30 PM',
      type: 'Teams Meeting',
      name: 'Sushanth Kaushik',
      position: 'Senior UX Designer',
    },
    {
      startTime: '04:00 PM',
      endTime: '04:30 PM',
      type: 'Teams Meeting',
      name: 'Ravi Mallam',
      position: 'Java Developer',
    },
  ];

  return (
    <>
      <Paper sx={{ height: '100%', width: '100%' }}>
        <div className='first-div-header'>
          <div
            className='first-div-header-left'
            style={{ display: 'flex', marginLeft: '2%', gap: '0.5rem' }}>
            <EventIcon />
            <span>Upcoming Interviews</span>
          </div>
          <div className='first-div-header-right'>
            <EventIcon style={{ display: 'flex', gap: '0.5rem' }} />
            <span>{formatDate(dayjs())}</span>
            <IconButton className='icon-btn'>
              <KeyboardArrowDownIcon />
            </IconButton>
            <MoreVertIcon style={{ marginRight: '10px', height: '24px' }} />
          </div>
        </div>
        <hr style={{ color: '#C1C0C0', marginBlockEnd: '0em', marginBlockStart: '0em' }}></hr>
        <div className='interview-div-container'>
          {interviews.map((interview, index) => (
            <div key={index}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box sx={{ minWidth: '80px', textAlign: 'right', paddingRight: '8px' }}>
                  <Typography sx={{ fontWeight: 400, color: '#333333' }}>
                    {interview.startTime}
                  </Typography>
                  <Typography sx={{ fontWeight: 400, color: '#9e9e9e' }}>
                    {interview.endTime}
                  </Typography>
                </Box>
                <Divider orientation='vertical' flexItem className='interview-divider' />
                <Box>
                  <Typography variant='body2' color='textSecondary'>
                    {interview.type}
                  </Typography>
                  <Typography variant='body1' sx={{ color: '#333' }}>
                    Interview with <strong>{interview.name}</strong> for the{' '}
                    <strong>{interview.position}</strong> job
                  </Typography>
                </Box>
              </Box>
            </div>
          ))}
        </div>
      </Paper>
    </>
  );
};
export default UpCommingInterviews;
