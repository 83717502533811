import axiosClient from '../common/axiosClient';
import globalObject from '../common/global-variables';
import { isUserRole } from './../common/commonUtil';
import { ROLES } from './../constants/common';

export async function getAllSjd() {
  let params = {};
  if (!isUserRole(ROLES.ADMIN)) {
    params = {
      userId: globalObject.userObject.id,
    };
  }
  return axiosClient.get('/sjd', {
    params,
  });
}

export async function createSjd(values: any) {
  return axiosClient.post('/sjd', values);
}

export async function updateSjd(values: any) {
  return axiosClient.patch('/sjd', values);
}

export async function getSjd(id: any) {
  return axiosClient.get(`/sjd/${id}`);
}

export async function tagCandidateToSjd(request: any) {
  return axiosClient.post('/sjd/candidate', request);
}

export async function callRecommendationsApi(request: any) {
  return axiosClient.post('/sjd/recommendation', request);
}

export async function untagCandidateApi(request: any) {
  return axiosClient.delete('/sjd/candidate', { data: request });
}
