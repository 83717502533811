import globalObject from '../../common/global-variables';
import { LookupTypes } from '../../common/lookupTypes';

export function toUpperFirstChar(str: string): string {
  return str && str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function getTadRole(user: any) {
  return user?.roles
    .map((role: any) => toUpperFirstChar(getNameFromId(role.id, LookupTypes.TAD_ROLE)))
    .join(', ');
}

export function scrollToTop() {
  return window.scrollTo({
    top: 0,
    behavior: 'smooth', // Optional: for smooth scrolling
  });
}

export function getLabel(value: string | number | boolean | undefined): string {
  switch (typeof value) {
    case 'boolean':
      return toUpperFirstChar(value.toString());
    case 'number':
      return value.toString();
    case 'string':
      return value && value.toString().length > 150
        ? `${value.toString().substring(0, 150)}...`
        : value;
    default:
      return value ? value : '';
  }
}

export function parseLocation(input: string): string[] {
  const result = input?.match(/\[([^\]]+)\]/g)?.map((location) => location.slice(1, -1)) || [];
  return result;
}

export function getNameFromId(id: number | undefined, type: LookupTypes) {
  const obj = globalObject.lookupvalues[type].find((v: any) => v.id === id);
  if (obj) {
    return obj.name;
  }
  return null;
}

export function getActivityEventName(evnetId: number, eventType: LookupTypes): string {
  return toUpperFirstChar(getNameFromId(evnetId, eventType).replace(/_/g, ' '));
}

export function handleCopy(text: string) {
  navigator.clipboard.writeText(text).then(() => {});
}

export function handleCandidateInitials(text: string) {
  const trimmedText = text?.trim();
  if (trimmedText && /\s+/.test(trimmedText)) {
    const words = trimmedText.split(/\s+/);
    const initials = words.map((word) => word.charAt(0).toUpperCase()).join('');
    return initials;
  } else {
    return trimmedText?.charAt(0)?.toUpperCase() || '';
  }
}

export function formatDate(timestamp: number) {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-GB', options);
}

export function isTadPermissions(module: string, subModule?: string): boolean {
  const roles: any[] = globalObject.userObject.roles.map((role: any) => role.name);
  const permissionType = globalObject.lookupvalues[LookupTypes.PERMISSION_TYPE].find(
    (e: any) => e.name == 'No Access',
  );
  for (const role of roles) {
    const permission = globalObject.permissions[role]?.[module];
    if (permission && permission.value.read !== permissionType?.id) {
      if (subModule) {
        const subModulePermission = permission.subModules.find(
          (e: any) => e.subModuleId['name'] == subModule,
        );
        if (subModulePermission?.isActive) {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
}

export function isTadPermissionsSubModule(module: string, subModule: string): boolean {
  const roles: any[] = globalObject.userObject.roles.map((role: any) => role.name);
  for (const role of roles) {
    const permission = globalObject.permissions[role]?.[module];
    if (permission) {
      const subModulePermission = permission.subModules.find(
        (e: any) => e.subModuleId['name'] === subModule,
      );
      if (subModulePermission?.isActive === true) {
        return true;
      }
    }
  }
  return false;
}

export function isTadPermissionsWrite(module: string, subModule?: string): boolean {
  const roles: any[] = globalObject.userObject.roles.map((role: any) => role.name);
  const permissionType = globalObject.lookupvalues[LookupTypes.PERMISSION_TYPE].find(
    (e: any) => e.name == 'No Access',
  );
  for (const role of roles) {
    const permission = globalObject.permissions[role]?.[module];
    if (permission && permission.value.read !== permissionType?.id) {
      if (subModule) {
        const subModulePermission = permission.subModules.find(
          (e: any) => e.subModuleId['name'] == subModule,
        );
        if (subModulePermission?.write) {
          return true;
        }
      } else {
        if (permission && permission.value.write !== permissionType?.id) {
          return true;
        }
      }
    }
  }
  return false;
}

export function isTadPermissionsDelete(module: string, subModule?: string): boolean {
  const roles: any[] = globalObject.userObject.roles.map((role: any) => role.name);
  const permissionType = globalObject.lookupvalues[LookupTypes.PERMISSION_TYPE].find(
    (e: any) => e.name == 'No Access',
  );
  for (const role of roles) {
    const permission = globalObject.permissions[role]?.[module];
    if (permission && permission.value.read !== permissionType?.id) {
      if (subModule) {
        const subModulePermission = permission.subModules.find(
          (e: any) => e.subModuleId['name'] == subModule,
        );
        if (subModulePermission?.delete) {
          return true;
        }
      } else {
        if (permission && permission.value.delete !== permissionType?.id) {
          return true;
        }
      }
    }
  }
  return false;
}
