import axios from 'axios';
import axiosClient from '../common/axiosClient';
import globalObject from '../common/global-variables';

export async function login(req: any) {
  return axiosClient.post('/security/login', req);
}
export async function authenticate(req: any) {
  // const flag = localStorage.getItem('switch');
  // console.log('authenticate => ', flag);
  // return axiosClient.post('/security/authenticate', req, { params: { flag: flag ? true : false } });
  return axiosClient.post('/security/authenticate', req);
}
export async function changePassword(
  newPassword: string,
  currentPassword?: string,
  flag?: boolean,
) {
  console.log({
    newPassword: btoa(newPassword),
    currentPassword: currentPassword && btoa(currentPassword),
    flag: flag,
  });
  return axiosClient.patch('/security/changePassword', {
    newPassword: btoa(newPassword),
    currentPassword: currentPassword && btoa(currentPassword),
    flag: flag,
  });
}

export async function refreshToken(refreshToken: string) {
  const formData = new FormData();
  formData.append('client_id', globalObject.properties.microsoft_client_id);
  formData.append('scope', globalObject.properties.microsoft_scope);
  formData.append('refresh_token', refreshToken);
  formData.append('grant_type', 'refresh_token');
  formData.append('redirect_uri', globalObject.properties.microsoft_redirect_url);

  return axios.post(
    `https://login.microsoftonline.com/${globalObject.properties.microsoft_tenant_id}/oauth2/v2.0/token`,
    formData,
  );
}
