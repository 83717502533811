import axiosClient from '../common/axiosClient';

export async function addQuestion(request: any) {
  return axiosClient.post('/questionnaire/question', request);
}

export async function addQuestionnaire(request: any) {
  return axiosClient.post('/questionnaire', request);
}

export async function updateQuestionnaire(request: any) {
  return axiosClient.patch('/questionnaire', request);
}

export async function getQuestionsByType(type: any, skills?: any[]) {
  return axiosClient.post('/questionnaire/question/type', {
    questionnaireType: type,
    skills,
  });
}

export async function getAllQuestionnaires() {
  return axiosClient.get('/questionnaire');
}

export async function getQuestionnaireById(questionnaireId: number | string) {
  return axiosClient.get(`/questionnaire/${questionnaireId}`);
}

export async function deleteQuestion(id: string) {
  return axiosClient.delete(`/questionnaire/question/${id}`);
}

export async function tagQuestionnaireToSjd(questionnaireId: any, sjdId: any) {
  return axiosClient.post('/questionnaire/sjd', {
    sjdId,
    questionnaireId,
  });
}

export async function getCandidateAnswers(sjdId: number, candidateId: number) {
  return axiosClient.get('/questionnaire/answer', {
    params: {
      sjdId,
      candidateId,
    },
  });
}

export async function saveCandidateAnswers(answers: any) {
  return axiosClient.put('/questionnaire/answer', answers);
}
