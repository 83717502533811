import React, { useEffect, useState } from 'react';
// import PageHeader from '../../../../components/common/page-header/page-header';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ReactComponent as DoneIcon } from './../../../assets/common-assets/Vector.svg';
import UploadResume from './upload-resume';
import { BreadCrumbConfig, getNameFromId, mapSkills } from '../../../../common/commonUtil';
import { parseResume } from '../../../../service/candidateService';
import Notify from '../../../common/notify';
import { getSkills } from '../../../../service/lookupService';
import globalObject from '../../../../common/global-variables';
import { LookupTypes } from '../../../../common/lookupTypes';
import CandidateDetails from './candidate-details';
import EmploymentDetails from './employment-details';
import EducationCertificationDetails from './education-certification-details';
import AdditionalDetails from './additional-details';
import { TadCandidateDetailsModel } from '../../../models/tad-candidate-form-model';
import TadPageHeader from '../../common/page-header/tad-page-header';
import { CANDIDATE_SOURCE } from '../../../../constants/common';
import { tadAddCandidate } from '../../../service/tadCandidateService';
// import mammoth from 'mammoth';
import useNavigate from '../../../../common/useNavigate';
import { ReactComponent as CloudUploadIcon } from '../../../assets/candidate-assets/cloud_upload.svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AddCandidates = () => {
  const [candidateDetails, setCandidateDetails] = useState({
    operations: globalObject.userObject.operations,
  } as TadCandidateDetailsModel);
  const [skills, setSkills] = useState([] as any[]);
  const [activeStep, setActiveStep] = useState(1);
  const [file, setFile] = useState();
  type AlertColor = 'success' | 'info' | 'warning' | 'error';
  const [showOverlay, setShowOverlay] = useState(false);
  // const [docContent, setDocContent] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [finalData, setFinalData] = useState({});
  const [showFeedbackAlert, setShowFeedbackAlert] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [navigate] = useNavigate();

  // const [formValue, setFormValue] = useState({
  //   details: {},
  //   sourcingDetails: {},
  //   hiringTeam: {},
  //   // interviewProcessDetails: {},
  // } as TadSjdModel);

  // const appendFieldValues = (values: any, formName: string) => {
  //   const updatedValue = formValue;
  //   updatedValue[formName as keyof typeof formValue] = values;
  //   setFormValue(updatedValue);
  // };

  useEffect(() => {
    getSkills()
      .then((resp) => setSkills(resp.data))
      .catch((_err) => setSkills([]));
  }, []);

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const updatedCandidateDetails = (value: TadCandidateDetailsModel) => {
    setCandidateDetails({
      ...candidateDetails,
      ...value,
    });
  };

  const save = (value: TadCandidateDetailsModel) => {
    setFinalData(value);
    if (
      globalObject.properties.show_autopopulate_feedback == 'true' &&
      getNameFromId(value.sourceChannel, LookupTypes.TAD_SOURCE_CHANNEL) !==
        CANDIDATE_SOURCE.REFERRAL
    ) {
      setShowFeedbackAlert(true);
    } else {
      saveCandidate();
    }
  };

  const saveCandidate = (aiCheckbox?: boolean) => {
    const newObj = {
      ...candidateDetails,
      ...finalData,
      aiCheckbox,
    };
    setCandidateDetails(newObj);
    setShowOverlay(true);
    console.log(aiCheckbox, newObj);
    getNameFromId(newObj.sourceChannel, LookupTypes.TAD_SOURCE_CHANNEL) !=
      CANDIDATE_SOURCE.REFERRAL &&
      tadAddCandidate(file, newObj)
        .then((_resp) => {
          setToastMsg({
            severity: 'success',
            msg: 'Candidate added successfully',
          });
          setShowToast(true);
          setTimeout(() => {
            setShowOverlay(false);
            const existingConfig = localStorage.getItem('breadcrumb-config');
            let existing: BreadCrumbConfig[] = [];
            if (existingConfig) {
              existing = JSON.parse(existingConfig);
              existing.splice(existing.length - 1, 1);
              navigate(existing, true);
            } else {
              navigate(
                [
                  {
                    label: 'Candidate',
                    link: '/candidate',
                  },
                ],
                true,
              );
            }
          }, 3000);
        })
        .catch((_err) => {
          setShowOverlay(false);
          setToastMsg({
            severity: 'error',
            msg: 'Something went wrong. Please try later!',
          });
          setShowToast(true);
        });
  };

  const formik = useFormik({
    initialValues: {
      resume: null,
    },
    validationSchema: Yup.object({
      resume: Yup.mixed().required('A resume file is required'),
    }),
    onSubmit: (values) => {
      console.log('Form values:', values);
    },
  });

  const handleResumeUpload = async (file: any) => {
    console.log('url:', URL.createObjectURL(file));
    setFile(file);

    // const fileType = file.name.split('.').pop().toLowerCase();

    // if (fileType === 'pdf') {
    //   // PDF handling
    //   // setDocContent(null); // Clear docContent for PDF rendering
    // } else if (fileType === 'docx' || fileType === 'doc') {
    //   // DOC or DOCX handling
    //   // const arrayBuffer = await file.arrayBuffer();
    //   // const result = await mammoth.convertToHtml({ arrayBuffer });
    //   // setDocContent(result.value);
    // }
    setShowOverlay(true);
    parseResume(file)
      .then((resp) => {
        setShowOverlay(false);
        const parsedData: TadCandidateDetailsModel = resp.data;
        parsedData.skills = mapSkills(parsedData.resumeSkills, skills);
        setCandidateDetails({
          ...candidateDetails,
          ...parsedData,
        });
        if (parsedData.message && parsedData.message !== '') {
          setToastMsg({
            severity: 'error',
            msg: parsedData.message,
          });
          setShowToast(true);
        }
        // if (parsedData.existing && parsedData.existing.length > 0) {
        //   setShowAlert(true);
        // } else {
        setActiveStep(activeStep + 1);
        // }
      })
      .catch((_err) => {
        setShowOverlay(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const StepperList = () => {
    const steps = [
      { number: 1, title: 'Upload Resume' },
      { number: 2, title: 'Candidate Details' },
      { number: 3, title: 'Employment Details' },
      { number: 4, title: 'Education & Certifications' },
      { number: 5, title: 'Additional Details' },
    ];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '18.5625rem',
          height: '21.3125rem',
          borderRight: '0.0625rem solid #C1C0C0',
          overflowY: 'auto',
        }}>
        {steps.map((step) => (
          <div
            key={step.number}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              backgroundColor: '#fff',
              borderBottom: '0.0625rem solid #C1C0C0',
              borderLeft:
                activeStep === step.number
                  ? ' 0.1875rem solid #DC3857'
                  : activeStep && activeStep > step.number
                  ? '0.1875rem solid #1976D2'
                  : '0.1875rem solid #fff',
              boxSizing: 'border-box',
              justifyContent: 'space-between',
              flex: 1,
              minHeight: '3.75rem',
            }}>
            <div style={{ display: 'flex', flex: 1, padding: '10px' }}>
              <div
                style={{
                  width: '1.875rem',
                  height: '1.875rem',
                  borderRadius: '50%',
                  backgroundColor:
                    activeStep === step.number
                      ? 'var(--Status-Red-500, #DC3857)'
                      : activeStep && activeStep > step.number
                      ? 'var(--Primary-Blue-500, #1976D2)'
                      : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '1.25rem',
                  color:
                    activeStep === step.number
                      ? 'var(--Secondary-colors-White, #FFF)'
                      : 'var(--Primary-Dark-grey-500, #333)',
                }}>
                <Typography variant='body2' style={{ display: 'flex', fontWeight: 'bold' }}>
                  {activeStep && activeStep > step.number ? <DoneIcon /> : step.number}
                </Typography>
              </div>
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: '400',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                  {step.title}
                </Typography>
              </div>
              {activeStep === step.number && (
                <div>
                  <ArrowForwardIosIcon
                    style={{ width: '1rem', height: '1rem', marginTop: '5px' }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='main-div' style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <TadPageHeader></TadPageHeader>
      </div>
      <div style={{ gap: '1.94rem', display: 'flex', flexWrap: 'nowrap' }}>
        <div className='paper' style={{ height: '19.5625rem' }}>
          <Paper sx={{ width: '18.5625rem' }}>
            <StepperList />
          </Paper>
        </div>
        <div style={{ width: '75%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
          {activeStep == 1 && (
            <>
              <div
                className='paper'
                style={{
                  height: '8.8125rem',
                  width: '100%',
                }}>
                <Paper
                  className='new-job-requisition'
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Upload Resume</span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Select Resume
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginRight: '3.05rem',
                      alignItems: 'center',
                      gap: '2.31rem',
                    }}>
                    <label
                      style={{
                        width: '11.375rem',
                        height: '3.125rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '0 1rem',
                        gap: '0.5rem',
                        borderRadius: '0.3125rem',
                        background: 'var(--Primary-Blue-500, #1976D2)',
                        color: '#FFF',
                        border: 'none',
                        fontFamily: 'Roboto',
                        fontSize: '1rem',
                        fontWeight: 500,
                        cursor: 'pointer',
                      }}>
                      <CloudUploadIcon style={{ color: 'white' }} />
                      Upload Resume
                      <input
                        type='file'
                        accept='.pdf, .docx, .jpg, .png'
                        style={{ display: 'none' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            handleResumeUpload(file);
                            formik.setFieldValue('resume', file);
                          }
                        }}
                      />
                    </label>
                  </div>
                </Paper>
              </div>

              <UploadResume handleResumeUpload={handleResumeUpload} />
            </>
          )}

          {activeStep === 2 && (
            <>
              <div className='paper' style={{ height: '8.8125rem', width: '100%' }}>
                <Paper className='new-job-requisition' sx={{ width: '100%', height: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Candidate Details</span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Comprehensive Overview of Candidate Information and Qualifications
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginRight: '3.05rem',
                      alignItems: 'center',
                      gap: '2.31rem',
                    }}>
                    {/* <select
                      style={{
                        width: '11.875rem',
                        height: 'var(--Font-size-h2, 2.5rem)',
                        flexShrink: 0,
                        borderRadius: '0.3125rem',
                        border: '1px solid var(--Secondary-colors-Grey, #868686)',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        fontFamily: 'Roboto',
                        fontSize: '1rem',
                        fontWeight: 400,
                        cursor: 'pointer',
                        padding: '0 1rem',
                      }}>
                      <option value='' disabled selected>
                        All Sources
                      </option>
                      <option value='candidate1'>Source 1</option>
                      <option value='candidate2'>Source 2</option>
                      <option value='candidate3'>Source 3</option>
                    </select> */}
                  </div>
                </Paper>
              </div>
              <CandidateDetails
                previous={() => {
                  setActiveStep(activeStep - 1);
                }}
                values={candidateDetails}
                next={(val) => {
                  updatedCandidateDetails(val);
                  console.log(candidateDetails);
                  setActiveStep(activeStep + 1);
                }}
              />
            </>
          )}

          {activeStep == 3 && (
            <>
              <div
                className='paper'
                style={{
                  height: '8.8125rem',
                  width: '100%',
                }}>
                <Paper
                  className='new-job-requisition'
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Employment Details</span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Overview of the Candidates Employment History
                    </span>
                  </div>
                </Paper>
              </div>

              <EmploymentDetails
                previous={() => setActiveStep(activeStep - 1)}
                values={candidateDetails}
                next={(val) => {
                  console.log('val => ', val);
                  updatedCandidateDetails(val);
                  console.log(candidateDetails);
                  setActiveStep(activeStep + 1);
                }}
              />
            </>
          )}

          {activeStep == 4 && (
            <>
              <div
                className='paper'
                style={{
                  height: '8.8125rem',
                  width: '100%',
                }}>
                <Paper
                  className='new-job-requisition'
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                      Education & Certifications
                    </span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Overview of Educational Background and Certifications
                    </span>
                  </div>
                </Paper>
              </div>

              <EducationCertificationDetails
                values={candidateDetails}
                previous={() => setActiveStep(activeStep - 1)}
                next={(val) => {
                  console.log('val => ', val);
                  updatedCandidateDetails(val);
                  console.log('candidateDetails => ', candidateDetails);
                  setActiveStep(activeStep + 1);
                }}
              />
            </>
          )}

          {activeStep == 5 && (
            <>
              <div
                className='paper'
                style={{
                  height: '8.8125rem',
                  width: '100%',
                }}>
                <Paper
                  className='new-job-requisition'
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Additional Details</span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Capture any additional details which are important for the job
                    </span>
                  </div>
                </Paper>
              </div>

              <AdditionalDetails
                values={candidateDetails}
                previous={() => setActiveStep(activeStep - 1)}
                next={(val) => {
                  console.log(val);
                  save(val);
                }}
              />
            </>
          )}
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showOverlay}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={handleToastClose}
        message={toastMsg.msg}></Notify>
      {showFeedbackAlert && (
        <Dialog open={showFeedbackAlert} fullWidth={true} maxWidth='md'>
          <DialogTitle>Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>Is all data autopopulated correctly?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className='button'
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '1rem',
                width: '8.25rem',
                height: '2.5rem',
                marginRight: '2rem',
                color: 'white !important',
                background: '#2D7DFE !important',
              }}
              onClick={() => {
                saveCandidate(true);
                setShowFeedbackAlert(false);
              }}>
              Yes
            </Button>
            <Button
              className='button'
              sx={{
                width: '7.375rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
              }}
              onClick={() => {
                // saveCandidate(false);
                setShowFeedbackAlert(false);
              }}
              autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AddCandidates;
