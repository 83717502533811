import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';

const Templates = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [rowData] = useState([] as any[]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div style={{ width: '100%', gap: '1.87rem', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          height: '8.8125rem',
          width: '100%',
        }}>
        <Paper
          className='new-job-requisition'
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1.88rem',
              gap: '7px',
            }}>
            <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Templates</span>
            <span
              style={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
              }}>
              Streamline your communication and minimize manual effort by using templates
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              marginRight: '2.5rem',
              alignItems: 'center',
              gap: '2.31rem',
            }}>
            <div>
              <Tooltip title='Add New Template'>
                <Button
                  className='button'
                  sx={{
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '1rem',
                    width: '9.5625rem',
                    height: '3.125rem',
                    color: 'white !important',
                    background: '#1976D2 !important',
                  }}>
                  <Add style={{ width: '15px', height: '15px', paddingRight: '4px' }} />
                  <div>
                    <InputLabel sx={{ color: 'white' }}>New Template</InputLabel>
                  </div>
                </Button>
              </Tooltip>
            </div>
          </div>
        </Paper>
      </div>
      <div
        className='paper'
        style={{
          width: '100%',
          height: '71.1875rem',
        }}>
        <Paper
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <Box sx={{ padding: '10px' }}>
            {' '}
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Template Name</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Created On{}</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rowData
                  ).map((user) => (
                    <TableRow
                      key={user.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>Template Name</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      count={rowData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      className='pagination'
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </div>
    </div>
  );
};

export default Templates;
