import React from 'react';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';
import { Select, MenuItem, Typography, Box } from '@mui/material';
import { ReactComponent as RedStartIcon } from '../../../assets/dashboard-assets/StarIcon.svg';

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };

  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill='#005483' />
      <path d={`M ${cx} ${cy} L ${target.x} ${target.y}`} stroke='#00a3ff' strokeWidth={3} />
      <text x={target.x + 10} y={target.y} fill='#005483' fontSize='12px' fontWeight='bold'>
        {Math.round(((valueAngle + 90) / 180) * 100)}
      </text>
    </g>
  );
}

const TotalHires = () => {
  const value = 75;
  const maxValue = 100;
  const percentage = (value / maxValue) * 100;

  return (
    <div style={{ flex: 1 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '-40px',
          marginBottom: '25px',
          padding: '0 20px',
          maxWidth: '1350px',
          marginLeft: '10px',
          marginRight: 'auto',
        }}>
        <Box display='flex' gap='1rem'>
          <Select
            sx={{ width: '135px', height: '30px' }}
            size='small'
            labelId='year-select'
            name='year'
            defaultValue={2024}>
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
          </Select>

          <Select
            sx={{ width: '182px', height: '30px' }}
            size='small'
            labelId='job-def-select'
            name='jobDefinition'
            defaultValue='Job Definition'>
            <MenuItem value='Job Definition'>Job Definition</MenuItem>
            <MenuItem value='Other'>Other</MenuItem>
          </Select>
        </Box>

        <Typography
          variant='body1'
          sx={{
            paddingRight: '10px',
            fontSize: '1rem',
            color: '#1565c0',
            fontWeight: '400',
          }}>
          Target: {value} hires
        </Typography>
      </div>

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='400px'
        width='100%'
        maxWidth='90%'
        margin='0 auto'
        marginTop='180px'
        position='relative'>
        <Box width='353px' height='262px' position='relative'>
          <GaugeContainer
            width={300}
            height={300}
            startAngle={-90}
            endAngle={90}
            value={percentage}>
            <GaugeReferenceArc />
            <GaugeValueArc />
            <GaugePointer />
          </GaugeContainer>
        </Box>

        <Typography
          variant='h6'
          sx={{
            marginLeft: '20px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '160px',
            fontSize: '1.5rem',
          }}>
          <RedStartIcon
            style={{ width: '1.25rem', height: '1.25rem', flexShrink: '0px', color: '#FF5959' }}
          />
          {value}
        </Typography>
      </Box>
    </div>
  );
};

export default TotalHires;
