import {
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Table,
  Paper,
  Tooltip,
  Backdrop,
  CircularProgress,
  AlertColor,
  Link,
} from '@mui/material';

import React, { useState } from 'react';
import { ReactComponent as CallIcon } from '../../../assets/candidate-assets/call.svg';
import { ReactComponent as Explicit } from '../../../assets/candidate-assets/explicit.svg';
import { ReactComponent as LightBulb } from '../../../assets/candidate-assets/lightbulb.svg';
import { ReactComponent as AttachMoney } from '../../../assets/candidate-assets/attach_money.svg';
import { ReactComponent as Location } from '../../../assets/candidate-assets/location_on.svg';
import { ReactComponent as Monetization } from '../../../assets/candidate-assets/monetization_on.svg';
import { ReactComponent as Star } from '../../../assets/candidate-assets/star_outline.svg';
import { ReactComponent as Trending } from '../../../assets/candidate-assets/trending_up.svg';
import { ReactComponent as Accountbalance } from '../../../assets/candidate-assets/account_balance.svg';
// import { ReactComponent as DownloadIcon } from '../../../assets/candidate-assets/download.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/candidate-assets/Group.svg';
// import { ReactComponent as ShareIcon } from '../../../assets/candidate-assets/share (1).svg';
// import { ReactComponent as MoveToNextIcon } from '../../../assets/candidate-assets/next_week.svg';
// import { ReactComponent as DuplicateIcon } from '../../../assets/candidate-assets/file_copy.svg';
import { ReactComponent as DotsIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import { ReactComponent as SortIcon } from '../../../assets/candidate-assets/sort_by_alpha.svg';
import { ReactComponent as ExportIcon } from '../../../assets/candidate-assets/save_alt.svg';
import GradeIcon from '@mui/icons-material/Grade';

import './../../candidate/candidate-table.scss';
import { getNameFromId, handleCandidateInitials } from '../../../common/commonUtil';
import { LookupTypes } from '../../../../common/lookupTypes';
import { tadUntagCandidateApi } from '../../../service/tadSjdService';
import Notify from '../../../common/notify';
// import useNavigate from '../../../../common/useNavigate';

interface Props {
  tabIconChange: boolean;
  tabListIconChange: boolean;
  selectedColumns: any;
  headers: any;
  candidates: any;
}

const SjdCandidateListTable = ({
  tabIconChange,
  tabListIconChange,
  headers,
  selectedColumns,
  candidates,
}: Props) => {
  // const statusToHeaderId: Record<string, number> = {
  //   'Profile Uploaded': 1,
  //   Profiling: 2,
  //   'Interviews Scheduled': 3,
  //   OFFERED: 4,
  //   'Document Verification': 5,
  //   Joining: 6,
  // };

  // const candidateStatus = globalObject.lookupvalues[LookupTypes.CANDIDATE_STATUS];
  const [loading, setLoading] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState<string[]>([]);
  const [candidateAnchorEl, setCandidateAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedCandidate, setSelectedCandidate] = useState({} as any);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const actionList = [
    { name: 'Sort By', icon: SortIcon },
    { name: 'Export to Excel', icon: ExportIcon },
    { name: 'Remove all Candidates', icon: RemoveIcon },
  ];
  // const [navigate] = useNavigate();

  const statusToHeaderId: Record<number, string[]> = {
    1: ['Profile Uploaded'],
    2: ['QC Rated', 'Profiler Rated'],
    3: [
      'Online Test Scheduled',
      'Online Test cleared',
      'L1 Scheduled',
      'L1 Cleared',
      'L2 Scheduled',
      'L2 Cleared',
      'L3 Scheduled',
      'L3 Cleared',
      'L4 Scheduled',
      'L4 Cleared',
      'L5 Scheduled',
      'L5 Cleared',
      'Client-Round-1 Scheduled',
      'Client-Round-1 Cleared',
      'Client-Round-2 Scheduled',
      'Client-Round-2 Cleared',
      'Client-Round-3 Scheduled',
      'Client-Round-3 Cleared',
      'HR-Round-1 Scheduled',
    ],
    4: ['Document Verification Started', 'Document Verification Completed'],
    5: ['Offer Approval Raised', 'Offer Released', 'Offer Accepted'],
    6: ['Joined'],
  };

  const getHeaderIdFromCandidate = (candidateStatusId: number) => {
    const statusName = getNameFromId(candidateStatusId, LookupTypes.TAD_CANDIDATE_STATUS);
    return Object.keys(statusToHeaderId)
      .map((key) => parseInt(key, 10)) // Convert keys to numbers
      .find((headerId) => statusToHeaderId[headerId].includes(statusName));
  };

  // const redirectToCandidatedDetailsPage = (candidateId: any, sjdId?: any) => {
  //   const path = sjdId
  //     ? `/candidate/${btoa(sjdId)}/${btoa(candidateId)}`
  //     : `/candidate/${btoa(candidateId)}`;
  //   navigate(
  //     [
  //       {
  //         label: 'Candidate Details',
  //         link: path,
  //       },
  //     ],
  //     true,
  //   );
  // };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedCandidates(candidates.map((candidate: any) => candidate.id));
    } else {
      setSelectedCandidates([]);
    }
  };

  const handleSelect = (candidateId: string) => {
    if (selectedCandidates.includes(candidateId)) {
      setSelectedCandidates(selectedCandidates.filter((id) => id !== candidateId));
    } else {
      setSelectedCandidates([...selectedCandidates, candidateId]);
    }
  };

  const CandidateActionsList = [
    // {
    //   name: 'Download Resume',
    //   icon: DownloadIcon,
    // },
    // { name: 'Move to next stage', icon: MoveToNextIcon },
    // { name: 'Share', icon: ShareIcon },
    // { name: 'Find Duplicates', icon: DuplicateIcon },
    { name: 'Remove', icon: RemoveIcon },
  ];

  const showCandidatesActionsList = (event: React.MouseEvent<HTMLElement>) => {
    setCandidateAnchorEl(event.currentTarget);
  };
  const closeCandidatesActionsMenu = () => {
    setCandidateAnchorEl(null);
  };

  return (
    <div>
      <TableContainer style={{ width: '100%', height: 'auto', marginBottom: '50px' }}>
        {tabIconChange && (
          <Table style={{ width: '100%' }}>
            <TableHead style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={
                      candidates.length > 0 && selectedCandidates.length === candidates.length
                    }
                    onChange={handleSelectAll}
                    inputProps={{ 'aria-label': 'select all candidates' }}
                  />
                </TableCell>
                {headers
                  .filter((column: any) => selectedColumns.includes(column.id))
                  .map((column: any) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody component={Paper}>
              {candidates.length > 0 ? (
                candidates.map((candidate: any) => (
                  <TableRow key={candidate.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedCandidates.includes(candidate.id)}
                        onChange={() => handleSelect(candidate.id)}
                        inputProps={{ 'aria-label': 'select candidate $(candidate.name) ' }}
                      />
                    </TableCell>

                    {selectedColumns.map((columnId: any) => {
                      switch (columnId) {
                        case 1:
                          return (
                            <TableCell key={columnId} className='cell'>
                              <p style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='feildHeader'>{`CAN${candidate.id}`}</span>
                                <Link
                                  href={
                                    candidate.sjdCandidateInfo?.[0]?.sjdId
                                      ? `/candidate/${btoa(
                                          candidate.sjdCandidateInfo?.[0]?.sjdId,
                                        )}/${btoa(candidate.id)}`
                                      : `/candidate/${btoa(candidate.id)}`
                                  }
                                  className='menuItem'>
                                  {candidate.name}
                                </Link>
                              </p>
                            </TableCell>
                          );
                        case 2:
                          return (
                            <TableCell className='cell' key={columnId}>
                              <p className='class-icon'>
                                <Location />
                                <span className='table-text'>{candidate.location}</span>
                              </p>
                              <p className='class-icon'>
                                <CallIcon />
                                <span className='table-text'>{candidate.phoneNumber}</span>
                              </p>
                            </TableCell>
                          );
                        case 3:
                          return (
                            <TableCell className='cell' key={columnId}>
                              {getNameFromId(
                                candidate.candidate?.sjdCandidateInfo[0]?.candidateStatusId,
                                LookupTypes.CANDIDATE_STATUS,
                              ) !== 'Profile Uploaded' && (
                                <p className='class-icon'>
                                  <GradeIcon style={{ color: '#FDD835' }} />{' '}
                                  <span className='table-text'>{candidate.suitabilityScore}</span>
                                </p>
                              )}
                              <p className='class-icon'>
                                <Star />
                                <span className='table-text'>{candidate.careerGrowthScore}</span>
                              </p>
                              <p className='class-icon'>
                                <Trending />
                                <span className='table-text'>
                                  {candidate?.sjdCandidateInfo[0]?.profilerRating}
                                </span>
                              </p>
                            </TableCell>
                          );
                        case 4:
                          return (
                            <TableCell className='cell' key={columnId}>
                              <p className='class-icon'>
                                <Explicit />
                                <span className='table-text'>{candidate.totalExperience}</span>
                              </p>
                              <p className='class-icon'>
                                <Accountbalance />
                                <span className='table-text'>{candidate.noticePeriod}</span>
                              </p>
                            </TableCell>
                          );
                        case 5:
                          return (
                            <TableCell className='cell' key={columnId}>
                              <p className='class-icon'>
                                <LightBulb />
                                <span className='table-text' style={{ width: '10rem' }}>
                                  <Tooltip
                                    title={candidate.skills?.map((s: any) => s.name).join(', ')}>
                                    <span style={{ overflowWrap: 'anywhere' }}>
                                      {candidate.skills && Array.isArray(candidate.skills)
                                        ? candidate.skills.map((s: any) => s.name).join(', ')
                                            .length > 20
                                          ? candidate.skills
                                              .map((s: any) => s.name)
                                              .join(', ')
                                              .slice(0, 20) + '...'
                                          : candidate.skills.map((s: any) => s.name).join(', ')
                                        : ''}
                                    </span>
                                  </Tooltip>
                                </span>
                              </p>
                              <p className='class-icon'>
                                <AttachMoney />
                                <span className='table-text'>{candidate.currentCtc}</span>
                              </p>
                              <p className='class-icon'>
                                <Monetization />
                                <span className='table-text'>{candidate.expectedSalary}</span>
                              </p>
                            </TableCell>
                          );
                        case 6:
                          return (
                            <TableCell key={columnId}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                }}>
                                <Chip
                                  label={getNameFromId(
                                    candidate.sjdCandidateInfo?.[0]?.candidateStatusId,
                                    LookupTypes.TAD_CANDIDATE_STATUS,
                                  )}
                                  style={{
                                    borderRadius: '0.3125rem',
                                    backgroundColor: 'var(--Primary-Blue-100, #B8D5F1)',
                                    color: '#333333',
                                    fontWeight: '400',
                                    textTransform: 'uppercase',
                                    fontSize: '0.875rem',
                                    marginBottom: '0.5rem',
                                  }}
                                />
                                {candidate.sjdCandidateInfo?.[0]?.candidateSubStatus && (
                                  <Chip
                                    style={{
                                      borderRadius: '0.3125rem',
                                      color: '#333333',
                                      fontWeight: '400',
                                      backgroundColor:
                                        candidate.sjdCandidateInfo?.[0]?.candidateSubStatus ===
                                        'Rejected'
                                          ? 'var(--Status-Red-100, #F4C1CB)'
                                          : candidate.sjdCandidateInfo?.[0]?.candidateSubStatus ===
                                            'On Hold'
                                          ? 'var(--Status-Yellow-100, #FEE6B8)'
                                          : '',
                                    }}
                                    label={candidate.sjdCandidateInfo?.[0]?.candidateSubStatus}
                                  />
                                )}
                              </div>
                            </TableCell>
                          );
                        case 7:
                          return (
                            <TableCell key={columnId}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <div>
                                    <Chip
                                      label='Reject'
                                      style={{
                                        borderRadius: '0.3125rem',
                                        backgroundColor: '#FFFFFF',
                                        color: '#000000',
                                        border: '1px solid #000000',
                                        padding: '10px 15px',
                                      }}
                                    />
                                  </div>
                                  <div style={{ marginTop: '20px' }}>
                                    <Chip
                                      label='Next Stage'
                                      style={{
                                        borderRadius: '0.3125rem',
                                        backgroundColor: '#FFFFFF',
                                        color: '#000000',
                                        border: '1px solid #000000',
                                        padding: '10px 15px',
                                      }}
                                    />
                                  </div>
                                </div>

                                <IconButton
                                  onClick={(e) => {
                                    console.log(candidate);
                                    setSelectedCandidate(candidate);
                                    showCandidatesActionsList(e);
                                  }}
                                  style={{ marginLeft: '20px' }}>
                                  <DotsIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          );
                      }
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={headers.length + 1}
                    align='center'
                    style={{ fontSize: 'large' }}>
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        {tabListIconChange && (
          <div>
            <div style={{ display: 'inline-flex' }}>
              {headers
                .filter((column: any) => selectedColumns.includes(column.id))
                .map((header: any) => (
                  <div
                    key={header.id}
                    style={{
                      height: '3.19rem',
                      marginBottom: '1.25rem',
                      width: '19.6875rem',
                      marginRight: '1.25rem',
                      borderBottom: '0.125rem solid #1976D2',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <span className='text-label'>{header.label}</span>
                    {/* <span onClick={(event) => setAnchorEl(event.currentTarget)}>
                      <DotsIcon style={{ cursor: 'pointer' }} />
                    </span> */}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}>
                      {actionList.map((action, index) => (
                        <MenuItem key={index} onClick={() => setAnchorEl(null)}>
                          <ListItemIcon>
                            <action.icon style={{ width: '20px', height: '20px' }} />
                          </ListItemIcon>
                          <ListItemText primary={action.name} />
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                ))}
            </div>
            <div style={{ display: 'flex' }}>
              {selectedColumns.map((columnId: number) => (
                <div key={columnId} style={{ marginBottom: '3.19rem' }}>
                  {candidates.filter(
                    (candidate: any) =>
                      getHeaderIdFromCandidate(
                        candidate?.sjdCandidateInfo?.[0]?.candidateStatusId,
                      ) === columnId,
                  ).length > 0 ? (
                    candidates
                      .filter(
                        (candidate: any) =>
                          getHeaderIdFromCandidate(
                            candidate?.sjdCandidateInfo?.[0]?.candidateStatusId,
                          ) === columnId,
                      )
                      .map((candidate: any) => (
                        <Paper
                          key={candidate.id}
                          sx={{
                            width: '19.6875rem',
                            // marginBottom: '3.19rem',
                            height: '15.0625rem',
                            flexShrink: 0,
                            marginBottom: '1.25rem',
                            marginRight: '1.25rem',
                            border: '1px solid var(--Secondary-Grey-2, #E7E7E7)',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                          }}>
                          <div
                            style={{
                              height: '11.56rem',
                              display: 'flex',
                              justifyContent: 'space-between',
                              gap: '5px',
                              marginLeft: '0.81rem',
                            }}>
                            <div style={{ display: 'flex' }}>
                              <div
                                className='user-icon'
                                style={{
                                  backgroundColor: 'rgb(25, 118, 210)',
                                  color: 'white',
                                  marginTop: '1.63rem',
                                }}>
                                {handleCandidateInitials(candidate.name)}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '0.5rem',
                                  marginTop: '1.63rem',
                                }}>
                                <Link
                                  href={
                                    candidate.sjdCandidateInfo?.[0]?.sjdId
                                      ? `/candidate/${btoa(
                                          candidate.sjdCandidateInfo?.[0]?.sjdId,
                                        )}/${btoa(candidate.id)}`
                                      : `/candidate/${btoa(candidate.id)}`
                                  }
                                  className='menuItem'>
                                  {candidate.name}
                                </Link>
                                <span className='feildHeader'>{candidate.location}</span>
                                <span className='menuItem' style={{ width: '10rem' }}>
                                  <Tooltip
                                    title={candidate.skills?.map((s: any) => s.name).join(', ')}>
                                    <span style={{ overflowWrap: 'anywhere' }}>
                                      {candidate.skills && Array.isArray(candidate.skills)
                                        ? candidate.skills.map((s: any) => s.name).join(', ')
                                            .length > 20
                                          ? candidate.skills
                                              .map((s: any) => s.name)
                                              .join(', ')
                                              .slice(0, 20) + '...'
                                          : candidate.skills.map((s: any) => s.name).join(', ')
                                        : ''}
                                    </span>
                                  </Tooltip>
                                </span>

                                <span className='menuItem'>{candidate.lastEmployer}</span>
                                <span className='menuItem'>{candidate.noticePeriod}</span>
                              </div>
                            </div>
                            <div style={{ marginTop: '0.62rem', marginRight: '0.5rem' }}>
                              <IconButton
                                onClick={(e) => {
                                  setSelectedCandidate(candidate);
                                  showCandidatesActionsList(e);
                                }}
                                style={{ marginLeft: '20px' }}>
                                <DotsIcon />
                              </IconButton>
                            </div>
                          </div>
                          <div
                            style={{
                              gap: '24.56rem',
                              alignItems: 'center',
                              marginBottom: '0.8rem',
                            }}>
                            <hr style={{ background: '#E7E7E7' }} />
                          </div>
                          <div style={{ marginLeft: '1.06rem', display: 'flex', gap: '1.94rem' }}>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <Star />
                              <span
                                style={{
                                  fontWeight: '600',
                                  fontSize: '1rem',
                                  marginLeft: '0.3rem',
                                }}>
                                {candidate?.sjdCandidateInfo[0]?.profilerRating}
                              </span>
                            </span>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <Trending />{' '}
                              <span
                                style={{
                                  fontWeight: '600',
                                  fontSize: '1rem',
                                  marginLeft: '0.3rem',
                                }}>
                                {candidate.suitabilityScore}
                              </span>
                            </span>
                            {getNameFromId(
                              candidate.candidate?.sjdCandidateInfo[0]?.candidateStatusId,
                              LookupTypes.CANDIDATE_STATUS,
                            ) !== 'Profile Uploaded' && (
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                <GradeIcon style={{ color: '#FDD835' }} />{' '}
                                <span
                                  style={{
                                    fontWeight: '600',
                                    fontSize: '1rem',
                                    marginLeft: '0.3rem',
                                  }}>
                                  {candidate.suitabilityScore}
                                </span>
                              </span>
                            )}
                          </div>
                        </Paper>
                      ))
                  ) : (
                    <div
                      style={{
                        width: '19.6875rem',
                        height: '15.0625rem',
                        flexShrink: 0,
                        marginBottom: '1.25rem',
                        marginRight: '1.25rem',
                        border: '1px dashed #E7E7E7',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#B0B0B0',
                      }}>
                      No Candidates
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </TableContainer>
      <Menu
        anchorEl={candidateAnchorEl}
        open={Boolean(candidateAnchorEl)}
        onClose={closeCandidatesActionsMenu}>
        {CandidateActionsList.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (action.name === 'Remove') {
                // setLoading(true);
                tadUntagCandidateApi({
                  sjdId: selectedCandidate?.sjdCandidateInfo[0]?.sjdId,
                  candidateId: selectedCandidate.id,
                })
                  .then((_res) => {
                    setLoading(false);
                    setShowToast(true);
                    setToastMsg({ severity: 'success', msg: 'Candidate untagged Successfully' });
                    window.location.reload();
                  })
                  .catch((_err) => {
                    setLoading(false);
                    setShowToast(true);
                    setToastMsg({ severity: 'error', msg: 'something went wrong!' });
                  });
              }
            }}>
            <ListItemIcon>
              <action.icon style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText primary={action.name} />
          </MenuItem>
        ))}
      </Menu>
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={() => setShowToast(false)}
        message={toastMsg.msg}></Notify>
    </div>
  );
};

export default SjdCandidateListTable;
