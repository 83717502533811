/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState, useEffect } from 'react';
// import { getSjdCountByStatus } from '../../service/dashboardService';
import { Loader } from '../common/loader';
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from 'recharts';
import { getIdFromName } from '../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';

interface Props {
  initialData: any;
}

const SjdStatusChart = ({ initialData }: Props) => {
  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setSctiveIndex] = useState(-1);
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ff0000'];

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
    } else {
      setLoading(false);
      const d: any[] = [];
      Object.keys(initialData).forEach((status) => {
        d.push({
          name: status,
          value: initialData[status],
          statusId: getIdFromName(status, LookupTypes.SJD_STATUS),
        });
      });
      d.sort((a, b) => a.statusId - b.statusId);
      setData(d);
    }
  }, [initialData]);

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
    } = props;
    // const sin = Math.sin(-RADIAN * midAngle);
    // const cos = Math.cos(-RADIAN * midAngle);
    // const sx = cx + (outerRadius + 10) * cos;
    // const sy = cy + (outerRadius + 10) * sin;
    // const mx = cx + (outerRadius + 30) * cos;
    // const my = cy + (outerRadius + 30) * sin;
    // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    // const ey = my;
    // const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
          {payload.name} <br />
          {` (${payload.value})`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' /> */}
        {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' /> */}
        {/* <text x={sx + (cos >= 0 ? 1 : -1) * 12} y={sy} textAnchor={textAnchor} fill='#333'>
          {`Count: ${payload.value}`}
        </text> */}
      </g>
    );
  };

  const onPieEnter = (_: any, index: number) => {
    setSctiveIndex(index);
  };

  return (
    <div>
      <Stack spacing={1}>
        <Typography variant='h6' fontWeight={700}>
          Job Definition Overview
        </Typography>
      </Stack>
      {loading && <Loader />}
      {!loading && (
        <>
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '250px' }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  dataKey='value'
                  nameKey='name'
                  cx='120'
                  cy='120'
                  innerRadius={80}
                  outerRadius={100}
                  fill='#8884d8'
                  paddingAngle={5}
                  labelLine={false}
                  legendType='line'
                  onMouseEnter={onPieEnter}>
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '10px',
              }}>
              {data.map((d, index) => (
                <React.Fragment key={index}>
                  {index < 3 && (
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <div
                        style={{
                          width: '10px',
                          height: '10px',
                          background: COLORS[index % COLORS.length],
                          marginRight: '5px',
                        }}></div>
                      <span>{d.name}</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {data.map((d, index) => (
                <React.Fragment key={index}>
                  {index >= 3 && (
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <div
                        style={{
                          width: '10px',
                          height: '10px',
                          background: COLORS[index % COLORS.length],
                          marginRight: '5px',
                        }}></div>
                      <span>{d.name}</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SjdStatusChart;
