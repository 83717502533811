import axiosClient from '../common/axiosClient';

export async function getAllProgramLevels() {
  return axiosClient.get('/employee/levels');
}

export async function getAllProviders() {
  return axiosClient.get('/employee/providers');
}

export async function getAllTechStack(userId?: any) {
  return axiosClient.get('/employee/tech-stack', { params: { userId } });
}

export async function getAllTechStackByLevelId(levelId: any) {
  return axiosClient.get('/employee/level', { params: { levelId } });
}

export async function getAllTechStackCoursesById(id?: any, userId?: any, levelId ?: any) {
  return axiosClient.get(`/employee/tech-stack/${id}`, { params: { userId, levelId } });
}

export async function getEmployeeCourseDetails(levelId: any) {
  return axiosClient.get('/employee', { params: { levelId } });
}
