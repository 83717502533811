import {
  Pagination,
  PaginationItem,
  Paper,
  Link,
  Tooltip,
  Dialog,
  Chip,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  AlertColor,
  Backdrop,
  CircularProgress,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Tab,
  Tabs,
  Box,
  LinearProgress,
  LinearProgressProps,
  linearProgressClasses,
  styled,
  Button,
} from '@mui/material';
import './source-job-definition.scss';
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import IosShareIcon from '@mui/icons-material/IosShare';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import {
  toUpperFirstChar,
  filterSjdInfo,
  getNameFromId,
  isUserRole,
  isPermissionsWrite,
  isUserRoleIn,
  isPermissionsDelete,
} from '../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';
import React, { useState, useEffect, useRef } from 'react';
import SortIcon from '@mui/icons-material/Sort';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import CandidateSearch from '../candidate-search/candidate-search';
import {
  CANDIDATE_SUB_STATUS,
  MODULE,
  ROLES,
  SJD_STATUS,
  SubModuleTypes,
} from '../../constants/common';
import Notify from '../common/notify';
import { Loader } from '../common/loader';
import { GroupAdd, Search } from '@mui/icons-material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import useNavigate from './../../common/useNavigate';

import { untagCandidateApi } from '../../service/sjdService';
import ConfirmationDialog from '../common/confirmation-dialog';
import globalObject from '../../common/global-variables';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import dateFormat from 'dateformat';
import { useParams } from 'react-router-dom';
import { bulkUploadResumes } from '../../service/candidateService';

interface Candidate {
  id: number;
}

interface Props {
  candidates: any[];
  applicants: any[];
  sjd: any;
  loading: boolean;
  addCandidate: () => void;
  tagCandidate: (resumeId: any) => Promise<boolean>;
  setCandidates: React.Dispatch<React.SetStateAction<Candidate[]>>;
}

interface SortOptions {
  label: string;
  value: number;
  asc: boolean;
  type: 'string' | 'date' | 'numeric';
  fieldName: string;
}

const SjdCandidateTable = ({
  candidates,
  applicants,
  sjd,
  addCandidate,
  tagCandidate,
  loading,
  setCandidates,
}: Props) => {
  let { sjdId } = useParams();
  if (sjdId) sjdId = atob(sjdId);
  const [display, setDisplay] = useState([] as any[]);
  const [filteredCandidates, setFilteredCandidates] = useState([] as any[]);
  const resultsPerPage = 10;
  const [navigate] = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showQuestionnaireAlert, setShowQuestionnaireAlert] = useState(false);
  const [showRemoveCandidateAlert, setShowRemoveCandidateAlert] = useState(false);
  const [candidateName, setCandidateName] = useState('');
  const [loader, setLoader] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [cid, setCid] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'error',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [candidateStatusList, setCandidateStatusList] = useState({} as any);
  const [candidateSubStatusList, setCandidateSubStatusList] = useState({} as any);
  const [selectedStatusId, setSelectedStatusId] = useState([] as any[]);
  const DEFAULT_SORT_VALUE = 4;

  type tabType = 'candidate' | 'applicant';
  const [selectedType, setSelectedType] = useState<tabType>('candidate');
  const [sortQuery, setSortQuery] = useState(DEFAULT_SORT_VALUE);
  useEffect(() => {
    const candidateStatus = candidates.map((c) => {
      return filterSjdInfo(c.sjdCandidateInfo, sjd.id).candidateStatusId;
    });
    const temp: any = {};
    candidateStatus.forEach((status) => {
      temp[status] = elementCount(candidateStatus, status);
    });
    setCandidateStatusList(temp);

    const candidateSubStatus = candidates
      .map((c) => {
        return filterSjdInfo(c.sjdCandidateInfo, sjd.id).candidateSubStatus;
      })
      .filter((s) => s != null);
    const t: any = {};
    candidateSubStatus.forEach((status) => {
      t[status] = elementCount(candidateSubStatus, status);
    });
    setCandidateSubStatusList(t);

    handlePagination('', [], DEFAULT_SORT_VALUE, 1);
  }, [candidates, selectedType]);

  function elementCount(arr: any[], element: any) {
    return arr.filter((currentElement) => currentElement == element).length;
  }

  const tag = (resumeId: any) => {
    tagCandidate(resumeId)
      .then((_r) => {
        setShowSearch(false);
      })
      .catch((_e) => {});
  };

  const isUserPartOfHiringTeam = () => {
    const userId = globalObject.userObject.id;
    return sjd.hiringTeam?.filter((h: any) => h.id == userId).length > 0;
  };

  const handlePagination = (search: string, status: number[], sortOption: number, v: number) => {
    const start = resultsPerPage * (v - 1);
    let c = handleSorting(sortOption);
    if (search && search.length) {
      c = c.filter(
        (candidate) =>
          (candidate.name && candidate.name.toLowerCase().includes(search.toLowerCase())) ||
          (candidate.email && candidate.email.toLowerCase().includes(search.toLowerCase())),
      );
    }
    if (status && status.length > 0) {
      c = c.filter((candidate) => {
        const filteredSjdInfo = filterSjdInfo(candidate.sjdCandidateInfo, sjd.id);
        return (
          status.indexOf(filteredSjdInfo.candidateStatusId.toString()) > -1 ||
          status.indexOf(filteredSjdInfo.candidateSubStatus) > -1
        );
      });
    }
    setCurrPage(v);
    setFilteredCandidates(c);
    setDisplay(c.slice(start, start + resultsPerPage));
  };

  const getSkills = (skills: any[]): React.ReactNode => {
    const skillsText = skills.map((s: any) => s.name).join(', ');
    return (
      <>
        <span className='candidate-list-key'>Skills:</span>{' '}
        <Tooltip title={skillsText}>
          <span className='candidate-list-value'>
            {skills.length > 6
              ? `${skills
                  .slice(0, 6)
                  .map((s: any) => s.name)
                  .join(', ')}...+${skills.length - 6}`
              : skillsText}
          </span>
        </Tooltip>
      </>
    );
  };

  const sortingOptions: SortOptions[] = [
    {
      label: 'Name (a-z)',
      value: 0,
      asc: true,
      type: 'string',
      fieldName: 'name',
    },
    {
      label: 'Name (z-a)',
      value: 1,
      asc: false,
      type: 'string',
      fieldName: 'name',
    },
    {
      label: 'Uploaded On (asc)',
      value: 2,
      asc: true,
      type: 'date',
      fieldName: 'createdOn',
    },
    {
      label: 'Uploaded On (des)',
      value: 3,
      asc: false,
      type: 'date',
      fieldName: 'createdOn',
    },
    {
      label: 'Suitability Score',
      value: 4,
      asc: false,
      type: 'numeric',
      fieldName: 'suitabilityScore',
    },
  ];

  const sort = (fieldName: string, type: 'string' | 'date' | 'numeric', asc: boolean) => {
    function compare(a: any, b: any) {
      if (type !== 'numeric') {
        const nameA =
          type == 'string'
            ? a[fieldName]
              ? a[fieldName].toLowerCase()
              : a[fieldName]
            : new Date(a[fieldName]);
        const nameB =
          type == 'string'
            ? b[fieldName]
              ? b[fieldName].toLowerCase()
              : b[fieldName]
            : new Date(b[fieldName]);
        if (nameA < nameB) return asc ? -1 : 1;
        if (nameA > nameB) return asc ? 1 : -1;
      } else {
        if (!a['sjdCandidateInfo'].length || !b['sjdCandidateInfo'].length) return 0;
        const nameA = a['sjdCandidateInfo'][0][fieldName];
        const nameB = b['sjdCandidateInfo'][0][fieldName];
        if (!nameA && !nameB) {
          return 0;
        } else if (!nameA) {
          return 1;
        } else if (!nameB) {
          return -1;
        }
        return asc ? nameA - nameB : nameB - nameA;
      }
    }
    return selectedType == 'candidate'
      ? [...candidates].sort(compare as any)
      : [...applicants].sort(compare as any);
  };

  const handleSorting = (index: any) => {
    const selectedOption = sortingOptions.filter((v) => v.value == index);
    return sort(selectedOption[0].fieldName, selectedOption[0].type, selectedOption[0].asc);
  };

  function handleExport() {
    setShowToast(false);
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = `${sjd.name.replace(/\s/g, '_')}_Candidates_List`;
    const excelDataCandidates: any[] = candidates.map((c) => ({
      Id: c.id,
      Name: c.name,
      Email: c.email,
      'Phone Number': c.phoneNumber,
      Location: c.location,
      'Notice Period (days)': c.noticePeriod,
      'Experience (years)': c.experience,
      'Source Channel': getNameFromId(c.sourceChannel, LookupTypes.SOURCE_CHANNEL),
      'Current CTC':
        c.currentCtcFrequency && c.currentCtc
          ? `${c.currentCtc} (${getNameFromId(c.currentCtcFrequency, LookupTypes.PAY_TYPE)})`
          : c.currentCtc,
      'Expected CTC':
        c.expectedCtcFrequency && c.expectedCtc
          ? `${c.expectedCtc} (${getNameFromId(c.expectedCtcFrequency, LookupTypes.PAY_TYPE)})`
          : c.expectedCtc,
      'Created On': dateFormat(new Date(c.createdOn), 'dd/mm/yyyy'),
      'Created By': c.createdByName,
      Status: c.sjdCandidateInfo
        .map((e: any) => getNameFromId(e.candidateStatusId, LookupTypes.CANDIDATE_STATUS))
        .join(','),
      'Sub-Status': c.sjdCandidateInfo.map((e: any) => e.candidateSubStatus).join(','),
      'Candidate Profile-Link': process.env.REACT_APP_CANDIDATE_PROFILE_URL?.replace(
        '{sjdId}',
        btoa(c.sjdCandidateInfo.map((e: any) => e.sjdId)),
      ).replace('{candidateId}', btoa(c.id)),
      Comments: c.sjdCandidateInfo.map((e: any) => e.comment).join(','),
      Skills: c.skills.map((e: any) => e.name).join(','),
    }));
    const excelDataApplicants: any[] = applicants.map((c) => ({
      Id: c.id,
      Name: c.name,
      Email: c.email,
      'Phone Number': c.phoneNumber,
      Location: c.location,
      'Notice Period (days)': c.noticePeriod,
      'Experience (years)': c.experience,
      'Source Channel': getNameFromId(c.sourceChannel, LookupTypes.SOURCE_CHANNEL),
      'Current CTC':
        c.currentCtcFrequency && c.currentCtc
          ? `${c.currentCtc} (${getNameFromId(c.currentCtcFrequency, LookupTypes.PAY_TYPE)})`
          : c.currentCtc,
      'Expected CTC':
        c.expectedCtcFrequency && c.expectedCtc
          ? `${c.expectedCtc} (${getNameFromId(c.expectedCtcFrequency, LookupTypes.PAY_TYPE)})`
          : c.expectedCtc,
      'Created On': dateFormat(new Date(c.createdOn), 'dd/mm/yyyy'),
      'Created By': c.createdByName,
      Status: c.sjdCandidateInfo
        .map((e: any) => getNameFromId(e.candidateStatusId, LookupTypes.CANDIDATE_STATUS))
        .join(','),
      'Sub-Status': c.sjdCandidateInfo.map((e: any) => e.candidateSubStatus).join(','),
      'Candidate Profile-Link': process.env.REACT_APP_CANDIDATE_PROFILE_URL?.replace(
        '{sjdId}',
        btoa(c.sjdCandidateInfo.map((e: any) => e.sjdId)),
      ).replace('{candidateId}', btoa(c.id)),
      Comments: c.sjdCandidateInfo.map((e: any) => e.comment).join(','),
      Skills: c.skills.map((e: any) => e.name).join(','),
    }));
    const ws = XLSX.utils.json_to_sheet(excelDataCandidates);
    const ws1 = XLSX.utils.json_to_sheet(excelDataApplicants);
    const range = XLSX.utils.decode_range(String(ws['!ref']));
    const range1 = XLSX.utils.decode_range(String(ws1['!ref']));
    const columns = [0, 5, 6, 10];
    if (candidates.length != 0) {
      ws['!cols'] = Array(Object.keys(excelDataCandidates[0]).length).fill({ wpx: 150 });
      for (let col = range.s.c; col <= range.e.c; col++) {
        const headerCellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        ws[headerCellAddress].s = {
          font: {
            bold: true,
          },
          alignment: {
            horizontal: 'center',
          },
        };
        for (let row = range.s.r + 1; row <= range.e.r; row++) {
          if (columns.includes(col)) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            ws[cellAddress].s = {
              alignment: {
                horizontal: 'center',
                vertical: 'center',
              },
            };
          }
        }
      }
    }
    if (applicants.length != 0) {
      ws1['!cols'] = Array(Object.keys(excelDataApplicants[0]).length).fill({ wpx: 150 });
      for (let col = range1.s.c; col <= range1.e.c; col++) {
        const headerCellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        ws1[headerCellAddress].s = {
          font: {
            bold: true,
          },
          alignment: {
            horizontal: 'center',
          },
        };
        for (let row = range1.s.r + 1; row <= range1.e.r; row++) {
          if (columns.includes(col)) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            ws1[cellAddress].s = {
              alignment: {
                horizontal: 'center',
                vertical: 'center',
              },
            };
          }
        }
      }
    }
    const wb = {
      Sheets: { candidates: ws, applicants: ws1 },
      SheetNames: ['candidates', 'applicants'],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const handleExportError = () => {
    setShowToast(true);
    setToastMsg({
      severity: 'error',
      msg: 'No data present',
    });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const [progress, setProgress] = useState(0);
  const [response, setResponse] = useState('');
  const [uploadFiles, setUploadFiles] = useState(0);

  const handleFileChange = (event: any) => {
    const files = event.target.files;
    setUploadFiles(files.length);
    setShowOverlay(true);
    bulkUploadResumes(files, sjdId, (onUploadProgress: any) => {
      setProgress(onUploadProgress);
    })
      .then((_resp) => {
        setResponse('uploaded');
        setToastMsg({
          severity: 'success',
          msg: 'Candidates Uploaded Sucessfully',
        });
        setShowToast(true);
      })
      .catch((error) => {
        setResponse('failed');
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: `${error}`,
        });
      })
      .finally(() => {
        if (fileInputRef.current) fileInputRef.current.value = '';
      });
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 3,
      backgroundColor: theme.palette.mode === 'light' ? '#11A811' : '#308fe8',
      border: '0.01px solid black',
    },
  }));

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <div
        style={{
          minWidth: '50vh',
          margin: 'auto',
          padding: '10px,10px,10px,10px',
        }}>
        <div>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <BorderLinearProgress variant='determinate' value={props.value} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant='body2' color='text.secondary' sx={{ color: 'black' }}>
                <b>{`${Math.round(props.value)}%`}</b>
              </Typography>
            </Box>
          </Box>
          {response == 'uploaded' && (
            <div>
              <span>{`${uploadFiles} files have been uploaded successfully,`}</span>
              <br></br>
              <span>{'please come back after 2-3 min to see the candidates in SJD'}</span>
            </div>
          )}
          {response == '' && <span>Uploading....</span>}
          {response == 'failed' && <span>Unable to upload Resumes</span>}
        </div>
        <div className='center-contents' style={{ marginBottom: '10px' }}>
          {progress == 100 && (
            <Button
              className='default-btn'
              sx={{ height: '30px', marginTop: '10px' }}
              onClick={() => {
                setShowOverlay(false);
                setResponse('');
                setProgress(0);
              }}>
              OK
            </Button>
          )}
        </div>
      </div>
    );
  }

  const addIcon = (): React.ReactNode => {
    return (
      <div>
        {isUserRoleIn([ROLES.LEAD, ROLES.RECRUITER]) && (
          <Tooltip title='Export'>
            <IosShareIcon
              fontSize='small'
              sx={{ marginRight: '20px', width: '30px', height: '28px', verticalAlign: '3px' }}
              onClick={
                candidates.length == 0 && applicants.length == 0 ? handleExportError : handleExport
              }
            />
          </Tooltip>
        )}
        {isPermissionsWrite(MODULE.SJD, SubModuleTypes.JD_CANDIDATES) &&
          isUserPartOfHiringTeam() && (
            <>
              <Tooltip title='Search in candidate pool'>
                <SearchIcon
                  fontSize='small'
                  sx={{ marginRight: '10px', width: '30px', height: '30px' }}
                  onClick={() => {
                    sjd.questionnaire.length === 0
                      ? setShowQuestionnaireAlert(true)
                      : setShowSearch(true);
                  }}
                />
              </Tooltip>
              <Tooltip title='Add Candidate'>
                <PersonAddAlt1RoundedIcon
                  fontSize='small'
                  sx={{ width: '50px', height: '30px' }}
                  onClick={
                    sjd.questionnaire.length === 0
                      ? () => setShowQuestionnaireAlert(true)
                      : addCandidate
                  }
                />
              </Tooltip>
              <input
                multiple
                type='file'
                accept='.pdf, .docx'
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <Tooltip title='Add Candidates'>
                <GroupAdd
                  fontSize='small'
                  sx={{ width: '40px', height: '30px' }}
                  onClick={
                    sjd.questionnaire.length === 0
                      ? () => setShowQuestionnaireAlert(true)
                      : () => handleFileButtonClick()
                  }></GroupAdd>
              </Tooltip>
            </>
          )}
      </div>
    );
  };

  const getChipColor = (subStatus: any) => {
    if (!subStatus) return 'success';
    if (subStatus === CANDIDATE_SUB_STATUS.CALL_BACK || subStatus === CANDIDATE_SUB_STATUS.ON_HOLD)
      return 'warning';
    if (subStatus === CANDIDATE_SUB_STATUS.REJECTED || subStatus === CANDIDATE_SUB_STATUS.DROPPED)
      return 'error';
    if (subStatus === CANDIDATE_SUB_STATUS.NOT_INTERESTED) return 'default';
    return 'primary';
  };

  const removeCandidateAlert = (id: any) => {
    setShowRemoveCandidateAlert(true);
    setCid(id);
  };

  const getSuitabilityScore = (candidate: any) => {
    const sjdCandidateInfo = filterSjdInfo(candidate.sjdCandidateInfo, sjd.id);
    return sjdCandidateInfo.suitabilityScore ? sjdCandidateInfo.suitabilityScore : '';
  };

  const removeCandidate = async () => {
    try {
      setShowRemoveCandidateAlert(false);
      setLoader(true);
      const payload = {
        sjdId: sjd.id,
        candidateId: cid,
      };
      await untagCandidateApi(payload);
      const updatedCandidates = candidates.filter((candidate) => candidate.id !== cid);
      setCandidates(updatedCandidates);
      handlePagination(searchQuery, selectedStatusId, sortQuery, 1);
      setShowToast(true);
      setToastMsg({
        severity: 'success',
        msg: 'Candidate removed from the Job Definition!',
      });
    } catch {
      setShowToast(true);
      setToastMsg({
        severity: 'error',
        msg: 'Candidate removal failed!',
      });
    } finally {
      setLoader(false);
      setShowRemoveCandidateAlert(false);
    }
  };

  const getStatusChips = (candidate: any) => {
    const sjdCandidateInfo = filterSjdInfo(candidate.sjdCandidateInfo, sjd.id);
    if (candidate.sjdCandidateInfo && candidate.sjdCandidateInfo.length > 0)
      return (
        <>
          <span className='candidate-list-value'>
            <Chip
              label={toUpperFirstChar(
                getNameFromId(sjdCandidateInfo.candidateStatusId, LookupTypes.CANDIDATE_STATUS),
              )}
              style={{ fontSize: '12px', height: '18px' }}
              size='small'
              color={getChipColor(sjdCandidateInfo.candidateSubStatus)}
            />
          </span>
          <br />
          {sjdCandidateInfo.candidateSubStatus && (
            <span className='candidate-list-value'>
              <Tooltip title={sjdCandidateInfo.comment}>
                <Chip
                  label={sjdCandidateInfo.candidateSubStatus}
                  style={{ fontSize: '12px', height: '18px' }}
                  size='small'
                  color={getChipColor(sjdCandidateInfo.candidateSubStatus)}
                />
              </Tooltip>
            </span>
          )}
        </>
      );
  };

  const handleChange = (newValue: tabType) => {
    setSearchQuery('');
    setSelectedStatusId([]);
    setSortQuery(newValue == 'applicant' ? 3 : DEFAULT_SORT_VALUE);
    setSelectedType(newValue);
  };

  return (
    <>
      <Paper
        sx={
          showOverlay
            ? {
                marginTop: 2,
                padding: [5, 2],
                boxShadow: 'none',
                position: 'relative',
                opacity: '0.3',
                pointerEvents: 'none',
              }
            : { marginTop: 2, padding: [5, 2], boxShadow: 'none' }
        }>
        <Stack spacing={1} className='detail-header'>
          <div
            style={{
              marginBottom: '10px',
              color: '#a72037',
              display: 'flex',
              flexWrap: 'wrap',
            }}>
            <div
              className='candidate-actions'
              style={{ width: '70%', display: 'flex', justifyContent: 'start' }}>
              <div>
                <Typography variant='h5' fontWeight={700}>
                  {`Candidates - ${candidates.length + applicants.length}`}
                </Typography>
              </div>
              <div>
                <OutlinedInput
                  id='table-search'
                  type='text'
                  placeholder='Search by name/email'
                  className='search-bar'
                  sx={{ margin: '0', height: '30px', borderRadius: '10px' }}
                  value={searchQuery}
                  onChange={(event) => {
                    setSearchQuery(event.target.value);
                    handlePagination(event.target.value, selectedStatusId, sortQuery, 1);
                  }}
                  endAdornment={
                    <InputAdornment position='end'>
                      <Search />
                    </InputAdornment>
                  }
                />
              </div>
              <div>
                <Select
                  size='small'
                  value={sortQuery}
                  onChange={(e) => {
                    setSortQuery(e.target.value as any);
                    handlePagination(searchQuery, selectedStatusId, Number(e.target.value), 1);
                  }}
                  defaultValue={DEFAULT_SORT_VALUE}
                  sx={{
                    borderRadius: '10px',
                    fontSize: 'small',
                    verticalAlign: '3px',
                    height: '30px',
                    width: '245px',
                  }}
                  renderValue={(selected) => {
                    return (
                      <p>
                        <SortIcon
                          sx={{ verticalAlign: '-3px', color: 'rgb(167, 32, 55)' }}
                          fontSize='small'
                        />
                        <span className='sortLabel'>
                          {' '}
                          Sort by: {sortingOptions[selected].label}
                        </span>
                      </p>
                    );
                  }}>
                  <MenuItem disabled value=' ' sx={{ fontSize: 'small' }}>
                    <em>Sort by:</em>
                  </MenuItem>
                  {sortingOptions
                    .filter((s) => selectedType == 'candidate' || s.value != 4)
                    .map((option, index) => (
                      <MenuItem key={index} value={option.value} sx={{ fontSize: 'small' }}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              {selectedType == 'candidate' && (
                <div>
                  <Select
                    size='small'
                    displayEmpty
                    multiple
                    value={selectedStatusId}
                    onChange={(event: any) => {
                      const temp = event.target.value.pop();
                      if (!temp || temp == -1) {
                        setSelectedStatusId([]);
                        handlePagination(searchQuery, [], sortQuery, 1);
                      } else {
                        setSelectedStatusId([...event.target.value, temp]);
                        handlePagination(searchQuery, [...event.target.value, temp], sortQuery, 1);
                      }
                    }}
                    input={<OutlinedInput />}
                    sx={{
                      borderRadius: '10px',
                      marginRight: '60px',
                      height: '30px',
                      width: '245px',
                      verticalAlign: '3px',
                    }}
                    renderValue={() => {
                      return (
                        <p>
                          <FilterAltIcon
                            className='filter-Alt-Icon'
                            fontSize='small'
                            sx={{ marginRight: '10px', color: 'rgb(167, 32, 55)' }}
                          />
                          {selectedStatusId.length == 0 ? (
                            <span className='status'>Filter by: Status</span>
                          ) : selectedStatusId.length == 1 ? (
                            <span className='status'>
                              {isNaN(selectedStatusId[0])
                                ? selectedStatusId[0]
                                : getNameFromId(
                                    Number(selectedStatusId[0]),
                                    LookupTypes.CANDIDATE_STATUS,
                                  )}
                            </span>
                          ) : (
                            <span className='status'>Filter by: Multiple selected</span>
                          )}
                        </p>
                      );
                    }}>
                    <MenuItem
                      value={-1}
                      sx={{ fontSize: 'small' }}
                      disabled={selectedStatusId.length == 0}>
                      <ListItemText primary='Clear All' />
                    </MenuItem>
                    {Object.keys(candidateStatusList).map((list) => (
                      <MenuItem key={list} value={list} sx={{ fontSize: 'small' }}>
                        <Checkbox checked={selectedStatusId.indexOf(list) > -1} size='small' />
                        <ListItemText
                          primary={
                            <span>
                              {getNameFromId(Number(list), LookupTypes.CANDIDATE_STATUS)} (
                              {candidateStatusList[list]})
                            </span>
                          }
                        />
                      </MenuItem>
                    ))}

                    <MenuItem value={-1} sx={{ fontSize: 'small' }} disabled>
                      <ListItemText primary='Sub Status' />
                    </MenuItem>
                    {Object.keys(candidateSubStatusList).map((list) => (
                      <MenuItem key={list} value={list} sx={{ fontSize: 'small' }}>
                        <Checkbox checked={selectedStatusId.indexOf(list) > -1} size='small' />
                        <ListItemText
                          primary={
                            <span>
                              {list} ({candidateSubStatusList[list]})
                            </span>
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            </div>
            <div style={{ width: '30%', justifyContent: 'end', display: 'flex' }}>
              {getNameFromId(sjd.sjdStatusId, LookupTypes.SJD_STATUS) !== SJD_STATUS.ON_HOLD &&
                getNameFromId(sjd.sjdStatusId, LookupTypes.SJD_STATUS) !== SJD_STATUS.COMPLETED && (
                  <div style={{ cursor: 'pointer', color: '#A72037' }}>{addIcon()}</div>
                )}
            </div>
            <div style={{ height: '30px' }}>
              <Tabs
                value={selectedType}
                onChange={(_e, v) => {
                  handleChange(v);
                }}>
                <Tab
                  className='q-tab'
                  label={`Sourced - ${candidates.length}`}
                  value={'candidate'}
                />
                <Tab
                  className='q-tab'
                  label={`Self-Applicants - ${applicants.length}`}
                  value={'applicant'}
                />
              </Tabs>
            </div>
          </div>
        </Stack>
        {loading && <Loader />}

        <div className='sjd-info'>
          <table className='candidate-info-table'>
            <tbody>
              {display.length > 0 && !loading ? (
                display.map((c, i) => (
                  <tr key={i} style={{ border: '2px solid #ccc' }}>
                    <td style={{ width: '18%' }}>
                      <p>
                        <Link
                          className='hyper-link'
                          href={`/source-job-definition/candidate/${btoa(sjd.id)}/${btoa(c.id)}`}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate([
                              {
                                label: `${c.name}`,
                                link: `/source-job-definition/candidate/${btoa(sjd.id)}/${btoa(
                                  c.id,
                                )}`,
                              },
                            ]);
                          }}>
                          {c.id} - {c.name}
                        </Link>
                      </p>
                      {getStatusChips(c)}
                    </td>
                    <td style={{ width: '22%' }}>
                      <p>
                        <LocationOnIcon className='candidate-list-icon' />
                        <span className='candidate-list-value'>{c.location}</span>
                      </p>
                      <p>
                        <SmartphoneIcon className='candidate-list-icon' />
                        <span className='candidate-list-value'>{c.phoneNumber}</span>
                      </p>
                      <p>
                        <EmailIcon className='candidate-list-icon' />
                        <span className='candidate-list-value'>{c.email}</span>
                      </p>
                    </td>

                    <td style={{ width: '20%' }}>
                      <p>
                        <PersonIcon className='candidate-list-icon' />
                        <span className='candidate-list-key'>Experience:</span>{' '}
                        <span className='candidate-list-value'>{c.experience} Years</span>
                      </p>
                      <p>
                        <MonetizationOnOutlinedIcon className='candidate-list-icon' />
                        <span className='candidate-list-key'>Current CTC:</span>{' '}
                        {isUserRole(ROLES.EVALUATOR, true) ? (
                          <>****</>
                        ) : (
                          <span className='candidate-list-value'>{c.currentCtc}</span>
                        )}
                      </p>
                      <p>
                        <MonetizationOnRoundedIcon className='candidate-list-icon' />
                        <span className='candidate-list-key'>Expected CTC:</span>{' '}
                        {isUserRole(ROLES.EVALUATOR, true) ? (
                          <>****</>
                        ) : (
                          <span className='candidate-list-value'>{c.expectedCtc}</span>
                        )}
                      </p>
                    </td>
                    <td style={{ width: '20%' }}>
                      <p>
                        <DateRangeIcon className='candidate-list-icon' />
                        <span className='candidate-list-key'>Notice Period:</span>
                        <span className='candidate-list-value'> {c.noticePeriod} days</span>
                      </p>
                      <p>
                        <DeviceHubIcon className='candidate-list-icon' />
                        <span className='candidate-list-key'>Source:</span>{' '}
                        <span className='candidate-list-value'>
                          {toUpperFirstChar(
                            getNameFromId(c.sourceChannel, LookupTypes.SOURCE_CHANNEL),
                          )}
                        </span>
                      </p>
                      <p>
                        <ScheduleRoundedIcon className='candidate-list-icon' />
                        <span className='candidate-list-key'>Uploaded on: </span>{' '}
                        <span className='candidate-list-value'>
                          {new Date(c.createdOn).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          })}
                        </span>
                      </p>
                      {/* <p>
                      <BookmarkIcon fontSize='small' style={{ height: '12px' }} />
                      Interested: YES
                    </p>
                    <p>
                      <HomeRepairServiceIcon fontSize='small' style={{ height: '12px' }} />
                      Holding Offer: YES
                    </p> */}
                    </td>
                    <td style={{ width: '20%' }}>
                      <p>
                        <BuildIcon className='candidate-list-icon' />
                        {getSkills(c.skills)}
                      </p>
                      <p>
                        <TrendingUpIcon className='candidate-list-icon' />
                        <span className='candidate-list-key'>Career Growth Score: </span>
                        <span className='candidate-list-value'>
                          {c.careerGrowthScore ? c.careerGrowthScore : ''}
                        </span>
                      </p>
                      <p>
                        <StarRateOutlinedIcon className='candidate-list-icon' />
                        <span className='candidate-list-key'>Suitability Score: </span>
                        <span className='candidate-list-value'>{getSuitabilityScore(c)}</span>
                      </p>
                    </td>
                    <td>
                      <p>
                        <Tooltip title='Remove candidate'>
                          <IconButton
                            disabled={
                              !isPermissionsDelete(MODULE.SJD, SubModuleTypes.JD_CANDIDATES)
                            }
                            onClick={() => {
                              removeCandidateAlert(c.id);
                              setCandidateName(c.name);
                            }}>
                            <DeleteIcon
                              className={
                                isPermissionsDelete(MODULE.SJD, SubModuleTypes.JD_CANDIDATES)
                                  ? 'delete-icon'
                                  : 'delete-icon-disabled'
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No records to display</td>
                </tr>
              )}
            </tbody>
          </table>
          {filteredCandidates.length > resultsPerPage && (
            <Pagination
              page={currPage}
              count={
                Math.floor(filteredCandidates.length / resultsPerPage) +
                (filteredCandidates.length % resultsPerPage == 0 ? 0 : 1)
              }
              onChange={(_e, v) => {
                {
                  handlePagination(searchQuery, selectedStatusId, sortQuery, v);
                }
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          )}
        </div>
      </Paper>
      {showOverlay && (
        <Dialog fullScreen open={showOverlay} className='search-dialog'>
          <div
            style={{
              boxShadow: 'none',
              borderRadius: '3px',
              backgroundColor: '#f5f5f5',
              minWidth: '25vw',
              padding: '10px',
              margin: 'auto',
            }}>
            <LinearProgressWithLabel value={progress} />
          </div>
        </Dialog>
      )}
      <Dialog
        fullScreen
        open={showSearch}
        onClose={() => setShowSearch(false)}
        className='search-dialog'>
        <div className='search-modal'>
          <CandidateSearch
            isModal={true}
            modalClose={() => setShowSearch(false)}
            tagCandidate={tag}
          />
        </div>
      </Dialog>
      {showRemoveCandidateAlert && (
        <ConfirmationDialog
          onClose={() => setShowRemoveCandidateAlert(false)}
          title='Confirm'
          content={`Do you want to remove ${candidateName} from this Job Definition?`}
          btn1Label='Cancel'
          btn2Label='Remove'
          btn1Action={() => setShowRemoveCandidateAlert(false)}
          btn2Action={removeCandidate}
        />
      )}
      <Notify
        open={showQuestionnaireAlert}
        severity='error'
        onClose={() => setShowQuestionnaireAlert(false)}>
        <span>Questionnaire not found, please attach questionnaire to the Job Definition.</span>
      </Notify>
      <Notify open={showToast} severity={toastMsg.severity} onClose={() => setShowToast(false)}>
        <span>{toastMsg.msg}</span>
      </Notify>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default SjdCandidateTable;
