import {
  DialogTitle,
  Select,
  DialogContent,
  InputLabel,
  TextField,
  Paper,
  Button,
  TableCell,
  TableRow,
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableSortLabel,
  MenuItem,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  styled,
  Tooltip,
  IconButton,
  DialogActions,
  Dialog,
  AlertColor,
} from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { visuallyHidden } from '@mui/utils';
import { getAllCourses, postCourses, updateCourses } from '../../service/tempCourseService';
import {
  getAllProgramLevels,
  getAllProviders,
  getAllTechStackByLevelId,
} from '../../service/EmployeeService';
import { useFormik } from 'formik';
import { Form } from 'react-router-dom';
import * as Yup from 'yup';
import Notify from '../common/notify';
import CloseIcon from '@mui/icons-material/Close';
import youtubeIcon from '../../assets/youtube.png';
import udemyIcon from '../../assets/udemy.png';
import ptgIcon from '../../assets/ptg.png';
import courseraIcon from '../../assets/coursera.png';
import { isPermissionsWrite } from '../../common/commonUtil';
import { MODULE, SubModuleTypes } from '../../constants/common';

const AddCourse = () => {
  type Order = 'asc' | 'desc';
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof any>('');
  const [header, setHeader] = useState('');
  const [courses, setCourses] = useState([] as any[]);
  const [levels, setLevels] = useState([] as any[]);
  const [techStacks, setTechStacks] = useState([] as any[]);
  const [providers, setproviders] = useState([] as any[]);
  const [submitted, setSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState(courses);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({} as any);
  const [selectedCourseId, setSelectedCourseId] = useState(0);

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const headers = [
    { id: 'courseName', label: 'Course Name' },
    { id: 'courseUrl', label: 'Course Link' },
    { id: 'courseDescription', label: 'Course Description' },
    { id: 'providerId.name', label: 'Provider' },
    { id: 'techStackId.name', label: 'Tech Stack' },
    { id: 'levelId.name', label: 'Level' },
    { id: 'actions', label: 'Actions' },
  ];

  const coursesValidation = Yup.object().shape({
    courseName: Yup.string().required('Course Name is required'),
    courseUrl: Yup.string().required('Course Url is required'),
    courseDescription: Yup.string().required('Course description is required'),
    provider: Yup.number().required('Provider is required'),
    techStack: Yup.number().required('TechStack is required'),
    level: Yup.number().required('Level is required'),
  });

  const formik = useFormik({
    initialValues: {
      courseName: '',
      courseUrl: '',
      courseDescription: '',
      provider: '',
      techStack: '',
      level: '',
    },
    validationSchema: coursesValidation,
    onSubmit: () => {
      postedCourses();
    },
    onReset: () => {
      formik.resetForm();
    },
  });
  const editFormik = useFormik({
    initialValues: {
      courseName: '',
      courseUrl: '',
      courseDescription: '',
      provider: '',
      techStack: '',
      level: '',
    },
    validationSchema: coursesValidation,
    onSubmit: () => {
      updateCourse();
    },
    onReset: () => {
      editFormik.resetForm();
    },
  });

  const postedCourses = () => {
    postCourses({
      courseName: formik.values.courseName,
      courseUrl: formik.values.courseUrl,
      courseDescription: formik.values.courseDescription,
      providerId: formik.values.provider,
      techStackId: formik.values.techStack,
      levelId: formik.values.level,
    })
      .then((_res) => {
        setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Course added successfully.',
        });
        setDialogOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: `${err.response.data.message}`,
        });
        setDialogOpen(false);
      });
  };

  const updateCourse = () => {
    updateCourses({
      id: selectedCourseId,
      courseName: editFormik.values.courseName,
      courseUrl: editFormik.values.courseUrl,
      courseDescription: editFormik.values.courseDescription,
      providerId: editFormik.values.provider,
      techStackId: editFormik.values.techStack,
      levelId: editFormik.values.level,
    })
      .then((_res) => {
        setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Course updated successfully.',
        });
        setDialogOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: `${err.response.data.message}`,
        });
        setDialogOpen(false);
      });
  };

  const getError = (formik: any, fieldName: string) => {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  };

  const getEditError = (editFormik: any, fieldName: string) => {
    if ((submitted || editFormik.touched[fieldName]) && editFormik.errors[fieldName])
      return editFormik.errors[fieldName]?.toString();
    return null;
  };

  const sortInterns = (array: any) => {
    const flag = order === 'desc' ? -1 : 1;

    return array.sort((a: any, b: any) => {
      const aValue = a[orderBy]?.toLowerCase() || '';
      const bValue = b[orderBy]?.toLowerCase() || '';

      if (aValue > bValue) {
        return flag * 1;
      }
      if (aValue < bValue) {
        return flag * -1;
      }
      return 0;
    });
  };
  const handleRequestSort = (property: keyof any, _isAsc?: boolean) => {
    if (_isAsc !== undefined) setOrder(_isAsc ? 'asc' : 'desc');
    else {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
    }
    setOrderBy(property);
  };

  useEffect(() => {
    getAllCourses()
      .then((resp) => {
        setRows(resp.data);
        setCourses(resp.data);
      })
      .catch((_err) => {});
    getAllProgramLevels().then((res) => setLevels(res.data));

    getAllProviders().then((res) => setproviders(res.data));
  }, []);

  useEffect(() => {
    if (edit && selectedCourse) {
      editFormik.setValues({
        courseName: selectedCourse.courseName,
        courseUrl: selectedCourse.courseUrl,
        courseDescription: selectedCourse.courseDescription,
        provider: selectedCourse.providerId.id,
        techStack: selectedCourse.techStackId.id,
        level: selectedCourse.levelId.id,
      });
    }
  }, [selectedCourse, edit, rowsPerPage]);

  const requestSearch = (searchedVal: string) => {
    const filteredRows = courses.filter((row) => {
      return Object.keys(row).some((k) => {
        if (typeof row[k] === 'object' && row[k] !== null) {
          return Object.keys(row[k]).some((nestedKey) =>
            String(row[k][nestedKey]).toLowerCase().includes(searchedVal.toLowerCase()),
          );
        } else {
          return String(row[k]).toLowerCase().includes(searchedVal.toLowerCase());
        }
      });
    });
    setRows(filteredRows);
    handleChangePage(null, 0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleIcons = (provider: string) => {
    switch (provider) {
      case 'Udemy':
        return udemyIcon;
      case 'Internal':
        return ptgIcon;
      case 'Coursera':
        return courseraIcon;
      default:
        return youtubeIcon;
    }
  };

  return (
    <>
      <Form
        onSubmit={(values) => {
          setSubmitted(true);
          edit ? editFormik.handleSubmit(values) : formik.handleSubmit(values);
        }}>
        <Dialog
          open={dialogOpen}
          fullWidth
          maxWidth='sm'
          onClose={() => {
            setDialogOpen(false);
          }}>
          <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {edit ? 'Edit Course' : 'Add New Course'}
              <CloseIcon
                onClick={() => setDialogOpen(false)}
                sx={{ cursor: 'pointer' }}></CloseIcon>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'space-around', gap: '30px' }}>
              <div>
                <div>
                  <InputLabel className='field-label'>Course Name</InputLabel>
                  <TextField
                    id='courseName'
                    variant='outlined'
                    size='small'
                    value={edit ? editFormik.values.courseName : formik.values.courseName}
                    onChange={edit ? editFormik.handleChange : formik.handleChange}
                    placeholder='Enter Course Name'
                  />
                </div>
                {edit ? (
                  <div className='mt-0 form-err-msg'>{getEditError(editFormik, 'courseName')}</div>
                ) : (
                  <div className='mt-0 form-err-msg'>{getError(formik, 'courseName')}</div>
                )}
              </div>

              <div>
                <div>
                  <InputLabel className='field-label'>Course Url</InputLabel>
                  <TextField
                    id='courseUrl'
                    variant='outlined'
                    size='small'
                    value={edit ? editFormik.values.courseUrl : formik.values.courseUrl}
                    onChange={edit ? editFormik.handleChange : formik.handleChange}
                    placeholder='Enter Course Url'
                  />
                </div>
                {edit ? (
                  <div className='mt-0 form-err-msg'>{getEditError(editFormik, 'courseUrl')}</div>
                ) : (
                  <div className='mt-0 form-err-msg'>{getError(formik, 'courseUrl')}</div>
                )}
              </div>
            </div>

            <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
              <div>
                <InputLabel className='field-label'>Course Description</InputLabel>
                <TextField
                  sx={{ minWidth: '510px' }}
                  id='courseDescription'
                  variant='outlined'
                  size='small'
                  value={
                    edit ? editFormik.values.courseDescription : formik.values.courseDescription
                  }
                  onChange={edit ? editFormik.handleChange : formik.handleChange}
                  placeholder='Enter Course Description'
                />
              </div>
              {edit ? (
                <div className='mt-0 form-err-msg'>
                  {getEditError(editFormik, 'courseDescription')}
                </div>
              ) : (
                <div className='mt-0 form-err-msg'>{getError(formik, 'courseDescription')}</div>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', gap: '30px' }}>
              <div style={{ width: '220px' }}>
                <div>
                  <InputLabel className='field-label'>Provider</InputLabel>
                  <Select
                    id='provider'
                    fullWidth
                    value={edit ? editFormik.values.provider : formik.values.provider}
                    onChange={(e) =>
                      edit
                        ? editFormik.setFieldValue('provider', e.target.value)
                        : formik.setFieldValue('provider', e.target.value)
                    }>
                    {providers.map((providers: any) => (
                      <MenuItem key={providers.id} value={providers.id}>
                        {providers.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {edit ? (
                  <div className='mt-0 form-err-msg'>{getEditError(editFormik, 'provider')}</div>
                ) : (
                  <div className='mt-0 form-err-msg'>{getError(formik, 'provider')}</div>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <InputLabel className='field-label'>Level</InputLabel>
                  <Select
                    sx={{ minWidth: '220px' }}
                    id='level'
                    fullWidth
                    labelId='students-label'
                    value={edit ? editFormik.values.level : formik.values.level}
                    onChange={(e: any) => {
                      getAllTechStackByLevelId(e.target.value).then((res) =>
                        setTechStacks(res.data),
                      );
                      edit
                        ? editFormik.setFieldValue('level', e.target.value)
                        : formik.setFieldValue('level', e.target.value);
                    }}>
                    {levels.map((level: any) => (
                      <MenuItem key={level.id} value={level.id}>
                        {level.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {edit ? (
                  <div className='mt-0 form-err-msg'>{getEditError(editFormik, 'level')}</div>
                ) : (
                  <div className='mt-0 form-err-msg'>{getError(formik, 'level')}</div>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <InputLabel className='field-label' id='tech-stack'>
                  Tech Stack
                </InputLabel>
                <div>
                  <Select
                    id='techStack'
                    style={{ width: '510px' }}
                    value={edit ? editFormik.values.techStack : formik.values.techStack}
                    onChange={(e) =>
                      edit
                        ? editFormik.setFieldValue('techStack', e.target.value)
                        : formik.setFieldValue('techStack', e.target.value)
                    }>
                    {techStacks.map((courses1: any) => (
                      <MenuItem key={courses1.id} value={courses1.id}>
                        {courses1.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              {edit ? (
                <div className='mt-0 form-err-msg'>{getEditError(editFormik, 'techStack')}</div>
              ) : (
                <div className='mt-0 form-err-msg'>{getError(formik, 'techStack')}</div>
              )}
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                gap: '2rem',
                alignItems: 'flex-end',
                padding: '0px 24px 0px 24px',
              }}>
              <Button
                sx={{ color: '#a72037' }}
                onClick={() => {
                  setEdit(false);
                  setDialogOpen(false);
                  formik.setFieldValue('courseName', '');
                  formik.setFieldValue('courseUrl', '');
                  formik.setFieldValue('courseDescription', '');
                  formik.setFieldValue('provider', '');
                  formik.setFieldValue('techStack', '');
                  formik.setFieldValue('level', '');
                  formik.resetForm();
                  editFormik.setFieldValue('courseName', selectedCourse?.courseName);
                  editFormik.setFieldValue('courseUrl', selectedCourse?.courseUrl);
                  editFormik.setFieldValue('courseDescription', selectedCourse?.courseDescription);
                  editFormik.setFieldValue('provider', selectedCourse?.provider);
                  editFormik.setFieldValue('techStack', selectedCourse?.techStack);
                  editFormik.setFieldValue('level', selectedCourse?.level);
                  editFormik.resetForm();
                }}>
                Cancel
              </Button>
              <Button
                className='bg-btn'
                onClick={() => {
                  edit ? editFormik.handleSubmit() : formik.handleSubmit();
                }}>
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </Form>
      <Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '1rem',
            marginBottom: '10px',
            marginTop: '10px',
          }}>
          <div>
            <OutlinedInput
              sx={{ margin: '0px', backgroundColor: 'white' }}
              id='table-search'
              type='text'
              placeholder='Search...'
              className='search-bar'
              onChange={(event) => requestSearch(event.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <Search />
                </InputAdornment>
              }
            />
          </div>
          <div>
            <Tooltip title='Add Course'>
              <IconButton
                className='add-sjd-btn'
                disabled={!isPermissionsWrite(MODULE.LMS, SubModuleTypes.COURSE_CATALOGUE)}
                onClick={() => {
                  setEdit(false);
                  setDialogOpen(true);
                }}>
                <Add
                  className={
                    isPermissionsWrite(MODULE.LMS, SubModuleTypes.COURSE_CATALOGUE)
                      ? 'delete-icon'
                      : 'delete-icon-disabled'
                  }
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, margin: 'auto' }} aria-label='simple table' size='small'>
            <TableHead sx={{ background: 'rgb(224 224 224)' }}>
              <TableRow>
                {headers.map((headCell, index: any) => (
                  <TableCell key={index}>
                    <div style={{ display: 'flex' }}>
                      <div>{headCell.label}</div>
                      <div
                        onClick={() => handleRequestSort(headCell.id)}
                        onMouseEnter={() => {
                          setHeader(headCell.label);
                        }}
                        onMouseLeave={() => {
                          setHeader('');
                        }}>
                        {headCell.label != 'Actions' && (
                          <TableSortLabel
                            active={orderBy === headCell.id || header === headCell.label}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => handleRequestSort(headCell.id)}>
                            {orderBy === headCell.id ? (
                              <Box component='span' sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        )}
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                sortInterns(rows)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((name: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{name.courseName}</TableCell>
                      <TableCell sx={{ paddingLeft: '40px' }}>
                        <a href={name.courseUrl} target='_blank' rel='noopener noreferrer'>
                          <img src={handleIcons(name.providerId.name)} alt='Course Icon' />
                        </a>
                      </TableCell>
                      <TableCell>{name.courseDescription}</TableCell>
                      <TableCell>{name.providerId?.name}</TableCell>
                      <TableCell>{name.techStackId?.name}</TableCell>
                      <TableCell>{name.levelId?.name}</TableCell>
                      <TableCell>
                        <IconButton
                          disabled={
                            !isPermissionsWrite(MODULE.LMS, SubModuleTypes.COURSE_CATALOGUE)
                          }
                          onClick={() => {
                            setSelectedCourseId(name.id);
                            setSelectedCourse(name);
                            getAllTechStackByLevelId(name.levelId?.id).then((res) =>
                              setTechStacks(res.data),
                            );
                            setEdit(true);
                            setDialogOpen(true);
                          }}
                          style={{ color: '#a72037' }}>
                          <EditIcon
                            className={
                              isPermissionsWrite(MODULE.LMS, SubModuleTypes.COURSE_CATALOGUE)
                                ? 'delete-icon'
                                : 'delete-icon-disabled'
                            }
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <StyledTableRow>
                  <TableCell>No records to display</TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Notify open={showToast} severity={toastMsg.severity} onClose={handleToastClose}>
          <span>{toastMsg.msg}</span>
        </Notify>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TablePagination
            sx={{ marginLeft: 'auto' }}
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={courses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className='pagination'
          />
        </div>
      </Grid>
    </>
  );
};
export default AddCourse;
