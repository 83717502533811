import { InputLabel, Paper, Typography } from '@mui/material';
import { getLabel } from '../../../common/commonUtil';
import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const JobRequirement = ({ sjd }: any) => {
  const [longerText, setLongerText] = useState(false);

  const getSkillText = () => {
    const text = sjd.skills
      ?.map((item: any) => {
        return item.skill.name;
      })
      .join(', ');
    return <span>{text}</span>;
  };

  const getLabelResponsibilities = (value: string) => {
    return longerText ? value : `${value.substring(0, 260)}...`;
  };

  return (
    <div className='candidate'>
      <Paper sx={{ padding: '1px 16px 1px 0', boxShadow: 'none' }}>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
              color: '#a72037',
            }}>
            <Typography variant='h5' fontWeight={700}>
              Job Requirement
            </Typography>
          </div>
        </div>
        <div className='row'>
          <div className='field'>
            <InputLabel className='field-label'>ID-Name</InputLabel>
            <span>
              {getLabel(sjd.id)}-{getLabel(sjd.name)}
            </span>
          </div>
          <div className='field'>
            <InputLabel className='field-label'>Skills</InputLabel>
            <span>{getSkillText()}</span>
          </div>
          <div className='field' style={{ width: '50%' }}>
            <InputLabel className='field-label'>Roles & Responsibilities</InputLabel>
            <span>
              {getLabelResponsibilities(sjd.description)}
              {sjd.description.length > 260 && (
                <a
                  style={{ color: 'blue', cursor: 'pointer', display: 'inline-flex' }}
                  onClick={() => setLongerText(!longerText)}>
                  <span style={{ display: 'flex' }}>
                    {longerText ? (
                      <>
                        {' '}
                        show less
                        <KeyboardArrowUpIcon />
                      </>
                    ) : (
                      <>
                        show more
                        <KeyboardArrowDownIcon />
                      </>
                    )}
                  </span>
                </a>
              )}
            </span>
          </div>
        </div>
      </Paper>
    </div>
  );
};
export default JobRequirement;
