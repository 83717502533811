import React, { useEffect, useState } from 'react';
import PageHeader from '../common/page-header/page-header';
import {
  styled,
  Table,
  TableBody,
  tableCellClasses,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Link,
  OutlinedInput,
  InputAdornment,
  TableSortLabel,
  Box,
  TablePagination,
} from '@mui/material';
import { Loader } from '../common/loader';
import useNavigate from '../../common/useNavigate';
import { Search } from '@mui/icons-material';
import { getApplicants } from '../../service/applicantService';
import { visuallyHidden } from '@mui/utils';
import dateFormat from 'dateformat';
import { getNameFromId } from '../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';

const Applicants = () => {
  type Order = 'asc' | 'desc';
  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [navigate] = useNavigate();
  const [rows, setRows] = useState(data);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof any>('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setLoading(true);
    getApplicants()
      .then((res) => {
        setData(res.data);
        setRows(res.data);
      })
      .catch((_err) => {})

      .finally(() => setLoading(false));
  }, []);

  const redirectToCandidatePage = (data: any) => {
    navigate(
      [
        {
          label: `${data['candidateName']}`,
          link: `/source-job-definition/candidate/${btoa(data['sjdId'])}/${btoa(
            data['candidateId'],
          )}`,
        },
      ],
      true,
    );
  };

  const redirectToSjdPage = (data: any) => {
    navigate(
      [
        {
          label: `${data['sjdId']} - ${data['sjdName']}`,

          link: `/source-job-definition/${btoa(data['sjdId'])}`,
        },
      ],

      true,
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array: any[]) {
    const flag = order === 'desc' ? -1 : 1;
    if (orderBy === 'createdOnString')
      return array.sort((a, b) =>
        new Date(a['createdOn']).getTime() > new Date(b['createdOn']).getTime()
          ? flag * 1
          : new Date(b['createdOn']).getTime() > new Date(a['createdOn']).getTime()
          ? flag * -1
          : 0,
      );
    return array.sort((a, b) =>
      a[orderBy] > b[orderBy] ? flag * 1 : b[orderBy] > a[orderBy] ? flag * -1 : 0,
    );
  }

  const requestSearch = (searchedVal: string) => {
    const filteredRows = data.filter((row) => {
      return Object.keys(row)
        .filter((k) => k != 'createdOn')
        .some((k) => String(row[k]).toLowerCase().includes(searchedVal.toLowerCase()));
    });
    setRows(filteredRows);
    handleChangePage(null, 0);
  };

  const handleRequestSort = (property: keyof any, _isAsc?: boolean) => {
    if (_isAsc !== undefined) setOrder(_isAsc ? 'asc' : 'desc');
    else {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
    }
    setOrderBy(property);
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F9F9F9',

      color: theme.palette.common.black,

      borderBottom: '2px solid #DEDEDE',
    },
  }));

  return (
    <>
      <PageHeader title='Applicants'></PageHeader>
      <TableContainer className='applicant-table'>
        <OutlinedInput
          id='table-search'
          type='text'
          placeholder='Search...'
          className='search-bar'
          onChange={(event) => requestSearch(event.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <Search />
            </InputAdornment>
          }
        />
        <Table sx={{ background: 'white', width: '60%' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{ width: '20%' }}
                sortDirection={orderBy === 'candidateName' ? order : false}
                onClick={() => handleRequestSort('candidateName')}
                className='table-head-cell'>
                Candidate
                <TableSortLabel
                  active={orderBy === 'candidateName'}
                  direction={orderBy === 'candidateName' ? order : 'asc'}
                  onClick={() => handleRequestSort('candidateName')}>
                  {orderBy === 'candidateName' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                sx={{ width: '20%' }}
                sortDirection={orderBy === 'sjdId' ? order : false}
                onClick={() => handleRequestSort('sjdId')}
                className='table-head-cell'>
                Job Definition
                <TableSortLabel
                  active={orderBy === 'sjdId'}
                  direction={orderBy === 'sjdId' ? order : 'asc'}
                  onClick={() => handleRequestSort('sjdId')}>
                  {orderBy === 'sjdId' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                sx={{ width: '10%' }}
                sortDirection={orderBy === 'sourceChannel' ? order : false}
                onClick={() => handleRequestSort('sourceChannel')}
                className='table-head-cell'>
                Source
                <TableSortLabel
                  active={orderBy === 'sourceChannel'}
                  direction={orderBy === 'sourceChannel' ? order : 'asc'}
                  onClick={() => handleRequestSort('sourceChannel')}>
                  {orderBy === 'sourceChannel' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                sx={{ width: '10%' }}
                sortDirection={orderBy === 'createdOn' ? order : false}
                onClick={() => handleRequestSort('createdOn')}
                className='table-head-cell'>
                Applied on
                <TableSortLabel
                  active={orderBy === 'createdOn'}
                  direction={orderBy === 'createdOn' ? order : 'asc'}
                  onClick={() => handleRequestSort('createdOn')}>
                  {orderBy === 'createdOn' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} sx={{ color: 'grey.500' }}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              stableSort(rows)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((ob, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link sx={{ cursor: 'pointer' }} onClick={() => redirectToCandidatePage(ob)}>
                        {ob.candidateName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link sx={{ cursor: 'pointer' }} onClick={() => redirectToSjdPage(ob)}>
                        {ob.sjdId}- {ob.sjdName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {getNameFromId(ob.sourceChannel, LookupTypes.SOURCE_CHANNEL)}
                    </TableCell>

                    <TableCell>{dateFormat(new Date(ob.createdOn), 'dd/mm/yyyy')}</TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell>No records to display</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          sx={{ marginRight: '40%' }}
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className='pagination'
        />
      </TableContainer>
    </>
  );
};
export default Applicants;
