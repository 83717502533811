import { Add, Search } from '@mui/icons-material';
import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableSortLabel,
  TablePagination,
  Link,
  Typography,
  OutlinedInput,
  InputAdornment,
  styled,
  tableCellClasses,
  Tooltip,
  IconButton,
  Chip,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React, { useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { getSJDColorFromStatus, isPermissionsWrite } from '../../common/commonUtil';
import useNavigate from '../../common/useNavigate';
import { Loader } from './loader';
import { MODULE } from '../../constants/common';

interface TableProps {
  rowData: any[];
  headers: any[];
  isSjd?: boolean;
  getActions?: (id: any) => React.ReactNode;
  onAddClick: () => void;
  loading?: boolean;
}
const TableComponent: React.FC<TableProps> = ({
  rowData,
  headers,
  loading,
  isSjd = false,
  getActions,
  onAddClick,
}) => {
  type Order = 'asc' | 'desc';
  const [rows, setRows] = useState(rowData);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof any>('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [header, setHeader] = useState('');

  useEffect(() => {
    setRows(rowData);
    if (rowData && rowData.length && 'modifiedOn' in rowData[0]) {
      handleRequestSort('modifiedOn', false);
    } else {
      handleRequestSort('createdOnString', false);
    }
  }, [rowData]);
  const [navigate] = useNavigate();

  function stableSort(array: any[]) {
    const flag = order === 'desc' ? -1 : 1;
    if (orderBy === 'createdOnString')
      return array.sort((a, b) =>
        new Date(a['createdOn']).getTime() > new Date(b['createdOn']).getTime()
          ? flag * 1
          : new Date(b['createdOn']).getTime() > new Date(a['createdOn']).getTime()
          ? flag * -1
          : 0,
      );
    return array.sort((a, b) =>
      a[orderBy] > b[orderBy] ? flag * 1 : b[orderBy] > a[orderBy] ? flag * -1 : 0,
    );
  }

  const handleRequestSort = (property: keyof any, _isAsc?: boolean) => {
    if (_isAsc !== undefined) setOrder(_isAsc ? 'asc' : 'desc');
    else {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
    }
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCell = (row: any, headerName: string) => {
    if (headerName === 'name') {
      const link = isSjd ? (
        <Link
          className='hyper-link'
          onClick={() =>
            navigate([
              {
                label: `${row['name']}`,
                link: `/source-job-definition/${btoa(row['id'])}`,
              },
            ])
          }>
          {row[headerName]}
          {row['isMigrated'] && <span style={{ fontSize: '30px' }}>&#8314;</span>}
        </Link>
      ) : (
        <Link
          className='hyper-link'
          underline='always'
          onClick={() =>
            navigate([
              {
                label: `${row['name']}`,
                link: `/questionnaire/create?id=${btoa(row['id'])}`,
              },
            ])
          }>
          {row[headerName]}
        </Link>
      );
      return link;
    } else if (headerName === 'status') {
      return (
        <Chip
          label={row[headerName]}
          // color={getChipColorFromStatus(row[headerName])}
          size='medium'
          style={{
            fontWeight: 'bold',
            width: '100px',
            backgroundColor: getSJDColorFromStatus(row[headerName]),
          }}
        />
      );
    }
    return <Typography className='table-cell'>{row[headerName]}</Typography>;
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = rowData.filter((row) => {
      return Object.keys(row)
        .filter((k) => k != 'modifiedOn')
        .some((k) => String(row[k]).toLowerCase().includes(searchedVal.toLowerCase()));
    });
    setRows(filteredRows);
    handleChangePage(null, 0);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F9F9F9',
      color: theme.palette.common.black,
      borderBottom: '2px solid #DEDEDE',
    },
  }));
  const StyledTableRow = styled(TableRow)(() => ({
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <>
      <TableContainer>
        <OutlinedInput
          id='table-search'
          type='text'
          placeholder='Search...'
          className='search-bar'
          onChange={(event) => requestSearch(event.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <Search />
            </InputAdornment>
          }
        />
        {isPermissionsWrite(isSjd ? MODULE.SJD : MODULE.QUESTIONNAIRE) && (
          <Tooltip title={isSjd ? 'Create New JD' : 'Create new questionnaire'}>
            <IconButton className='add-sjd-btn' onClick={() => onAddClick()}>
              <Add />
            </IconButton>
          </Tooltip>
        )}
        <Table sx={{ background: 'white' }}>
          <TableHead>
            <TableRow>
              {headers.map((headCell, index) => (
                <StyledTableCell
                  key={index}
                  sortDirection={orderBy === headCell.id ? order : false}
                  onClick={() => handleRequestSort(headCell.id)}
                  className='table-head-cell'
                  onMouseEnter={() => {
                    setHeader(headCell.label);
                  }}
                  onMouseLeave={() => {
                    setHeader('');
                  }}>
                  {headCell.label}
                  <TableSortLabel
                    active={orderBy === headCell.id || header === headCell.label}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => handleRequestSort(headCell.id)}>
                    {orderBy === headCell.id ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={headers.length} sx={{ color: 'grey.500' }}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              stableSort(rows)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <StyledTableRow key={rowIndex}>
                    {headers.map((header, colIndex) => (
                      <React.Fragment key={colIndex}>
                        {header.id === 'actions' ? (
                          <TableCell key={header.id}>
                            {getActions ? getActions(row) : <MoreHorizIcon />}
                          </TableCell>
                        ) : (
                          <TableCell className='table-cell'>{getCell(row, header.id)}</TableCell>
                        )}
                      </React.Fragment>
                    ))}
                  </StyledTableRow>
                ))
            ) : (
              <StyledTableRow>
                <TableCell>No records to display</TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className='pagination'
        />
      )}
    </>
  );
};
export default TableComponent;
