import { useState, useEffect } from 'react';
import { Loader } from '../common/loader';
import {
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Stack, styled } from '@mui/system';
import useNavigate from '../../common/useNavigate';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getQcRatedCandidates } from '../../service/dashboardService';

interface Props {
  initialData: any;
}

const QcRatedCandidatesTable = ({ initialData }: Props) => {
  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [recruiters, setRecruiters] = useState([] as any[]);
  const [selectedRecruiter, setSelectedRecruiter] = useState('All');
  const [startDate, setStartDate] = useState(dayjs(''));
  const [endDate, setEndDate] = useState(dayjs(''));
  const [navigate] = useNavigate();

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
    } else {
      setLoading(false);
      setData(initialData);
      setRecruiters(initialData);
      handleRecruiters(initialData, selectedRecruiter);
      if (startDate.isValid() && !endDate.isValid()) {
        setEndDate(dayjs());
      }
      if (!startDate.isValid() && endDate.isValid()) {
        setStartDate(dayjs(dayjs()).subtract(15, 'days'));
      }
      if (startDate.isValid() && endDate.isValid()) {
        setLoading(true);
        getQcRatedCandidates(
          startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
          endDate.format('YYYY-MM-DDTHH:mm:ssZ'),
        )
          .then((resp) => {
            setData(resp.data);
            setRecruiters(resp.data);
          })
          .catch((_err) => {})
          .finally(() => setLoading(false));
      }
    }
  }, [initialData, startDate, endDate]);

  const redirectToCandidatePage = (data: any) => {
    navigate(
      [
        {
          label: 'Job Definitions',
          link: '/source-job-definition',
        },
        {
          label: `${data['sjdId']} - ${data['sjdName']}`,
          link: `/source-job-definition/${btoa(data['sjdId'])}`,
        },
        {
          label: `${data['candidateName']}`,
          link: `/source-job-definition/candidate/${btoa(data['sjdId'])}/${btoa(
            data['candidateId'],
          )}`,
        },
      ],
      true,
    );
  };

  const listOfRecruiters = recruiters
    .map((names) => names.recruiterName)
    .filter((value, index, self) => self.indexOf(value) === index);

  const handleRecruiters = (recruiters: any, recruiter: any) => {
    const data: any[] = recruiters;
    recruiter === 'All'
      ? setData(data)
      : setData(data.filter((names) => names.recruiterName === recruiter));
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F9F9F9',
      borderBottom: '2px solid #DEDEDE',
    },
  }));
  return (
    <>
      <Stack spacing={1}>
        <Typography variant='h6' fontWeight={700}>
          Awaiting Profiler Rating
        </Typography>
        <div
          style={{
            margin: '6px ',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '5px',
          }}>
          <DatePicker
            sx={{ marginRight: '15px' }}
            className='date-picker-funnel'
            format='DD-MM-YYYY'
            label='From'
            value={startDate.isValid() ? dayjs(startDate) : dayjs(dayjs()).subtract(15, 'days')}
            onChange={(value) => {
              setStartDate(dayjs(value));
            }}
          />
          <DatePicker
            sx={{ marginRight: '10px' }}
            className='date-picker-funnel'
            format='DD-MM-YYYY'
            label='To'
            value={endDate.isValid() ? dayjs(endDate) : dayjs()}
            onChange={(value) => {
              setEndDate(dayjs(value));
            }}
          />
          <Select
            sx={{ width: '23%', height: '22px', justifyContent: 'right' }}
            value={selectedRecruiter}
            onChange={(e) => {
              setSelectedRecruiter(e.target.value);
              handleRecruiters(recruiters, e.target.value);
            }}>
            <MenuItem key={0} value='All'>
              All Recruiters
            </MenuItem>
            {listOfRecruiters.map((recruiter, index) => (
              <MenuItem key={index} value={recruiter}>
                {recruiter}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Stack>
      <TableContainer className='qc-table'>
        <Table sx={{ background: 'white' }} size='small'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Candidate</StyledTableCell>
              <StyledTableCell>Source Definition</StyledTableCell>
              <StyledTableCell>QC Rating</StyledTableCell>
              <StyledTableCell>Recruiter</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={4} sx={{ color: 'grey.500' }}>
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              data.map((d, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link sx={{ cursor: 'pointer' }} onClick={() => redirectToCandidatePage(d)}>
                      {d.candidateName}
                    </Link>
                  </TableCell>
                  <TableCell>{d.sjdName}</TableCell>
                  <TableCell>{d.qcRating}</TableCell>
                  <TableCell>{d.recruiterName}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QcRatedCandidatesTable;
