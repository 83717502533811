import { Paper, Tooltip } from '@mui/material';
import {
  getNameFromId,
  getSJDColorFromStatus,
  getIdFromName,
  isUserRole,
} from '../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';
import { CandidateStatus } from '../../common/candidateStatus';
import { ROLES } from '../../constants/common';

const SjdDetailsDashBoard = ({ sjd, candidates = [] }: any) => {
  function getSkillsLable(skills: any[]): string {
    if (skills.length == 0) return '';

    if (skills.length == 1) return skills[0].skill.name;

    return `${skills[0].skill.name} ${skills.length > 1 ? `+${skills.length - 1}` : ''}`;
  }
  const filledPositions = Object.values(candidates)
    .flatMap((item: any) => Object.values(item?.sjdCandidateInfo || {}))
    .filter(
      (item: any) =>
        item &&
        item.candidateStatusId ===
          getIdFromName(CandidateStatus.JOINED, LookupTypes.CANDIDATE_STATUS),
    ).length;

  return (
    <Paper className='sjd-info-block'>
      <div className='sjd-info'>
        <table className='sjd-info-table'>
          <tbody>
            <tr style={{ verticalAlign: 'top' }}>
              <td
                style={{
                  background: `${getSJDColorFromStatus(
                    getNameFromId(sjd.sjdStatusId, LookupTypes.SJD_STATUS),
                  )}`,
                  borderLeft: '5px solid white',
                }}
                rowSpan={2}>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}>{`${sjd.id} - ${sjd.name}`}</p>
              </td>
              <td>
                <p>JD Status</p>
              </td>
              <td>
                <p>Total Positions</p>
              </td>
              {!isUserRole(ROLES.EVALUATOR, true) && (
                <>
                  <td>
                    <p>Filled Positions</p>
                  </td>
                  <td>
                    <p>Open Positions</p>
                  </td>
                </>
              )}
              <td>
                <p>Skills</p>
              </td>
              <td>
                <p>Notice Period</p>
              </td>
              <td>
                <p>Priority</p>
              </td>
              <td>
                <p>Min. Experience (years)</p>
              </td>
              <td>
                <p>Roles and Responsibilities</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{getNameFromId(sjd.sjdStatusId, LookupTypes.SJD_STATUS)}</p>
              </td>
              <td>
                <p>{sjd.newOpenPositions + sjd.backfillOpenPositions}</p>
              </td>
              {!isUserRole(ROLES.EVALUATOR, true) && (
                <>
                  <td>
                    <p>{filledPositions}</p>
                  </td>
                  <td>
                    <p>{sjd.newOpenPositions + sjd.backfillOpenPositions - filledPositions}</p>
                  </td>
                </>
              )}
              <td>
                <p>{sjd.skills && getSkillsLable(sjd.skills)}</p>
              </td>
              <td>
                <p>{sjd.noticePeriod}</p>
              </td>
              <td>
                <p>{getNameFromId(sjd.priorityId, LookupTypes.PRIORITY)}</p>
              </td>
              <td>
                <p>{sjd.experienceLevel}</p>
              </td>
              <td>
                {sjd.description && (
                  <Tooltip title={sjd.description}>
                    <p>
                      {sjd.description.length < 20
                        ? sjd.description
                        : `${sjd.description.substr(0, 20)}...`}
                    </p>
                  </Tooltip>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Paper>
  );
};
export default SjdDetailsDashBoard;
