import { Button, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import './lms-reporting.scss';
import { useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import { Loader } from '../common/loader';
import DownloadIcon from '@mui/icons-material/Download';

import { NewHireReport } from './lms-newHireReport';
import { TaskStatusReport } from './lms-taskStatusReport';
import { getLmsReport } from '../../service/lms-reportService';
import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';

const LmsReporting = () => {
  const [reportData, setReportData] = useState(undefined as any);
  const [rowData, setRowData] = useState(undefined as any);
  const [submitted, setSubmitted] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const reportValidationSchema = yup.object({
    fromDate: yup.date().required('Please select from date'),
    toDate: yup
      .date()
      .required('Please select to date')
      .min(yup.ref('fromDate'), 'To date should be greater or equal to from date'),
  });

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  const formik = useFormik({
    initialValues: {
      reportType: 'newHire',
      fromDate: '',
      toDate: '',
    },
    validationSchema: reportValidationSchema,
    onSubmit: () => {
      setSubmitted(true);
      setLoading(true);
      setExcelLoading(false);
      const req: any = { ...formik.values };
      getLmsReport(req)
        .then((resp) => {
          setReportData(resp.data);
          setRowData(resp.data);
        })
        .catch((_err) => {})
        .finally(() => setLoading(false));
    },
  });

  const resetResults = () => {
    setReportData(undefined);
    setRowData(undefined);
  };

  const disablePastEndDate = (date: any) => {
    if (!formik.values.fromDate) return false;

    return dayjs(date).isBefore(dayjs(formik.values.fromDate));
  };

  function handleExport() {
    if (!rowData || rowData.length === 0) {
      console.error('No data available to export');
      return;
    }

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName =
      formik.values.reportType === 'newHire' ? 'New_Hired_Export' : 'Task_Status_Export';

    const excelDataCandidates = rowData.map((c: any) => {
      if (formik.values.reportType === 'newHire') {
        return {
          'Employee Name': c.employeeName,
          'Manager Name': c.mangerName,
          'Hired On': dayjs(c.createdOn).format('DD-MMM-YYYY'),
          Level: c.levelName,
          'Created By': c.createdByName,
        };
      } else {
        return {
          'Employee Name': c.employeeName,
          // 'Course ID': c.courseId,
          'Course Name': c.courseName,
          'Start Date': dayjs(c.startDate).format('DD-MMM-YYYY'),
          'End Date': dayjs(c.endDate).format('DD-MMM-YYYY'),
          'Actual End Date': c.actualEndDate ? dayjs(c.actualEndDate).format('DD-MMM-YYYY') : 'N/A',
          Percentage: c.percentage,
          // 'Manager ID': c.managerId,
          'Manager Name': c.managerName,
          Status: c.status,
          'Created On': dayjs(c.createdOn).format('DD-MMM-YYYY'),
          'Created By': c.createdByName,
          'Level Name': c.levelName,
          // 'Employee ID': c.employeeId,
        };
      }
    });

    const ws = XLSX.utils.json_to_sheet(excelDataCandidates);
    // console.log('ws:', ws);

    if (ws['!ref']) {
      const range = XLSX.utils.decode_range(ws['!ref']);
      const columns = formik.values.reportType === 'newHire' ? [5, 6, 10] : [2, 3, 4, 7, 8, 11];

      if (rowData.length !== 0) {
        ws['!cols'] = Array(Object.keys(excelDataCandidates[0]).length).fill({ wpx: 150 });
        for (let col = range.s.c; col <= range.e.c; col++) {
          const headerCellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
          ws[headerCellAddress].s = {
            font: {
              bold: true,
            },
            alignment: {
              horizontal: 'center',
            },
          };
          for (let row = range.s.r + 1; row <= range.e.r; row++) {
            if (columns.includes(col)) {
              const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
              if (ws[cellAddress]) {
                ws[cellAddress].s = {
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                  },
                };
              }
            }
          }
        }
      }
    }

    const wb = {
      Sheets: { Sheet1: ws },
      SheetNames: ['Sheet1'],
    };
    // console.log('wb:', wb);
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <div>
      {/* <PageHeader title='Reporting'></PageHeader> */}
      <Paper sx={{ marginTop: 4, padding: [5, 2], boxShadow: 'none' }}>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '10%' }}>
              <InputLabel id='reportTypeLabel' sx={{ fontSize: 'small' }}>
                Select Report Type
              </InputLabel>
              <Select
                sx={{ fontSize: 'small', height: '33px' }}
                name='reportType'
                id='reportType'
                labelId='reportTypeLabel'
                fullWidth
                size='small'
                onChange={(e) => {
                  resetResults();
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                defaultValue='newHire'
                displayEmpty>
                <MenuItem sx={{ fontSize: 'small' }} disabled defaultValue='Select Report Type'>
                  Select Report Type
                </MenuItem>
                <MenuItem sx={{ fontSize: 'small' }} value='newHire'>
                  New Hire
                </MenuItem>
                <MenuItem sx={{ fontSize: 'small' }} value='taskStatus'>
                  Training Status
                </MenuItem>
              </Select>
              {formik.errors && <div className='mt-0 form-err-msg'></div>}
            </div>
            <div>
              <InputLabel id='reportTypeLabel' sx={{ fontSize: 'small' }}>
                From
              </InputLabel>
              <DatePicker
                className='date-picker-lms'
                value={formik.values.fromDate ? dayjs(formik.values.fromDate) : ''}
                format='DD-MM-YYYY'
                onChange={(value) => {
                  resetResults();
                  formik.setFieldValue(
                    'fromDate',
                    dateFormat(value?.toString(), 'isoDateTime'),
                    true,
                  );
                }}
              />
              <div className='mt-0 form-err-msg'>{getError(formik, 'fromDate')}</div>
            </div>
            <div>
              <InputLabel id='reportTypeLabel' sx={{ fontSize: 'small' }}>
                To
              </InputLabel>
              <DatePicker
                shouldDisableDate={disablePastEndDate}
                className='date-picker-lms'
                value={formik.values.toDate ? dayjs(formik.values.toDate) : ''}
                format='DD-MM-YYYY'
                onChange={(value) => {
                  resetResults();
                  formik.setFieldValue(
                    'toDate',
                    dateFormat(value?.toString(), 'isoDateTime'),
                    true,
                  );
                }}
              />
              <div className='mt-0 form-err-msg'>{getError(formik, 'toDate')}</div>
            </div>
            <Button
              sx={{ height: '35px', marginRight: '3px', marginTop: '18px' }}
              type='submit'
              className='grid-btn'>
              Generate Report
            </Button>
            <div>
              {!excelLoading && (
                <Button
                  disabled={
                    excelLoading ||
                    !reportData ||
                    !(Array.isArray(reportData)
                      ? reportData.length
                      : Object.keys(reportData).length)
                  }
                  sx={{ height: '35px', marginTop: '18px', fontSize: 'small' }}
                  className='grid-btn'
                  onClick={handleExport}>
                  {<DownloadIcon />}
                </Button>
              )}
              {excelLoading && (
                <div className='loader-button'>
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </form>
      </Paper>
      <div>
        {loading && <Loader />}
        {rowData && formik.values.reportType == 'newHire' && <NewHireReport data={rowData} />}
        {rowData && formik.values.reportType == 'taskStatus' && <TaskStatusReport data={rowData} />}
      </div>
    </div>
  );
};

export default LmsReporting;
