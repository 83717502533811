import { Typography, IconButton, DialogContent, DialogTitle } from '@mui/material';
import Close from '../../tad-revamp-ui/assets/login/close.svg';
import Group from '../../tad-revamp-ui/assets/login/Group.svg';
import './TermsPrivacyPolicy.scss';

interface TermsOfServiceProps {
  handleCloseDialog: () => void;
}
// Disable the prop-types ESLint rule for this component
// eslint-disable-next-line react/prop-types
const TermsOfService: React.FC<TermsOfServiceProps> = ({ handleCloseDialog }) => {
  return (
    <>
      <DialogTitle>
        {' '}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2.12rem' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '1.13rem' }}>
              <img
                src={Group}
                style={{
                  width: '2.375rem',
                  height: '2.1875rem',
                  flexShrink: '0',
                  background: '#1976D2',
                }}
              />
            </div>
            <div>
              <Typography
                sx={{
                  color: 'var(--Primary-Dark-grey-500, #333)',
                  fontFamily: 'var(--Font, Roboto)',
                  fontSize: 'var(--Font-size-h6, 1.1875rem)',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.5625rem',
                }}>
                Terms of Service
              </Typography>
              <Typography
                sx={{
                  color: 'var(--Secondary-colors-Grey, #868686)',
                  fontFamily: 'var(--Font, Roboto)',
                  fontSize: 'var(--Font-size-P, 1rem)',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '1.5625rem',
                }}>
                Last updated August 2024
              </Typography>
            </div>
          </div>
          <div>
            <IconButton style={{ marginLeft: '23rem' }} onClick={handleCloseDialog}>
              <img src={Close} alt='Close' />
            </IconButton>
          </div>
        </div>
        <div>
          <hr
            style={{
              margin: 0,
              width: '40rem',
              background: 'var(--Secondary-colors-Grey-2, #E6E7E8)',
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: 'auto',
          maxHeight: '100%',
          padding: '2rem',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
        <div style={{ width: '39.79413rem', paddingTop: '10px' }}>
          <Typography className='body2' paragraph>
            These Terms of Service (&quot;Terms&quot;) govern your use of the Talent Acquisition and
            Development services (&quot;Services&quot;) provided by &nbsp;
            <span style={{ fontWeight: '600' }}>PeopleTech - A Quest Global Company</span>
            (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). By accessing or using our
            Services, you agree to comply with and be bound by these Terms. Please read them
            carefully.
          </Typography>
          <Typography className='custom-typography'>1. Acceptance of Terms</Typography>
          <Typography variant='body2' paragraph>
            By accessing or using our Services, you agree to be bound by these Terms and any
            additional guidelines, rules, or amendments that we may post from time to time. If you
            do not agree with these Terms, you should not use the Services.
          </Typography>
          <Typography className='custom-typography'>2. Services Provided</Typography>
          <Typography variant='body2' paragraph>
            Our Services are designed to assist with the recruitment, hiring, and development of
            talent for businesses. The specific services include but are not limited to:
          </Typography>
          <ul style={{ margin: 0 }}>
            <li>
              <Typography variant='body2'>Job posting and requisition management</Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Candidate sourcing, screening, and interviewing
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>Skills assessments and background checks</Typography>
            </li>
            <li>
              <Typography variant='body2'>Employee onboarding and training development</Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Career development and performance management tools
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Analytics and reporting on talent acquisition and development
              </Typography>
            </li>
          </ul>
          <Typography className='custom-typography'>3. User Responsibilities</Typography>
          <Typography variant='body2' paragraph>
            You agree to:
          </Typography>
          <ul style={{ margin: 0 }}>
            <li>
              <Typography variant='body2'>
                Provide accurate, current, and complete information as required by the Services.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Use the Services solely for legitimate recruitment, talent acquisition, and
                development purposes.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Comply with all applicable laws, regulations, and industry standards.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Protect the confidentiality of your login information and promptly notify us of any
                unauthorized use.
              </Typography>
            </li>
          </ul>
          <Typography className='custom-typography'>4. Prohibited Uses</Typography>
          <Typography variant='body2' paragraph>
            You may not use the Services to:
          </Typography>
          <ul style={{ margin: 0 }}>
            <li>
              <Typography variant='body2'>
                Post or transmit false, misleading, or illegal job descriptions or employment
                opportunities.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Discriminate based on race, color, religion, sex, national origin, age, disability,
                genetic information, or any other legally protected characteristic.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Share candidate information with unauthorized third parties or for purposes not
                related to recruitment and talent development.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Upload malicious software, viruses, or any code that may harm or disrupt the
                Services.
              </Typography>
            </li>
          </ul>
          <Typography className='custom-typography'>5. Privacy and Data Protection</Typography>
          <Typography variant='body2' paragraph>
            We take data privacy seriously. Any personal data collected during the recruitment and
            development processes will be handled in accordance with our Privacy Policy. You are
            responsible for ensuring that any data you collect or process using our Services
            complies with applicable data protection laws, including GDPR, CCPA, and others.
          </Typography>
          <Typography className='custom-typography'>6. Payment and Fees</Typography>
          <Typography variant='body2' paragraph>
            Certain aspects of the Services may require payment. The fees for these Services will be
            clearly outlined on our website or communicated to you directly. Payments are
            non-refundable unless explicitly stated otherwise. Failure to make timely payments may
            result in suspension or termination of your access to paid features.
          </Typography>
          <Typography className='custom-typography'>7. Intellectual Property</Typography>
          <Typography variant='body2' paragraph>
            All content, trademarks, and materials related to the Services are the intellectual
            property of PeopleTech - A Quest Global Company. You agree not to reproduce, distribute,
            or modify any part of the Services without our express written consent.
          </Typography>
          <Typography className='custom-typography'>8. Termination</Typography>
          <Typography variant='body2' paragraph>
            We reserve the right to terminate or suspend your access to the Services at our
            discretion, with or without cause, for any reason including but not limited to:
          </Typography>
          <ul style={{ margin: 0 }}>
            <li>
              <Typography variant='body2'>Violation of these Terms</Typography>
            </li>
            <li>
              <Typography variant='body2'>Misuse of the Services</Typography>
            </li>
            <li>
              <Typography variant='body2'>Non-payment of fees</Typography>
            </li>
          </ul>
          <Typography variant='body2' paragraph>
            You may terminate your account at any time by providing written notice to us.
          </Typography>
          <Typography className='custom-typography'>9. Disclaimer of Warranties</Typography>
          <Typography variant='body2' paragraph>
            The Services are provided &quot;as-is&quot; and &quot;as available&quot; without any
            warranties of any kind, either express or implied, including but not limited to implied
            warranties of merchantability, fitness for a particular purpose, or non-infringement. We
            do not warrant that:
          </Typography>
          <ul style={{ margin: 0 }}>
            <li>
              <Typography variant='body2'>
                The Services will meet your requirements or be uninterrupted, secure, or error-free.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Any information provided will be accurate, reliable, or complete.
              </Typography>
            </li>
          </ul>
          <Typography className='custom-typography'>10. Limitation of Liability</Typography>
          <Typography variant='body2' paragraph>
            To the maximum extent permitted by law, we shall not be liable for any indirect,
            incidental, special, consequential, or punitive damages, or any loss of profits or
            revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill,
            or other intangible losses arising out of:
          </Typography>
          <ul style={{ margin: 0 }}>
            <li>
              <Typography variant='body2'>Your use of or inability to use the Services.</Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Unauthorized access, use, or alteration of your data.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Any interaction with third-party vendors, clients, or candidates using our Services.
              </Typography>
            </li>
          </ul>
          <Typography className='custom-typography'>11. Indemnification</Typography>
          <Typography variant='body2' paragraph>
            You agree to indemnify and hold <strong>PeopleTech - A Quest Global Company</strong>{' '}
            harmless from any claims, losses, damages, liabilities, costs, or expenses (including
            legal fees) <br></br>arising from:
          </Typography>
          <ul style={{ margin: 0 }}>
            <li>
              <Typography variant='body2'>Your use or misuse of the Services.</Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Your violation of any laws or third-party rights.
              </Typography>
            </li>
            <li>
              <Typography variant='body2'>
                Any breach of these Terms by you or your organization..
              </Typography>
            </li>
          </ul>
          <Typography className='custom-typography'>12. Governing Law</Typography>
          <Typography variant='body2' paragraph>
            These Terms will be governed by and construed in accordance with the laws of <br></br>
            <strong>Hyderabad, Telangana,</strong> without regard to its conflict of laws
            principles. Any legal actions or proceedings related to these Terms shall be brought
            exclusively in the courts located within <strong>Hyderabad, Telangana.</strong>
          </Typography>
          <Typography className='custom-typography'>13. Amendments</Typography>
          <Typography variant='body2' paragraph>
            We reserve the right to amend or modify these Terms at any time by posting the updated
            version on our website. Your continued use of the Services after any changes constitute
            your acceptance of the revised Terms.
          </Typography>
          <Typography className='custom-typography'>14. Contact Information</Typography>
          <Typography variant='body2' paragraph>
            If you have any questions or concerns about these Terms or our Services, please<br></br>
            contact us at: <strong>PeopleTech - A Quest Global Company</strong>
          </Typography>
          <Typography variant='body2' paragraph>
            <br></br>
            <a className='itsupport-email' href='mailto:ITSupport@peopletech.com'>
              ITSupport@peopletech.com
            </a>
            <br />
            <strong> PeopleTech IT Consultancy Private Limited</strong>
            <br />
            Hyderabad
            <br />
            RMZ Futura Block B,<br></br>Plot no. 14 & 15, Phase 2, <br></br>HITEC City, Hyderabad -
            500081
          </Typography>
        </div>
      </DialogContent>
    </>
  );
};

export default TermsOfService;
