import { useState, useEffect } from 'react';
import { getMyActiveInterviews } from '../../service/dashboardService';
import { Loader } from '../common/loader';
import {
  Link,
  Pagination,
  PaginationItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Stack, styled } from '@mui/system';
import useNavigate from '../../common/useNavigate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import dateFormat from 'dateformat';
import { INTERVIEW_RESULT } from '../../constants/common';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const InterviewScheduleTable = () => {
  const [filter, setFilter] = useState('active');
  const [data, setData] = useState([] as any[]);
  const [selectedInterview, setSelectedInterview] = useState([] as any[]);
  const [interviewsToDisplay, setInterviewsToDisplay] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const resultsPerPage = 10;
  const [navigate] = useNavigate();

  const isActiveInterview = (i: any) =>
    (i.interviewResult == null &&
      new Date(dateFormat(new Date(i.endDate), 'isoDate')) >=
        new Date(dateFormat(new Date(), 'isoDate'))) ||
    i.interviewResult == INTERVIEW_RESULT.ON_HOLD;
  const isCompletedInterview = (i: any) =>
    i.interviewResult != null && i.interviewResult != INTERVIEW_RESULT.ON_HOLD;
  const isExpiredInterview = (i: any) =>
    new Date(dateFormat(new Date(i.endDate), 'isoDate')) <
      new Date(dateFormat(new Date(), 'isoDate')) && i.interviewResult == null;

  useEffect(() => {
    setLoading(true);
    getMyActiveInterviews()
      .then((res) => {
        setData([...res.data]);
        const activeInterviews = res.data.filter(isActiveInterview);
        setSelectedInterview(activeInterviews);
        setInterviewsToDisplay(activeInterviews.slice(0, resultsPerPage));
      })
      .catch((_err) => {})
      .finally(() => setLoading(false));
  }, []);

  const handleFilterSelect = (value: string, pageNo: number) => {
    const sortedData = data.slice().sort((a: any, b: any) => {
      const dateA = a.startDate ? new Date(a.startDate).getTime() : 0;
      const dateB = b.startDate ? new Date(b.startDate).getTime() : 0;
      return dateB - dateA;
    });
    setCurrPage(pageNo);
    const start = (pageNo - 1) * resultsPerPage;
    let temp = [];
    console.log(sortedData.filter(isExpiredInterview));
    temp =
      value === 'expired'
        ? sortedData.filter(isExpiredInterview)
        : value === 'completed'
        ? sortedData.filter(isCompletedInterview)
        : sortedData.reverse().filter(isActiveInterview);

    setSelectedInterview(temp);
    setInterviewsToDisplay(temp.slice(start, start + resultsPerPage));
  };

  const redirectToCandidatePage = (data: any) => {
    navigate(
      [
        {
          label: 'Job Definitions',
          link: '/source-job-definition',
        },
        {
          label: `${data['sjdId']} - ${data['sjdName']}`,
          link: `/source-job-definition/${btoa(data['sjdId'])}`,
        },
        {
          label: `${data['candidateName']}`,
          link: `/source-job-definition/candidate/${btoa(data['sjdId'])}/${btoa(
            data['candidateId'],
          )}`,
        },
      ],
      true,
    );
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F9F9F9',
      borderBottom: '2px solid #DEDEDE',
    },
  }));
  return (
    <>
      <Stack spacing={1}>
        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h6' fontWeight={700}>
            Your Interviews
          </Typography>
          <RadioGroup
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='row-radio-buttons-group'
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
              handleFilterSelect(e.target.value, 1);
            }}>
            <FormControlLabel value='active' control={<Radio />} label='Upcoming' />
            <FormControlLabel value='completed' control={<Radio />} label='Completed' />
            <FormControlLabel value='expired' control={<Radio />} label='Expired' />
          </RadioGroup>
        </Grid>
      </Stack>
      <TableContainer className='qc-table'>
        <Table sx={{ background: 'white' }} size='small'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Candidate</StyledTableCell>
              <StyledTableCell>Source Definition</StyledTableCell>
              <StyledTableCell>Interview Level</StyledTableCell>
              <StyledTableCell>Scheduled</StyledTableCell>
              <StyledTableCell>Scheduled By</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={4} sx={{ color: 'grey.500' }}>
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {!loading && interviewsToDisplay.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center', color: 'black' }}>
                  <Typography variant='body1' style={{ marginTop: '10px', marginBottom: '10px' }}>
                    {
                      // eslint-disable-next-line quotes
                      "There's nothing here!"
                    }
                    <SentimentSatisfiedAltIcon
                      fontSize='small'
                      style={{ verticalAlign: 'middle', fontSize: '20px', marginLeft: '5px' }}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              interviewsToDisplay.length > 0 &&
              interviewsToDisplay.map((d, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link sx={{ cursor: 'pointer' }} onClick={() => redirectToCandidatePage(d)}>
                      {d.candidateName}
                    </Link>
                  </TableCell>
                  <TableCell>{d.sjdName}</TableCell>
                  <TableCell>{d.interviewLevel}</TableCell>
                  <TableCell>
                    {dateFormat(new Date(d.startDate), 'dd/mm/yyyy')}
                    <br />
                    {dateFormat(new Date(d.startDate), 'hh:MM TT')}
                    {' - '}
                    {dateFormat(new Date(d.endDate), 'hh:MM TT')}
                  </TableCell>
                  <TableCell>{d.createdByName}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedInterview.length > resultsPerPage && (
        <Pagination
          page={currPage}
          count={
            Math.floor(selectedInterview.length / resultsPerPage) +
            (selectedInterview.length % resultsPerPage == 0 ? 0 : 1)
          }
          onChange={(_e, v) => handleFilterSelect(filter, v)}
          renderItem={(item) => (
            <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
          )}
        />
      )}
    </>
  );
};

export default InterviewScheduleTable;
