import React, { useEffect, useState } from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../assets/dashboard-assets/cancel.svg';
import { ReactComponent as MoreIcon } from '../../assets/dashboard-assets/more_vert.svg';

const TadCandidateQuestionnaire = () => {
  const [selectedOptions, setSelectedOptions] = useState<Record<number, number | undefined>>({});
  const [score, setScore] = useState(0);
  const [handleScoreResult, sethandleScoreResult] = useState(false);

  const questionData = {
    jdTitle: 'HTML Hyperlink Creation',
    questions: [
      {
        questionNumber: 1,
        question: 'How do you create a hyperlink in HTML?',
        options: [
          { text: '<link>Example</link>', correct: false },
          { text: '<a href="www.example.com">Example</a>', correct: true },
          { text: '<a href="www.example.com">Example</a>', correct: false },
          { text: 'www.example.com Example', correct: false },
        ],
      },
      {
        questionNumber: 2,
        question: 'What is the correct HTML element for inserting a line break?',
        options: [
          { text: '<br>', correct: true },
          { text: '<break>', correct: false },
          { text: '<lb>', correct: false },
          { text: '<newline>', correct: false },
        ],
      },
      {
        questionNumber: 3,
        question: 'How do you create a hyperlink in HTML?',
        options: [
          { text: '<link>Example</link>', correct: false },
          { text: '<a href="www.example.com">Example</a>', correct: true },
          { text: '<a href="www.example.com">Example</a>', correct: false },
          { text: 'www.example.com Example', correct: false },
        ],
      },
      {
        questionNumber: 4,
        question: 'How do you create a hyperlink in HTML?',
        options: [
          { text: '<link>Example</link>', correct: false },
          { text: '<a href="www.example.com">Example</a>', correct: true },
          { text: '<a href="www.example.com">Example</a>', correct: false },
          { text: 'www.example.com Example', correct: false },
        ],
      },
      {
        questionNumber: 5,
        question: 'How do you create a hyperlink in HTML?',
        options: [
          { text: '<link>Example</link>', correct: false },
          { text: '<a href="www.example.com">Example</a>', correct: true },
          { text: '<a href="www.example.com">Example</a>', correct: false },
          { text: 'www.example.com Example', correct: false },
        ],
      },
    ],
  };

  const optionStyle = (isCorrect: boolean): React.CSSProperties => ({
    height: '3.3125rem',
    flexShrink: 0,
    borderRadius: '0.3125rem',

    background: isCorrect
      ? 'var(--Status-Green-100, #C7E8C5)'
      : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  });

  //   const handleChange = (_event: any) => {
  //     const { name, value, type, checked } = event.target;
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [name]: type === 'checkbox' ? checked : value,
  //     }));
  //   };

  //   const handleSubmit = async (event: any) => {
  //     event.preventDefault();

  //     try {
  //       const response = await axios.post('https://jsonplaceholder.typicode.com/users', formData);
  //       console.log('Response from API:', response.data);
  //     } catch (error) {
  //       console.error('Error submitting form:', error);
  //     }
  //   };

  useEffect(() => {
    calculateScore();
  }, [selectedOptions]);

  const handleOptionClick = (questionNumber: number, index: number) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [questionNumber]: index,
    }));
  };

  const calculateScore = () => {
    let finalScore = 0;
    questionData.questions.forEach((question) => {
      const selectedOption = selectedOptions[question.questionNumber];
      if (selectedOption !== undefined) {
        const isCorrect = question.options[selectedOption]?.correct;
        if (isCorrect) finalScore++;
      }
    });
    setScore(finalScore);
  };

  const handlescore = () => {
    sethandleScoreResult(true);
  };

  return (
    <div>
      <Paper>
        <div className='header-container'>
          <div className='header-content-title'>
            <span>Questionnaire</span>
          </div>
          <div className='header-content-icon'>
            <IconButton
            // onClick={() => (edit == false ? setEdit(true) : setEdit(false))}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </div>
        <div
          className='containerStyle'
          style={{
            padding: '1.5rem',
            maxHeight: '800px',
            overflow: 'scroll',
            paddingRight: '15px',
          }}>
          <div style={{ textAlign: 'left' }}>
            {/* <Tabs className='tab' value={selectedTab} onChange={(_e, v) => setSelectedTab(v)}>
            {tabsHeaders.map((header, index) => (
              <Tab
                sx={{
                  color: 'var(--Primary-Dark-grey-500, #333)',
                  fontFamily: 'Roboto',
                  fontSize: '0.7625rem',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '1.5625rem',
                  textTransform: 'none',
                  borderBottom: '2px solid transparent',
                  '&.Mui-selected': {
                    borderBottom: '5px solid var(--Primary-Blue-500, #1976D2)',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    fontWeight: 600,
                  },
                }}
                key={index}
                value={header}
                label={header}
              />
            ))}
          </Tabs> */}
          </div>

          {handleScoreResult && (
            <div
              style={{
                height: '7.4375rem',
                border: (() => {
                  if (score === 1 || score === 2) return '1px solid #DC3857'; // Red for score 1 and 2
                  if (score === 3) return '1px solid #1976D2'; // Blue for score 3
                  return '1px solid #4BB543'; // Green for score 4 and 5
                })(),
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '0.3125rem',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#FFF',
              }}>
              <div>
                <div style={{ display: 'flex', marginLeft: '1.38rem', marginTop: '1.25rem' }}>
                  {score === 1 && <CancelIcon />}
                  {score === 2 && <CancelIcon />}
                  {/* {score === 3 && (
                  <img
                    src=
                    {require('../../assets/dashboard-assets/info.png')}
                    alt='Average'
                    style={{
                      width: '1.5rem',
                      height: '1.5rem',
                      borderRadius: '0.3125rem',
                      marginRight: '0.5rem',
                    }}
                  />
                )
                } */}
                  {/* {score >= 4 && score <= 5 && (
                  <img
                    src={require('../../assets/dashboard-assets/check_circle.png')

                    }
                    alt='Good or Excellent'
                    style={{
                      width: '1.5rem',
                      height: '1.5rem',
                      borderRadius: '0.3125rem',
                      marginRight: '0.5rem',
                    }}
                  />
                )} */}
                  <span
                    style={{
                      color: (() => {
                        if (score === 1 || score === 2) return '#DC3857';
                        if (score === 3) return '#1976D2';
                        return '#4BB543';
                      })(),
                      fontFamily: 'Roboto',
                      fontSize: '1rem',
                      fontWeight: 600,
                      lineHeight: '1.5625rem',
                    }}>
                    {score === 1 && '1: Poor'}
                    {score === 2 && '2: Below Average'}
                    {score === 3 && '3: Average'}
                    {score === 4 && '4: Good'}
                    {score === 5 && '5: Excellent'}
                  </span>
                </div>
                <div style={{ marginLeft: '3.25rem' }}>
                  <Typography
                    style={{
                      width: '29.5rem',
                      height: '2.9375rem',
                      color: (() => {
                        if (score === 1 || score === 2) return '#DC3857';
                        if (score === 3) return '#1976D2';
                        return '#4BB543';
                      })(),
                      fontFamily: 'Roboto',
                      fontSize: '1rem',
                      fontWeight: 400,
                      lineHeight: '1.5625rem',
                    }}>
                    {score === 1 &&
                      'Very limited understanding or proficiency in technical skills.'}
                    {score === 2 &&
                      'Some understanding but lacks key competencies; needs significant improvement.'}
                    {score === 3 &&
                      'Adequate proficiency with room for improvement; basic technical skills are present.'}
                    {score === 4 &&
                      'Strong technical skills with a solid grasp of necessary competencies.'}
                    {score === 5 &&
                      'Exceptional technical skills, showing a high level of expertise.'}
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: '1.5rem',
                  marginRight: '1.56rem',
                  marginBottom: '1.5rem',
                }}>
                <Gauge
                  value={(score / questionData.questions.length) * 100}
                  startAngle={-180}
                  endAngle={180}
                  sx={{
                    width: '4.4375rem',
                    height: '4.4375rem',
                    flexShrink: 0,
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: '1rem',
                      color: (() => {
                        if (score <= 3) return '#DC3857';
                        if (score <= 6) return '#1976D2';
                        return '#4BB543';
                      })(),
                      fontFamily: 'Roboto',
                      fontWeight: 600,
                      lineHeight: '1.5625rem',
                      fill: (() => {
                        if (score <= 3) return '#DC3857';
                        if (score <= 6) return '#1976D2';
                        if (score <= 10) return '#4BB543';
                        return '#0000ff';
                      })(),
                    },
                  }}
                  text={() => `${score}/${questionData.questions.length}`}
                />
              </div>
            </div>
          )}

          <div>
            <div>
              {questionData.questions.map((question) => (
                <div key={question.questionNumber} style={{ marginBottom: '2rem' }}>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    style={{
                      color: '#333',
                      fontFamily: 'Roboto',
                      fontSize: '1rem',
                      fontWeight: 400,
                      lineHeight: '1.5625rem',
                    }}>
                    Q{question.questionNumber}: {question.question}
                  </Typography>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                    }}>
                    {question.options.map((option, index) => {
                      const isSelected = selectedOptions[question.questionNumber] === index;
                      const isCorrect = option.correct;

                      return (
                        <div
                          key={index}
                          style={optionStyle(isSelected)}
                          onClick={() => handleOptionClick(question.questionNumber, index)}>
                          <Typography
                            variant='body1'
                            style={{
                              color: '#333',
                              fontFamily: 'Roboto',
                              fontSize: '1rem',
                              fontWeight: 400,
                              lineHeight: '1.5625rem',
                              margin: 0,
                            }}>
                            {String.fromCharCode(65 + index)}. {option.text}
                          </Typography>
                          {isSelected && (
                            <>
                              {/* <img
                              src={require('../../assets/dashboard-assets/' +
                                (isCorrect ? 'check_circle_outline.png' : 'highlight_off.png'))}
                              alt={isCorrect ? 'Correct' : 'Incorrect'}
                              style={{
                                width: '1.5rem',
                                height: '1.5rem',
                                borderRadius: '0.3125rem',
                              }}
                            /> */}
                            </>
                          )}

                          {isSelected != isCorrect && (
                            <>
                              {/* <img
                              src={require('../../assets/dashboard-assets/' +
                                (isCorrect ? 'check_circle_outline.png' : 'highlight_off.png'))}
                              alt={isCorrect ? 'Correct' : 'Incorrect'}
                              style={{
                                width: '1.5rem',
                                height: '1.5rem',
                                borderRadius: '0.3125rem',
                              }}
                            /> */}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: 'auto',
                  marginRight: '1rem',
                  marginBottom: '1.5rem',
                }}>
                <Button
                  style={{
                    width: '7.5rem',
                    height: 'var(--Font-size-h2, 2.5rem)',
                    flexShrink: 0,
                    borderRadius: '0.3125rem',
                    border: '1px solid #2D7DFE',
                    background: 'var(--Primary-Blue-500, #1976D2)',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  onClick={handlescore}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default TadCandidateQuestionnaire;
