import React from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { Select, MenuItem, Box } from '@mui/material';

const OfferAcceptanceRate = () => {
  const data = [
    { month: 'Jan', barValue: 10, lineValue: 40 },
    { month: 'Feb', barValue: 20, lineValue: 30 },
    { month: 'Mar', barValue: 30, lineValue: 10 },
    { month: 'Apr', barValue: 20, lineValue: 30 },
    { month: 'May', barValue: 20, lineValue: 40 },
    { month: 'June', barValue: 50, lineValue: 20 },
    { month: 'July', barValue: 60, lineValue: 20 },
    { month: 'Aug', barValue: 30, lineValue: 10 },
    { month: 'Sep', barValue: 20, lineValue: 50 },
    { month: 'Oct', barValue: 10, lineValue: 60 },
    { month: 'Nov', barValue: 100, lineValue: 20 },
    { month: 'Dec', barValue: 100, lineValue: 10 },
  ];

  const formatYAxis: (value: any) => string = (value) => `${value}%`;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '-40px',
          marginBottom: '25px',
          padding: '0 20px',
          maxWidth: '1000px',
          marginLeft: '10px',
          marginRight: 'auto',
          gap: '1rem',
        }}>
        <Box display='flex' gap='1rem'>
          <Select
            sx={{ width: '182px', height: '30px' }}
            size='small'
            labelId='time-period-select'
            name='timePeriod'
            defaultValue={2024}>
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
          </Select>
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '150px',
          height: '400px',
          width: '100%',
          position: 'relative',
        }}>
        <Box sx={{ width: '90%', height: '100%' }}>
          <ResponsiveContainer width='100%' height='100%'>
            <ComposedChart
              data={data}
              style={{ marginLeft: '25px' }}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray='none' />
              <XAxis dataKey='month' />
              <YAxis orientation='right' domain={[0, 100]} tickCount={6} tickFormatter={formatYAxis} />
              <Tooltip formatter={(value) => `${value}%`} />
              <Bar dataKey='barValue' fill='#8CD6FF' barSize={15}>
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} />
                ))}
              </Bar>
              <Line type='linear' dataKey='lineValue' stroke='#FF5959' dot={false} />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </div>
    </div>
  );
};

export default OfferAcceptanceRate;
