import { Paper, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material';
import '../source-job-definition.scss';
import { useFormik, FormikProps } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import globalObject from '../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
import { SourcingStrategy } from '../../../models/sjd-form.model';
import Autocomplete from '@mui/material/Autocomplete';
import { getLabel, getNameFromId, isPermissionsWrite } from './../../../common/commonUtil';
import SectionHeaderWithEdit from '../../common/section-heading';
import { MODULE, SubModuleTypes } from '../../../constants/common';

interface SjdSourcingFormProps {
  save: (values: SourcingStrategy) => Promise<boolean>;
  previous?: () => void;
  mode?: 'create-page' | 'details-page';
  values: SourcingStrategy | undefined;
}

const SourcingStrategyForm: React.FC<SjdSourcingFormProps> = ({
  save,
  previous = () => {},
  mode = 'create-page',
  values,
}: SjdSourcingFormProps) => {
  const sourcingDetailsSchema = Yup.object().shape({
    sourcingChannels: Yup.array()
      .of(Yup.object())
      .min(1, 'Sourcing Channel is required')
      .required(),
    experienceLevel: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .min(0, 'Value must be positive')
      .required('Min. Experience is required'),
    maxExperience: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .min(0, 'Value must be positive'),
    qualificationId: Yup.number().required('Highest education is required'),
    noticePeriod: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .positive('The value must be positive')
      .required('Notice Period is required'),
    keywords: Yup.string().max(500, 'Keywords should not exceed 500 characters').nullable(),
    targetCompanies: Yup.string()
      .max(500, 'Target Companies should not exceed 500 charcters')
      .nullable(),
  });
  const formik = useFormik({
    initialValues: values || {
      sourcingChannels: undefined,
      experienceLevel: undefined,
      maxExperience: undefined,
      qualificationId: undefined,
      keywords: undefined,
      noticePeriod: undefined,
      targetCompanies: undefined,
    },
    onSubmit: (values) => {
      save(values).then(() => setEdit(mode === 'create-page'));
    },
    validationSchema: sourcingDetailsSchema,
  });
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode === 'create-page');

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  return (
    <>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        {mode === 'details-page' && (
          <SectionHeaderWithEdit
            text='Sourcing Strategy'
            edit={edit}
            hideIcon={!isPermissionsWrite(MODULE.SJD, SubModuleTypes.JD_SOURCING_STRATEGY)}
            click={() => {
              if (edit) {
                setSubmitted(true);
                formik.handleSubmit();
              } else {
                setEdit(!edit);
              }
            }}
          />
        )}
        <form
          style={{ marginBottom: '10px' }}
          onSubmit={(values) => {
            setSubmitted(true);
            formik.handleSubmit(values);
          }}>
          <div className='row'>
            <div className='field-by-4'>
              <InputLabel className='field-label' id='sourcing-channel'>
                Sourcing Channel *
              </InputLabel>
              {edit ? (
                <div>
                  <Autocomplete
                    multiple
                    id='combo-box-demo'
                    options={globalObject.lookupvalues[LookupTypes.SOURCE_CHANNEL]}
                    sx={{ width: 300 }}
                    size='small'
                    limitTags={2}
                    getOptionLabel={(option: any) => option.name}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('sourcingChannels', true, true)}
                    defaultValue={values?.sourcingChannels ? values.sourcingChannels : []}
                    renderInput={(params: any) => <TextField {...params} label='' />}
                    onChange={(_e: any, val: any[]) =>
                      formik.setFieldValue('sourcingChannels', val)
                    }
                  />
                </div>
              ) : (
                <span style={{ overflowWrap: 'anywhere' }}>
                  {formik.values.sourcingChannels?.map((s) => s.name).join(', ')}
                </span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'sourcingChannels')}</div>
            </div>
            <div className='field-by-4'>
              <InputLabel className='field-label' id='exp-level'>
                Min. Experience (years) *
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.experienceLevel}
                  fullWidth
                  id='exp-level'
                  name='experienceLevel'
                  size='small'
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\s/g, '');
                    formik.handleChange({
                      target: { name: 'experienceLevel', value: numericValue },
                    });
                  }}
                  onBlur={formik.handleBlur}
                  inputProps={{ inputMode: 'numeric' }}
                />
              ) : (
                <span>{getLabel(formik.values.experienceLevel)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'experienceLevel')}</div>
            </div>
            <div className='field-by-4'>
              <InputLabel className='field-label' id='exp-level'>
                Max. Experience (years)
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.maxExperience}
                  fullWidth
                  id='maxExperience'
                  name='maxExperience'
                  size='small'
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\s/g, '');
                    formik.handleChange({ target: { name: 'maxExperience', value: numericValue } });
                  }}
                  onBlur={formik.handleBlur}
                  inputProps={{ inputMode: 'numeric' }}
                />
              ) : (
                <span>{getLabel(formik.values.maxExperience)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'maxExperience')}</div>
            </div>
            <div className='field-by-4'>
              <InputLabel className='field-label' id='notie-period-input'>
                Notice Period (days) *
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.noticePeriod}
                  fullWidth
                  id='notie-period-input'
                  name='noticePeriod'
                  size='small'
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\s/g, '');
                    formik.handleChange({ target: { name: 'noticePeriod', value: numericValue } });
                  }}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.noticePeriod)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'noticePeriod')}</div>
            </div>
          </div>
          <div className='row'>
            <div className='field-by-4'>
              <InputLabel className='field-label' id='high-education-select'>
                Minimum Qualification *
              </InputLabel>
              {edit ? (
                <Select
                  size='small'
                  fullWidth
                  labelId='high-education-select'
                  value={formik.values.qualificationId}
                  name='qualificationId'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id='high-education-select'>
                  <MenuItem disabled selected>
                    Select
                  </MenuItem>
                  {globalObject.lookupvalues[LookupTypes.QUALIFICATION].map(
                    (qualification: any, index: number) => (
                      <MenuItem key={index} value={qualification.id}>
                        {qualification.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              ) : (
                <span>
                  {getLabel(
                    getNameFromId(formik.values.qualificationId, LookupTypes.QUALIFICATION),
                  )}
                </span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'qualificationId')}</div>
            </div>
            <div className='field-by-4'>
              <InputLabel className='field-label' id='keywords'>
                Keywords
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.keywords}
                  fullWidth
                  id='keywords'
                  name='keywords'
                  size='small'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.keywords)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'keywords')}</div>
            </div>
            <div className='field-by-4'>
              <InputLabel className='field-label' id='target-companies'>
                Target Companies
              </InputLabel>
              {edit ? (
                <TextField
                  value={formik.values.targetCompanies}
                  fullWidth
                  id='target-companies'
                  name='targetCompanies'
                  size='small'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <span>{getLabel(formik.values.targetCompanies)}</span>
              )}
              <div className='mt-0 form-err-msg'>{getError(formik, 'targetCompanies')}</div>
            </div>
            <div className='field-by-4'></div>
          </div>
        </form>
      </Paper>
      {mode === 'create-page' && (
        <div className='bottom-btn-block'>
          <Button sx={{ marginRight: 3 }} onClick={() => previous()} className='default-btn bg-btn'>
            Previous
          </Button>
          <Button
            type='submit'
            sx={{ marginLeft: 3 }}
            onClick={() => {
              setSubmitted(true);
              formik.handleSubmit();
            }}
            className='default-btn bg-btn'>
            Next
          </Button>
        </div>
      )}
    </>
  );
};
export default SourcingStrategyForm;
