import React from 'react';

import './tad-timeline.scss';
import { ReactComponent as GroupIcon } from '../../../assets/sjd/Group.svg';
import { ReactComponent as PauseIcon } from '../../../assets/sjd/pause.svg';
import { ReactComponent as ArrowForwardIcon } from '../../../assets/sjd/arrow_forward.svg';
import { ReactComponent as CalendarMonthIcon } from '../../../assets/sjd/date_range.svg';
import { ReactComponent as QuestionnaireIcon } from '../../../assets/sjd/questionnaire.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/sjd/remove_circle.svg';
import { ReactComponent as CallbackIcon } from '../../../assets/sjd/contact_phone.svg';
import { ReactComponent as CandidateCreated } from '../../../assets/sjd/person_add.svg';
import { ReactComponent as AssignmentTurnedInIcon } from '../../../assets/sjd/assignment_turned_in.svg';
import { ReactComponent as StarBorderIcon } from '../../../assets/sjd/star_border.svg';
import { ReactComponent as CommentIcon } from '../../../assets/sjd/comment.svg';
import { ReactComponent as CancelIcon } from '../../../assets/sjd/cancel.svg';
import { ReactComponent as PauseCircleIcon } from '../../../assets/sjd/pause_circle.svg';
import { ReactComponent as LocalOfferIcon } from '../../../assets/sjd/local_offer.svg';
import { ReactComponent as LabelOffIcon } from '../../../assets/sjd/label_off.svg';
import { ReactComponent as MapsUgcIcon } from '../../../assets/sjd/add_location_alt.svg';

import { Tooltip } from '@mui/material';
import dateFormat from 'dateformat';
import { LookupTypes } from '../../../../common/lookupTypes';
import { getActivityEventName, getNameFromId } from '../../../common/commonUtil';

export interface EventProps {
  eventTypeId: number;
  description: string;
  createdBy: string;
  createdOn: string;
}

interface TimeLineProps {
  events: EventProps[];
  parent: 'sjd' | 'candidate';
}

const TadTimeLine = ({ events, parent }: TimeLineProps) => {
  const candidateIconMap = new Map();
  candidateIconMap.set('CANDIDATE_CREATED', {
    icon: <CandidateCreated fontSize='medium' />,
    descriptionLabel: 'Candidate Id: ',
  });
  candidateIconMap.set('CANDIDATE_TAGGED_TO_SJD', {
    icon: <LocalOfferIcon fontSize='medium' />,
    descriptionLabel: 'SJD Id: ',
  });
  candidateIconMap.set('CANDIDATE_UNTAGGED_FROM_SJD', {
    icon: <LabelOffIcon fontSize='medium' />,
    descriptionLabel: 'SJD Id: ',
  });
  candidateIconMap.set('RESUME_UPDATED', {
    icon: <GroupIcon fontSize='medium' />,
    descriptionLabel: '',
  });
  candidateIconMap.set('CANDIDATE_STATUS_CHANGED', {
    icon: <ArrowForwardIcon fontSize='medium' />,
    descriptionLabel: 'New Status: ',
  });
  candidateIconMap.set('CANDIDATE_DETAILS_UPDATED', {
    icon: <AssignmentTurnedInIcon fontSize='medium' />,
    descriptionLabel: '',
  });
  candidateIconMap.set('COMMENTS_ADDED', {
    icon: <MapsUgcIcon fontSize='medium' />,
    descriptionLabel: 'Comment:  ',
  });
  candidateIconMap.set('QC_RATING_ADDED', {
    icon: <StarBorderIcon fontSize='medium' />,
    descriptionLabel: 'Rating: ',
  });
  candidateIconMap.set('FEEDBACK_ADDED', {
    icon: <CommentIcon fontSize='medium' />,
    descriptionLabel: 'Comments: ',
  });
  candidateIconMap.set('PROFILER_RATING_ADDED', {
    icon: <StarBorderIcon fontSize='medium' />,
    descriptionLabel: 'Rating: ',
  });
  candidateIconMap.set('CANDIDATE_REJECTED', {
    icon: <CancelIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });
  candidateIconMap.set('NOT_INTERESTED', {
    icon: <RemoveIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });
  candidateIconMap.set('CANDIDATE_DROPPED', {
    icon: <CancelIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });
  candidateIconMap.set('CANDIDATE_ON_HOLD', {
    icon: <PauseCircleIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });
  candidateIconMap.set('QUESTIONNAIRE_ANSWER_UPDATED', {
    icon: <QuestionnaireIcon fontSize='medium' />,
  });
  candidateIconMap.set('INTERVIEW_SCHEDULED', {
    icon: <CalendarMonthIcon fontSize='medium' />,
    descriptionLabel: 'Schedule: ',
  });
  candidateIconMap.set('INTERVIEW_RESCHEDULED', {
    icon: <CalendarMonthIcon fontSize='medium' />,
    descriptionLabel: 'Schedule: ',
  });
  candidateIconMap.set('INTERVIEW_DELETED', {
    icon: <CalendarMonthIcon fontSize='medium' />,
    descriptionLabel: '',
  });
  candidateIconMap.set('CALLBACK_ADDED', {
    icon: <CallbackIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });

  const sjdIconMap = new Map();
  sjdIconMap.set('SJD_CREATED', {
    icon: <GroupIcon fontSize='medium' />,
    descriptionLabel: 'SJD Id: ',
    createdOn: '',
  });
  sjdIconMap.set('SJD_DETAILS_UPDATED', {
    icon: <PauseIcon fontSize='medium' />,
    descriptionLabel: 'SJD Id: ',
  });
  sjdIconMap.set('CANDIDATE_ADDED', {
    icon: <ArrowForwardIcon fontSize='medium' />,
    descriptionLabel: 'Candidate Id: ',
  });
  sjdIconMap.set('CANDIDATE_REMOVED', {
    icon: <RemoveIcon fontSize='medium' />,
    descriptionLabel: 'Candidate Id: ',
  });

  sjdIconMap.set('SJD_STATUS_CHANGED', {
    icon: <ArrowForwardIcon fontSize='medium' />,
    descriptionLabel: 'New Status: ',
  });

  sjdIconMap.set('QUESTIONNAIRE_ATTACHED', {
    icon: <QuestionnaireIcon fontSize='medium' />,
    descriptionLabel: 'Questionnaire Id: ',
  });

  const geticon = (eventTypeId: number) => {
    return parent == 'candidate'
      ? candidateIconMap.get(getNameFromId(eventTypeId, LookupTypes.CANDIDATE_EVENT_TYPE))['icon']
      : sjdIconMap.get(getNameFromId(eventTypeId, LookupTypes.SJD_EVENT_TYPE))['icon'];
  };

  const getDescription = (event: EventProps) => {
    const iconInfo =
      parent == 'candidate'
        ? candidateIconMap.get(getNameFromId(event.eventTypeId, LookupTypes.CANDIDATE_EVENT_TYPE))
        : sjdIconMap.get(getNameFromId(event.eventTypeId, LookupTypes.SJD_EVENT_TYPE));
    let text = '';
    if (iconInfo.descriptionLabel) {
      text = iconInfo.descriptionLabel;
      text =
        text +
        (event.description && event.description.length > 30
          ? event.description.slice(0, 30).concat('...')
          : event.description);
      return (
        <Tooltip title={event.description}>
          <p>{text}</p>
        </Tooltip>
      );
    }
    return <p></p>;
  };

  return (
    <div style={{ overflowY: 'scroll', height: '40rem', marginTop: '2rem', marginBottom: '2rem' }}>
      <table className='tad-table' style={{ height: '40rem' }}>
        <tbody
          style={{
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
          }}>
          {events &&
            events.length &&
            events.map((event, index) => (
              <React.Fragment key={index}>
                <tr className='tad-content-row' style={{ display: 'flex', alignItems: 'center' }}>
                  <td>
                    <p>
                      {parent == 'sjd'
                        ? getActivityEventName(event.eventTypeId, LookupTypes.SJD_EVENT_TYPE)
                        : getActivityEventName(event.eventTypeId, LookupTypes.CANDIDATE_EVENT_TYPE)}
                    </p>
                    {getDescription(event)}
                    <p>on {dateFormat(new Date(event.createdOn), 'dd/mm/yyyy hh:MM TT')}</p>
                  </td>
                  <td>
                    <div className='tad-icon-element'>{geticon(event.eventTypeId)}</div>
                  </td>
                  <td>
                    <p>{event.createdBy}</p>
                  </td>
                </tr>
                <tr className='tad-connector-row'>
                  <td></td>
                  <td>
                    <div></div>
                  </td>
                  <td></td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TadTimeLine;
