import {
  // Avatar,
  Backdrop,
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
} from '@mui/material';
import './new-app-header.scss';
import { useEffect, useState } from 'react';
import { MODULE, TADROLES } from '../../../../constants/common';

import { ReactComponent as DashboardIcon } from '../../../assets/dashboard-assets/dashboard.svg';
import { ReactComponent as SjdIcon } from '../../../assets/dashboard-assets/description.svg';
import { ReactComponent as CandidatesIcon } from '../../../assets/dashboard-assets/Group.svg';
// import { ReactComponent as ReportsIcon } from '../../../assets/dashboard-assets/poll.svg';
// import { ReactComponent as EmployeesIcon } from '../../../assets/dashboard-assets/account_box.svg';
import { ReactComponent as LmsIcon } from '../../../assets/dashboard-assets/lmsIcon.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/dashboard-assets/settings.svg';

import useNavigate from '../../../../common/useNavigate';
import { DatePicker } from '@mui/x-date-pickers';
import CommonMenu from '../../dashboard/common-menu';
import {
  getInitials,
  isPermissions,
  isUserRole,
  redirectToLogin,
  toUpperFirstChar,
} from '../../../../common/commonUtil';
import globalObject from '../../../../common/global-variables';
import { ReactComponent as ToggleOff } from '../../../../assets/toggle_on.svg';
import { ReactComponent as ToggleOn } from '../../../../assets/toggle_on_1.svg';
import { ReactComponent as ToggleOffNewTad } from '../../../assets/dashboard-assets/toggle_off_newtad.svg';
import tadLoader from './../../../assets/common-assets/Whole animation.gif';
import { ReactComponent as SearchIcon } from '../../../assets/dashboard-assets/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/dashboard-assets/close.svg';
import { ReactComponent as EditIcon } from '../../../assets/dashboard-assets/edit.svg';
import { ReactComponent as ExitToAppIcon } from '../../../assets/dashboard-assets/exit_to_app.svg';
import { ReactComponent as SettingCompo } from '../../../assets/dashboard-assets/SettingCompo.svg';
import { ReactComponent as EmailIcon } from '../../../assets/dashboard-assets/email.svg';
import { ReactComponent as DescriptionIcon } from '../../../assets/dashboard-assets/descriptionCompo.svg';
import { ReactComponent as HamburgerMenuIcon } from '../../../assets/dashboard-assets/hamburger-menu-icon.svg';
import { ReactComponent as LogoIcon } from '../../../assets/dashboard-assets/Logo.svg';
// import { ReactComponent as PlusIcon } from '../../../assets/dashboard-assets/plusIcon.svg';
import { ReactComponent as NotificationIcon } from '../../../assets/dashboard-assets/NotificationIcon.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/info.svg';

interface Job {
  id: string;
  title: string;
}

const NewAppHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [filterOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [anchorEl] = useState<null | HTMLElement>(null);
  const [profileAnchorE1, setProfileAnchorE1] = useState<null | HTMLElement>(null);
  const [profileFlag, setProfileFlag] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [dialogOpen, setDialogOPen] = useState(false);
  const [switchTad, setSwitchTad] = useState(false);

  const jobs: Job[] = [
    { id: 'product-manager', title: 'Product Manager' },
    { id: 'enior-php-developer', title: 'Senior PHP Developer' },
    { id: 'devops-engineer', title: 'DevOps Engineer' },
    { id: 'ux-designer', title: 'UX Designer' },
    { id: 'graphic-designer', title: 'Graphic Designer' },
  ];

  const tabs = [
    {
      label: 'DASHBOARD',
      link: '/dashboard',
      module: MODULE.DASHBOARD,
      // roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER, ROLES.EVALUATOR],
      roles: [TADROLES.ADMIN, TADROLES.LEAD, TADROLES.RECRUITER, TADROLES.EVALUATOR],
      icon: DashboardIcon,
    },
    {
      label: 'JOB DEFINITIONS',
      link: '/source-job-definition',
      module: MODULE.SJD,
      roles: [TADROLES.ADMIN, TADROLES.LEAD, TADROLES.RECRUITER],
      icon: SjdIcon,
    },
    {
      label: 'CANDIDATES',
      link: '/candidate',
      module: MODULE.CANDIDATES,
      roles: [TADROLES.ADMIN, TADROLES.LEAD, TADROLES.RECRUITER],
      icon: CandidatesIcon,
    },
    // {
    //   label: 'REPORTS',
    //   link: '/reporting',
    //   module: MODULE.REPORTING,
    //   roles: [TADROLES.ADMIN, TADROLES.LEAD],
    //   icon: ReportsIcon,
    // },
    // {
    //   label: 'EMPLOYEES',
    //   link: '/candidate-search',
    //   module: MODULE.CANDIDATE_SEARCH,
    //   roles: [TADROLES.ADMIN, TADROLES.LEAD, TADROLES.RECRUITER],
    //   icon: EmployeesIcon,
    // },
    // {
    //   label: 'REFERRALS',
    //   link: '/employee-referral',
    //   module: MODULE.EMPLOYEE_REFERRAL,
    //   roles: [TADROLES.ADMIN, TADROLES.LEAD, TADROLES.RECRUITER, TADROLES.EVALUATOR],
    //   icon: CandidatesIcon,
    // },
    {
      label: 'LMS',
      link: '/lms',
      module: MODULE.APPLICANTS,
      roles: [TADROLES.ADMIN, TADROLES.LEAD, TADROLES.RECRUITER],
      icon: LmsIcon,
    },
    {
      label: 'SETTINGS',
      link: '/settings',
      module: MODULE.LMS,
      roles: [TADROLES.EMPLOYEE, TADROLES.HIRING_MANAGER, TADROLES.ADMIN],
      icon: SettingsIcon,
    },
  ];

  const DrawerList = (
    <Box role='presentation' sx={{ height: '100%' }} onClick={() => setMenuOpen(false)}>
      <List sx={{ padding: '2.063rem 0rem 0rem 2.06rem' }}>
        <ListItem
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          disablePadding
          sx={{ marginBottom: '0.5rem', cursor: 'pointer', fontWeight: 400 }}>
          <ListItemIcon>
            <CloseIcon style={{ width: '1.5rem', height: '1.5rem', flexShrink: '0px' }} />
          </ListItemIcon>
        </ListItem>
        {tabs.map((text, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ marginBottom: '0.5rem', cursor: 'pointer', fontWeight: 400 }}
            onClick={() => handleMenuClick(text)}>
            <ListItemIcon>
              <text.icon />
            </ListItemIcon>
            <ListItemText primary={text.label} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
  const [navigate] = useNavigate();

  const handleMenuClick = (tab: any) => {
    navigate(
      [
        {
          ...tab,
        },
      ],
      true,
    );
  };

  const handleSwitch = (val: any) => {
    localStorage.setItem('switch', val);
    setShowOverlay(true);
    setProfileFlag(false);
    setTimeout(() => {
      redirectToLogin();
    }, 2000);
  };

  const filteredTabs = tabs.filter((tab) => isPermissions(tab.module));

  useEffect(() => {
    if (location.pathname === '/') {
      const targetTab = isUserRole(TADROLES.EMPLOYEE)
        ? {
            label: 'LMS',
            link: '/lms',
          }
        : filteredTabs.length > 0
        ? {
            label: filteredTabs[0].label,
            link: filteredTabs[0].link,
          }
        : null;

      if (targetTab) {
        navigate([targetTab], true);
      } else {
        console.error('No valid tabs available for navigation.');
      }
    }
  }, []);

  const getRoles = (): string => {
    return toUpperFirstChar(globalObject.userObject?.roles[0].name);
  };

  return (
    <>
      <div className='app-bar' id='nav-bar'>
        <div
          className='left-div'
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '1.17rem',
            gap: '1.87rem',
            flexWrap: 'wrap',
          }}>
          <IconButton onClick={() => setMenuOpen(true)}>
            <HamburgerMenuIcon />
          </IconButton>
          <LogoIcon />
        </div>
        <div
          className='right-div'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '2.19rem',
          }}>
          <div style={{ display: 'flex', gap: '2.19rem' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ color: 'white' }}>Classic TAD</span>
              <Switch
                size='small'
                className='switch'
                inputProps={{ 'aria-label': 'controlled' }}
                checked={switchTad}
                onChange={() => {
                  setDialogOPen(true);
                }}
                icon={<ToggleOffNewTad />}
                checkedIcon={<ToggleOn style={{ color: 'white' }} />}
              />
              <Dialog
                open={dialogOpen}
                fullWidth
                maxWidth='sm'
                sx={{
                  '& .MuiDialog-paper': {
                    width: '29.375rem',
                    height: '13.25rem',
                    borderRadius: '0.3125rem',
                    border: '1px solid var(--Primary-Dark-grey-500, #333)',
                    background: 'var(--Secondary-colors-White, #FFF)',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  },
                }}>
                <div style={{ margin: '1.25rem 1.25rem 1.87rem 1.25rem' }}>
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: '1.37rem',
                      justifyContent: 'space-between',
                    }}>
                    <InfoIcon />
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: 'var(--Font-size-P, 1rem)',
                        width: '23.125rem',
                      }}>
                      {`By selecting "Try the old TAD," you will be logged out of the current version.
                  You'll need to log in again to access the previous version, and you can switch back to
                  the previous version at any time.`}
                    </span>
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setDialogOPen(false)} />
                  </div>
                  <div style={{ gap: '20px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                      className='button'
                      onClick={() => {
                        setDialogOPen(false);
                        setSwitchTad(false);
                      }}
                      sx={{
                        width: '7.375rem',
                        height: '2.5rem',
                        borderRadius: '0.3125rem',
                        border: '1px solid var(--Primary-Blue-500, #1976D2)',
                        backgroundColor: '#FFF',
                        color: 'black',
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 400,
                      }}>
                      Cancel
                    </Button>
                    <Button
                      className='button'
                      onClick={() => {
                        setDialogOPen(false);
                        setSwitchTad(true);
                        handleSwitch(false);
                      }}
                      sx={{
                        textTransform: 'none',
                        fontWeight: 400,
                        fontSize: '1rem',
                        width: '7.375rem',
                        height: '2.5rem',
                        marginRight: '10px',
                        color: 'white !important',
                        background: 'var(--Primary-Blue-500, #1976D2) !important',
                      }}>
                      Switch
                    </Button>
                  </div>
                </div>
              </Dialog>
            </div>
            {/* <IconButton onClick={() => setFilterOpen(!filterOpen)}>
              <SearchIcon color='inherit' style={{ color: 'white' }} />
            </IconButton> */}
            {/* <IconButton
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setFlag(true);
              }}>
              <PlusIcon className='img' />
            </IconButton> */}
            <IconButton
              onClick={() =>
                handleMenuClick({
                  label: 'Settings',
                  link: '/settings',
                })
              }>
              <SettingCompo className='img' style={{ color: '#fff' }} />
            </IconButton>
            <NotificationIcon style={{ marginTop: '2%' }} />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              marginRight: '20.31px',
              textAlign: 'end',
            }}>
            <div>
              <p style={{ fontSize: '16px', fontWeight: 400, color: 'white', margin: '0' }}>
                {globalObject.userObject.firstName}
              </p>
              <p style={{ fontSize: '16px', fontWeight: 600, color: 'white', margin: '0' }}>
                {getRoles()}
              </p>
            </div>
            <div
              onClick={(event) => {
                setProfileAnchorE1(event.currentTarget);
                setProfileFlag(true);
              }}>
              {/* <Avatar
                alt='Remy Sharp'
                sx={{ width: '1.875rem', height: '1.875rem' }}
                src={require('../../../../assets/user-icon.png')}
              /> */}
              <div
                className='user-icon'
                style={{
                  backgroundColor: 'white',
                  color: 'rgb(25, 118, 210)',
                }}>
                {getInitials(globalObject.userObject)}
              </div>
            </div>
          </div>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            className='menu-list'
            anchorEl={anchorEl}
            open={flag}
            onClose={() => setFlag(false)}>
            <MenuItem>
              <DescriptionIcon style={{ flexShrink: '0px', marginRight: '0.7rem' }} />
              Create a New Job
            </MenuItem>
            <MenuItem>
              <CandidatesIcon /> Add a Candidate
            </MenuItem>
            <MenuItem>
              <EmailIcon style={{ flexShrink: '0px', marginRight: '0.7rem' }} />
              Create a New Template
            </MenuItem>
            <MenuItem>
              <SearchIcon style={{ flexShrink: '0px', marginRight: '0.7rem', color: '#2D7DFE' }} />
              Add Activity
            </MenuItem>
          </Menu>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            className='app-list'
            anchorEl={profileAnchorE1}
            open={profileFlag}
            onClose={() => setProfileFlag(false)}>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '1.75rem' }}>
              {/* <div>
                <Avatar
                  alt='Remy Sharp'
                  sx={{ width: '3.125rem', height: '3.09619rem' }}
                  src={require('../../../../assets/user-icon.png')}
                />
              </div> */}
              <div
                className='user-icon'
                style={{ backgroundColor: 'rgb(25, 118, 210)', color: 'white' }}>
                {getInitials(globalObject.userObject)}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span className='menuItem'>
                  {globalObject.userObject.firstName} {globalObject.userObject.lastName}
                </span>
                <span style={{ fontWeight: 600, fontSize: 'var(--Font-size-P, 1rem)' }}>
                  {getRoles()}
                </span>
                <a
                  style={{ fontSize: 'var(--Font-size-P, 1rem)' }}
                  href={globalObject.userObject.email}>
                  {globalObject.userObject.email}
                </a>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1.75rem',
                justifyContent: 'space-between',
              }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span
                  style={{
                    fontSize: 'var(--Font-size-P, 1rem)',
                    fontWeight: 400,
                    color: 'var(--Primary-Dark-grey-500, #333)',
                  }}>
                  Switch to US Operations
                </span>
                <span
                  style={{
                    fontSize: 'var(--Font-size-P, 1rem)',
                    fontWeight: 400,
                    color: 'var(--Secondary-colors-Grey, #868686)',
                  }}>
                  You cannot access India Operations data
                </span>
              </div>
              <div>
                <Switch
                  // disabled={globalObject.userObject.id !== 2734}
                  size='small'
                  className='switch'
                  inputProps={{ 'aria-label': 'controlled' }}
                  icon={<ToggleOff />}
                  checkedIcon={<ToggleOn style={{ color: '#1976D2' }} />}
                />
              </div>
            </div>

            <div
              style={{
                marginBottom: '1.5rem',
              }}>
              <hr style={{ color: '#E6E7E8)', margin: 0 }} />
            </div>
            <MenuItem
              sx={{ marginBottom: '0.5rem' }}
              onClick={() =>
                handleMenuClick({
                  label: 'Edit-Page',
                  link: '/settings/edit-page',
                })
              }>
              <EditIcon style={{ flexShrink: '0px', marginRight: '0.7rem' }} />
              <span className='menuItem'>Edit Profile</span>
            </MenuItem>
            <MenuItem
              sx={{ marginBottom: '0.5rem' }}
              onClick={() =>
                handleMenuClick({
                  label: 'Settings',
                  link: '/settings',
                })
              }>
              <SettingCompo style={{ flexShrink: '0px', marginRight: '0.7rem', fill: '#1976D2' }} />
              <span className='menuItem'>Account Settings</span>
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleMenuClick({
                  label: 'Login',
                  link: '/login',
                })
              }>
              <ExitToAppIcon style={{ flexShrink: '0px', marginRight: '0.7rem' }} />{' '}
              <span className='menuItem'>Sign Out</span>
            </MenuItem>
          </Menu>
        </div>
      </div>

      <Drawer
        className='drawer'
        sx={{
          borderRadius: '0.3125rem !important',
          width: '21.0625rem',
          height: '100%',
          flexShrink: 0,
          '& .css-4t3x6l-MuiPaper-root-MuiDrawer-paper': {
            height: '100% !important',
            width: '21.0625rem !important',
            backgroundImage: require('../../../assets/dashboard-assets/abstract-plexus-background-with-connected-lines-dots-plexus-geometric-effect-digital-data-visual_230610-1011 1.png'),
            backgroundSize: 'cover !important',
          },
        }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}>
        {DrawerList}
      </Drawer>
      {filterOpen && (
        <div className='new-dashboard-menu'>
          <div style={{ width: '41.04%', display: 'flex', alignItems: 'center' }}>
            <InputBase
              sx={{ ml: '19px', width: '749px' }}
              placeholder='Search a keyword'
              inputProps={{ 'aria-label': 'Search a keyword' }}
            />
            <div>
              <SearchIcon
                style={{ width: '1.25rem', height: '1.25rem', flexShrink: '0px', color: '#000000' }}
              />
            </div>
            <hr style={{ marginLeft: '16px', color: '#C1C0C0', height: '28px' }} />
          </div>
          <div
            style={{
              width: '58.96%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginLeft: '-27px',
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px' }}>All Job Definitions</span>
              <CommonMenu list={jobs} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '16px' }}>All Operations</span>
              <CommonMenu list={jobs} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>All Recruiters</span>
              <CommonMenu list={jobs} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>All Locations</span>
              <CommonMenu list={jobs} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>All Clients</span>
              <CommonMenu list={jobs} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Start Date</span>
              <DatePicker className='date' />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>End Date</span>
              <DatePicker className='date' />
            </div>
          </div>
        </div>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showOverlay}
        onClick={() => setShowOverlay(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#fff',
            alignItems: 'center',
            paddingBottom: '10px',
          }}>
          <img src={tadLoader} alt='Loading' style={{ width: '14rem', height: '7rem' }} />
          <span
            style={{
              color: 'var(--Primary-Dark-grey-500,#333)',
              fontFamily: 'Roboto',
              fontSize: 'var(--Font-size-small, 1.75rem)',
              fontStyle: 'normal',
              fontWeight: '500',
              letterSpacing: '0.42rem',
              textAlign: 'center',
            }}>
            LOADING...
          </span>
        </div>
      </Backdrop>
    </>
  );
};
export default NewAppHeader;
