import {
  Tabs,
  Tab,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { ReactComponent as TreandingICon } from '../../assets/dashboard-assets/trending_up.svg';
import { ReactComponent as MoreVertIcon } from '../../assets/dashboard-assets/more_vert.svg';
import { ReactComponent as GreenTriangleIcon } from '../../assets/dashboard-assets/GreentrangleIcon.svg';

const SjdProgress = () => {
  type subTabType = 'Recruitment Team Progress' | 'Job Definition Progress';
  const [selectedSubType, setSelectedSubType] = useState<subTabType>('Recruitment Team Progress');

  const handleTabChange = (newValue: subTabType) => {
    setSelectedSubType(newValue);
  };

  const headers: any[] = [
    'Recruiter',
    'Total Jobs',
    'Total Candidates',
    'PR Rating',
    'Interview',
    'Shortlisted',
    'Offer Released',
    'Joined',
    'Progress',
  ];

  return (
    <>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Tabs
          className='tab'
          value={selectedSubType}
          onChange={(_e, v) => {
            handleTabChange(v);
          }}>
          <Tab
            label={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <TreandingICon style={{ marginLeft: '20px', marginRight: '10px' }} />
                Recruitment Team Progress
              </span>
            }
            value={'Recruitment Team Progress'}
          />
          <Tab
            label={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <TreandingICon style={{ marginLeft: '20px', marginRight: '10px' }} />
                Job Definition Progress
              </span>
            }
            value={'Job Definition Progress'}
          />
        </Tabs>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MoreVertIcon style={{ marginRight: '10px' }} />
        </div>
      </div>
      <hr style={{ color: '#C1C0C0', margin: 0 }}></hr>
      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          marginBottom: '30px',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex' }}>
          <div>
            <Select
              sx={{ width: '182px', height: '30px', marginRight: '10px', marginLeft: '26.61px' }}
              size='small'
              fullWidth
              value={0}
              labelId='domain'
              name='domain'
              id='demo-simple-select'>
              <MenuItem key={0} value={0}>
                All Job Defintions
              </MenuItem>
              <MenuItem>Select</MenuItem>
              <MenuItem>Select</MenuItem>
            </Select>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginRight: '9.49px' }}>
            <span>Start Date</span>
            <DatePicker className='date' />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>End Date</span>
            <DatePicker className='date' />
          </div>
        </div>
        <span
          style={{
            fontSize: '1rem',
            color: '#1976D2',
            fontWeight: '400',
            marginRight: '20px',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>
          View All
        </span>
      </div>
      <div className='qc-table' style={{ display: 'flex' }}>
        <Table sx={{ marginLeft: '1.69rem', marginRight: '1.75rem' }}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }} key={header}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }}>Gopal Krishna</TableCell>
              <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }}>04</TableCell>
              <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }}>14</TableCell>
              <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }}>07</TableCell>
              <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }}>07</TableCell>
              <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }}>04</TableCell>
              <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }}>03</TableCell>
              <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }}>03</TableCell>
              <TableCell sx={{ padding: '1rem 0.61rem 9px 5px' }}>
                <GreenTriangleIcon style={{ marginRight: '10px' }} />
                90%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default SjdProgress;
