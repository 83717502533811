import {
  InputAdornment,
  Link,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import dateFormat from 'dateformat';
import { Search } from '@mui/icons-material';

export const AuditReport = (props: { data: any }) => {
  const { data } = props;
  const [filteredData, setFilteredData] = useState(data);

  const requestSearch = (_searchedVal: string) => {
    if (!_searchedVal || _searchedVal.length == 0) {
      setFilteredData(data);
      return;
    }

    const filteredRows: any[] = [];
    const searchParameters = [
      'sjdId',
      'sjdName',
      'candidateName',
      'recruiterName',
      'auditComments',
      'auditedBy',
    ];
    data.forEach((element: any) => {
      searchParameters.forEach((key) => {
        if (element[key].toString().toLowerCase().includes(_searchedVal.toLowerCase())) {
          filteredRows.push(element);
        }
      });
    });

    setFilteredData(filteredRows);
  };

  const Row = ({ data }: any) => {
    return (
      <>
        <TableRow>
          <TableCell>
            <Link href={`/source-job-definition/${btoa(data.sjdId)}`} target='_blank'>
              {data.sjdId} - {data.sjdName}
            </Link>
          </TableCell>
          <TableCell>
            <Link
              href={`/source-job-definition/candidate/${btoa(data.sjdId)}/${btoa(
                data.candidateId,
              )}`}
              target='_blank'>
              {data.candidateName}
            </Link>
          </TableCell>
          <TableCell>
            <span>{data.recruiterName}</span>
          </TableCell>
          <TableCell>
            <span>{data.auditResult ? 'Pass' : 'Fail'}</span>
          </TableCell>
          <TableCell>
            <span>{data.auditComments}</span>
          </TableCell>
          <TableCell>
            <span>{data.auditedBy}</span>
          </TableCell>
          <TableCell>
            <span>{dateFormat(new Date(data.auditedOn), 'dd/mm/yyyy hh:MM TT')}</span>
          </TableCell>
        </TableRow>
      </>
    );
  };
  return (
    <>
      <div className='report-table-header'>
        <h1>Audit Report</h1>
        <div className='export-search-block'>
          <OutlinedInput
            id='table-search'
            type='text'
            placeholder='Search...'
            className='search-bar-report'
            onChange={(event) => requestSearch(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <Search />
              </InputAdornment>
            }
          />
        </div>
      </div>
      <TableContainer>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell>Job Definition</TableCell>
              <TableCell>Candidate</TableCell>
              <TableCell>Recruiter</TableCell>
              <TableCell>Audit Result</TableCell>
              <TableCell>Audit Comments</TableCell>
              <TableCell>Audited By</TableCell>
              <TableCell>Audited on</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 &&
              filteredData.map((d: string, index: number) => {
                return <Row key={index} data={d} />;
              })}
            {filteredData.length == 0 && (
              <TableRow>
                <TableCell colSpan={7}>
                  <span>No results found</span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
