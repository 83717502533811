import { useState } from 'react';
import { isPermissions, isUserRoleIn } from '../../common/commonUtil';
import { MODULE, ROLES, SubModuleTypes } from '../../constants/common';
import PageHeader from '../common/page-header/page-header';
import LmsInternDashboard from './lms-intern-dashboard';
import LmsManagerDashboard from './lms-manager-dashboard';
import LmsHeader from './lms_header';
const LmsCourseDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(() => {
    const hasDashboard = isPermissions(MODULE.LMS, SubModuleTypes.LMS_DASHBOARD);
    const hasLmsReport = isPermissions(MODULE.LMS, SubModuleTypes.LMS_REPORT);
    const hasCourseCatalogue = isPermissions(MODULE.LMS, SubModuleTypes.COURSE_CATALOGUE);
    const hasAdminRole = isUserRoleIn([ROLES.ADMIN]);
    if (!isUserRoleIn([ROLES.MANAGER])) {
      return hasAdminRole && !hasLmsReport
        ? hasDashboard
          ? 'Dashboard'
          : hasCourseCatalogue
          ? 'Course Catalogue'
          : null
        : 'Lms Report';
    } else {
      return isUserRoleIn([ROLES.MANAGER]) && !hasDashboard
        ? hasCourseCatalogue
          ? 'Course Catalogue'
          : hasLmsReport
          ? 'Lms Report'
          : null
        : 'Dashboard';
    }
  });

  return (
    <>
      <PageHeader title='LMS' />
      {isUserRoleIn([ROLES.ADMIN, ROLES.MANAGER]) && <LmsHeader selectedTab={setSelectedTab} />}
      {isUserRoleIn([ROLES.INTERN]) && <LmsInternDashboard />}
      {isUserRoleIn([ROLES.MANAGER, ROLES.ADMIN]) && (
        <LmsManagerDashboard selectedType={selectedTab} />
      )}
    </>
  );
};
export default LmsCourseDashboard;
