/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState, useEffect } from 'react';
import { getSourcingTrend } from '../../service/dashboardService';
import { Loader } from '../common/loader';
import {
  ResponsiveContainer,
  Tooltip,
  LineChart,
  XAxis,
  YAxis,
  Line,
  AreaChart,
  Area,
  Legend,
} from 'recharts';
import dateFormat from 'dateformat';

interface Props {
  initialData: any;
}

const SourcingTrendChart = ({ initialData }: Props) => {
  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
    } else {
      setLoading(false);
      setData(initialData.reverse());
    }
  }, [initialData]);

  // useEffect(() => {
  //   handleFilter(30);
  // }, []);

  const handleFilter = (count: any) => {
    setLoading(true);
    getSourcingTrend(count)
      .then((resp) => {
        setData(resp.data.reverse());
      })
      .catch((_err) => {})
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Stack spacing={1} sx={{ marginLeft: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            marginRight: '5px',
          }}>
          <Typography variant='h6' fontWeight={700}>
            Sourcing and Audit Trend
          </Typography>
          <select
            className='audit-period-select'
            onChange={(e: any) => handleFilter(e.target.value)}>
            <option value={30}>Last 30 days</option>
            <option value={15}>Last 15 days</option>
            <option value={10}>Last 10 days</option>
          </select>
        </div>
      </Stack>
      {loading && <Loader />}
      {!loading && (
        <>
          <div
            className='candidate-count-graph'
            style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '300px' }}>
            <ResponsiveContainer>
              <AreaChart
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                width={530}
                height={150}>
                <defs>
                  <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
                    <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#82ca9d' stopOpacity={0.8} />
                    <stop offset='95%' stopColor='#82ca9d' stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey='date'
                  minTickGap={10}
                  tickFormatter={(value) => {
                    return dateFormat(new Date(value), 'dd-mmm');
                  }}
                />
                <YAxis />
                <Tooltip />
                <Legend
                  formatter={(value: string, entry: any) => {
                    return (
                      <span>{value == 'candidateCount' ? 'Candidate count' : 'Audit count'}</span>
                    );
                  }}
                />
                <Area
                  type='monotone'
                  dataKey='candidateCount'
                  stroke='#8884d8'
                  strokeWidth={3}
                  fillOpacity={1}
                  fill='url(#colorUv)'
                />
                <Area
                  type='monotone'
                  dataKey='auditCount'
                  stroke='#82ca9d'
                  strokeWidth={3}
                  fillOpacity={1}
                  fill='url(#colorPv)'
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default SourcingTrendChart;
