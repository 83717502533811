import './slider.scss';
import CircleIcon from '@mui/icons-material/Circle';
import { toUpperFirstChar } from './../../../common/commonUtil';
import React from 'react';

interface Props {
  noOfSteps?: number;
  selectedIndex?: number;
  currentStatus?: string;
  sjd: any;
}
const Slider = ({ noOfSteps = 10, selectedIndex = 4, currentStatus = '', sjd }: Props) => {
  const widthOfEachStep = 100 / noOfSteps;
  const interviewLevels =
    (sjd.isOnlineTest ? 2 : 0) + (sjd.isClientRound ? 2 : 0) + sjd.noOfTechnicalInterview * 2;
  const targetIndexes = [
    {
      index: 3,
      name: 'Profiling',
    },
    {
      index: 3 + interviewLevels + 2,
      name: 'Interview',
    },
    {
      index: 3 + interviewLevels + 2 + 2,
      name: 'Documentation',
    },
    {
      index: 3 + interviewLevels + 2 + 2 + 3,
      name: 'Offer',
    },
  ];
  let previousIndex = 0;
  return (
    <>
      <div className='slider'>
        <div className='progress-bar'>
          <div
            className='completed'
            style={{
              width: `${selectedIndex * widthOfEachStep}%`,
              height: '10px',
              background: `${currentStatus === 'Rejected' ? '#f13535' : '#0ca61c'}`,
            }}></div>
          <div
            className='pending'
            style={{ width: `${100 - selectedIndex * widthOfEachStep}%`, height: '10px' }}></div>
        </div>
        <div className='icon-div'>
          {/* <CircleIcon fontSize='medium' className='icon' /> */}
          {targetIndexes.map((target, index) => {
            const width = (target.index - previousIndex) * widthOfEachStep;
            previousIndex = target.index;
            return (
              <React.Fragment key={index}>
                <div style={{ width: `${width}%` }}></div>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircleIcon
                      sx={{
                        display: 'block',
                        fontSize: '24px',
                        paddingBottom: '3px',
                        color: '#464048',
                      }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: '500' }}>{toUpperFirstChar(target.name)}</span>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
          <div style={{ width: `${(noOfSteps - previousIndex) * widthOfEachStep}%` }}></div>
          {/* <CircleIcon fontSize='medium' className='icon' /> */}
        </div>
      </div>
    </>
  );
};

export default Slider;
