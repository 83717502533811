import {
  Paper,
  Rating,
  IconButton,
  InputLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Tooltip,
} from '@mui/material';
import { useFormik } from 'formik';
import SectionHeaderWithEdit from './../../common/section-heading';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { updateInterviewDetails, uploadDocument } from '../../../service/candidateService';
import globalObject from '../../../common/global-variables';
import useNavigate from '../../../common/useNavigate';
import { INTERVIEW_RESULT } from '../../../constants/common';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextareaAutosize } from '@mui/base';

interface Props {
  candidate: any;
  sjdId: number;
  details?: any;
  setLoading: (isLoading: boolean) => void;
  alert: (msg: string, success: boolean) => void;
}

interface assessedSkill {
  skill?: any;
  rating?: any;
}

const InterviewFeedback = ({
  sjdId,
  candidate,
  details = {
    feedback: '',
    recording: '',
    interviewResult: '',
    assessedSkills: '[{}]',
    attachment: undefined as unknown as File,
  },
  setLoading,
  alert,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navigate, redirect] = useNavigate();
  const skillsJson =
    details.assessedSkills == null || details.assessedSkills == undefined
      ? [{}]
      : JSON.parse(details.assessedSkills);

  const [skills, setSkills] = useState(skillsJson as assessedSkill[]);

  const handleSkillChange = (index: number, value: string) => {
    const temp = skills;
    temp[index].skill = value ? value : null;
    setSkills(() => [...temp]);
  };

  const handleRatingChange = (index: number, value: string) => {
    const temp = skills;
    temp[index].rating = value ? Number(value) : null;
    setSkills(() => [...temp]);
  };

  const addNewRow = () => {
    setSkills((skills) => [...skills, {}]);
  };

  const handleDelete = (index: number) => {
    const newList = [...skills];
    newList.splice(index, 1);
    if (newList.length == 0) newList.push({});
    setSkills([...newList]);
  };
  const validateSkillAndRating = (skills: assessedSkill[]) => {
    const errors: any = {};

    if (!skills.some((item) => item.skill && item.rating)) {
      errors.errorMsg = 'At least one skill  is required';
    }
    skills.forEach((item) => {
      if (item.skill && !item.rating) {
        errors.errorMsg = 'Rating is required for the entered skill';
      }
    });

    return errors;
  };

  const submitFeedback = (req: any) => {
    const skillsWithRating = JSON.stringify(skills);
    setLoading(true);
    updateInterviewDetails(
      {
        id: details.id,
        sjdId,
        interviewLevel: details.interviewLevel,
        candidateId: candidate.id,
        evaluator: {
          id: globalObject.userObject.id,
        },
        feedback: req.feedback,
        recording: req.recording,
        interviewResult: req.interviewResult,
        assessedSkills: skillsWithRating,
      },
      true,
    )
      .then((_resp) => {
        setLoading(false);
        alert('Thank you! Your feedback has been submitted successfully', true);
        setTimeout(() => {
          navigate(
            [
              {
                label: 'Dashboard',
                link: '/dashboard',
              },
            ],
            true,
          );
        }, 3000);

        formik.resetForm();
      })
      .catch((_err) => {
        setLoading(false);
        alert('Something went wrong. Please try later!', false);
      });

    setLoading(true);
    uploadDocument(req.attachment, candidate.id, `Interview Feedback - ${details.interviewLevel}`)
      .then((_resp) => {})
      .catch((_err) => {})
      .finally(() => setLoading(false));
  };
  const formik: any = useFormik({
    initialValues: {
      ...details,
      recording: details.recording ? details.recording : details.isOnlineMeeting ? '' : 'NA',
    },
    onSubmit: submitFeedback,
    validationSchema: Yup.object().shape({
      feedback: Yup.string()
        .required('Feedback is required')
        .min(100, 'Minimum 100 characters required for detailed feedback'),
      recording: Yup.string().required(
        'Recording Link is required. In case of offline interview, mark it as NA',
      ),
      interviewResult: Yup.string().required('Result  is required'),
    }),
    validate: () => {
      const skillAndRatingErrors = validateSkillAndRating(skills);
      return {
        ...skillAndRatingErrors,
      };
    },
  });

  const [showAlert, setShowAlert] = useState(false);

  return (
    <div>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        <SectionHeaderWithEdit text='Your Feedback' edit={true} hideIcon={true} click={() => {}} />

        <div className='field' style={{ width: '95%' }}>
          <InputLabel className='field-label' id='feedback'>
            Detailed feedback*
          </InputLabel>

          <TextareaAutosize
            name='feedback'
            id='feedback'
            placeholder='Please enter description to justify your recommendation or rejection'
            className='textarea-field'
            onChange={formik.handleChange}
            value={formik.values.feedback}
            onBlur={formik.handleBlur}
            style={{ width: '97%' }}
            // disabled={details?.feedback}
            minRows={3}></TextareaAutosize>
          {formik.touched['feedback'] && formik.errors['feedback'] && (
            <div className='mt-0 form-err-msg'>{formik.errors['feedback']?.toString()}</div>
          )}
        </div>

        <div className='field' style={{ width: '60%' }}>
          <Tooltip title='Copy the interview recordign link and paste here'>
            <InputLabel className='field-label' id='recording'>
              Recording Link*
            </InputLabel>
          </Tooltip>
          <TextareaAutosize
            name='recording'
            className='textarea-field'
            id='recording'
            style={{ width: '97%' }}
            minRows={2}
            onChange={formik.handleChange}
            value={formik.values.recording}
            disabled={details?.feedback || !details.isOnlineMeeting}
            onBlur={formik.handleBlur}
          />
          {formik.touched['recording'] && formik.errors['recording'] && (
            <div className='mt-0 form-err-msg'>{formik.errors['recording']?.toString()}</div>
          )}
        </div>

        {/* <div className='field' style={{ marginTop: '20px', width: '100%' }}>
              <InputLabel htmlFor='browse-document' className='field-label'>
                Attach Feedback Form
              </InputLabel>
              <TextField
                id='browse-document'
                type='file'
                name='attachment'
                size='small'
                fullWidth
                onChange={(event: any) => formik.setFieldValue('attachment', event.target.files[0])}
              />
            </div> */}

        <div className='field'>
          <InputLabel className='field-label'>Assessed Skills* </InputLabel>
          {skills.map((item, index) => (
            <div className='row' key={index} style={{ alignItems: 'center', marginBottom: '10px' }}>
              <div>
                <TextField
                  fullWidth
                  size='small'
                  value={item?.skill}
                  onChange={(e) => {
                    handleSkillChange(index, e.target.value);
                  }}></TextField>
              </div>

              <div
                style={{
                  marginLeft: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '5px 10px',
                }}
                className='rating-div'>
                <Tooltip title=''>
                  <Rating
                    id='Rating'
                    value={item?.rating}
                    onChange={(event: any) => handleRatingChange(index, event.target.value)}
                    name='Rating'
                    precision={0.5}
                    size='large'
                  />
                </Tooltip>
              </div>

              <Tooltip title='Remove Skill' style={{ marginLeft: '20px' }}>
                <IconButton>
                  <DeleteIcon
                    onClick={() => {
                      handleDelete(index);
                    }}
                    sx={{ color: '#a72037' }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          ))}
          <div style={{ padding: '2px' }}>
            <Link onClick={addNewRow} sx={{ cursor: 'pointer' }}>
              Add more
            </Link>
            {formik.errors.errorMsg && <div className='form-err-msg'>{formik.errors.errorMsg}</div>}
          </div>
        </div>
        <div className='field'>
          <div style={{ marginTop: '10px' }}>
            <InputLabel className='field-label'>Result* </InputLabel>
          </div>
          <div>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='interviewResult'
              sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
              value={formik.values.interviewResult}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}>
              <Tooltip title='Excellent fit for this position'>
                <FormControlLabel
                  value={INTERVIEW_RESULT.SELECTED}
                  sx={{ padding: '0 10px' }}
                  control={
                    <Radio
                      disabled={
                        details?.feedback && details.interviewResult != INTERVIEW_RESULT.ON_HOLD
                      }
                    />
                  }
                  label={INTERVIEW_RESULT.SELECTED}
                />
              </Tooltip>
              <Tooltip title='Not good for this position'>
                <FormControlLabel
                  value={INTERVIEW_RESULT.REJECTED}
                  sx={{ padding: '0 10px' }}
                  control={
                    <Radio
                      disabled={
                        details?.feedback && details.interviewResult != INTERVIEW_RESULT.ON_HOLD
                      }
                    />
                  }
                  label={INTERVIEW_RESULT.REJECTED}
                />
              </Tooltip>
              <Tooltip title='Will give final decision later'>
                <FormControlLabel
                  value={INTERVIEW_RESULT.ON_HOLD}
                  sx={{ padding: '0 10px' }}
                  control={
                    <Radio
                      disabled={
                        details?.feedback && details.interviewResult != INTERVIEW_RESULT.ON_HOLD
                      }
                    />
                  }
                  label={INTERVIEW_RESULT.ON_HOLD}
                />
              </Tooltip>
            </RadioGroup>
            {formik.touched['interviewResult'] && formik.errors['interviewResult'] && (
              <div className='mt-0 form-err-msg'>
                {formik.errors['interviewResult']?.toString()}
              </div>
            )}
          </div>
        </div>

        <div className='bottom-btn-block'>
          <Button
            sx={{ marginLeft: 3, marginBottom: '5px' }}
            // disabled={details?.feedback}
            // disabled={!formik.dirty || Object.keys(formik.errors).length > 0 || details?.feedback}
            onClick={() => {
              if (!formik.dirty || Object.keys(formik.errors).length > 0 || details?.feedback) {
                formik.handleSubmit();
              } else {
                setShowAlert(true);
              }
            }}
            className='default-btn bg-btn'>
            Submit
          </Button>
        </div>
      </Paper>
      <Dialog
        open={showAlert}
        fullWidth={true}
        maxWidth='xs'
        onClose={() => setShowAlert(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Your feedback for this candidate is: <strong>{formik.values.interviewResult}</strong>
            .<br />
            Please confirm.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className='default-btn'
            onClick={() => {
              setShowAlert(false);
            }}>
            Cancel
          </Button>
          <Button
            className='default-btn bg-btn'
            onClick={() => {
              setShowAlert(false);
              formik.handleSubmit();
            }}
            autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InterviewFeedback;
