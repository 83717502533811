import Marquee from 'react-fast-marquee';

interface NotificationBannerProps {
  msg: string | undefined;
  direction?: 'left' | 'right' | 'up' | 'down';
  speed?: number | undefined;
}

const NotificationBanner = (props: NotificationBannerProps) => {
  return (
    <div>
      <Marquee
        style={{
          color: 'yellow',
          backgroundColor: 'rgb(0,0,0,.7)',
        }}
        speed={props.speed}
        direction={props.direction}>
        <span dangerouslySetInnerHTML={{ __html: props.msg || '' }}></span>
      </Marquee>
    </div>
  );
};

export default NotificationBanner;
