import { Search } from '@mui/icons-material';
import {
  AlertColor,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  InputAdornment,
  TableRow,
  TextField,
  Checkbox,
} from '@mui/material';
import { useState, useEffect } from 'react';
import globalObject from '../../common/global-variables';
import { LookupTypes } from '../../common/lookupTypes';
import { toUpperFirstChar } from './../../common/commonUtil';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {
  deleteLookup,
  getLookupValues,
  getSkills,
  saveLookupInfo,
  saveSkills,
} from '../../service/lookupService';
import { useFormik } from 'formik';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { AxiosResponse } from 'axios';
import Notify from '../common/notify';
import AddSkills from '../common/add-skills';

const GlobalSettings = () => {
  const editableSettings: string[] = [
    LookupTypes.CLIENT,
    LookupTypes.HIRING_MANAGER,
    LookupTypes.DOMAIN,
    LookupTypes.JOB_TYPE,
    LookupTypes.PAY_TYPE,
    LookupTypes.PRIORITY,
    LookupTypes.QUALIFICATION,
    LookupTypes.SOURCE_CHANNEL,
    'skills',
    LookupTypes.PROVIDER,
    LookupTypes.TECH_STACK,
    LookupTypes.LEVEL,
  ];
  const [selectedSetting, setSelectedSetting] = useState('');
  const [tableData, setTableData] = useState([] as any[]);
  const [editIndex, setEditIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDialog, setShowDialog] = useState(false);
  const [showSkillsDialog, setShowSkillsDialog] = useState(false);
  const [error, setError] = useState('');
  const [textValue, setTextValue] = useState('');
  const [selectedLevels, setSelectedLevels] = useState([] as number[]);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  useEffect(() => {
    setSelectedSetting(LookupTypes.CLIENT);
    setTableData(globalObject.lookupvalues[LookupTypes.CLIENT]);
  }, []);

  const handleSearch = (searchText: string) => {
    const filteredData = globalObject.lookupvalues[selectedSetting].filter((value: any) =>
      value.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    setTableData(filteredData);
    setPage(0);
  };

  const handleChange = (value: string) => {
    setSelectedSetting(value);
    setPage(0);
    setRowsPerPage(5);
    if (value == 'skills') {
      loadSkills();
    } else {
      setTableData(globalObject.lookupvalues[value]);
    }
  };

  const loadSkills = () => {
    setLoading(true);
    getSkills()
      .then((resp) => setTableData(resp.data))
      .catch((_err) => setTableData([]))
      .finally(() => setLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: (_value) => {},
  });

  const refreshGlobalObject = () => {
    getLookupValues(selectedSetting)
      .then((resp) => {
        globalObject.lookupvalues[selectedSetting] = resp.data[selectedSetting];
        setTableData(globalObject.lookupvalues[selectedSetting]);
        setLoading(false);
      })
      .catch((_err) => {
        setLoading(false);
      });
  };

  const handleSave = (id?: any) => {
    const existingLookupData: any[] = globalObject.lookupvalues[selectedSetting].map((e: any) =>
      e.name.toLowerCase(),
    );
    const isExisting: boolean = formik.values.name
      .toLocaleLowerCase()
      .split(',')
      .map((name) => name.trim())
      .some((name) => existingLookupData.some((e) => e == name));

    if (id == null) {
      if (!isExisting) {
        save();
      } else {
        const duplicateNames = formik.values.name
          .toLocaleLowerCase()
          .split(',')
          .map((name) => name.trim())
          .filter((name) => existingLookupData.some((e) => e == name))
          .join(', ');

        setError(`${duplicateNames} already exist`);
      }
    } else {
      textValue.toLowerCase() == formik.values.name.toLowerCase() || !isExisting
        ? save(id)
        : (setToastMsg({ severity: 'error', msg: `${formik.values.name} already exist` }),
          setShowToast(true));
    }
  };

  const save = (id?: any) => {
    setLoading(true);
    const req: any[] = [];
    if (id) {
      req.push({ id, name: formik.values.name });
    } else {
      const names: string[] = formik.values.name.split(',');
      names.forEach((name) => {
        req.push({
          name: name.trim(),
        });
      });
    }

    const apiCall: Promise<AxiosResponse<any, any>> =
      selectedSetting == 'skills'
        ? saveSkills(req)
        : selectedSetting === LookupTypes.TECH_STACK
        ? saveLookupInfo(req, selectedSetting, selectedLevels)
        : saveLookupInfo(req, selectedSetting);

    apiCall
      .then((_resp) => {
        setShowDialog(false);
        setLoading(false);
        setEditIndex(-1);
        setShowToast(true);
        setError('');
        setToastMsg({
          severity: 'success',
          msg: 'Updated succcessfully',
        });
        setSelectedLevels([]);
        if (selectedSetting != 'skills') refreshGlobalObject();
        else loadSkills();
      })
      .catch((_err) => {
        setLoading(false);
        setShowToast(true);
        setSelectedLevels([]);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong.!',
        });
      });
  };

  const deleteData = (id: any) => {
    setLoading(true);
    deleteLookup(selectedSetting, id)
      .then((_resp) => {
        setLoading(false);
        setEditIndex(-1);
        setPage(0);
        setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Deleted succcessfully',
        });
        if (selectedSetting != 'skills') refreshGlobalObject();
        else loadSkills();
      })
      .catch((err) => {
        setLoading(false);
        setShowToast(true);
        if (err && err.response && err.response.status == 400) {
          setToastMsg({
            severity: 'error',
            msg: 'The data you are trying to delete is mapped to some other entites.',
          });
        } else {
          setToastMsg({
            severity: 'error',
            msg: 'Something went wrong.!',
          });
        }
      });
  };

  const handleCheckboxChange = (levelId: number) => {
    setSelectedLevels((prevSelected) =>
      prevSelected.includes(levelId)
        ? prevSelected.filter((id) => id !== levelId)
        : [...prevSelected, levelId],
    );
  };

  return (
    <>
      <Box sx={{ width: 200, marginLeft: 5 }}>
        <Box>
          <h3>Global Settings</h3>
        </Box>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            width: '50vw',
          }}>
          <div style={{ width: '100%' }}>
            <InputLabel>Select a setting to edit</InputLabel>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <div style={{ width: '35%' }}>
                <Select
                  size='small'
                  fullWidth
                  labelId='setting'
                  value={selectedSetting}
                  name='sourceChannel'
                  onChange={(e) => handleChange(e.target.value)}
                  id='demo-simple-select'>
                  {editableSettings.map((setting, i) => (
                    <MenuItem key={i} value={setting}>
                      {toUpperFirstChar(setting)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ width: '35%' }}>
                <TextField
                  id='table-search-bar'
                  type='text'
                  placeholder='Search...'
                  fullWidth
                  value={textValue}
                  size='small'
                  onChange={(e) => {
                    setTextValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div style={{ width: '55vw' }}>
          <Table className='global-setting' style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '40vw' }}>Value</TableCell>
                <TableCell style={{ width: '5vw' }}>Action</TableCell>
                <TableCell style={{ width: '10vw' }}>
                  <Button
                    className='default-btn bg-btn'
                    onClick={() => {
                      setEditIndex(-1);
                      formik.resetForm();
                      if (selectedSetting == 'skills') setShowSkillsDialog(true);
                      else setShowDialog(true);
                    }}>
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : tableData
              ).map((data, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {i != editIndex && data.name}
                    {i == editIndex && (
                      <form>
                        <TextField
                          value={formik.values.name}
                          fullWidth
                          id='name'
                          size='small'
                          name='name'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </form>
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex != i && (
                      <DriveFileRenameOutlineIcon
                        fontSize='small'
                        style={{ cursor: 'pointer', color: '#a72037' }}
                        onClick={() => {
                          setEditIndex(i);
                          formik.setFieldValue('name', data.name);
                          setTextValue(data.name);
                        }}
                      />
                    )}
                    {editIndex == i && (
                      <CheckCircleRoundedIcon
                        style={{
                          cursor:
                            !formik.values.name || formik.values.name.trim() == ''
                              ? 'default'
                              : 'pointer',
                          color: '#a72037',
                          opacity:
                            !formik.values.name || formik.values.name.trim() == '' ? '50%' : '100%',
                        }}
                        onClick={() => {
                          if (formik.values.name && formik.values.name.trim() != '')
                            handleSave(data.id);
                        }}
                      />
                    )}
                    <DeleteRoundedIcon
                      fontSize='small'
                      style={{ marginLeft: '15px', cursor: 'pointer', color: '#a72037' }}
                      onClick={() => deleteData(data.id)}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={(_e, p) => setPage(p)}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(Number(e.target.value));
                    setPage(0);
                  }}
                  className='pagination'
                />
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Notify open={showToast} severity={toastMsg.severity} onClose={() => setShowToast(false)}>
        <span>{toastMsg.msg}</span>
      </Notify>

      <Dialog
        open={showDialog}
        fullWidth={true}
        maxWidth='xs'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Add {selectedSetting}</DialogTitle>
        <DialogContent>
          <InputLabel>For multiple values, seperate them using comma</InputLabel>
          <TextField
            value={formik.values.name}
            fullWidth
            id='name'
            size='small'
            name='name'
            onChange={(e) => {
              setError('');
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />
          <DialogContentText id='alert-dialog-description' sx={{ color: 'red' }}>
            {error}
          </DialogContentText>
        </DialogContent>
        {selectedSetting === LookupTypes.TECH_STACK && (
          <DialogContent>
            {globalObject.lookupvalues[LookupTypes.LEVEL].map((level: any) => (
              <div key={level.id}>
                <Checkbox
                  checked={selectedLevels.includes(level.id)}
                  onChange={() => handleCheckboxChange(level.id)}
                />
                {level.name}
              </div>
            ))}
          </DialogContent>
        )}

        <DialogActions>
          <Button
            className='default-btn'
            onClick={() => {
              setShowDialog(false);
              setError('');
              setSelectedLevels([]);
            }}>
            Cancel
          </Button>
          <Button
            className='default-btn bg-btn'
            onClick={() => {
              handleSave();
            }}
            disabled={!formik.values.name || formik.values.name.trim() == ''}
            autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {showSkillsDialog && (
        <AddSkills
          handleResponse={(flag) => {
            if (flag === 'success') {
              setShowSkillsDialog(false);
              loadSkills();
            } else if (flag === 'error') {
              setShowToast(true);
              setToastMsg({
                severity: 'error',
                msg: 'Something went wrong.!',
              });
            } else if (flag === 'close') {
              setShowSkillsDialog(false);
            }
          }}
        />
      )}
    </>
  );
};

export default GlobalSettings;
