import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  TableContainer,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import {
  TadCandidateDetailsModel,
  TadCandidateDocumentModel,
} from '../../../models/tad-candidate-form-model';
import { tadDeleteDocument, tadUploadDocument } from '../../../service/tadCandidateService';
import dayjs from 'dayjs';

interface TadCandidateDocumentProps {
  candidatedocument: TadCandidateDetailsModel;
  reloadCandidate: () => void;
  setLoading: (isLoading: boolean) => void;
  alert: (msg: string, success: boolean) => void;
}

const TadCandidateAttachments: React.FC<TadCandidateDocumentProps> = ({
  candidatedocument,
  reloadCandidate,
  setLoading,
  alert,
}) => {
  const [documentName, setDocumentName] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState([] as TadCandidateDocumentModel[]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string>('No file chosen');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      setFileName(event.target.files[0].name);
    }
  };

  useEffect(() => {
    if (candidatedocument.documents) setData([...candidatedocument.documents]);
  }, [candidatedocument]);

  const upload = (values: any) => {
    // console.log('Uploading document with values:', values);

    setLoading(true);
    setData(values);
    tadUploadDocument(values.attachment, values.id, values.documentName)
      .then((resp) => {
        setData(resp.data);
        // console.log('Document upload response:', resp);
        alert('Document added successfully', true);
        reloadCandidate();
      })
      .catch((_err) => {
        // console.error('Error uploading document:', _err);
        alert('Something went wrong. Please try later!', false);
      })
      .finally(() => {
        setLoading(false);
        // console.log('Loading state set to false');
      });
  };

  // const delete = (values: TadCandidateDocumentModel) => {
  //   setLoading(true);
  //   tadDeleteDocument(values.id, null).then((_resp) => {
  //     console.log('delete document', _resp.data);
  //     // setData(values);
  //     alert('Document Deleted successfully', true);
  //     reloadCandidate();
  //   }).catch((_err) => {
  //     alert('Something went wrong. Please try later!', false);
  //   })
  // }

  const handleUpload = () => {
    if (!documentName || !file) {
      alert('Please fill in the document name and choose a file.', false);
      return;
    }

    const values = {
      attachment: file,
      id: candidatedocument.id,
      documentName,
    };

    upload(values);

    setDocumentName('');
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDownload = (fileURL: string, fileName: string) => {
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDelete = (index: number) => {
    const documentToDelete = data[index];

    if (!documentToDelete) {
      alert('Document not found for deletion.', false);
      return;
    }

    const { candidateId, id: documentId } = documentToDelete;

    setLoading(true);
    tadDeleteDocument(candidateId, documentId)
      .then((_resp) => {
        // console.log('Deleted document:', _resp);
        const updatedData = data.filter((_, i) => i !== index);

        setData(updatedData);
        alert('Document deleted successfully', true);
        reloadCandidate();
      })
      .catch((_err) => {
        alert('Something went wrong. Please try again later!', false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper style={{ minHeight: '35.6875rem', width: '100%' }} className='paper'>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
        }}>
        <div style={{ display: 'flex' }}>
          <span
            className='heading'
            style={{
              height: '24px',
              fontSize: '18px',
              marginLeft: '1.81rem',
            }}>
            Attachments({data.length})
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MoreVertIcon style={{ marginRight: '10px' }} />
        </div>
      </div>
      <div style={{ padding: '1.5rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}>
          <Typography className='input-field'>Document Name</Typography>

          <TextField
            variant='outlined'
            fullWidth
            placeholder='Ex: Cover Letter, Portfolio Document etc.'
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: '20px',
          }}>
          <Typography
            style={{
              color: 'var(--Primary-Dark-grey-500, #333)',
              fontFamily: 'Roboto',
              fontSize: '1rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '1.5625rem',
              marginBottom: '8px',
            }}>
            Browse Document
          </Typography>

          <div
            style={{
              border: '1px solid var(--Secondary-Grey-2, #E7E7E7)',
              padding: '0.8rem',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              fontFamily: 'Arial, sans-serif',
              fontSize: '1rem',
              borderRadius: '0.3125rem',
              color: '#333',
            }}>
            <input
              ref={fileInputRef}
              type='file'
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <button
              onClick={() => fileInputRef.current?.click()}
              style={{
                height: '2.5rem',
                padding: '0 1rem',
                border: '1px solid #007BFF',
                borderRadius: '0.25rem',
                cursor: 'pointer',
                background: 'white',
                fontSize: '1rem',
              }}>
              Choose File
            </button>
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {fileName}
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '2rem',
              marginTop: '1rem',
            }}>
            <Button
              variant='contained'
              onClick={handleUpload}
              sx={{
                width: '6rem',
                height: '2.5rem',
                borderRadius: '0.3125rem',
                background: 'var(--Secondary-colors-Grey, #868686)',
                textTransform: 'capitalize',
              }}>
              Upload
            </Button>
          </div>
        </div>

        {data.length > 0 ? (
          <div className='qc-table'>
            <TableContainer>
              <Table stickyHeader>
                <TableHead style={{ borderBottom: '0.125rem solid #C1C0C0' }}>
                  <TableRow>
                    <TableCell>Document Name</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>Uploaded On</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.map((item, index) => (
                    <TableRow
                      key={index}
                      style={{
                        width: '43.875rem',
                        height: '4.875rem',
                        backgroundColor: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                      }}>
                      <TableCell>
                        <span
                          style={{
                            textDecoration: 'underline',
                            fontSize: '1rem',
                            fontWeight: 600,
                            color: '#1976D2',
                          }}>
                          {item.documentName}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '1rem',
                          fontWeight: 600,
                          textDecoration: 'underline',
                          color: '#1976D2',
                        }}>
                        {item.createdByName}
                      </TableCell>
                      <TableCell>{dayjs(item.createdOn).format('DD-MMM-YYYY')}</TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          marginTop: '1.69rem',
                          marginBottom: '1.31rem',
                          marginRight: '0.78rem',
                        }}>
                        <DownloadIcon
                          sx={{
                            cursor: 'pointer',
                            width: '1.5rem',
                            height: '1.5rem',
                            marginRight: '1rem',
                            color: '#323232',
                          }}
                          onClick={() => {
                            if (item.documentUrl) {
                              handleDownload(item.documentUrl, item.documentName);
                            } else {
                              console.warn('Document URL is undefined');
                            }
                          }}
                        />

                        <DeleteIcon
                          sx={{
                            width: '1.5rem',
                            height: '1.5rem',
                            cursor: 'pointer',
                            color: '#323232',
                          }}
                          onClick={() => handleDelete(index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Typography
            variant='body1'
            style={{
              textAlign: 'center',
              marginTop: '1rem',
              color: '#888',
            }}>
            No resumes uploaded yet.
          </Typography>
        )}
      </div>
    </Paper>
  );
};

export default TadCandidateAttachments;
