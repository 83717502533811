import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  // Text,
} from 'recharts';

const HiresByGender = () => {
  const data = [
    { name: 'Women', value: 40, target: 35 },
    { name: 'Men', value: 35, target: 40 },
    { name: 'Others', value: 5, target: 10 },
  ];

  const CustomBarWithTarget = (props: any) => {
    const { fill, x, y, width, height, payload } = props;
    const amt = payload.value;
    const t = payload.target;
    const totalHeight = y + height;
    const targetY = totalHeight - (height / amt) * t;

    return (
      <svg>
        <rect x={x} y={y} width={width} height={height} stroke='none' fill={fill} />
        <line
          x1={x + width - width / 1.5}
          x2={x + width - width / 3}
          y1={targetY}
          y2={targetY}
          stroke='red'
          strokeWidth={2}
        />
      </svg>
    );
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '150px' }}>
      <ResponsiveContainer width='70%' height={400}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 30,
          }}
          barCategoryGap='15%'>
          <CartesianGrid strokeDasharray='3 3' horizontal={true} vertical={false} />
          <XAxis dataKey='name' />
          <YAxis
            orientation='left'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
            tickFormatter={(tick) => `${tick}%`}
            axisLine={false}
          />
          <Tooltip formatter={(value) => `${value}%`} />
          <Bar
            dataKey='value'
            fill='rgb(21, 101, 192)'
            shape={(props: any) => {
              return <CustomBarWithTarget {...props} />;
            }}
            isAnimationActive={false}></Bar>
        </BarChart>
      </ResponsiveContainer>

      <div style={{ width: '30%', marginLeft: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <div
            style={{ width: 10, height: 10, backgroundColor: 'rgb(21, 101, 192)', marginRight: 10 }}
          />
          <span>Actual Value</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '10px',
              height: '3px',
              backgroundColor: 'red',
              marginRight: '10px',
            }}
          />
          <span>Target</span>
        </div>
      </div>
    </div>
  );
};

export default HiresByGender;
