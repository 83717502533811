import axiosClient from '../../common/axiosClient';
import { TadCandidateDetailsModel, TadSjdCandidateInfo } from '../models/tad-candidate-form-model';

export async function getCandidate(sjdId?: any, candidateId?: any) {
  return axiosClient.get(`/tad-candidate/${candidateId}`, { params: { sjdId } });
}

export async function tadGetCandidatesForSjd(sjdId: any) {
  return axiosClient.get(`/tad-candidate/sjd/${sjdId}`);
}

export async function tadGetAllCandidate(sjdData: any) {
  return axiosClient.get('/tad-candidate', { params: { sjdData } });
}

export async function tadGetAllInterviewSubStages(interviewStageId: any) {
  return axiosClient.get('/tad-candidate/interviewSubStages', { params: { interviewStageId } });
}

export async function getAllInterviewLevels(sjdId: any) {
  return axiosClient.get('/tad-candidate/interviewLevels', { params: { sjdId } });
}

export async function tadAddCandidate(resume: any, request: TadCandidateDetailsModel) {
  const formData = new FormData();
  delete request.resumeSkills;
  formData.append('resume', resume);
  formData.append('details', JSON.stringify(request));
  return axiosClient.post('/tad-candidate', formData);
}

export async function tadScheduleInterview(request: any, accessToken: string) {
  return axiosClient.post('/tad-candidate/interview', request, {
    headers: {
      accessToken,
    },
  });
}

export async function tadUpdateInterviewDetails(
  request: any,
  isFeedback: boolean,
  accessToken?: string,
) {
  return axiosClient.patch('/tad-candidate/interview', request, {
    params: {
      isFeedback,
    },
    headers: {
      accessToken,
    },
  });
}

export async function tadDeleteInterviewData(req: any, accessToken: string) {
  return axiosClient.delete('/tad-candidate/interview', {
    data: req,
    headers: {
      accessToken,
    },
  });
}

export async function tadUpdateCandidate(request: TadCandidateDetailsModel, flag?: boolean) {
  return axiosClient.patch(
    '/tad-candidate',
    {
      ...request,
    },
    {
      params: { flag },
    },
  );
}

export async function tadUpdateCandidateRating(request: TadSjdCandidateInfo, type: string) {
  return axiosClient.patch(`/tad-candidate/rating/${type}`, {
    ...request,
  });
}

export async function tadAddCommentsToCandidate(request: any) {
  return axiosClient.post('/tad-candidate/comment', {
    ...request,
  });
}

export async function tadUploadDocument(
  file: File,
  candidateId: number | undefined,
  documentName: string,
) {
  const formData = new FormData();
  formData.append('document', file);
  formData.append(
    'details',
    JSON.stringify({
      candidateId,
      documentName,
    }),
  );
  return axiosClient.post('/tad-candidate/document', formData);
}

export async function tadDeleteDocument(candidateId: any, documentId: any) {
  return axiosClient.delete(`/tad-candidate/document/${candidateId}/${documentId}`);
}

export async function tadUpdateResume(resume: any, request: TadCandidateDetailsModel) {
  const formData = new FormData();
  delete request.resumeSkills;
  resume.forEach((item: any) => {
    if (item.file) {
      formData.append(`${item.resumeName}`, item.file);
    }
  });
  formData.append('resumeList', JSON.stringify(resume));
  formData.append('details', JSON.stringify(request));
  return axiosClient.patch('/tad-candidate/resume', formData);
}

export async function tadGetResume(resumeId: string, isNewIndex: boolean) {
  const url = `/tad-candidate/resume/${resumeId}?isNewIndex=${isNewIndex}`;
  return axiosClient.get(url);
}
