import PageHeader from './../common/page-header/page-header';
import {
  AlertColor,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getNameFromId } from '../../common/commonUtil';
import { LookupTypes } from '../../common/lookupTypes';
import { QuestionnaireModel } from '../../models/questionanaire.model';
import TableComponent from '../common/table';
import { getAllQuestionnaires, tagQuestionnaireToSjd } from '../../service/questionnaireService';
import LinkIcon from '@mui/icons-material/Link';
import { getAllSjd, getSjd } from '../../service/sjdService';
import useNavigate from '../../common/useNavigate';
import Notify from '../common/notify';
import { Loader } from '../common/loader';
import { useSearchParams } from 'react-router-dom';

const Questionnaire = () => {
  const [navigate] = useNavigate();
  const headers = [
    { id: 'name', label: 'Questionnaire Name' },
    { id: 'questionnaireType', label: 'Questionnaire Type' },
    { id: 'createdBy', label: 'Created By' },
    { id: 'createdOnString', label: 'Created On' },
    { id: 'actions', label: 'Actions' },
  ];
  const [questionnaireData, setQuestionnaireDate] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [sjd, setSjd] = useState([] as any[]);
  const [selectedSjd, setselectedSjd] = useState({} as any);
  const [questionnaireId, setQuestionnaireId] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [params] = useSearchParams();
  const sjdId = params.get('sjd');
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const getQuestionnaires = async () => {
    setLoading(true);
    try {
      const { data } = await getAllQuestionnaires();
      data.forEach((element: QuestionnaireModel) => {
        element.questionnaireType = getNameFromId(
          element.questionnaireTypeId,
          LookupTypes.QUESTIONNAIRE_TYPE,
        );
        element.createdOnString = moment(element.createdOn).format('MMM Do YYYY');
        element.createdBy = element.createdBy
          ? element.createdBy.firstName + ' ' + element.createdBy.lastName
          : '';
      });
      setQuestionnaireDate(data);
    } catch (e) {
      setToastMsg({
        severity: 'error',
        msg: 'Something went wrong. Please try later!',
      });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (val: any) => {
    if (val.questionnaire.find((e: any) => e.id == questionnaireId)) {
      setShowDuplicateError(true);
    } else {
      setShowDuplicateError(false);
      setselectedSjd(val);
    }
  };
  useEffect(() => {
    getQuestionnaires();
  }, []);

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const getActions = (row: any): React.ReactNode => {
    return (
      <Tooltip title='Tag to SJD'>
        <LinkIcon
          sx={{ cursor: 'pointer', color: '#a72037' }}
          onClick={() => {
            setQuestionnaireId(row.id);
            if (sjdId) {
              getSjdInfo();
            } else {
              getSjds();
            }
          }}
        />
      </Tooltip>
    );
  };

  const getSjds = () => {
    setShowDialog(true);
    setDialogLoading(true);
    getAllSjd()
      .then((resp) => {
        setSjd([...resp.data]);
      })
      .catch((_err) => {
        setSjd([]);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Unable to retrieve SJD list.',
        });
      })
      .finally(() => setDialogLoading(false));
  };

  const getSjdInfo = () => {
    setShowAlert(true);
    setDialogLoading(true);
    getSjd(sjdId)
      .then((resp) => {
        setSjd([resp.data]);
        if (resp.data.questionnaire.find((e: any) => e.id == questionnaireId)) {
          setShowAlert(false);
          setShowToast(true);
          setToastMsg({
            severity: 'error',
            msg: 'Selected questionnaire is already tagged to the SJD.',
          });
        }
      })
      .catch((_err) => {
        setSjd([]);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Unable to retrieve SJD list.',
        });
      })
      .finally(() => setDialogLoading(false));
  };

  const tagQuestionnaire = () => {
    setLoading(true);
    const id = sjdId ? sjdId : selectedSjd.id;
    tagQuestionnaireToSjd(questionnaireId, id)
      .then((_resp) => {
        setToastMsg({
          severity: 'success',
          msg: 'Questionnaire linked to SJD successfully',
        });
        setShowToast(true);
      })
      .catch((_err) => {
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      })
      .finally(() => {
        setLoading(false);
        setShowDialog(false);
        setShowAlert(false);
      });
  };

  return (
    <>
      <PageHeader title='Questionnaire'></PageHeader>

      <div>
        <TableComponent
          loading={loading}
          headers={headers}
          rowData={questionnaireData}
          getActions={getActions}
          onAddClick={() =>
            navigate([
              {
                label: 'Create',
                link: sjdId ? `/questionnaire/create?sjd=${sjdId}` : '/questionnaire/create',
              },
            ])
          }
        />
      </div>
      <Notify open={showToast} severity={toastMsg.severity} onClose={handleToastClose}>
        <span>{toastMsg.msg}</span>
      </Notify>
      {showDialog && (
        <Dialog
          maxWidth='md'
          open={showDialog}
          onClose={() => setShowDialog(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          {dialogLoading ? (
            <div style={{ width: '200px', height: '100px', display: 'flex', alignItems: 'center' }}>
              <Loader />
            </div>
          ) : (
            <>
              <DialogTitle id='alert-dialog-title'>Link Questionnaire to SJD</DialogTitle>
              <DialogContent sx={{ padding: '0px 25px' }}>
                <div style={{ width: '500px' }}>
                  <Autocomplete
                    id='combo-box-demo'
                    options={sjd}
                    sx={{ borderRadius: '5px !important', padding: '5px' }}
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option) => option.id + ' - ' + option.name}
                    isOptionEqualToValue={(option, value) => option.id && option.id === value.id}
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} label='' />}
                    onChange={(_e, val) => handleSelect(val)}
                  />
                  {showDuplicateError && (
                    <span style={{ color: '#ff0000' }}>
                      Questionnaire is already tagged to the selected SJD
                    </span>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='default-btn' onClick={() => setShowDialog(false)}>
                  Cancel
                </Button>
                <Button
                  className='default-btn bg-btn'
                  onClick={() => tagQuestionnaire()}
                  autoFocus
                  disabled={!selectedSjd.id || showDuplicateError}>
                  Link
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}

      {showAlert && (
        <Dialog
          maxWidth='md'
          open={showAlert}
          onClose={() => setShowAlert(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          {dialogLoading ? (
            <div style={{ width: '200px', height: '100px', display: 'flex', alignItems: 'center' }}>
              <Loader />
            </div>
          ) : (
            <>
              <DialogTitle id='alert-dialog-title'>Link Questionnaire to SJD</DialogTitle>
              <DialogContent sx={{ padding: '0px 25px' }}>
                Do you want to tag the questionnaire to SJD{' '}
                <strong>{`${sjd[0].id} - ${sjd[0].name}`}</strong>
              </DialogContent>
              <DialogActions>
                <Button className='default-btn' onClick={() => setShowAlert(false)}>
                  Cancel
                </Button>
                <Button className='default-btn bg-btn' onClick={() => tagQuestionnaire()} autoFocus>
                  Link
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};
export default Questionnaire;
