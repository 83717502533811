import {
  Alert,
  Snackbar,
  AlertColor,
  SnackbarOrigin,
  Button,
  IconButton,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as SuccessIcon } from './../assets/common-assets/toast_check_circle.svg';
import { ReactComponent as WarningIcon } from './../assets/common-assets/toast_warning_amber.svg';
import { ReactComponent as ErrorIcon } from './../assets/common-assets/toast-cancel.svg';
import { ReactComponent as InfoIcon } from './../assets/common-assets/toast_info.svg';

interface NotifyProps {
  open: boolean;
  onClose: () => void;
  severity: AlertColor;
  title?: string;
  message?: string;
  actionButtons?: Array<{
    label: string;
    onClick: () => void;
    variant: 'text' | 'contained' | 'outlined';
  }>;
  duration?: number;
  position?: SnackbarOrigin;
  transition?: typeof Slide;
  disableAutoHide?: boolean;
  maxWidth?: string | number;
  sx?: object;
}

const Notify = (props: NotifyProps) => {
  const {
    open,
    onClose,
    severity,
    title,
    message,
    actionButtons,
    duration = 4000,
    position = { vertical: 'bottom', horizontal: 'center' },
    transition = Slide,
    disableAutoHide = false,
    maxWidth = '100%',
    sx = {},
  } = props;

  const getIcon = (type: string) => {
    if (type === 'success') {
      return <SuccessIcon />;
    } else if (type === 'error') {
      return <ErrorIcon />;
    } else if (type === 'warning') {
      return <WarningIcon />;
    } else if (type === 'info') {
      return <InfoIcon />;
    }
  };

  const getColor = (type: string) => {
    if (type === 'success') {
      return 'var(--Status-Green-500, #4BB543)';
    } else if (type === 'error') {
      return 'var(--Status-Red-500, #DC3857)';
    } else if (type === 'warning') {
      return 'var(--Status-Orange-500, #F26C20)';
    } else if (type === 'info') {
      return 'var(--Primary-Blue-500, #1976D2)';
    }
  };

  const renderActionButtons = () => {
    return (
      <Stack direction='row' spacing={1} style={{ marginTop: '1rem', justifyContent: 'center' }}>
        {actionButtons?.map((button, idx) => (
          <Button
            key={idx}
            variant={button.variant}
            onClick={button.onClick}
            sx={{
              minWidth: '90px',
              borderRadius: '0.375rem',
              height: '2.25rem',
              color: button.label === 'Cancel' ? '#333' : '#fff',
            }}>
            {button.label}
          </Button>
        ))}
      </Stack>
    );
  };

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={disableAutoHide ? null : duration}
      anchorOrigin={position}
      TransitionComponent={transition}
      sx={{ maxWidth, ...sx }}>
      <Alert
        severity={severity}
        icon={getIcon(severity)}
        sx={{
          minWidth: '21rem',
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '0.375rem',
          border: '1px solid',
          borderColor: getColor(severity),
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
        action={
          <IconButton aria-label='close' size='small' onClick={onClose} sx={{ padding: '0' }}>
            <CloseIcon fontSize='small' />
          </IconButton>
        }>
        {title && (
          <Typography
            variant='subtitle1'
            sx={{ fontWeight: 600, marginBottom: '8px', color: getColor(severity) }}>
            {title}
          </Typography>
        )}
        <Typography
          variant='body2'
          component='div'
          style={{ color: '#333' }}
          dangerouslySetInnerHTML={{ __html: message || '' }}
        />
        {actionButtons && renderActionButtons()}
      </Alert>
    </Snackbar>
  );
};

export default Notify;
