import {
  Paper,
  InputLabel,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  AlertColor,
} from '@mui/material';
import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  CandidateDetailsModel,
  CandidateEmploymentModel,
} from '../../../models/candidate-form.model';
import { useState, useEffect } from 'react';
import '../candidate.scss';
import SectionHeaderWithEdit from '../../common/section-heading';
import CandidateDataTable, { HeaderProps } from './candidate-data-table';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import dateFormat from 'dateformat';
import { isPermissionsWrite } from '../../../common/commonUtil';
import { MODULE, SubModuleTypes } from '../../../constants/common';
import { deleteEmployment } from '../../../service/candidateService';
import Notify from '../../common/notify';
import ConfirmationDialog from '../../common/confirmation-dialog';
interface Props {
  values: CandidateDetailsModel;
  sjdId: any;
  mode?: 'create-page' | 'details-page';
  next?: (value: CandidateDetailsModel) => void;
  previous?: () => void;
}

const CandidateEmploymentForm = ({
  values,
  sjdId,
  mode = 'create-page',
  next = () => {},
  previous = () => {},
}: Props) => {
  const candidateEmploymentSchema = Yup.object().shape({
    companyName: Yup.string()
      .max(200, 'Company Name should not exceed 200 characters')
      .required('Company Name is required'),
    responsibilities: Yup.string()
      .max(5000, 'Responsibilities should not exceed 5000 characters')
      .nullable(),
    location: Yup.string().max(200, 'Location should not exceed 200 characters').nullable(),
    designation: Yup.string().max(100, 'Designation should not exceed 100 characters').nullable(),
  });
  const formik = useFormik({
    initialValues: {
      id: undefined,
      companyName: '',
      designation: '',
      location: '',
      responsibilities: '',
      startDate: '',
      endDate: '',
    } as CandidateEmploymentModel,
    validationSchema: candidateEmploymentSchema,
    onSubmit: (values) => {
      setEmploymentList([...employmentList, values]);
      formik.resetForm();
      setSubmitted(false);
      setShowDialog(false);
      setEditValue(undefined as unknown as CandidateEmploymentModel);
    },
  });
  const [errorMessage, setErrorMessage] = useState([] as any[]);
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode == 'create-page');
  const [employmentList, setEmploymentList] = useState([] as CandidateEmploymentModel[]);
  const [objectError, setObjectError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [currentEmployer, setCurrentEmployer] = useState(values.lastEmployer);

  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [deleteId, setDeleteId] = useState(0);

  const [editValue, setEditValue] = useState(undefined as unknown as CandidateEmploymentModel);
  useEffect(() => {
    if (values.employmentDetails?.length) {
      setEmploymentList(values.employmentDetails);
    }
  }, []);

  const getError = (formik: FormikProps<any>, fieldName: string) => {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  };

  const tableHeaders: HeaderProps[] = [
    {
      name: 'Company Name *',
      key: 'companyName',
    },
    {
      name: 'Designation',
      key: 'designation',
    },
    {
      name: 'Start Date',
      key: 'startDate',
      type: 'date',
      format: 'mm/yyyy',
    },
    {
      name: 'End Date',
      key: 'endDate',
      type: 'date',
      format: 'mm/yyyy',
    },
    {
      name: 'Location',
      key: 'location',
    },
    {
      name: 'Responsibilities',
      key: 'responsibilities',
      type: 'long-text',
    },
  ];

  const handleEdit = (index: number) => {
    formik.setValues(employmentList[index]);
    setEditValue(employmentList[index]);
    handleDelete(index);
    setShowDialog(true);
  };

  const handleDelete = (index: number) => {
    const list = employmentList;
    list.splice(index, 1);
    setEmploymentList([...list]);
  };
  const deleteEmploymentData = () => {
    const list = employmentList;
    list[deleteId].companyName === values.lastEmployer
      ? setCurrentEmployer(undefined)
      : setCurrentEmployer(currentEmployer);
    if (list[deleteId].id == undefined) {
      setDeleteDialog(false);
      list.splice(deleteId, 1);
      setEmploymentList([...list]);
    } else {
      list[deleteId].companyName === currentEmployer
        ? (values.lastEmployer = undefined)
        : values.lastEmployer;
      deleteEmployment({
        candidateId: values.id,
        sjdId: sjdId,
        idToBeDeleted: list[deleteId].id,
      })
        .then(() => {
          setDeleteDialog(false);
          list.splice(deleteId, 1);
          setEmploymentList([...list]);
          setToastMsg({
            severity: 'success',
            msg: 'Employment deleted successfully',
          });
          setShowToast(true);
        })
        .catch(() => {
          setToastMsg({
            severity: 'error',
            msg: 'Something went wrong. Please try later!',
          });
          setShowToast(true);
        });
    }
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialog(false);
  };

  const handleNextClick = () => {
    setObjectError(false);
    let isValid = true;
    employmentList.forEach((emp) => {
      if (!candidateEmploymentSchema.isValidSync(emp)) {
        setObjectError(true);
        candidateEmploymentSchema.validate(emp).catch((validationError) => {
          const newErrorMessages: any[] = [];
          validationError.errors.map((error: any) => {
            newErrorMessages.push(`For ${emp.companyName} - ${error}`);
          });
          setErrorMessage((prevErrorMessages) => [
            ...prevErrorMessages,
            ...newErrorMessages.filter((message) => !prevErrorMessages.includes(message)),
          ]);
        });
        isValid = false;
      }
    });
    if (isValid) next({ employmentDetails: employmentList });
  };

  return (
    <div className='candidate'>
      <Paper sx={{ padding: [5, 2], boxShadow: 'none' }}>
        {mode === 'details-page' && (
          <SectionHeaderWithEdit
            text='Employment'
            edit={edit}
            hideIcon={
              values.sjdCandidateInfo?.length == 0 ||
              !isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_EMPLOYEMENT)
            }
            click={() => {
              if (edit) {
                values.lastEmployer = currentEmployer;
                next({ employmentDetails: employmentList });
              }
              setEdit(!edit);
            }}
          />
        )}
        {objectError && (
          <div className='mt-0 form-err-msg'>
            {errorMessage.map((error, i) => (
              <p key={i}>{error}</p>
            ))}{' '}
          </div>
        )}
        <CandidateDataTable
          component={SubModuleTypes.CANDIDATE_EMPLOYEMENT}
          currentEmployer={values.lastEmployer}
          setCurrentEmployer={(data: any) => setCurrentEmployer(data)}
          headers={tableHeaders}
          data={employmentList}
          readonly={!edit}
          edit={handleEdit}
          remove={(i) => {
            setDeleteId(i);
            setDeleteDialog(true);
          }}></CandidateDataTable>
        <Notify open={showToast} severity={toastMsg.severity} onClose={() => setShowToast(false)}>
          <span>{toastMsg.msg}</span>
        </Notify>

        {edit && (
          <div className='center-contents' style={{ marginBottom: '30px' }}>
            <Button
              className='default-btn'
              sx={{ marginLeft: 3 }}
              onClick={() => {
                setShowDialog(true);
              }}>
              Add Employment
            </Button>
          </div>
        )}
      </Paper>
      {mode === 'create-page' && (
        <div className='bottom-btn-block'>
          <Button sx={{ marginLeft: 3 }} onClick={previous} className='default-btn bg-btn'>
            Previous
          </Button>
          <Button
            sx={{ marginLeft: 3 }}
            onClick={() => {
              handleNextClick();
            }}
            className='default-btn bg-btn'>
            Next
          </Button>
        </div>
      )}
      {showDialog && (
        <Dialog
          open={showDialog}
          fullWidth={true}
          maxWidth='md'
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>Add Employment</DialogTitle>
          <DialogContent>
            <form
              style={{ marginBottom: '10px' }}
              onSubmit={(values) => {
                setSubmitted(true);
                formik.handleSubmit(values);
              }}>
              <div className='row'>
                <div className='field'>
                  <InputLabel className='field-label' id='companyName'>
                    Company Name *
                  </InputLabel>
                  <TextField
                    value={formik.values.companyName}
                    fullWidth
                    id='companyName'
                    size='small'
                    name='companyName'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className='mt-0 form-err-msg'>{getError(formik, 'companyName')}</div>
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='designation'>
                    Designation
                  </InputLabel>
                  <TextField
                    value={formik.values.designation}
                    fullWidth
                    id='designation'
                    size='small'
                    name='designation'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className='mt-0 form-err-msg'>{getError(formik, 'designation')}</div>
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='startDate'>
                    Start Date
                  </InputLabel>
                  <DatePicker
                    className='date-picker'
                    format='MM/YYYY'
                    views={['year', 'month']}
                    value={formik.values.startDate ? dayjs(formik.values.startDate) : null}
                    onChange={(value) => {
                      formik.setFieldValue(
                        'startDate',
                        dateFormat(value?.toString(), 'isoDateTime'),
                        true,
                      );
                    }}
                  />
                  <div className='mt-0 form-err-msg'>{getError(formik, 'startDate')}</div>
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='endDate'>
                    End Date
                  </InputLabel>
                  <DatePicker
                    className='date-picker'
                    format='MM/YYYY'
                    views={['year', 'month']}
                    value={formik.values.endDate ? dayjs(formik.values.endDate) : null}
                    onChange={(value) => {
                      formik.setFieldValue(
                        'endDate',
                        dateFormat(value?.toString(), 'isoDateTime'),
                        true,
                      );
                    }}
                  />
                  <div className='mt-0 form-err-msg'>{getError(formik, 'endDate')}</div>
                </div>
              </div>
              <div className='row' style={{ marginTop: '20px' }}>
                <div style={{ width: '25%', padding: '0px 30px 0px 20px' }}>
                  <InputLabel className='field-label' id='location'>
                    Location
                  </InputLabel>
                  <TextField
                    value={formik.values.location}
                    fullWidth
                    id='location'
                    size='small'
                    name='location'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className='mt-0 form-err-msg'>{getError(formik, 'location')}</div>
                </div>
                <div style={{ width: '50%', padding: '0px 30px' }}>
                  <InputLabel className='field-label' id='responsibilities'>
                    Responsibilities
                  </InputLabel>
                  <textarea
                    className='textarea-field'
                    name='responsibilities'
                    id='responsibilities'
                    onChange={formik.handleChange}
                    value={formik.values.responsibilities}
                    onBlur={formik.handleBlur}
                    style={{ width: '100%' }}
                    rows={7}></textarea>
                  <div className='mt-0 form-err-msg'>{getError(formik, 'responsibilities')}</div>
                </div>
              </div>
            </form>
            <div className='center-contents' style={{ marginBottom: '30px' }}>
              <Button
                className='default-btn'
                sx={{ marginLeft: 3 }}
                onClick={() => {
                  setSubmitted(true);
                  formik.handleSubmit();
                }}>
                Add
              </Button>
              <Button
                className='default-btn'
                sx={{ marginLeft: 3 }}
                onClick={() => {
                  formik.resetForm();
                  setShowDialog(false);
                  if (editValue) setEmploymentList([...employmentList, editValue]);
                }}>
                Cancel
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {deleteDialog && (
        <ConfirmationDialog
          title='Confirm'
          content={`Are you sure you want to delete employment "${employmentList[deleteId].companyName}" ?`}
          onClose={handleDeleteDialogClose}
          btn1Label='Cancel'
          btn2Label='Delete'
          btn1Action={handleDeleteDialogClose}
          btn2Action={deleteEmploymentData}
        />
      )}
    </div>
  );
};

export default CandidateEmploymentForm;
