import React from 'react';
import { Paper } from '@mui/material';

const Notifications = () => {
  return (
    <div style={{ width: '100%', gap: '1.87rem', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          height: '8.8125rem',
          width: '100%',
        }}>
        <Paper
          className='new-job-requisition'
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1.88rem',
              gap: '7px',
            }}>
            <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Notifications</span>
            <span
              style={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
              }}>
              Set notifications for smarter decisions
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              marginRight: '2.5rem',
              alignItems: 'center',
              gap: '2.31rem',
            }}></div>
        </Paper>
      </div>
      <div
        
        style={{
          width: '100%',
          height: '71.1875rem',
        }}>
        <Paper
          sx={{
            width: '100%',
            height: '100%',
          }}></Paper>
      </div>
    </div>
  );
};

export default Notifications;
