import {
  Collapse,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Search } from '@mui/icons-material';
import dayjs from 'dayjs';

export const NewHireReport = (props: { data: any[] }) => {
  const { data } = props;
  const [filteredData, setFilteredData] = useState({} as any);
  const [empCountObj, setEmpCountObj] = useState({} as any);

  useEffect(() => {
    const groupedData = {} as any;

    data.forEach((item) => {
      const key = item.createdByName;
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(item);

      empCountObj[key] = (empCountObj[key] || 0) + 1;
    });

    setFilteredData(groupedData);
    setEmpCountObj(empCountObj);
  }, [data]);

  const Row = ({ employeeData, employeeName }: any) => {
    const [open, setOpen] = useState(false);
    return (
      <React.Fragment>
        <TableRow>
          <TableCell sx={{ textTransform: 'capitalize' }}>{employeeName}</TableCell>
          <TableCell>{employeeData.length}</TableCell>
          {/* <TableCell>{employeeData.filter((d: any) => d.status === 'completed').length}</TableCell> */}
          <TableCell>
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ margin: '10px 50px 10px 50px' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Table size='small' aria-label='reporting-table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Employee Name</TableCell>
                    <TableCell>Manager Name</TableCell>
                    <TableCell>Level</TableCell>
                    <TableCell>Hired On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeData.map((data: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{data.employeeName}</TableCell>
                      <TableCell>{data.mangerName}</TableCell>
                      <TableCell>{data.levelName}</TableCell>
                      <TableCell>{dayjs(data.createdOn).format('DD-MMM-YYYY')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const requestSearch = (_searchedVal: string) => {
    if (!_searchedVal || _searchedVal.length === 0) {
      setFilteredData(() => {
        const groupedData: { [key: string]: any[] } = {};
        data.forEach((item) => {
          const key = item.createdByName;
          if (!groupedData[key]) {
            groupedData[key] = [];
          }
          groupedData[key].push(item);
        });
        return groupedData;
      });
      return;
    }

    const searchParameters = ['employeeName', 'managerName', 'levelName', 'createdOn'];
    const filteredRows: { [key: string]: any[] } = {};

    data.forEach((element) => {
      for (const key of searchParameters) {
        if (
          typeof element[key] === 'string' &&
          element[key].toLowerCase().includes(_searchedVal.toLowerCase())
        ) {
          const groupKey = element.createdByName;
          if (!filteredRows[groupKey]) {
            filteredRows[groupKey] = [];
          }
          filteredRows[groupKey].push(element);
          break;
        }
      }
    });

    setFilteredData(filteredRows);
  };

  return (
    <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
      <div className='report-table-header'>
        <h1>New Hire</h1>
        <div className='export-search-block'>
          <OutlinedInput
            id='table-search'
            type='text'
            placeholder='Search...'
            className='search-bar-report'
            onChange={(event) => requestSearch(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <Search />
              </InputAdornment>
            }
          />
        </div>
      </div>
      <TableContainer>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '40%' }}>Name</TableCell>
              <TableCell sx={{ width: '40%' }}>No Of Hires Details</TableCell>
              {/* <TableCell>No Of Task Status Details</TableCell> */}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(filteredData).length > 0 &&
              Object.keys(filteredData).map((employeeName: any, index: number) => {
                const employeeData = filteredData[employeeName];
                return <Row key={index} employeeData={employeeData} employeeName={employeeName} />;
              })}
            {Object.keys(filteredData).length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <span>No results found</span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
