import { Tooltip, Stack, Typography } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface SectionProps {
  text: string;
  edit: boolean;
  hideIcon?: boolean;
  click?: () => void;
  getIcon?: () => React.ReactNode;
}

const SectionHeaderWithEdit = ({
  text,
  edit,
  hideIcon = false,
  click = () => {},
  getIcon,
}: SectionProps) => {
  return (
    <>
      <Stack spacing={1} className='detail-header'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            color: '#a72037',
          }}>
          <div>
            <Typography variant='h5' fontWeight={700}>
              {text}
            </Typography>
          </div>
          {!hideIcon && (
            <div style={{ cursor: 'pointer', color: '#A72037' }}>
              {getIcon ? (
                getIcon()
              ) : (
                <>
                  {edit ? (
                    <Tooltip title='Save'>
                      <CheckCircleRoundedIcon fontSize='small' onClick={click} />
                    </Tooltip>
                  ) : (
                    <Tooltip title='Edit'>
                      <DriveFileRenameOutlineIcon fontSize='small' onClick={click} />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Stack>
    </>
  );
};

export default SectionHeaderWithEdit;
