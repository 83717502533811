import React, { useEffect, useState } from 'react'; // Import React
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
} from '@mui/material'; // Import Material-UI components
// import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg'; // Import your icon
import { TadCandidateCertifications } from '../../../models/tad-candidate-form-model';
import dayjs from 'dayjs';

interface CandidateEducationProps {
  certificates: TadCandidateCertifications[] | undefined; // Assuming certificates is an array
}

const CandidateCertificateTable: React.FC<CandidateEducationProps> = ({ certificates }) => {
  const [selectedUsers, setSelectedUsers] = useState<TadCandidateCertifications[]>([]); // Update type

  useEffect(() => {
    if (certificates) {
      setSelectedUsers(certificates);
    }
  }, [certificates]);

  return (
    <Paper style={{ height: '26.125rem', width: '100%' }}>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
        }}>
        <div style={{ display: 'flex' }}>
          <span
            className='heading'
            style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
            Certifications
          </span>
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          <MoreVertIcon style={{ marginRight: '10px' }} />
        </div> */}
      </div>

      {/* Table section */}
      <div className='qc-table' style={{ marginLeft: '2.02rem', marginRight: '1.25rem' }}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: '0.125rem solid #C1C0C0' }}>
              <TableRow>
                <TableCell>Certification Name</TableCell>
                <TableCell>Issued By</TableCell>
                <TableCell>Issued On</TableCell>
                <TableCell>Expiry Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedUsers.map((item, index) => (
                <TableRow key={index} style={{ backgroundColor: 'transparent' }}>
                  <TableCell>
                    <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                      {item.certificationName}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                      {item.issuedBy}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                      {dayjs(item.startDate).format('DD/MM/YYYY hh:mm A')}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                      {dayjs(item.endDate).format('DD/MM/YYYY hh:mm A')}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
};

export default CandidateCertificateTable;
