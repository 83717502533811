import React, { useEffect, useState } from 'react';
import {
  Box,
  // Button,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Rating,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';

import SectionHeaderWithEdit from '../common/section-heading';
import { LookupTypes } from '../../common/lookupTypes';
import { getNameFromId, isPermissionsWrite } from '../../common/commonUtil';
import { QuestionnaireModel } from '../../models/questionanaire.model';
import { getCandidateAnswers, saveCandidateAnswers } from '../../service/questionnaireService';
import { MODULE, SubModuleTypes } from '../../constants/common';
import useNavigate from '../../common/useNavigate';

interface CandidateQuestionnaireProps {
  mode?: 'sjd-page' | 'candidate-page';
  sjd: any;
  candidate?: any;
  setLoading?: (flag: boolean) => void;
  reloadCandidate?: () => void;
  alert?: (msg: string, success: boolean) => void;
}
const CandidateQuestionnaire: React.FC<CandidateQuestionnaireProps> = ({
  mode,
  sjd,
  candidate,
  setLoading = () => {},
  reloadCandidate = () => {},
  alert = () => {},
}: CandidateQuestionnaireProps) => {
  const [questionnaireData, setQuestionnaireData] = useState([] as QuestionnaireModel[]);
  const [existingAnswers, setExistingAnswers] = useState([]);
  const [value, setValue] = useState(0);
  const [edit, setEdit] = useState(false);
  const [navigate] = useNavigate();

  const getQuestions = () => {
    sjd.questionnaire.forEach((element: QuestionnaireModel) => {
      element.questions.forEach((question) => {
        question.inputType = getNameFromId(question.answerTypeId, LookupTypes.ANSWER_TYPE);
      });
    });
    // setQuestionnaireData([...data]);
    if (candidate && candidate.id) {
      getExistingAnswers(sjd.questionnaire);
    } else {
      setQuestionnaireData(sjd.questionnaire);
    }
  };

  const getExistingAnswers = (questionnaireList: QuestionnaireModel[]) => {
    setLoading(true);
    getCandidateAnswers(sjd.id, candidate.id)
      .then((resp) => {
        setExistingAnswers((_prev) => {
          return resp.data;
        });
        questionnaireList.forEach((questionnaire, i) => {
          questionnaire.questions.forEach((question, j) => {
            const found: any[] = resp.data.filter(
              (e: any) => e.questionnaireId == questionnaire.id && e.questionId == question.id,
            );
            if (found.length > 0) {
              questionnaireList[i].questions[j].answer = found[0].answer;
            }
          });
        });
        setQuestionnaireData((_prev) => {
          return questionnaireList;
        });
      })
      .catch((_err) => {
        setExistingAnswers((_prev) => {
          return [];
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getQuestions();
  }, [sjd]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFieldChange = (event: any, firstIndex: number, secondIndex: number) => {
    event.preventDefault();
    setQuestionnaireData((prevQue) => {
      const qData = [...prevQue];
      qData[firstIndex].questions[secondIndex].answer = event.target.value;
      return qData;
    });
  };
  const handleSubmit = () => {
    setQuestionnaireData((prevQue) => {
      const qData = [...prevQue];
      qData[value].isSubmitted = true;
      return qData;
    });
    let valid = true;
    questionnaireData[value].questions.forEach((q) => {
      if (q.required && (!q.answer || q.answer == '')) {
        valid = false;
      }
    });
    const updatedAnswers: any[] = [];
    if (valid) {
      questionnaireData[value].questions.forEach((q) => {
        const found: any[] = existingAnswers.filter(
          (e: any) => e.questionnaireId == questionnaireData[value].id && e.questionId == q.id,
        );
        if (found.length > 0) {
          found[0].answer = q.answer;
          updatedAnswers.push(found[0]);
        } else {
          updatedAnswers.push({
            sjdId: sjd.id,
            candidateId: candidate.id,
            questionnaireId: questionnaireData[value].id,
            questionId: q.id,
            answer: q.answer,
          });
        }
      });
      setLoading(true);
      saveCandidateAnswers(updatedAnswers)
        .then((_resp) => {
          reloadCandidate();
          setEdit(false);
          alert('Response saveed successfully!', true);
        })
        .catch((_err) => {
          alert('Unable to save response. Please try again later!', false);
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
      <SectionHeaderWithEdit
        text='Questionnaire'
        hideIcon={!isPermissionsWrite(MODULE.SJD, SubModuleTypes.JD_QUESTIONNAIRE)}
        edit={edit}
        click={() => {
          if (edit) {
            handleSubmit();
          } else {
            if (mode == 'sjd-page') {
              navigate([
                {
                  label: 'Questionnaire',
                  link: `/questionnaire?sjd=${sjd.id}`,
                },
              ]);
            } else {
              setEdit(!edit);
            }
          }
        }}
      />

      <form onSubmit={(e) => e.preventDefault()}>
        <Tabs value={value} onChange={handleChange} scrollButtons={true}>
          {questionnaireData.map((item) => (
            <Tab className='q-tab' key={item.id} label={item.name} />
          ))}
        </Tabs>
        {questionnaireData.map((item, i) =>
          item.questions.map((question, ind) => (
            <div
              key={ind}
              role='tabpanel'
              hidden={value !== i}
              id={`simple-tabpanel-${i}`}
              aria-labelledby={`simple-tab-${i}`}>
              {value === i && (
                <Box sx={{ mt: 2, pt: 1, pb: 2, borderBottom: '1.5px solid #D9D9D9' }}>
                  {question.inputType === 'Multiline' && (
                    <div>
                      <InputLabel className='q-label' htmlFor={`text-field-${question.id}`}>
                        Q&#41; {question.question}
                        {question.required && <span>*</span>}
                      </InputLabel>
                      {mode === 'candidate-page' && (
                        <>
                          <TextField
                            disabled={
                              !isPermissionsWrite(
                                MODULE.SJD,
                                SubModuleTypes.CANDIDATE_QUESTIONNAIRE,
                              ) || !edit
                            }
                            className='q-text-field'
                            id={`text-field-${question.id}`}
                            value={question.answer}
                            onChange={(event: any) => handleFieldChange(event, i, ind)}
                            autoFocus={true}
                          />
                          {item.isSubmitted &&
                            question.required &&
                            (question.answer === '' || question.answer === undefined) && (
                              <div className='err-msg mt-0'>This field is required</div>
                            )}
                        </>
                      )}
                    </div>
                  )}
                  {question.inputType === 'Yes or No' && (
                    <>
                      <FormLabel className='q-label' id={`radio-btn-group-${question.id}`}>
                        Q&#41; {question.question} {question.required && <span>*</span>}
                      </FormLabel>
                      {mode === 'candidate-page' && (
                        <RadioGroup
                          aria-labelledby={`radio-btn-group-${question.id}`}
                          defaultValue='female'
                          className='q-radio-group'
                          value={question.answer && question.answer.toString()}
                          name={`radio-${question.id}`}>
                          <FormControlLabel
                            className='q-radio-label'
                            value='yes'
                            control={
                              <Radio
                                disabled={
                                  !isPermissionsWrite(
                                    MODULE.SJD,
                                    SubModuleTypes.CANDIDATE_QUESTIONNAIRE,
                                  ) || !edit
                                }
                                className='q-radio-input'
                              />
                            }
                            label='Yes'
                            name={`radio-${question.id}`}
                            onChange={(event: any) => handleFieldChange(event, i, ind)}
                          />
                          <FormControlLabel
                            className='q-radio-label'
                            value='no'
                            onChange={(event: any) => handleFieldChange(event, i, ind)}
                            control={
                              <Radio
                                disabled={
                                  !isPermissionsWrite(
                                    MODULE.SJD,
                                    SubModuleTypes.CANDIDATE_QUESTIONNAIRE,
                                  ) || !edit
                                }
                                className='q-radio-input'
                              />
                            }
                            name={`radio-${question.id}`}
                            label='No'
                          />
                        </RadioGroup>
                      )}
                      {item.isSubmitted &&
                        question.required &&
                        (question.answer === '' || question.answer === undefined) && (
                          <div className='err-msg mt-0'>This field is required</div>
                        )}
                    </>
                  )}
                  {question.inputType === 'Rating' && (
                    <>
                      <InputLabel className='q-label' htmlFor={`rating-field-${question.id}`}>
                        Q&#41; {question.question} {question.required && <span>*</span>}
                      </InputLabel>
                      {mode === 'candidate-page' && (
                        <Rating
                          disabled={
                            !isPermissionsWrite(
                              MODULE.SJD,
                              SubModuleTypes.CANDIDATE_QUESTIONNAIRE,
                            ) || !edit
                          }
                          value={Number(question.answer)}
                          id={`rating-field-${question.id}`}
                          onChange={(event: any) => handleFieldChange(event, i, ind)}
                        />
                      )}
                      {item.isSubmitted &&
                        question.required &&
                        (question.answer === '' ||
                          question.answer === undefined ||
                          question.answer === 0) && (
                          <div className='err-msg mt-0'>This field is required</div>
                        )}
                    </>
                  )}
                </Box>
              )}
            </div>
          )),
        )}
        {/* {mode === 'candidate-page' && (
          <div className='s-btn-block'>
            <Button
              disabled={!isUserRoleIn([ROLES.LEAD, ROLES.RECRUITER]) || !edit}
              onClick={() => handleSubmit()}
              className='bg-btn sub-btn'>
              Submit
            </Button>
          </div>
        )} */}
      </form>
    </Paper>
  );
};
export default CandidateQuestionnaire;
