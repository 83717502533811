import {
  Typography,
  Link,
  OutlinedInput,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useState, useEffect } from 'react';
import { Loader } from '../common/loader';
import dateFormat from 'dateformat';
import useNavigate from '../../common/useNavigate';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getActiveInterviews } from '../../service/dashboardService';

const ActiveInterviews = () => {
  const [data, setData] = useState([] as any[]);
  const [scheduledInterviews, setScheduledInterviews] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null as any as dayjs.Dayjs);
  const [selectedScheduledBy, setSelectedScheduledBy] = useState([] as any[]);
  const [navigate] = useNavigate();
  const [scheduledByMap, setScheduledByMap] = useState([] as any[]);
  const [scheduledByList, setScheduledByList] = useState([] as any[]);

  useEffect(() => {
    const scheduledBy = scheduledInterviews.reduce((acc: any, interview: any) => {
      const { createdByName } = interview;
      if (!acc[createdByName]) {
        acc[createdByName] = 0;
      }
      acc[createdByName]++;
      return acc;
    }, {});

    const keyList = Object.keys(scheduledBy);
    setScheduledByMap(scheduledBy);
    setScheduledByList(keyList);
  }, [scheduledInterviews]);

  useEffect(() => {
    if (startDate && startDate.isValid()) {
      fetchInterviews(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    setStartDate(dayjs());
  }, []);

  const fetchInterviews = (date: dayjs.Dayjs) => {
    const fromDate = date.startOf('day');
    const toDate = date.endOf('day');
    setLoading(true);
    getActiveInterviews(
      fromDate.format('YYYY-MM-DDTHH:mm:ssZ'),
      toDate.format('YYYY-MM-DDTHH:mm:ssZ'),
    )
      .then((resp) => {
        setData(resp.data);
        setScheduledInterviews(resp.data);
      })
      .catch((_err) => {})
      .finally(() => setLoading(false));
  };

  const minDate = dayjs().subtract(1, 'month');

  const handleFilter = (newValue: any[]) => {
    let filtered = scheduledInterviews;

    if (newValue.length > 0) {
      filtered = scheduledInterviews.filter((names) => newValue.includes(names.createdByName));
    }
    setData(filtered);
  };

  const redirectToCandidatePage = (data: any) => {
    navigate(
      [
        {
          label: 'Job Definitions',
          link: '/source-job-definition',
        },
        {
          label: `${data['sjdId']} - ${data['sjdName']}`,
          link: `/source-job-definition/${btoa(data['sjdId'])}`,
        },
        {
          label: `${data['candidateName']}`,
          link: `/source-job-definition/candidate/${btoa(data['sjdId'])}/${btoa(
            data['candidateId'],
          )}`,
        },
      ],
      true,
    );
  };

  return (
    <div className='active-interviews'>
      <Stack spacing={1}>
        <Typography variant='h6' fontWeight={700}>
          Interview Schedules
        </Typography>

        <div
          style={{
            display: 'flex',
            marginBottom: '10px',
            justifyContent: 'space-between',
          }}>
          <DatePicker
            sx={{ marginRight: '15px' }}
            className='date-picker-funnel'
            format='DD-MM-YYYY'
            minDate={minDate}
            value={startDate}
            onChange={(value) => {
              setStartDate(dayjs(value));
              setSelectedScheduledBy([]);
            }}
          />
          <Select
            size='small'
            displayEmpty
            multiple
            value={selectedScheduledBy}
            onChange={(event: any) => {
              const temp = event.target.value.pop();
              if (!temp || temp == -1) {
                setSelectedScheduledBy([]);
                handleFilter([]);
              } else {
                setSelectedScheduledBy([...event.target.value, temp]);
                handleFilter([...event.target.value, temp]);
              }
            }}
            input={<OutlinedInput />}
            sx={{
              borderRadius: '5px',
              height: '25px',
              width: '220px',
              verticalAlign: '3px',
              color: 'black',
            }}
            renderValue={() => {
              return (
                <p>
                  {selectedScheduledBy.length == 0 ? (
                    <span className='status'>Scheduled By</span>
                  ) : selectedScheduledBy.length == 1 ? (
                    <span className='status'>{selectedScheduledBy[0]}</span>
                  ) : (
                    <span className='status'>Multiple selected</span>
                  )}
                </p>
              );
            }}>
            <MenuItem
              value={-1}
              sx={{ fontSize: 'small' }}
              disabled={selectedScheduledBy.length == 0}>
              <ListItemText primary='Clear All' />
            </MenuItem>
            {scheduledByList.map((list, index) => (
              <MenuItem key={index} value={list} sx={{ fontSize: 'small' }}>
                <Checkbox checked={selectedScheduledBy.indexOf(list) > -1} size='small' />
                <ListItemText primary={`${list} (${scheduledByMap[list]})`} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </Stack>
      {loading && <Loader />}
      {!loading && (
        <>
          {data.length == 0 && (
            <Typography
              variant='body1'
              style={{
                textAlign: 'center',
                marginTop: '30px',
                color: '#888',
                fontStyle: 'italic',
              }}>
              No interviews found based on selected filters.
            </Typography>
          )}
          {data.map((d, index) => (
            <div className='interview-info' key={index}>
              <div>
                <div>
                  <span>Job Definition</span>
                  <br />
                  <span>{d.sjdName}</span>
                </div>
                <div>
                  <span>Candidate Name</span>
                  <br />
                  <span>
                    <Link sx={{ cursor: 'pointer' }} onClick={() => redirectToCandidatePage(d)}>
                      {d.candidateName}
                    </Link>
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span>Interviewer</span>
                  <br />
                  <span>{d.evaluatorName}</span>
                </div>
                <div>
                  <span>Level</span>
                  <br />
                  <span>{d.interviewLevel}</span>
                </div>
              </div>
              <div>
                <div>
                  <span>Schedule</span>
                  <br />
                  <span>
                    {dateFormat(new Date(d.startDate), 'dd/mm/yyyy')}
                    <br />
                    {dateFormat(new Date(d.startDate), 'hh:MM TT')}
                    {' - '}
                    {dateFormat(new Date(d.endDate), 'hh:MM TT')}
                  </span>
                </div>
                <div>
                  <span>Scheduled By</span>
                  <br />
                  <span>{d.createdByName}</span>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ActiveInterviews;
