import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { CandidateEmploymentModel } from '../../../models/candidate-form.model';
import dateFormat from 'dateformat';
import { IconButton, Radio, RadioGroup, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { isPermissionsDelete, isPermissionsWrite } from '../../../common/commonUtil';
import { MODULE } from '../../../constants/common';

interface Props {
  component?: string;
  currentEmployer?: any;
  setCurrentEmployer?: any;
  headers: HeaderProps[];
  data: CandidateEmploymentModel[];
  readonly?: boolean;
  edit?: (index: number) => void;
  remove?: (index: number) => void;
  getActions?: (row: any, index?: number) => React.ReactNode;
}

export interface HeaderProps {
  name: string;
  key: string;
  type?: 'date' | 'long-text' | undefined;
  format?: 'dd/mm/yyyy' | 'mm/yyyy' | 'dd/mm/yyyy hh:MM TT';
}

const formatText = (value: any, props: HeaderProps): React.ReactNode => {
  if (props.type === 'date') {
    return <span>{value ? dateFormat(new Date(value), props.format || 'dd/mm/yyyy') : ''}</span>;
  } else if (props.type === 'long-text') {
    return (
      <Tooltip title={value}>
        <span>{value && value.length > 50 ? `${value.substr(0, 50)}...` : value}</span>
      </Tooltip>
    );
  } else {
    return <span>{value}</span>;
  }
};

const CandidateDataTable = ({
  headers,
  data,
  remove,
  edit,
  readonly = false,
  getActions,
  setCurrentEmployer,
  currentEmployer,
  component,
}: Props) => {
  const [selectedRow, setSelectedRow] = useState(undefined as undefined | number);
  const handleRowSelection = (index: any, d: any) => {
    setSelectedRow(index);
    if (setCurrentEmployer) setCurrentEmployer(d.companyName);
  };

  useEffect(() => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].companyName == currentEmployer) {
        setSelectedRow(i);
        if (setCurrentEmployer) {
          setCurrentEmployer(data[i].companyName);
          break;
        }
      } else {
        setSelectedRow(undefined);
      }
    }
  }, [data]);

  return (
    <div>
      <RadioGroup value={selectedRow}>
        <table className='candidate-data'>
          <tbody>
            <tr>
              {headers.map((h, i) => (
                <td key={i}>{h.name}</td>
              ))}
              {headers.map((h, i) => h.key === 'companyName' && <td key={i}>Current Employer</td>)}
              {!readonly && <td>Actions</td>}
            </tr>
            {data.length == 0 && (
              <tr>
                <td colSpan={readonly ? 7 : 8}>No records to display</td>
              </tr>
            )}
            {data.map((d, i) => (
              <tr key={i}>
                {headers.map((h, i) => (
                  <td key={i}>{formatText(d[h.key as keyof typeof d], h)}</td>
                ))}

                {headers.map(
                  (h, j) =>
                    h.key === 'companyName' && (
                      <td key={j}>
                        {!readonly ? (
                          <>
                            <Radio
                              value={i}
                              checked={i === selectedRow}
                              name='rowSelection'
                              onChange={() => handleRowSelection(i, d)}
                            />
                          </>
                        ) : i == selectedRow && d.companyName === currentEmployer ? (
                          <DoneIcon />
                        ) : (
                          <></>
                        )}
                      </td>
                    ),
                )}
                {!readonly && (
                  <td>
                    {getActions ? (
                      getActions(d, i)
                    ) : (
                      <>
                        <IconButton disabled={!isPermissionsWrite(MODULE.SJD, component)}>
                          <DriveFileRenameOutlineIcon
                            fontSize='small'
                            sx={
                              isPermissionsWrite(MODULE.SJD, component)
                                ? { color: '#3366CC', cursor: 'pointer' }
                                : { color: '#3366cc7a' }
                            }
                            onClick={() => edit && edit(i)}
                          />
                        </IconButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <IconButton disabled={!isPermissionsDelete(MODULE.SJD, component)}>
                          <DeleteRoundedIcon
                            fontSize='small'
                            sx={
                              isPermissionsDelete(MODULE.SJD, component)
                                ? { color: '#a72037', cursor: 'pointer' }
                                : { color: '#e4bac1' }
                            }
                            onClick={() => remove && remove(i)}
                          />
                        </IconButton>
                      </>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </RadioGroup>
    </div>
  );
};

export default CandidateDataTable;
