import { MenuItem, Paper, Select, styled } from '@mui/material';
import { PieChart, useDrawingArea } from '@mui/x-charts';
import { DatePicker } from '@mui/x-date-pickers';
import './new-dashboard.scss';
import { ReactComponent as AssessmentIcon } from '../../assets/dashboard-assets/assessment.svg';
import { ReactComponent as MoreVertIcon } from '../../assets/dashboard-assets/more_vert.svg';

const HiringPipeLine = () => {
  const data = [
    { value: 5, label: 'A' },
    { value: 5, label: 'B' },
    { value: 10, label: 'C' },
    { value: 2, label: 'D' },
    { value: 10, label: 'E' },
    { value: 10, label: 'F' },
    { value: 15, label: 'G' },
    { value: 70, label: 'H' },
  ];

  const size = {
    width: 400,
    height: 200,
  };

  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));

  function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  return (
    <>
      <Paper sx={{ height: '100%', width: '100%' }}>
        <div className='first-div-header'>
          <div
            className='first-div-header-left'
            style={{ display: 'flex', marginLeft: '2%', gap: '0.5rem' }}>
            <AssessmentIcon />
            <span>Hiring Pipeline</span>
          </div>
          <div className='first-div-header-right'>
            <MoreVertIcon style={{ marginRight: '0.5rem' }} />
          </div>
        </div>

        <hr style={{ color: '#C1C0C0', marginBlockEnd: '0em', marginBlockStart: '0em' }}></hr>
        <div
          style={{
            display: 'flex',
            padding: '10px 10px 10px 10px',
            gap: '1rem',
          }}>
          <Select
            sx={{ height: '30px', minWidth: '22%' }}
            size='small'
            fullWidth
            labelId='domain'
            name='domain'
            id='demo-simple-select'
            value={0}>
            <MenuItem key={0} value={0}>
              All Job Defintions
            </MenuItem>
            <MenuItem>Select</MenuItem>
            <MenuItem>Select</MenuItem>
          </Select>
          <Select
            sx={{ height: '30px', minWidth: '22%' }}
            size='small'
            fullWidth
            labelId='domain'
            name='domain'
            id='demo-simple-select'
            value={0}>
            <MenuItem key={0} value={0}>
              All Recruiters
            </MenuItem>
            <MenuItem>Select</MenuItem>
            <MenuItem>Select</MenuItem>
          </Select>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ width: '66px' }}>Start Date</span>
            <DatePicker className='date' />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ width: '66px' }}>End Date</span>
            <DatePicker className='date' />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column', // Ensure that the pie chart and label are in a column layout
            margin: '35px 28px',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <PieChart series={[{ data, innerRadius: 65 }]} {...size}>
            <PieCenterLabel>63</PieCenterLabel>
          </PieChart>
          <div
            style={{ marginTop: '10px', position: 'relative', left: '-45px', fontWeight: 'bold' }}>
            Total Job Definitions
          </div>{' '}
          {/* Moved below the pie chart */}
        </div>
      </Paper>
    </>
  );
};

export default HiringPipeLine;
