import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState, useEffect } from 'react';
import { getRatingTrend } from '../../service/dashboardService';
import { Loader } from '../common/loader';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import dateFormat from 'dateformat';

interface Props {
  initialData: any;
}

const RatingTrendChart = ({ initialData }: Props) => {
  const [data, setData] = useState([] as any[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
    } else {
      setLoading(false);
      setData(initialData.reverse());
    }
  }, [initialData]);

  // useEffect(() => {
  //   handleFilter(30);
  // }, []);

  const handleFilter = (count: any) => {
    setLoading(true);
    getRatingTrend(count)
      .then((resp) => {
        setData(resp.data.reverse());
      })
      .catch((_err) => {})
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Stack spacing={1} sx={{ marginLeft: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            marginRight: '5px',
          }}>
          <Typography variant='h6' fontWeight={700}>
            Average Rating
          </Typography>
          <select
            className='audit-period-select'
            onChange={(e: any) => handleFilter(e.target.value)}>
            <option value={30}>Last 30 days</option>
            <option value={15}>Last 15 days</option>
            <option value={10}>Last 10 days</option>
          </select>
        </div>
      </Stack>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className='rating-trend-graph' style={{ display: 'flex', justifyContent: 'center' }}>
            <ResponsiveContainer>
              <LineChart
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                width={530}
                height={150}>
                <XAxis
                  dataKey='date'
                  minTickGap={10}
                  tickFormatter={(value) => {
                    return dateFormat(new Date(value), 'dd-mmm');
                  }}
                />
                <YAxis minTickGap={1} ticks={[0, 1, 2, 3, 4, 5]} domain={[0, 'dataMax']} />
                <Tooltip />
                <Legend
                  align='right'
                  formatter={(value: string, _entry: any) => {
                    return <span>{value == 'qcRating' ? 'QC Rating' : 'Profiler Rating'}</span>;
                  }}
                />
                <Line
                  type='monotone'
                  dataKey='qcRating'
                  stroke='#8884d8'
                  dot={false}
                  strokeWidth={3}
                />
                <Line
                  type='monotone'
                  dataKey='profilerRating'
                  stroke='#82ca9d'
                  dot={false}
                  strokeWidth={3}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default RatingTrendChart;
