import {
  Paper,
  InputLabel,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { CandidateDetailsModel } from '../../../models/candidate-form.model';
import { useState, useEffect } from 'react';
import '../candidate.scss';
import SectionHeaderWithEdit from '../../common/section-heading';
import CandidateDataTable, { HeaderProps } from './candidate-data-table';
import { isPermissionsWrite } from '../../../common/commonUtil';
import { MODULE, SubModuleTypes } from '../../../constants/common';

interface Props {
  values: CandidateDetailsModel;
  mode?: 'create-page' | 'details-page';
  next?: (value: CandidateDetailsModel) => void;
  previous?: () => void;
}

const CandidateAdditionalInfoForm = ({
  values,
  mode = 'create-page',
  next = () => {},
  previous = () => {},
}: Props) => {
  const AdditionalInfoSchema = Yup.object().shape({
    key: Yup.string().required('Key is required'),
    value: Yup.string().required('Value is required'),
  });
  const formik = useFormik({
    initialValues: {
      key: '',
      value: '',
    },
    validationSchema: AdditionalInfoSchema,
    onSubmit: (values) => {
      setAdditionalInfoList([...additionalInfoList, values]);
      formik.resetForm();
      setSubmitted(false);
      setShowDialog(false);
      setEditValue(undefined);
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode == 'create-page');
  const [additionalInfoList, setAdditionalInfoList] = useState([] as any[]);
  const [showDialog, setShowDialog] = useState(false);
  const [editValue, setEditValue] = useState(undefined);
  useEffect(() => {
    if (values.additionalInfo?.length) {
      try {
        const info = JSON.parse(values.additionalInfo);
        setAdditionalInfoList(info);
      } catch {
        setAdditionalInfoList([]);
      }
    }
  }, []);

  const getError = (formik: FormikProps<any>, fieldName: string) => {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  };

  const tableHeaders: HeaderProps[] = [
    {
      name: 'Key',
      key: 'key',
    },
    {
      name: 'Value',
      key: 'value',
    },
  ];

  const handleEdit = (index: number) => {
    formik.setValues(additionalInfoList[index]);
    setEditValue(additionalInfoList[index]);
    handleDelete(index);
    setShowDialog(true);
  };

  const handleDelete = (index: number) => {
    const list = additionalInfoList;
    list.splice(index, 1);
    setAdditionalInfoList([...list]);
  };

  const handleNextClick = () => {
    next({ additionalInfo: JSON.stringify(additionalInfoList) });
  };

  return (
    <div className='candidate'>
      <Paper sx={{ padding: [5, 2], boxShadow: 'none' }}>
        {mode === 'details-page' && (
          <SectionHeaderWithEdit
            text='Additional Info'
            edit={edit}
            hideIcon={
              values.sjdCandidateInfo?.length == 0 ||
              !isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_ADDITIONAL_INFO)
            }
            click={() => {
              if (edit) {
                next({ additionalInfo: JSON.stringify(additionalInfoList) });
              }
              setEdit(!edit);
            }}
          />
        )}
        <CandidateDataTable
          component={SubModuleTypes.CANDIDATE_ADDITIONAL_INFO}
          headers={tableHeaders}
          data={additionalInfoList}
          readonly={!edit}
          edit={handleEdit}
          remove={handleDelete}></CandidateDataTable>
        {edit && (
          <div className='center-contents' style={{ marginBottom: '30px' }}>
            <Button
              className='default-btn'
              sx={{ marginLeft: 3 }}
              onClick={() => {
                setShowDialog(true);
              }}>
              Add Info
            </Button>
          </div>
        )}
      </Paper>
      {mode === 'create-page' && (
        <div className='bottom-btn-block'>
          <Button sx={{ marginLeft: 3 }} onClick={previous} className='default-btn bg-btn'>
            Previous
          </Button>
          <Button
            sx={{ marginLeft: 3 }}
            onClick={() => {
              handleNextClick();
            }}
            className='default-btn bg-btn'>
            Submit
          </Button>
        </div>
      )}
      {showDialog && (
        <Dialog
          open={showDialog}
          fullWidth={true}
          maxWidth='md'
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>Add Info</DialogTitle>
          <DialogContent>
            <form
              style={{ marginBottom: '10px' }}
              onSubmit={(values) => {
                setSubmitted(true);
                formik.handleSubmit(values);
              }}>
              <div className='row'>
                <div className='field'>
                  <InputLabel className='field-label' id='key'>
                    Key
                  </InputLabel>
                  <TextField
                    value={formik.values.key}
                    fullWidth
                    id='key'
                    size='small'
                    name='key'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className='mt-0 form-err-msg'>{getError(formik, 'key')}</div>
                </div>
                <div className='field'>
                  <InputLabel className='field-label' id='value'>
                    Value
                  </InputLabel>
                  <TextField
                    value={formik.values.value}
                    fullWidth
                    id='value'
                    size='small'
                    name='value'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className='mt-0 form-err-msg'>{getError(formik, 'value')}</div>
                </div>
              </div>
            </form>
            <div className='center-contents' style={{ marginBottom: '30px' }}>
              <Button
                className='default-btn'
                sx={{ marginLeft: 3 }}
                onClick={() => {
                  setSubmitted(true);
                  formik.handleSubmit();
                }}>
                Add
              </Button>
              <Button
                className='default-btn'
                sx={{ marginLeft: 3 }}
                onClick={() => {
                  formik.resetForm();
                  setShowDialog(false);
                  if (editValue) setAdditionalInfoList([...additionalInfoList, editValue]);
                }}>
                Cancel
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default CandidateAdditionalInfoForm;
