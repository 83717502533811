import React, { useState } from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Select, MenuItem, Box } from '@mui/material';

const SourceQcPrCount = () => {
  const [selectedDefinition, setSelectedDefinition] = useState<string>('All Job Definitions');

  const data = [
    {
      name: 'ganesh',
      sourceCount: 15,
      sourceCountTarget: 20,
      qr: 18,
      qrTarget: 15,
      pc: 30,
      pcTarget: 40,
    },
    {
      name: 'suresh',
      sourceCount: 18,
      sourceCountTarget: 25,
      qr: 19,
      qrTarget: 20,
      pc: 28,
      pcTarget: 35,
    },
    {
      name: 'kamesh',
      sourceCount: 21,
      sourceCountTarget: 20,
      qr: 22,
      qrTarget: 25,
      pc: 35,
      pcTarget: 40,
    },
  ];

  const CustomBarWithTarget = ({ fill, x, y, width, height, payload, dataKey, targetKey }: any) => {
    const amt = payload[dataKey];
    const targetValue = payload[targetKey];
    const totalHeight = y + height;
    const targetY = totalHeight - (height / amt) * targetValue;

    return (
      <svg>
        <rect x={x} y={y} width={width} height={height} stroke='none' fill={fill} />
        <line
          x1={x + width / 4}
          x2={x + (3 * width) / 4}
          y1={targetY}
          y2={targetY}
          stroke='red'
          strokeWidth={2}
        />
      </svg>
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '-40px',
          marginBottom: '25px',
          padding: '0 20px',
          maxWidth: '1350px',
          marginLeft: '10px',
          marginRight: 'auto',
        }}>
        <Select
          value={selectedDefinition}
          onChange={(e) => setSelectedDefinition(e.target.value as string)}
          variant='outlined'
          sx={{ width: '182px', height: '30px' }}
          size='small'>
          <MenuItem value='All Job Definitions'>All Job Definitions</MenuItem>
        </Select>
        <span
          style={{
            fontSize: '1rem',
            color: '#1565c0',
            fontWeight: '400',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>
          View All
        </span>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '150px',
          height: '400px',
          width: '100%',
          position: 'relative',
        }}>
        <Box sx={{ width: '90%', height: '100%' }}>
          <ResponsiveContainer width='100%' height='100%'>
            <ComposedChart
              data={data}
              style={{ marginLeft: '25px' }}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray='none' />
              <XAxis dataKey='name' />
              <YAxis
                orientation='right'
                ticks={[0, 20, 40, 60, 80, 100]}
                tickFormatter={(value) => value}
                axisLine={false}
              />
              <Tooltip formatter={(value) => `${value}`} />

              <Bar
                dataKey='sourceCount'
                fill='#f48544'
                barSize={10}
                isAnimationActive={false}
                shape={(props: any) => (
                  <CustomBarWithTarget
                    {...props}
                    dataKey='sourceCount'
                    targetKey='sourceCountTarget'
                  />
                )}
              />
              <Bar
                dataKey='qr'
                fill='#f2bd05'
                barSize={10}
                isAnimationActive={false}
                shape={(props: any) => (
                  <CustomBarWithTarget {...props} dataKey='qr' targetKey='qrTarget' />
                )}
              />
              <Bar
                dataKey='pc'
                fill='#4871be'
                barSize={10}
                isAnimationActive={false}
                shape={(props: any) => (
                  <CustomBarWithTarget {...props} dataKey='pc' targetKey='pcTarget' />
                )}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </div>
    </div>
  );
};

export default SourceQcPrCount;
