import { Box, Button, Drawer, IconButton, Radio, Input, Paper, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Share } from '../../../assets/candidate-assets/share.svg';
import { ReactComponent as Download } from '../../../assets/candidate-assets/download.svg';
import { ReactComponent as Edit } from '../../../assets/candidate-assets/edit.svg';
import { ReactComponent as Delete } from '../../../assets/candidate-assets/delete_outline.svg';
import CloseIcon from '@mui/icons-material/Close';
import * as pdfjs from 'pdfjs-dist';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import mammoth from 'mammoth';
import { tadGetResume } from '../../../service/tadCandidateService';
import dateFormat from 'dateformat';

interface TadCandidateDetailsModelProps {
  candidate: any;
  resumeIds: any;
  isNewIndex?: boolean;
  file?: any;
  submit?: () => void;
  handleResumeUpload: (files: any) => void;
}

const CandidateResume: React.FC<TadCandidateDetailsModelProps> = ({
  resumeIds,
  isNewIndex = false,
  candidate,
  submit,
  handleResumeUpload,
}) => {
  const [file, setFile] = useState<File | undefined>();
  const [newResumeFile, setNewResumeFile] = useState<File | undefined>();
  const [docContent, setDocContent] = useState<string | null>(null);
  const workerSrcUrl = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const [editResume, setEditResume] = useState(false);
  const [fileName, setFileName] = useState('');
  const [resumeName, setResumeName] = useState('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedResumeId, setSelectedResumeId] = useState<string | null>(null);

  const [resumeList, setResumeList] = useState([] as any);

  useEffect(() => {
    setResumeList(resumeIds);
    handleResumeUpload(resumeIds);
    const resumeId = resumeIds?.filter((e: any) => e.isActive === true)?.[0]?.resumeId;
    if (resumeIds) {
      tadGetResume(resumeId, !isNewIndex)
        .then(async (resp) => {
          const base64Content = resp.data;
          const fileName = resp.data.fileName || 'resume.pdf';
          // setFileName(resp.data.fileName?.split('.').pop()?.toUppserCase());
          try {
            const byteCharacters = atob(base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const fileType = fileName?.split('.').pop()?.toLowerCase();
            let mimeType = 'application/octet-stream';

            if (fileType === 'pdf') {
              mimeType = 'application/pdf';
            } else if (fileType === 'doc' || fileType === 'docx') {
              mimeType = 'application/msword';
            }
            const blob = new Blob([byteArray], { type: mimeType });

            const resumeFile = new File([blob], fileName, { type: mimeType });

            setFile(resumeFile);
            if (fileType === 'pdf') {
              setDocContent(null);
            } else if (fileType === 'docx' || fileType === 'doc') {
              const arrayBuffer = await resumeFile.arrayBuffer();
              const result = await mammoth.convertToHtml({ arrayBuffer });
              setDocContent(result.value);
            }
          } catch (error) {
            console.error('Error decoding base64 content:', error);
          }
        })
        .catch((error: any) => {
          console.error('Error fetching resume:', error);
        });
    }
  }, [resumeIds, isNewIndex]);

  const onDownloadClick = () => {
    const resumeId = resumeIds?.filter((e: any) => e.isActive == true)[0].resumeId;
    tadGetResume(resumeId, !isNewIndex)
      .then((resp) => {
        const base64Content = resp.data;
        const fileName = resp.data.fileName || 'document.pdf';

        try {
          const byteCharacters = atob(base64Content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const fileType = fileName?.split('.').pop()?.toLowerCase();
          let mimeType = 'application/octet-stream';

          if (fileType === 'pdf') {
            mimeType = 'application/pdf';
          } else if (fileType === 'doc' || fileType === 'docx') {
            mimeType = 'application/msword';
          }

          const blob = new Blob([byteArray], { type: mimeType });

          const element = document.createElement('a');
          element.href = URL.createObjectURL(blob);
          element.download = candidate.name?.replace(/ /g, '_') + '_' + Date.now() + `.${fileType}`;
          document.body.appendChild(element);
          element.click();

          document.body.removeChild(element);
          URL.revokeObjectURL(element.href);
        } catch (error) {
          console.error('Error processing file:', error);
        }
      })
      .catch((error) => {
        console.error('Error fetching resume:', error);
      });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    file && setResumeName(file.name);
    setNewResumeFile(file);
  };

  const handleClearFile = () => {
    setResumeName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleAddResume = () => {
    // const tempId = `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
    setSelectedResumeId(null);
    const newResume = {
      file: newResumeFile as File,
      resumeId: null,
      candidateId: candidate.id,
      resumeName: fileName ? fileName : resumeName,
      uploadedOn: new Date(),
      isActive: true,
    };
    const updatedList = [
      ...resumeList.map((resume: any) => ({ ...resume, isActive: false })),
      newResume,
    ];
    setResumeList(updatedList);
    handleResumeUpload(updatedList);
  };

  const handleSelectResume = (index: any) => {
    setSelectedResumeId(index);
    const updatedList = resumeList.map((resume: any, i: any) => ({
      ...resume,
      isActive: index == i,
    }));
    setResumeList(updatedList);
    handleResumeUpload(updatedList);
  };

  const handleDeletResume = (index: number) => {
    const updatedList = resumeList.filter((_e: any, i: any) => i !== index);
    if (updatedList?.length > 0) {
      const latestResume = updatedList.reduce((latest: any, current: any) =>
        new Date(latest?.uploadedOn) > new Date(current?.uploadedOn) ? latest : current,
      );
      const updatedListWithActive = updatedList.map((resume: any) => ({
        ...resume,
        isActive: resume?.resumeId === latestResume?.resumeId,
      }));
      setResumeList(updatedListWithActive);
      handleResumeUpload(updatedListWithActive);
    } else {
      setResumeList([]);
    }
  };

  const DrawerList = (
    <div style={{ width: '35rem', overflow: 'hidden', height: '100%' }}>
      <div
        style={{
          position: 'relative',
          margin: '2.5rem 1.81rem 0rem 1.81rem',
          paddingBottom: '1rem',
          borderBottom: '1px solid #C1C0C0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div style={{ fontSize: 'var(--Font-size-h6, 1.1875rem)', fontWeight: '600' }}>
          Edit Candidate Resume
        </div>
        <IconButton
          aria-label='close'
          onClick={() => {
            setEditResume(false);
          }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '1rem 2rem 1rem 2rem',
        }}>
        <span>Document Name</span>
        <TextField
          className='input-field'
          placeholder='Type here...'
          value={fileName}
          // error={Boolean(fileInputRef.current && fileInputRef.current.value == '')}
          onChange={(e) => {
            // fileInputRef.current &&
            //   fileInputRef.current.value !== '' &&
            setFileName(e.target.value);
          }}></TextField>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '2rem',
            border: '1px solid var(--Secondary-Grey-2, #E7E7E7)',
            padding: '0.5rem',
            borderRadius: '0.3125rem',
          }}>
          <Input
            id='file-input'
            type='file'
            inputProps={{ accept: '.pdf, .docx' }}
            inputRef={fileInputRef}
            sx={{
              display: 'none',
            }}
            onChange={handleFileChange}
          />
          <label htmlFor='file-input'>
            <span
              style={{
                cursor: 'pointer',
                border: '1px solid #2D7DFE',
                borderRadius: '0.3125rem',
                textTransform: 'none',
                color: 'black',
                padding: '6px 16px',
                fontSize: '1rem',
                fontWeight: '400',
              }}>
              Choose File
            </span>
          </label>
          <Box
            sx={{
              display: 'flex',
              marginLeft: '8px',
              alignItems: 'center',
            }}>
            {resumeName == '' ? 'No File Choosen' : resumeName}
            {fileInputRef.current && fileInputRef.current.value !== '' && (
              <IconButton onClick={handleClearFile}>
                <CloseIcon sx={{ fontSize: 'medium' }} />
              </IconButton>
            )}
          </Box>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            disabled={fileInputRef.current && fileInputRef.current.value == '' ? true : false}
            sx={{
              textTransform: 'none',
              backgroundColor:
                resumeName == ''
                  ? 'var(--Secondary-Grey-1, #868686) !important'
                  : '#1976d2 !important',
              color: 'white !important',
            }}
            onClick={() => {
              handleAddResume();
            }}>
            Upload
          </Button>
        </div>
      </div>
      <div style={{ fontSize: '1rem', fontWeight: '600', padding: '0rem 2rem 0rem 2rem' }}>
        Uploaded Resume
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 2rem 1rem 2rem',
          gap: '1rem',
          height: '45%',
          marginTop: '1rem',
          marginBottom: '1rem',
          overflow: 'auto',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {resumeList?.map((resume: any, index: any) => (
            <div
              key={index}
              style={{
                display: 'flex',
                border: '1px solid var(--Secondary-colors-Grey-2, #E6E7E8)',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.5rem 3rem 0.5rem 1rem',
              }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{resume.resumeName}</span>
                <span>{dateFormat(resume.uploadedOn, 'mm/dd/yyyy')}</span>
              </div>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <Radio
                  checked={selectedResumeId ? selectedResumeId === index : resume.isActive}
                  onChange={() => handleSelectResume(index)}
                  value={resume.resumeId}
                  size='small'
                  sx={{
                    padding: '0px',
                    color: 'black !important',
                    flexShrink: 0,
                    '& .MuiSvgIcon-root': {
                      fontSize: '1.5rem',
                    },
                  }}
                />
                <IconButton sx={{ padding: '0px' }} onClick={() => submit}>
                  <Download style={{ color: '#323232' }} />
                </IconButton>
                <IconButton sx={{ padding: '0px' }} onClick={() => handleDeletResume(index)}>
                  <Delete />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #C1C0C0',
          paddingTop: '1rem',
          margin: '1rem 2rem 1rem 2rem',
          gap: '1rem',
        }}>
        <Button
          variant='outlined'
          sx={{ textTransform: 'none' }}
          onClick={() => {
            setEditResume(false);
            setResumeList(resumeIds);
          }}>
          Cancel
        </Button>
        <Button variant='contained' sx={{ textTransform: 'none' }} onClick={submit}>
          Update
        </Button>
      </div>
    </div>
  );

  return (
    <Paper className='paper' style={{ width: '100%' }}>
      <div>
        <div className='header-container'>
          <div className='header-content-title'>
            <span>Candidate Resume</span>
          </div>
          <div className='header-content-icon'>
            <div style={{ height: '2.5rem', display: 'flex', gap: '0.5rem' }}>
              {file && (
                <>
                  <IconButton onClick={() => onDownloadClick()}>
                    <Share style={{ color: '#333333' }} />
                  </IconButton>
                  <IconButton onClick={() => onDownloadClick()}>
                    <Download style={{ color: '#333333' }} />
                  </IconButton>
                </>
              )}
              <IconButton onClick={() => setEditResume(true)}>
                <Edit style={{ color: '#333333' }} />
              </IconButton>
            </div>
          </div>
        </div>
        <div
          className='resume-preview'
          style={{
            textAlign: 'center',
            marginTop: '1rem',
            maxHeight: '40rem',
            overflowY: 'scroll',
          }}>
          {file ? (
            docContent && docContent != null ? (
              <Paper>
                <div
                  style={{ padding: '20px 20px', maxHeight: '150vh', overflow: 'auto' }}
                  dangerouslySetInnerHTML={{ __html: docContent }}
                />
              </Paper>
            ) : (
              <Worker workerUrl={workerSrcUrl}>
                <Viewer fileUrl={URL.createObjectURL(file ? file : new Blob()) as any} />
              </Worker>
            )
          ) : (
            <p>No resume available to display.</p>
          )}
        </div>
      </div>
      <Drawer anchor='right' open={editResume} onClose={() => setEditResume(false)}>
        {DrawerList}
      </Drawer>
    </Paper>
  );
};

export default CandidateResume;
