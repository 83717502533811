import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
// import { ReactComponent as MoreIcon } from '../../assets/dashboard-assets/more_vert.svg';
import './../source-job-definition/./tad-source-job-definition.scss';

interface JobBoardsProps {
  incomingJobBoards: string[];
}

const JobBoards: React.FC<JobBoardsProps> = ({ incomingJobBoards }) => {
  const jobBoards = [
    { src: 'Linkdin.png', alt: 'LinkedIn' },
    { src: 'Naukri.png', alt: 'Naukri' },
    { src: 'Dice.png', alt: 'Dice' },
    { src: 'Indeed.png', alt: 'Indeed' },
  ];

  const [selectedBoards, setSelectedBoards] = useState<string[]>([]);

  useEffect(() => {
    const matchedBoards = incomingJobBoards.filter((incomingBoard) =>
      jobBoards.some((board) => board.alt === incomingBoard),
    );
    setSelectedBoards(matchedBoards);
  }, [incomingJobBoards]);

  return (
    <Paper className='paper' style={{ height: '21.75rem' }}>
      <div className='header-container'>
        <div className='header-content-title'>
          <span>Job Boards</span>
        </div>
        {/* <IconButton className='header-content-icon'>
          <MoreIcon />
        </IconButton> */}
      </div>
      <div style={{ padding: '1.06rem', height: '15rem' }}>
        <Typography className='text' style={{ marginBottom: '1.5rem' }}>
          Selected job boards
        </Typography>
        <div className='job-boards-icons'>
          {selectedBoards.map((selectedAlt) => {
            const board = jobBoards.find((b) => b.alt === selectedAlt);
            return (
              board && (
                <div key={board.alt} className='box'>
                  <img
                    style={{ width: '3.75rem', height: '2.10938rem' }}
                    src={require(`../../assets/dashboard-assets/${board.src}`)}
                    alt={board.alt}
                  />
                </div>
              )
            );
          })}
        </div>
      </div>
    </Paper>
  );
};

export default JobBoards;
