import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Link,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import { useRef, useState } from 'react';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { ReactComponent as CloseIcon } from '../../assets/sjd/close.svg';
import { ReactComponent as AddIcon } from '../../assets/sjd/add.svg';
import { ReactComponent as ThumbsDownIcon } from '../../assets/candidate-assets/thumb_down_alt.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/candidate-assets/thumb_up_alt.svg';
import { ReactComponent as WatchIcon } from '../../assets/candidate-assets/watch.svg';
import { ReactComponent as WineBarIcon } from '../../assets/candidate-assets/wine_bar.svg';
// import { ReactComponent as FileIcon } from '../../assets/candidate-assets/insert_drive_file.svg';
// import { ReactComponent as DeleteOutlineIcon } from '../../assets/candidate-assets/delete_outline.svg';
// import { ReactComponent as DownloadOutlineIcon } from '../../assets/candidate-assets/download copy.svg';
import DoneIcon from '@mui/icons-material/Done';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { tadUpdateInterviewDetails } from '../../service/tadCandidateService';
import globalObject from '../../../common/global-variables';
import dateFormat from 'dateformat';
import { getInitials } from '../../../common/commonUtil';
// import { useFormik } from 'formik';

interface Props {
  candidate: any;
  details?: any;
  setLoading: (isLoading: boolean) => void;
  alert: (msg: string, success: boolean) => void;
}

interface assessedSkill {
  skill?: any;
  rating?: any;
}

const TadInterviewFeedback = ({
  candidate,
  details = {
    performanceRating: '',
    feedback: '',
    recording: '',
    interviewResult: '',
    assessedSkills: '[{}]',
    attachment: undefined as unknown as File,
  },
  setLoading,
  alert,
}: Props) => {
  const options = [
    {
      value: 'Selected',
      label: 'Shortlisted',
      icon: ThumbsUpIcon,
      comment: 'I would recommend moving the candidate forward.',
    },
    {
      value: '',
      label: 'Selected',
      icon: WineBarIcon,
      comment: 'The candidate has been chosen for the job.',
    },
    {
      value: 'Rejected',
      label: 'Rejected',
      icon: ThumbsDownIcon,
      comment: 'Not suitable for this job description or lacks the required technical skills.',
    },
    {
      value: 'On Hold',
      label: 'On Hold',
      icon: WatchIcon,
      comment: 'The candidate is neither shortlisted nor rejected.',
    },
  ];

  const skillsJson =
    details.assessedSkills == null || details.assessedSkills == undefined
      ? [{}]
      : JSON.parse(details.assessedSkills);

  const [skills, setSkills] = useState(skillsJson as assessedSkill[]);
  const [performanceRating, setPerformanceRating] = useState(0);
  const [fileName, setFileName] = useState<string>('No file chosen');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  // const [feedbackRow, setFeedbackRow] = useState({});

  const handleClose = (index: number) => {
    const newList = [...skills];
    newList.splice(index, 1);
    if (newList.length == 0) newList.push({});
    const validRatings = newList
      .map((skill) => skill.rating)
      .filter((rating) => rating !== null) as number[];
    setPerformanceRating(
      validRatings.reduce((sum, rating) => sum + rating, 0) / validRatings.length,
    );
    setSkills([...newList]);
  };

  const addNewRow = () => {
    setSkills((skills) => [...skills, { id: skills.length, skill: '' }]);
  };
  const handleSkillChange = (index: number, value: string) => {
    const temp = skills;
    temp[index].skill = value ? value : null;
    setSkills(() => [...temp]);
  };

  const handleRatingChange = (index: number, value: string) => {
    const temp = skills;
    temp[index].rating = value ? Number(value) : null;
    const validRatings = temp
      .map((skill) => skill.rating)
      .filter((rating) => rating !== null) as number[];
    setPerformanceRating(
      validRatings.reduce((sum, rating) => sum + rating, 0) / validRatings.length,
    );
    setSkills(() => [...temp]);
  };

  const submitFeedback = (req: any) => {
    const skillsWithRating = JSON.stringify(skills);
    setLoading(true);
    tadUpdateInterviewDetails(
      {
        id: details.id,
        sjdId: details.sjdId,
        interviewLevel: details.interviewLevel,
        candidateId: details.candidateId,
        evaluator: {
          id: globalObject.userObject.id,
        },
        performanceRating: performanceRating,
        feedback: req.feedback,
        recording: req.recording,
        interviewResult: req.interviewResult,
        assessedSkills: skillsWithRating,
      },
      true,
    )
      .then((_resp) => {
        setLoading(false);
        alert('Thank you! Your feedback has been submitted successfully', true);

        formik.resetForm();
      })
      .catch((_err) => {
        setLoading(false);
        alert('Something went wrong. Please try later!', false);
      });
    setLoading(true);
    // uploadDocument(req.attachment, candidate.id, `Interview Feedback - ${details.interviewLevel}`)
    //   .then((_resp) => {})
    //   .catch((_err) => {})
    //   .finally(() => setLoading(false));
  };

  const validateSkillAndRating = (skills: assessedSkill[]) => {
    const errors: any = {};

    if (!skills.some((item) => item.skill && item.rating)) {
      errors.errorMsg = 'At least one skill  is required';
    }
    skills.forEach((item) => {
      if (item.skill && !item.rating) {
        errors.errorMsg = 'Rating is required for the entered skill';
      }
    });

    return errors;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      formik.setFieldValue('attachment', event.target.files[0]);
      setFileName(event.target.files[0].name);
    }
  };

  const formik: any = useFormik({
    initialValues: {
      ...details,
      recording: details.recording ? details.recording : details.isOnlineMeeting ? '' : 'NA',
    },
    onSubmit: submitFeedback,
    validationSchema: Yup.object().shape({
      feedback: Yup.string()
        .required('Feedback is required')
        .min(100, 'Minimum 100 characters required for comments'),
      recording: Yup.string().required(
        'Recording Link is required. In case of offline interview, mark it as NA',
      ),
      interviewResult: Yup.string().required('Result  is required'),
    }),
    validate: () => {
      const skillAndRatingErrors = validateSkillAndRating(skills);
      return {
        ...skillAndRatingErrors,
      };
    },
  });

  const [selectedOption, setSelectedOption] = useState<null | string>(null);
  return (
    <Paper style={{ paddingBottom: '30px' }}>
      <div className='header-container'>
        <div className='header-content-title'>
          <span>Interview Feedback</span>
        </div>
      </div>
      <div style={{ paddingLeft: '1.63rem', paddingRight: '1.37rem', marginTop: '20px' }}>
        <div>
          <span className='header-label'>
            How would you rate the candidate performance overall after the interview?
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
          className='rating'>
          <Tooltip title=''>
            <Rating
              className='rating'
              id='Rating'
              readOnly
              value={performanceRating}
              name='Rating'
              icon={
                <StarIcon style={{ opacity: 0.55 }} className='filled-star' fontSize='inherit' />
              }
              emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
              precision={0.5}
            />
          </Tooltip>
        </div>

        <div style={{ marginTop: '10px' }}>
          <span className='header-label'>Assessed Skills</span>
          {skills.map((skill, index) => (
            <Box key={index} className='box-bg'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <TextField
                  sx={{ minWidth: '2rem', marginLeft: '1.5rem' }}
                  variant='outlined'
                  size='small'
                  value={skill?.skill}
                  onChange={(e) => {
                    handleSkillChange(index, e.target.value);
                  }}
                />
                <Rating
                  id='Rating'
                  value={skill?.rating}
                  onChange={(event: any) => handleRatingChange(index, event.target.value)}
                  name='Rating'
                  icon={<StarIcon className='filled-star' fontSize='inherit' />}
                  emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
                  style={{ gap: '0.5rem', marginLeft: '20px' }}
                  precision={0.5}
                />
              </div>
              <IconButton>
                <CloseIcon onClick={() => handleClose(index)} />
              </IconButton>
            </Box>
          ))}
          {formik.errors.errorMsg && <div className='form-err-msg'>{formik.errors.errorMsg}</div>}
        </div>

        <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
          <AddIcon className='add-icon path' />
          <span
            style={{ color: '#1976D2', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={addNewRow}>
            Add
          </span>
        </div>
        <TextField
          fullWidth
          value={formik.values.feedback}
          onChange={(e) => formik.setFieldValue('feedback', e.target.value)}
          onBlur={formik.handleBlur}
          placeholder='Comments *'
          multiline
          minRows={4}
          sx={{ marginTop: '40px' }}
        />
        {formik.touched['feedback'] && formik.errors['feedback'] && (
          <div className='mt-0 form-err-msg'>{formik.errors['feedback']?.toString()}</div>
        )}
        <div style={{ marginTop: '1.5rem' }}>
          <span className='header-label'>Interview recording link</span>
          <TextField
            fullWidth
            placeholder='Paste here'
            multiline
            value={formik.values.recording}
            onChange={(e) => formik.setFieldValue('recording', e.target.value)}
            disabled={details?.feedback || !details.isOnlineMeeting}
            minRows={4}
            sx={{ marginTop: '0.5rem' }}
          />
          {formik.touched['recording'] && formik.errors['recording'] && (
            <div className='mt-0 form-err-msg'>{formik.errors['recording']?.toString()}</div>
          )}
        </div>

        <div style={{ marginTop: '40px' }}>
          <InputLabel
            style={{
              color: 'var(--Primary-Dark-grey-500, #333)',
              fontFamily: 'Roboto',
              fontSize: '1rem',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '1.5625rem',
            }}>
            Upload a screenshot
          </InputLabel>
          <div
            style={{
              border: '1px solid var(--Secondary-Grey-2, #E7E7E7)',
              padding: '0.8rem',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              fontFamily: 'Arial, sans-serif',
              fontSize: '1rem',
              marginTop: '0.5rem',
              borderRadius: '0.3125rem',
              color: '#333',
            }}>
            <input
              ref={fileInputRef}
              type='file'
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <button
              onClick={() => fileInputRef.current?.click()}
              style={{
                height: '2.5rem',
                padding: '0 1rem',
                border: '1px solid #007BFF',
                borderRadius: '0.25rem',
                cursor: 'pointer',
                background: 'white',
                fontSize: '1rem',
              }}>
              Choose File
            </button>
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {fileName}
            </span>
          </div>
        </div>

        <div style={{ marginTop: '40px' }}>
          <span className='header-label'>Result</span>
          {options.map((option, index) => (
            <div
              key={index}
              style={{
                height: '4.125rem',
                display: 'flex',
                marginTop: '30px',
                alignItems: 'center',
                cursor: 'pointer',
                paddingLeft: '10px',
                justifyContent: 'space-between ',
                background: option.label === selectedOption ? '#F1F4F8' : '',
              }}
              onClick={() => {
                formik.setFieldValue('interviewResult', option.value);
                setSelectedOption(option.label);
              }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <option.icon style={{ color: '#1976D2' }} />
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '20px',
                    }}>
                    <span className='semi-bold'>{option.label}</span>
                    <span>{option.comment}</span>
                  </div>
                </div>
              </div>
              <div style={{ marginRight: '30px' }}>
                {option.label === selectedOption && <DoneIcon style={{ fill: '#2D7DFE' }} />}
              </div>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              formik.handleSubmit();

              // else {
              //   setShowAlert(true);
              // }
            }}
            style={{
              background: '#1976D2',
              color: '#ffffff',
              marginTop: '20px',
              width: '7rem',
              height: 'var(--Font-size-h2, 2.5rem)',
            }}>
            Submit
          </Button>
        </div>
        <hr style={{ background: '#868686', marginTop: '20px' }} />
        {candidate.interview?.map(
          (v: any, index: any) =>
            v.feedback && (
              <div key={index}>
                <div style={{ marginTop: '40px', display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      backgroundColor: '#1976D2',
                      color: 'white',
                      padding: '0.5rem',
                      borderRadius: '50%',
                      width: '1.875rem',
                      height: '1.875rem',
                      fontSize: '1rem',
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}>
                    {getInitials(v.evaluator)}
                  </div>
                  <div
                    style={{
                      marginLeft: '0.5rem',
                      color: '#1976D2',
                      textDecoration: 'underline',
                      fontWeight: '400',
                    }}>
                    {`${v.evaluator?.firstName} ${v.evaluator?.lastName}`}
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Rating
                      className='rating'
                      name='half-rating'
                      readOnly
                      value={v.performanceRating}
                      precision={0.5}
                      icon={<StarIcon className='filled-star' fontSize='inherit' />}
                      emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
                      style={{ gap: '1rem', marginLeft: '1rem', marginRight: '1rem' }}
                    />
                    <span className='header-label'>{`${v.performanceRating} / 5`}</span>
                  </div>
                </div>
                <span
                  className='feildHeader'
                  style={{ marginLeft: '3.5rem' }}>{`Submitted on ${dateFormat(
                  new Date(v.createdOn),
                  'dd/mm/yyyy',
                )}`}</span>
                <div
                  style={{
                    display: 'flex',
                    height: '4.875rem',
                    borderRadius: '0.3125rem',
                    background: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '100px',
                      marginLeft: '20px',
                    }}>
                    <span className='semi-bold'>Interview Date</span>
                    <span>{dateFormat(new Date(v.startTime), 'dd/mm/yyyy')}</span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', marginRight: '100px' }}>
                    <span className='semi-bold'>Interview Level</span>
                    <span>{v.interviewLevel}</span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', marginRight: '100px' }}>
                    <span className='semi-bold'>Result</span>
                    <span>
                      {v.interviewResult === 'Selected' ? 'Shorlisted' : v.interviewResult}
                    </span>
                  </div>
                </div>
                <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column' }}>
                  <span className='semi-bold'>Comments</span>
                  <span style={{ maxWidth: '100%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                    {v.feedback}
                  </span>
                </div>
                <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
                  <span className='semi-bold'>Interview recording link</span>
                  {v.recording.startsWith('http') ? (
                    <Link href={v.recording} target='_blank'>
                      {v.recording}
                    </Link>
                  ) : (
                    <Link href={v.recording} target='_blank'>
                      {v.recording}
                    </Link>
                  )}
                </div>
                {/* {
                  <div style={{ marginTop: '30px' }}>
                    <span className='semi-bold'>Attachments</span>
                    <div
                      style={{
                        display: 'flex',
                        background: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                        height: ' 3.875rem',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <div style={{ display: 'flex', marginLeft: '0.5rem' }}>
                        <FileIcon />
                        <div
                          style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
                          <span>Feedback by Richard M.PNG</span>
                          <span style={{ color: 'var(--Secondary-colors-Grey, #868686)' }}>
                            02/09/2024
                          </span>
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginRight: '0.5rem' }}>
                        <DownloadOutlineIcon style={{ marginRight: '3rem' }} />
                        <DeleteOutlineIcon />
                      </div>
                    </div>
                  </div>
                } */}
              </div>
            ),
        )}
      </div>
    </Paper>
  );
};

export default TadInterviewFeedback;
