export enum CandidateStatus {
  PROFILE_UPLOADED = 'Profile Uploaded',
  QC_RATED = 'QC Rated',
  PROFILER_RATED = 'Profiler Rated',
  ONLILNE_TEST_SCHEDULED = 'Online Test Scheduled',
  WAITING_FOR_TECH_INTERVIEW = 'Waiting for next round of Tech interview',
  TECH_INTERVIEW_SCHEDULED = 'Tech Interview Scheduled',
  TECH_INTERVIEW_CLEARED = 'Tech Interview cleared',
  CLIENT_INTERVIEW_SCHEDULED = 'Client Interview Scheduled',
  CLIENT_INTERVIEW_CLEARED = 'Client Interview Cleared',
  HR_INTERVIEW_SCHEDULED = 'HR Interview Scheduled',
  HR_INTERVIEW_CLEARED = 'HR Interview Cleared',
  DOC_VERIFICATION_STARTED = 'Document Verification Started',
  DOC_VERIFICATION_COMPLETED = 'Document Verification Completed',
  OFFER_APPROVED = 'Offer approved',
  OFFER_RELEASED = 'Offer Released',
  OFFFER_ACCEPTED = 'Offer accepted',
  JOINED = 'Joined',
  REJECTED = 'Rejected',
}
