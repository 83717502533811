import axiosClient from '../common/axiosClient';
import { UserDetails } from '../models/user.model';

export async function getUser() {
  return axiosClient.get('/user');
}

export async function getUserById(id: any, flag?: any) {
  return axiosClient.get(`/user/${id}`, { params: { flag: flag ? flag : false } });
}

export async function getAllUsers(role?: any) {
  const flag = localStorage.getItem('switch');
  return axiosClient.get('/user/all', { params: { role: role, flag: flag ? flag : false } });
}

export async function addUser(user: UserDetails, flag: any) {
  const requestBody = { ...user };
  if (flag) {
    if (requestBody.roles && !Array.isArray(requestBody.roles)) {
      requestBody.roles = [requestBody.roles];
    }
  }
  return axiosClient.post('/user', requestBody, { params: { flag: flag ? flag : false } });
}

export async function updateUser(user: UserDetails, flag?: any) {
  const requestBody = { ...user };
  if (flag) {
    if (requestBody.roles && !Array.isArray(requestBody.roles)) {
      requestBody.roles = [requestBody.roles];
    }
  }
  return axiosClient.patch('/user', requestBody, { params: { flag: flag ? flag : false } });
}
