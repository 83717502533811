import { MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { ReactComponent as DropDownArrow } from '../../assets/dashboard-assets/dropdown.svg';

import { useState } from 'react';
import dayjs from 'dayjs';

const General = () => {
  const [selectedDate, setSelectedDate] = useState<string>('');

  const [timeFormat, setTimeFormat] = useState('24 hour');
  const [timeZone, setTimeZone] = useState('(UTC+5:30) Chennai, Kolkata, Mumbai, New Delhi');

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = e.target.value;
    const formattedDate = dayjs(inputDate).format('MM/DD/YYYY');
    setSelectedDate(formattedDate);
  };
  return (
    <div style={{ width: '100%', gap: '1.87rem', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          height: '8.8125rem',
          width: '100%',
        }}>
        <Paper
          className='new-job-requisition'
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1.88rem',
              gap: '7px',
            }}>
            <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>General</span>
            <span
              style={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
              }}>
              Customize your preferences and configurations.
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              marginRight: '2.5rem',
              alignItems: 'center',
              gap: '2.31rem',
            }}></div>
        </Paper>
      </div>
      <div
        className='paper'
        style={{
          width: '100%',
          height: '70.625rem',
        }}>
        <Paper
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <div style={{ padding: '1.94rem 1.94rem 0rem 1.88rem' }}>
            <Typography style={{ fontSize: '1.25rem', fontWeight: 600 }}>Language</Typography>
            <Typography
              style={{ fontSize: '1rem', fontWeight: 400, color: '#868686', marginBottom: '1rem' }}>
              Set up display language and regional language.
            </Typography>

            <div
              style={{
                display: 'flex',
                gap: '24.56rem',
                alignItems: 'center',
                marginBottom: '1rem',
                height: '7.4375rem',
                borderRadius: '0.625rem',
                border: '1px solid #C1C0C0',
              }}>
              <div style={{ width: '319px', marginLeft: '2rem', marginRight: '28rem' }}>
                <Typography sx={{ fontWeight: 400 }}>Display Language</Typography>
                <div>
                  <Select
                    className='input-field'
                    sx={{
                      width: '26.3125rem',
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '10px',
                      marginTop: '0.62rem',
                    }}
                    IconComponent={() => (
                      <DropDownArrow
                        style={{
                          marginRight: '5px',
                          width: '0.9375rem',
                          height: '0.9375rem',
                        }}
                      />
                    )}
                    size='small'
                    labelId='jobDefinitions'
                    name='jobDefinitions'
                    id='jobDefinitions-select'>
                    <MenuItem key={0}>Job Definitions</MenuItem>
                    <MenuItem>English Language (India)</MenuItem>
                    <MenuItem>Select</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '24.56rem',
                alignItems: 'center',
                marginBottom: '1.87rem',
                height: '10rem',
                borderRadius: '0.625rem',
                border: '1px solid #C1C0C0',
              }}>
              <div style={{ marginLeft: '1.87rem' }}>
                <Typography sx={{ fontWeight: 400, color: '#333' }}>
                  Preferred Language - English (India)
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: '1rem', color: '#868686' }}>
                  Languages you understand and use to create and consume content.
                </Typography>
                <div>
                  <Select
                    className='input-field'
                    sx={{
                      width: '26.3125rem',
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '10px',
                      marginTop: '0.62rem',
                    }}
                    IconComponent={() => (
                      <DropDownArrow
                        style={{
                          marginRight: '5px',
                          width: '0.9375rem',
                          height: '0.9375rem',
                        }}
                      />
                    )}
                    size='small'
                    labelId='jobDefinitions'
                    name='jobDefinitions'
                    id='jobDefinitions-select'>
                    <MenuItem key={0}>Job Definitions</MenuItem>
                    <MenuItem>English Language (India)</MenuItem>
                    <MenuItem>Select</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <Typography style={{ fontSize: '1.25rem', fontWeight: 600 }}>
              Region & Timezone
            </Typography>
            <Typography
              style={{
                fontSize: '1rem',
                fontWeight: 400,
                color: '#868686',
                marginBottom: '1.25rem',
              }}>
              Region-specific & Timezone settings and configurations.
            </Typography>
            <div
              style={{
                display: 'flex',
                gap: '24.56rem',
                alignItems: 'center',
                marginBottom: '1.87rem',
                height: '22.5625rem',
                borderRadius: '0.625rem',
                border: '1px solid #C1C0C0',
              }}>
              <div style={{ marginLeft: '1.87rem' }}>
                <Typography sx={{ fontWeight: 400, color: '#333' }}>Date Format</Typography>
                <TextField
                  className='input-field'
                  type='date'
                  sx={{ width: '26.3125rem' }}
                  value={selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : ''}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    sx: {
                      height: '2.5rem',
                    },
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#333',
                    marginTop: '1.87rem',
                  }}>
                  Time Format
                </Typography>
                <div>
                  <Select
                    className='input-field'
                    sx={{
                      width: '26.3125rem',
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '10px',
                      marginTop: '0.62rem',
                    }}
                    value={timeFormat}
                    onChange={(e) => setTimeFormat(e.target.value as string)}
                    displayEmpty
                    IconComponent={() => (
                      <DropDownArrow
                        style={{
                          marginRight: '5px',
                          width: '0.9375rem',
                          height: '0.9375rem',
                        }}
                      />
                    )}>
                    <MenuItem value='12 hour'>12 hour</MenuItem>
                    <MenuItem value='24 hour'>24 hour</MenuItem>
                  </Select>
                </div>
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#333',
                    marginTop: '1.87rem',
                  }}>
                  Time Zone
                </Typography>
                <div>
                  <Select
                    className='input-field'
                    sx={{
                      width: '26.3125rem',
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '10px',
                      marginTop: '0.62rem',
                    }}
                    value={timeZone}
                    onChange={(e) => setTimeZone(e.target.value as string)}
                    displayEmpty
                    IconComponent={() => (
                      <DropDownArrow
                        style={{
                          marginRight: '5px',
                          width: '0.9375rem',
                          height: '0.9375rem',
                        }}
                      />
                    )}>
                    <MenuItem value='(UTC+5:30) Chennai, Kolkata, Mumbai, New Delhi'>
                      (UTC+5:30) Chennai, Kolkata, Mumbai, New Delhi
                    </MenuItem>
                    <MenuItem value='(UTC+1:00) London'>(UTC+1:00) London</MenuItem>
                    <MenuItem value='(UTC-5:00) New York'>(UTC-5:00) New York</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <Typography sx={{ fontSize: '1.1875rem', fontWeight: 600 }}>Currency</Typography>
            <Typography sx={{ fontSize: '1rem', fontWeight: 400, color: '#868686' }}>
              Change the way the currency is displayed. Changing the preferred currency will not
              update any monetary values already in your account.
            </Typography>
            <div
              style={{
                display: 'flex',
                gap: '24.56rem',
                alignItems: 'center',
                marginBottom: '1rem',
                height: '7.4375rem',
                borderRadius: '0.625rem',
                border: '1px solid #C1C0C0',
              }}>
              <div style={{ width: '319px', marginLeft: '2rem', marginRight: '28rem' }}>
                <Typography sx={{ fontWeight: 400 }}>Currency</Typography>
                <div>
                  <Select
                    className='input-field'
                    sx={{
                      width: '26.3125rem',
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '10px',
                      marginTop: '0.62rem',
                    }}
                    IconComponent={() => (
                      <DropDownArrow
                        style={{
                          marginRight: '5px',
                          width: '0.9375rem',
                          height: '0.9375rem',
                        }}
                      />
                    )}
                    size='small'
                    labelId='jobDefinitions'
                    name='jobDefinitions'
                    id='jobDefinitions-select'>
                    <MenuItem key={0}>Job Definitions</MenuItem>
                    <MenuItem>English Language (India)</MenuItem>
                    <MenuItem>Select</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};
export default General;
