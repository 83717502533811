import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  OutlinedInput,
  InputAdornment,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { getSjd } from '../../service/applicantService';
import PageHeader from '../common/page-header/page-header';
import './employee-referral.scss';
import { Loader } from '../common/loader';
import useNavigate from '../../common/useNavigate';
import { CANDIDATE_SOURCE } from '../../constants/common';

interface Skill {
  id: number;
  name: string;
}

interface JobDefinition {
  id: number;
  name: string;
  skills: {
    skill: Skill;
  }[];
  jobLocation: string | undefined;
  experienceLevel: number | undefined;
  description: string | undefined;
}

const itemsPerPageOptions = [15, 30, 45];

export default function EmployeeReferral() {
  const [navigate] = useNavigate();
  const [data, setData] = useState<JobDefinition[]>([]);
  const [selectedCard, setSelectedCard] = useState<JobDefinition | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState<JobDefinition[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(itemsPerPageOptions[0]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSJDs();
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = Math.min(startIndex + cardsPerPage, data.length);
    setFilteredData(data.slice(startIndex, endIndex));
  }, [currentPage, cardsPerPage, data]);

  const fetchSJDs = () => {
    setLoading(true);
    getSjd()
      .then((resp) => {
        const response = resp.data;
        setData(response);
        setFilteredData(response.slice(0, cardsPerPage));
      })
      .catch((_err) => {})
      .finally(() => setLoading(false));
  };

  const handleReferClick = (jobDefinition: JobDefinition) => {
    setSelectedCard(jobDefinition);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
    requestSearch(value);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = data.filter((jobDefinition) => {
      const combinedFields = [
        jobDefinition.name,
        jobDefinition.jobLocation,
        jobDefinition.experienceLevel,
        ...jobDefinition.skills.map((skillEntry) => skillEntry.skill.name),
      ]
        .join(' ')
        .toLowerCase();

      return combinedFields.includes(searchedVal.toLowerCase());
    });
    setFilteredData(filteredRows);
    setCurrentPage(1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleCardsPerPageChange = (event: SelectChangeEvent<number>) => {
    const newCardsPerPage = parseInt(event.target.value as string, 10);
    setCardsPerPage(newCardsPerPage);
    setCurrentPage(1);
  };

  const totalCards = data.length;
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = Math.min(startIndex + cardsPerPage, totalCards);

  return (
    <>
      <PageHeader title='Employee Referral' />
      <div className='search-bar'>
        <OutlinedInput
          id='job-search'
          type='text'
          placeholder='Search...'
          value={searchText}
          onChange={handleSearchChange}
          endAdornment={
            <InputAdornment position='end'>
              <Search />
            </InputAdornment>
          }
        />
      </div>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className='employee-referral'>
            {filteredData.map((jobDefinition, index) => (
              <Card key={index} className='card'>
                <CardContent>
                  <Typography variant='h5' component='div'>
                    {jobDefinition.name}
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    <strong>Job ID: </strong>
                    {jobDefinition.id}
                    <br />
                    <strong>Required Skills: </strong>
                    {jobDefinition.skills.map((skillEntry, skillIndex) => (
                      <span key={skillIndex}>
                        {skillEntry.skill.name}
                        {skillIndex === jobDefinition.skills.length - 1 ? '.' : ', '}
                      </span>
                    ))}
                    <br />
                    <strong>Job Location: </strong>
                    {jobDefinition.jobLocation}
                    <br />
                    <strong>Min Experience:</strong> {jobDefinition.experienceLevel}
                  </Typography>
                </CardContent>
                <CardActions className='card-actions'>
                  <Button
                    sx={{
                      position: 'absolute',
                      bottom: '0px',
                      right: '5px',
                    }}
                    type='submit'
                    className='grid-btn'
                    color='error'
                    size='small'
                    onClick={() => handleReferClick(jobDefinition)}>
                    Details
                  </Button>
                </CardActions>
              </Card>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div className='bottom-right-corner'>
              <div className='pagination-container'>
                <div className='cards-container'>
                  <span>Cards per page: </span>
                  <Select
                    value={cardsPerPage}
                    onChange={handleCardsPerPageChange}
                    variant='outlined'
                    className='cards-per-page-select no-outline'>
                    {itemsPerPageOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className='pagination-info'>
                  {`${startIndex + 1}-${endIndex} of ${totalCards} cards`}
                </div>
                <Pagination
                  count={Math.ceil(totalCards / cardsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          </div>
        </>
      )}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{selectedCard ? selectedCard.name : 'Card Details'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedCard && (
              <>
                <strong>Job ID: </strong>
                {selectedCard.id}
                <br />
                <strong>Required Skills: </strong>
                {selectedCard.skills.map((skillEntry, skillIndex) => (
                  <span key={skillIndex}>
                    {skillEntry.skill.name}
                    {skillIndex === selectedCard.skills.length - 1 ? '.' : ', '}
                  </span>
                ))}
                <br />
                <strong>Job Location: </strong>
                {selectedCard.jobLocation}
                <br />
                <strong>Min Experience:</strong> {selectedCard.experienceLevel}
                <br />
                <strong>Roles & Responsibilities: </strong>
                {selectedCard.description}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            Cancel
          </Button>
          <Button
            color='error'
            onClick={() =>
              navigate([
                {
                  label: 'Add Candidate',
                  link: `/source-job-definition/candidate/${btoa(
                    selectedCard ? selectedCard.id.toString() : '-1',
                  )}?source=${btoa(CANDIDATE_SOURCE.REFERRAL)}&&title=${btoa(
                    selectedCard ? selectedCard.name : '',
                  )}`,
                },
              ])
            }>
            Refer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
