import axiosClient from '../common/axiosClient';
import { CandidateDetailsModel } from '../models/candidate-form.model';

export async function getApplicants() {
  return axiosClient.get('/careers/applicants');
}

export async function getApplicantsForSjd(sjdId: any) {
  return axiosClient.get('/careers/applicants/sjd', { params: { sjdId } });
}

export async function getSjd() {
  return axiosClient.get('/careers/sjd');
}

export async function deleteApplicantMapping(sjdId: any, candidateId: any) {
  return axiosClient.delete('/careers/applicants/sjd', { params: { sjdId, candidateId } });
}

export async function saveApplicant(
  resume: any,
  request: CandidateDetailsModel,
  sjdId: string | undefined,
) {
  const formData = new FormData();
  delete request.resumeSkills;
  formData.append('resume', resume);
  formData.append('details', JSON.stringify(request));
  return axiosClient.post('/careers/applicants', formData, {
    params: { sjdId },
  });
}
