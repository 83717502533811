import { Paper, InputLabel, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import SectionHeaderWithEdit from '../../common/section-heading';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { auditCandidate } from '../../../service/candidateService';
import { filterSjdInfo, isPermissionsWrite } from '../../../common/commonUtil';
import { MODULE, SubModuleTypes } from '../../../constants/common';
import dateFormat from 'dateformat';

interface Props {
  sjd: any;
  candidate: any;
  setLoading: (isLoading: boolean) => void;
  reloadCandidate: () => void;
  alert: (msg: string, success: boolean) => void;
}

const CandidateAudit = ({ sjd, candidate, setLoading, reloadCandidate, alert }: Props) => {
  const [edit, setEdit] = useState(false);
  const [info, setInfo] = useState({} as any);
  const validationSchema = Yup.object().shape({
    auditResult: Yup.string().required('Please select a result'),
    auditComments: Yup.string()
      .required('Please enter comments')
      .max(500, 'comments should not exceed 500 characters'),
  });
  const formik = useFormik({
    initialValues: {
      auditResult: '',
      auditComments: '',
    },
    onSubmit: () => {
      saveAudit(formik.values);
    },
    validationSchema,
  });

  useEffect(() => {
    const i = filterSjdInfo(candidate.sjdCandidateInfo, sjd.id);
    setInfo(i);
    formik.setValues({
      auditResult: i.auditResult,
      auditComments: i.auditComments,
    });
  }, [candidate]);

  const saveAudit = (req: any) => {
    setLoading(true);
    auditCandidate({
      sjdId: sjd.id,
      candidateId: candidate.id,
      ...req,
    })
      .then((_resp) => {
        reloadCandidate();
        alert('Audit comments saved successfully', true);
      })
      .catch((_err) => {
        alert('Something went wrong. Please try later!', false);
      })
      .finally(() => {
        setLoading(false);
        setEdit(false);
      });
  };

  return (
    <div>
      <Paper sx={{ marginTop: 2, padding: [5, 2], boxShadow: 'none' }}>
        <SectionHeaderWithEdit
          text='Audit'
          edit={edit}
          hideIcon={!isPermissionsWrite(MODULE.SJD, SubModuleTypes.CANDIDATE_AUDIT)}
          click={() => {
            if (edit) {
              if (Object.keys(formik.touched).length > 0) formik.handleSubmit();
              else setEdit(false);
            } else {
              setEdit(true);
            }
          }}
        />
        <>
          <form style={{ marginBottom: '10px' }}>
            <div className='row'>
              <div className='w-10'>
                <InputLabel className='field-label' id='interviewLevel'>
                  Audit Result
                </InputLabel>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='auditResult'
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                  value={formik.values.auditResult?.toString()}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}>
                  <FormControlLabel
                    value={'true'}
                    control={
                      <Radio
                        disabled={!edit}
                        className={formik.values.auditResult?.toString() == 'true' ? 'pass' : ''}
                      />
                    }
                    label='Pass'
                  />
                  <FormControlLabel
                    value={'false'}
                    control={
                      <Radio
                        disabled={!edit}
                        className={formik.values.auditResult?.toString() == 'false' ? 'fail' : ''}
                      />
                    }
                    label='Fail'
                  />
                </RadioGroup>
                {formik.touched['auditResult'] && formik.errors['auditResult'] && (
                  <div className='mt-0 form-err-msg'>{formik.errors['auditResult']}</div>
                )}
              </div>

              <div className='interview-field w-60'>
                <InputLabel className='field-label' id='evaluator'>
                  Comments
                </InputLabel>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  size='small'
                  name='auditComments'
                  id='auditComments'
                  disabled={!edit}
                  onChange={formik.handleChange}
                  value={formik.values.auditComments}
                  onBlur={formik.handleBlur}></TextField>
                {formik.touched['auditComments'] && formik.errors['auditComments'] && (
                  <div className='mt-0 form-err-msg'>{formik.errors['auditComments']}</div>
                )}
              </div>
            </div>
            {!edit && info && info.auditBy && (
              <div className='row' style={{ marginTop: '10px' }}>
                <div style={{ width: '75%' }}></div>
                <div style={{ fontWeight: '600' }}>
                  <span>
                    - {info.auditBy.firstName} {info.auditBy.lastName}
                  </span>
                  <br />
                  &nbsp;
                  <span> {dateFormat(new Date(info.auditOn), 'dd/mm/yyyy hh:MM TT')}</span>
                </div>
              </div>
            )}
          </form>
        </>
      </Paper>
    </div>
  );
};

export default CandidateAudit;
