import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { getSkills } from '../../../service/lookupService';

interface props {
  handleClose: () => void;
  handleSubmit: (resp: SkillItem[]) => void;
  existingSkills?: any;
}
interface SkillItem {
  skill?: any;
  minExperience?: any;
}
const AddSJDSkills = ({ handleClose, handleSubmit, existingSkills }: props) => {
  const [allSkills, setAllSkills] = useState([] as any[]);

  const [skills, setSkills] = useState(
    existingSkills && existingSkills.length > 0 ? (existingSkills as SkillItem[]) : [{}],
  );

  useEffect(() => {
    getSkills()
      .then((resp) => setAllSkills(resp.data))
      .catch((_err) => setAllSkills([]));
  }, []);

  const addNewRow = () => {
    setSkills((skills) => [...skills, {}]);
  };

  const handleSkillSubmit = () => {
    handleClose();
    handleSubmit(skills);
  };

  const handleExperienceChange = (index: number, value: string) => {
    const temp = skills;
    temp[index].minExperience = value ? Number(value) : null;
    setSkills(() => [...temp]);
  };

  const handleSkillChange = (index: number, value: any) => {
    const temp = skills;
    temp[index].skill = value ? value : null;
    setSkills(() => [...temp]);
  };

  const handleDelete = (index: number) => {
    const newList = [...skills];
    newList.splice(index, 1);
    if (newList.length == 0) newList.push({});
    setSkills([...newList]);
  };

  return (
    <div>
      <Dialog open onClose={() => {}}>
        <DialogTitle id='alert-dialog-title'>Add Skills</DialogTitle>
        <Box>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Table>
              <TableBody>
                {skills.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <FormControl variant='standard' className='user-form'>
                        <Autocomplete
                          id='combo-box-demo'
                          options={allSkills}
                          sx={{ width: '200px !important', border: 'none' }}
                          size='small'
                          limitTags={2}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          value={item.skill ? item.skill : null}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField {...params} label='Select skill *' variant='standard' />
                          )}
                          onChange={(_e, val) => handleSkillChange(index, val)}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl variant='standard' className='user-form'>
                        <InputLabel>Min. Experience(years)</InputLabel>
                        <Input
                          id='skillMinExp'
                          name='skillMinExp'
                          value={item?.minExperience}
                          onChange={(e) => handleExperienceChange(index, e.target.value)}></Input>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Remove'>
                        <HighlightOffIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleDelete(index);
                          }}></HighlightOffIcon>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div style={{ justifyContent: 'right', display: 'flex', marginRight: '20px' }}>
              <Link onClick={addNewRow} sx={{ cursor: 'pointer' }}>
                Add more
              </Link>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Button className='default-btn' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className='default-btn bg-btn'
              onClick={handleSkillSubmit}
              sx={{ marginLeft: '10px' }}>
              Done
            </Button>
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddSJDSkills;
