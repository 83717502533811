import dateFormat from 'dateformat';
import React from 'react';
import { getActivityEventName, getNameFromId } from '../../../common/commonUtil';
import { LookupTypes } from '../../../common/lookupTypes';
import './timeline.scss';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import CommentIcon from '@mui/icons-material/Comment';
import CancelIcon from '@mui/icons-material/Cancel';
import PostAddIcon from '@mui/icons-material/PostAdd';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import { Tooltip } from '@mui/material';

export interface EventProps {
  eventTypeId: number;
  description: string;
  createdBy: string;
  createdOn: string;
}

interface TimeLineProps {
  events: EventProps[];
  parent: 'sjd' | 'candidate';
}

const TimeLine = ({ events, parent }: TimeLineProps) => {
  const candidateIconMap = new Map();
  candidateIconMap.set('CANDIDATE_CREATED', {
    icon: <PersonAddIcon fontSize='medium' />,
    descriptionLabel: 'Candidate Id: ',
  });
  candidateIconMap.set('CANDIDATE_TAGGED_TO_SJD', {
    icon: <LocalOfferIcon fontSize='medium' />,
    descriptionLabel: 'SJD Id: ',
  });
  candidateIconMap.set('CANDIDATE_UNTAGGED_FROM_SJD', {
    icon: <LabelOffIcon fontSize='medium' />,
    descriptionLabel: 'SJD Id: ',
  });
  candidateIconMap.set('RESUME_UPDATED', {
    icon: <FilePresentIcon fontSize='medium' />,
    descriptionLabel: '',
  });
  candidateIconMap.set('CANDIDATE_STATUS_CHANGED', {
    icon: <ArrowCircleRightIcon fontSize='medium' />,
    descriptionLabel: 'New Status: ',
  });
  candidateIconMap.set('CANDIDATE_DETAILS_UPDATED', {
    icon: <AssignmentTurnedInIcon fontSize='medium' />,
    descriptionLabel: '',
  });
  candidateIconMap.set('COMMENTS_ADDED', {
    icon: <MapsUgcIcon fontSize='medium' />,
    descriptionLabel: 'Comment:  ',
  });
  candidateIconMap.set('QC_RATING_ADDED', {
    icon: <StarBorderPurple500Icon fontSize='medium' />,
    descriptionLabel: 'Rating: ',
  });
  candidateIconMap.set('FEEDBACK_ADDED', {
    icon: <CommentIcon fontSize='medium' />,
    descriptionLabel: 'Comments: ',
  });
  candidateIconMap.set('PROFILER_RATING_ADDED', {
    icon: <StarBorderPurple500Icon fontSize='medium' />,
    descriptionLabel: 'Rating: ',
  });
  candidateIconMap.set('CANDIDATE_REJECTED', {
    icon: <CancelIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });
  candidateIconMap.set('NOT_INTERESTED', {
    icon: <DoDisturbOnIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });
  candidateIconMap.set('CANDIDATE_DROPPED', {
    icon: <CancelIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });
  candidateIconMap.set('CANDIDATE_ON_HOLD', {
    icon: <PauseCircleFilledIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });
  candidateIconMap.set('QUESTIONNAIRE_ANSWER_UPDATED', {
    icon: <LiveHelpIcon fontSize='medium' />,
  });
  candidateIconMap.set('INTERVIEW_SCHEDULED', {
    icon: <CalendarMonthIcon fontSize='medium' />,
    descriptionLabel: 'Schedule: ',
  });
  candidateIconMap.set('INTERVIEW_RESCHEDULED', {
    icon: <CalendarMonthIcon fontSize='medium' />,
    descriptionLabel: 'Schedule: ',
  });
  candidateIconMap.set('INTERVIEW_DELETED', {
    icon: <CalendarMonthIcon fontSize='medium' />,
    descriptionLabel: '',
  });
  candidateIconMap.set('CALLBACK_ADDED', {
    icon: <ContactPhoneIcon fontSize='medium' />,
    descriptionLabel: 'Reason: ',
  });

  const sjdIconMap = new Map();
  sjdIconMap.set('SJD_CREATED', {
    icon: <PostAddIcon fontSize='medium' />,
    descriptionLabel: 'SJD Id: ',
  });
  sjdIconMap.set('SJD_DETAILS_UPDATED', {
    icon: <AssignmentTurnedInIcon fontSize='medium' />,
    descriptionLabel: 'SJD Id: ',
  });
  sjdIconMap.set('CANDIDATE_ADDED', {
    icon: <LocalOfferIcon fontSize='medium' />,
    descriptionLabel: 'Candidate Id: ',
  });
  sjdIconMap.set('CANDIDATE_REMOVED', {
    icon: <LabelOffIcon fontSize='medium' />,
    descriptionLabel: 'Candidate Id: ',
  });

  sjdIconMap.set('SJD_STATUS_CHANGED', {
    icon: <ArrowCircleRightIcon fontSize='medium' />,
    descriptionLabel: 'New Status: ',
  });

  sjdIconMap.set('QUESTIONNAIRE_ATTACHED', {
    icon: <LiveHelpIcon fontSize='medium' />,
    descriptionLabel: 'Questionnaire Id: ',
  });

  const geticon = (eventTypeId: number) => {
    return parent == 'candidate'
      ? candidateIconMap.get(getNameFromId(eventTypeId, LookupTypes.CANDIDATE_EVENT_TYPE))['icon']
      : sjdIconMap.get(getNameFromId(eventTypeId, LookupTypes.SJD_EVENT_TYPE))['icon'];
  };

  const getDescription = (event: EventProps) => {
    const iconInfo =
      parent == 'candidate'
        ? candidateIconMap.get(getNameFromId(event.eventTypeId, LookupTypes.CANDIDATE_EVENT_TYPE))
        : sjdIconMap.get(getNameFromId(event.eventTypeId, LookupTypes.SJD_EVENT_TYPE));
    let text = '';
    if (iconInfo.descriptionLabel) {
      text = iconInfo.descriptionLabel;
      text =
        text +
        (event.description && event.description.length > 30
          ? event.description.slice(0, 30).concat('...')
          : event.description);
      return (
        <Tooltip title={event.description}>
          <p>{text}</p>
        </Tooltip>
      );
    }
    return <p></p>;
  };

  return (
    <div>
      <table>
        <tbody>
          {events &&
            events.length &&
            events.map((event, index) => (
              <React.Fragment key={index}>
                <tr className='content-row'>
                  <td>
                    <p>
                      {parent == 'sjd'
                        ? getActivityEventName(event.eventTypeId, LookupTypes.SJD_EVENT_TYPE)
                        : getActivityEventName(event.eventTypeId, LookupTypes.CANDIDATE_EVENT_TYPE)}
                    </p>
                    {getDescription(event)}
                  </td>
                  <td>
                    <div className='icon-element'>{geticon(event.eventTypeId)}</div>
                  </td>
                  <td>
                    <p>{event.createdBy}</p>
                    <p>on {dateFormat(new Date(event.createdOn), 'dd/mm/yyyy hh:MM TT')}</p>
                  </td>
                </tr>
                <tr className='connector-row'>
                  <td></td>
                  <td>
                    <div></div>
                  </td>
                  <td></td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TimeLine;
